import store from 'store2';

import { NonceData } from './types';

const parseJson = <T>(json: string): T | undefined => {
  try {
    return JSON.parse(json);
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export class ApiError extends Error {
  status?: number;
  constructor(message?: string | undefined, status?: number) {
    super(message);
    this.status = status;
  }
}

const fetcher = <T extends string>(path: string) => (authUrl: string): Promise<T> =>
  fetch(authUrl + path, { credentials: 'include' })
    .then(async res => {
      const dataText = (await res.text()) as T;

      if (!res.ok) {
        const dataJson = parseJson<{ detail?: string }>(dataText);
        const message = typeof dataJson?.detail === 'string' && dataJson.detail;
        throw new ApiError(message || undefined, res.status);
      }
      return dataText;
    })
    .catch(err => {
      if (err instanceof ApiError) throw err;
      throw new ApiError();
    });

export const authEndpoints = {
  '/token/check': () => fetcher<'true' | 'false'>('/token/check'),
  '/token/refresh': () => fetcher('/token/refresh'),
  '/token/revoke': () => fetcher('/token/revoke'),
  '/token': ({ code, redirect_uri, service }: { code: string; redirect_uri: string; service: string }) =>
    fetcher('/token?' + new URLSearchParams({ code, redirect_uri, service })),
  '/token/google': (code: string, redirect_uri: string) =>
    fetcher(`/token/google?code=${code}&redirect_uri=${redirect_uri}`),
  '/token/ms': (code: string, redirect_uri: string) => fetcher(`/token/ms?code=${code}&redirect_uri=${redirect_uri}`),
};

export const loginUrls = {
  '/auth': ({
    authUrl,
    redirect_uri,
    service,
    state,
  }: {
    state: string;
    authUrl: string;
    redirect_uri: string;
    service: string;
  }) => authUrl + '/auth?' + new URLSearchParams({ redirect_uri, service, state }),
  '/login/google': (authUrl: string, redirect_uri: string) => authUrl + '/login/google?redirect_uri=' + redirect_uri,
  '/login/ms': (authUrl: string, redirect_uri: string) => authUrl + '/login/ms?redirect_uri=' + redirect_uri,
};

export const localStore = {
  saveNonce: (nonce: string, data: NonceData) => {
    store.set(nonce, data);
  },
  getNonce: (nonce: string): undefined | NonceData => {
    return store.get(nonce);
  },
  deleteNonce: (nonce: string) => {
    store.remove(nonce);
  },
};
