import store from 'store2';

export const localStore = {
  save: (nonce: string, data: string) => {
    store.set(nonce, data);
  },
  get: (nonce: string): undefined | string => {
    return store.get(nonce);
  },
  delete: (nonce: string) => {
    store.remove(nonce);
  },
};