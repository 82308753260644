import { TargetColumnDefinition } from '../types'

export class EmailSupervisorRatio
{
  private emailUnique: Set<string>           = new Set();
  private emailSupervisorUnique: Set<string> = new Set();
  
  constructor()
  {
    this.clean();
  }

  clean = () => 
  {
    this.emailUnique           = new Set();
    this.emailSupervisorUnique = new Set();
  }

  processRow = (row: any[], rowIndex: number, email: TargetColumnDefinition, emailSupervisor: TargetColumnDefinition) =>
  {
    const emailValue           = row[email.sourceIndex!];
    const emailSupervisorValue = row[emailSupervisor.sourceIndex!];
    this.emailUnique.add(emailValue);
    this.emailSupervisorUnique.add(emailSupervisorValue);
  }

  getRatio = (): number => {
    return this.emailSupervisorUnique.size / this.emailUnique.size;
  }
}
