import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { applySpacingProps, SpacingProps } from '../../utils';
import { IconPlay } from '../icons';

import { TimelineContext } from './TimelineContext';
import { TimelineGroupButton } from './TimelineGroupButton';

type TimelineSize = 'small' | 'normal';

interface Props {
  className?: string;
  children?: React.ReactNode;
  size?: TimelineSize;
  allActive?: boolean;
  dark?: boolean;
  vertical?: boolean;
  onPlayPauseClick?: () => void;
  onAllClick?: () => void;
  spacing?: SpacingProps;
}

export const Timeline: FC<Props> = observer(props => {
  const {
    className,
    allActive,
    children,
    size = 'normal',
    onPlayPauseClick,
    onAllClick,
    dark,
    vertical,
    spacing,
  } = props;

  return (
    <Container className={className} size={size} vertical={vertical} spacing={spacing}>
      <TimelineContext.Provider value={{ allActive: false, dark, vertical }}>
        {onPlayPauseClick && <TimelineGroupButton label={<IconPlay />} onClick={onPlayPauseClick} />}
      </TimelineContext.Provider>

      <TimelineContext.Provider value={{ allActive, dark, vertical }}>
        {children}
        {onAllClick && <TimelineGroupButton label='All' onClick={onAllClick} active={allActive} />}
      </TimelineContext.Provider>
    </Container>
  );
});

const Container = styled.div<{ size: TimelineSize; vertical?: boolean; spacing?: SpacingProps }>`
  height: ${props => props.theme.timeFilter.largeHeight};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.size === 'small' &&
    css`
      height: ${props => props.theme.timeFilter.smallHeight};
    `}

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
      height: auto;
    `}

  ${props => applySpacingProps(props.spacing)}
`;
