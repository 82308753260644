import React, { FC } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import {
  IconAccountTree,
  IconBackupTable,
  IconCalendar,
  IconEmail,
  IconGroups,
  IconMarkEmailUnread,
  IconNightsStay,
  IconSlack,
  IconSociomap,
  IconSync,
  IconWellbeing,
} from '../icons';

interface Props {
  iconName: string;
  size?: number;
  className?: string;
}

export const Icon: FC<Props> = ({ iconName, size, className }) => {
  const commonProps = { size, className };

  switch (iconName) {
    case 'account_tree':
      return <IconAccountTree {...commonProps} />;
    case 'backup_table':
      return <IconBackupTable {...commonProps} />;
    case 'calendar':
      return <IconCalendar {...commonProps} />;
    case 'email':
      return <IconEmail {...commonProps} />;
    case 'groups':
      return <IconGroups {...commonProps} />;
    case 'mark_email_unread':
      return <IconMarkEmailUnread {...commonProps} />;
    case 'nights_stay':
      return <IconNightsStay {...commonProps} />;
    case 'slack':
      return <IconSlack {...commonProps} />;
    case 'sociomap':
      return <IconSociomap {...commonProps} />;
    case 'sync':
      return <IconSync {...commonProps} />;
    case 'wellbeing':
      return <IconWellbeing {...commonProps} />;

    default:
      return (
        <StyledIcon size={size} className={classNames('material-icons', className)}>
          {iconName}
        </StyledIcon>
      );
  }
};

const StyledIcon = styled.i.withConfig<{ size?: number }>({
  shouldForwardProp: prop => !['size'].includes(prop),
})`
  font-size: ${props => props.size || 24}px;
  max-width: ${props => props.size || 24}px;
  direction: rtl;
`;
