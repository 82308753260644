/* eslint-disable no-console */
import { Email } from './validatorEmail';
import {
  EmailIndexMap
} from '../types'

const checkNotEmpty = (value: any) => (typeof value !== 'string') || value.trim().length > 0;

export class EmailSupervisor
{
  private traversingLeft: number[]; // Employess except CEOs
  private emailIndexMap: EmailIndexMap = {
    processed: false,
    map: {}
  }
  private ceoRows: number[] = [];
  private employeesSupervisorRows: (number[] | null)[] = [];
  private validationSelf: number[];

  constructor(emailIndexMap: EmailIndexMap, employeesSupervisorRows: (number[] | null)[], ceoRows: number[])
  {
    this.traversingLeft = [];
    this.emailIndexMap = emailIndexMap;
    this.employeesSupervisorRows = employeesSupervisorRows;
    this.ceoRows = ceoRows;
    this.validationSelf = [];
  }

  processRow = (row: any[], rowIndex: number, colIndex: number) =>
  {
    const value = row[colIndex];
    if(value in this.emailIndexMap.map)
    {
      this.employeesSupervisorRows[rowIndex] = this.emailIndexMap.map[value];
      if(this.emailIndexMap.map[value].includes(rowIndex))
      {
        this.validationSelf.push(rowIndex);
      }
    }
    if(!this.ceoRows.includes(rowIndex))
    {
      this.traversingLeft.push(rowIndex);
    }
  }

  getSelfRows = (): number[] => {
    return this.validationSelf;
  }

  getCycledRows = (): number[] => {
    if(this.employeesSupervisorRows.length <= 1) return [];
    const pairs: ([number, (number | null)])[] = [];
    this.employeesSupervisorRows.forEach((indexSupervisor, index) => {
      if(indexSupervisor === null) {
        pairs.push([index, indexSupervisor]);
        return;
      }
      indexSupervisor.forEach(indexSupervisorOne => {
        pairs.push([index, indexSupervisorOne]);
      })
    })
    pairs.shift();

    const cycledChunks: number[][] = []
    const traversed: number[] = [];
    const indexes: number[] = [];
    while(pairs.length) {
      if (traversed.length === 0) {
        indexes.push(0);
      }
    
      const cycled = traversed.find(item => item === pairs[indexes[indexes.length - 1]][0]);
      if(cycled) {
        const cycledStartIndex = indexes.findIndex(index => index === indexes[indexes.length - 1]);
        const indexesCycled = [...indexes];
        if(cycledStartIndex + 1 < traversed.length) {
          indexesCycled.splice(0, cycledStartIndex + 1);
        }
        cycledChunks.push(pairs.filter((_pair, index) => indexesCycled.includes(index)).map(pair => pair[0]));
        Array.from(new Set(indexes)).reverse().forEach(index => {
          pairs.splice(index, 1);
        });
        indexes.length = 0;
        traversed.length = 0;
        continue;
      }
    
      traversed.push(pairs[indexes[indexes.length - 1]][0]);
      const nextIndex = pairs.findIndex(item => item[0] === pairs[indexes[indexes.length - 1]][1]);
      if(nextIndex !== -1) {
        indexes.push(nextIndex);
        continue;
      } else {
        indexes.forEach(index => pairs.splice(index, 1));
        indexes.length = 0;
        traversed.length = 0;
      }
    }
    return (Array.from(new Set(cycledChunks.flat())));
  }

  static empty = (row: any[], colIndex: number) => Email.empty(row, colIndex);
  
  static format = (row: any[], colIndex: number) => Email.format(row, colIndex);

  static missing = (row: any[], colIndex: number, usedEmails: string[]) => {
    const value = row[colIndex];
    return !checkNotEmpty(value) || usedEmails.indexOf(value) === -1;
  }
}
