import * as patterns from '../patterns'

export const emailCheckFormat = (value: any) => (typeof value === 'string') && patterns.emailRE.test(value.trim());
export const emailCheckEmpty  = (value: any) => (((typeof value === 'string') && value.trim() === '') || typeof value === 'undefined' || value === null);

export class Email
{
  static empty = (row: any[], colIndex: number) => {
    const value = row[colIndex];
    return emailCheckEmpty(value);
  }
  
  static format = (row: any[], colIndex: number) => {
    const value = row[colIndex];
    return emailCheckEmpty(value) || emailCheckFormat(value);
  }
}