import React, { FC, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react-lite';

const MODAL_ROOT_ID = 'MODAL_ROOT_ID';

interface Props {
  children?: React.ReactNode;
  open?: boolean;
  onClose: () => void;
}

export const BaseModal: FC<Props> = observer(({ children, open, onClose }) => {
  const modalRoot = useRef(document.getElementById(MODAL_ROOT_ID));

  useEffect(() => {
    const onEscape = (event: KeyboardEvent) => {
      if (event.key === 'Esc' || event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', onEscape);

    return () => window.removeEventListener('keydown', onEscape);
  }, [onClose]);

  if (!modalRoot.current) {
    modalRoot.current = document.createElement('div');
    modalRoot.current.setAttribute('id', MODAL_ROOT_ID);
    document.body.appendChild(modalRoot.current);
  }

  if (!open) return null;

  return ReactDOM.createPortal(children, modalRoot.current);
});
