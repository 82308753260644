import * as React from 'react';
import styled from 'styled-components';
import { Button as ButtonUI } from './ui/components';

type UploadProps = {
  arrayBuffer?: boolean;
  onChange: (fileRef: File) => void;
  onLoading?: () => void;
  accept?: string;
  progress?: string;
  buttonText?: string;
  disabled?: boolean;
}

export interface UploadState
{
  loading: boolean;
  loaded: boolean;
  filename: string | null;
}

const getDefaultState = (): UploadState => {
  return {
    loading: false,
    loaded: false,
    filename: null,
  }
}

const InputFile = styled.input`
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
`;

export class Upload extends React.Component<UploadProps, UploadState> 
{
  constructor(props: Readonly<UploadProps>)
  {
    super(props);
    this.state      = getDefaultState();
  }

  private selectFile = (event: React.ChangeEvent<HTMLInputElement>) =>
  {
    if(this.props.disabled)
    {
      console.warn('File(s) cannot select - disabled');
      return;
    }
    if(!event.target.files || event.target.files.length === 0)
    {
      this.setState(getDefaultState());
      console.warn('No files!');
      return;
    }
    if(this.props.onLoading)
    {
      this.props.onLoading();
    }
    const fileRef = event.target.files[0];
    this.props.onChange(fileRef);
  }

  render()
  {
    const opts = (this.props.accept) ? { 'accept': this.props.accept } : {};
    return (
      <ButtonUI>
        <>
          {this.props.buttonText ? this.props.buttonText : 'Browse Files'}
          <InputFile type="file" {...opts} className="upload-input" onChange={this.selectFile} onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            if(this.props.disabled) 
            {
              e.preventDefault();
              return false;
            }
          }} />
        </>
      </ButtonUI>
    )
  }
}