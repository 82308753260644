import React, { FC, Fragment } from 'react';
import styled, { css } from 'styled-components';
import NavigateBefore from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNext from '@material-ui/icons/NavigateNextOutlined';
import Check from '@material-ui/icons/Check';
import { theme } from '../../ui/theme';
import { Button } from '../../Button'
import { lang } from '../../../libs'
import { bypassValidation } from '../../../rest';

interface ButtonProps {
  onClick: () => any;
  title?: string;
  disabled: boolean;
}

interface Prop {
  step: number;
  finished?: boolean;
  prevButton?: ButtonProps;
  nextButton?: ButtonProps;
}

const ProgressBarWrapper = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 40px;
  top: 54px;
  background: #FFFFFF;
  z-index: 100;
  user-select: none;
  justify-content: space-between;
  box-shadow: 0px 3px 6px rgba(69, 90, 100, 0.16);
  padding: 0px 10px;
  align-items: center;
`;

const ProgressBarElement = styled.div`
  display: block;
  width: 472px;
`;

const ProgressBarItems = styled.div`
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface StepProps extends React.HTMLAttributes<unknown>
{
  done?: boolean;
  active?: boolean;
  text?: boolean;
  separator?: boolean;
  isStep?: boolean;
  skipped?: boolean;
}

const Step: React.FC<StepProps> = styled.div`
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;


  ${(props: StepProps) => props.isStep && css`
    border: 1px solid gray;
    border-radius: 15px;
    color: #000000;
    width: 18px;
    height: 18px;
  `}

  ${(props: StepProps) => (props.done || props.active) && css`color: #FFF;`}
  
  ${(props: StepProps) => props.active && css`
    background-color: ${theme.appColors.brandDark};
    border-color: ${theme.appColors.brandDark};
  `}

  ${(props: StepProps) => props.done && css`
    background-color: #7EC53C;
    border-color: #7EC53C;
    padding: 3px 1px 1px;
  `}

  ${(props: StepProps) => props.text && css`
    font-size: 15px;
    color: ${theme.colors.darkGray};
    margin-left: 10px;
    color: #333;
  `}
  
  ${(props: StepProps) => props.separator && css`
    color: ${theme.colors.mediumGray};
    margin-top: -4px;
  `}

  ${(props: StepProps) => props.skipped && css`
    opacity: .4;
  `}
`;

type ButtonWrapperProps = {
  justify: 'flex-start' | 'center' | 'flex-end';
}

const ButtonWrapper: React.FC<ButtonWrapperProps> = styled.div`
  display: flex;
  justify-content: ${(props: ButtonWrapperProps) => props.justify};
  width: 25%;
`

const steps: string[] = [lang.progressStep1, lang.progressStep2, lang.progressStep3, lang.progressStep4];
const checkMark = <div><Check fontSize="inherit" /></div>;

export const ProgressBar: FC<Prop> = ({ 
  step, 
  finished,
  prevButton,
  nextButton,
  children 
}) => (
  <ProgressBarWrapper>
    <ButtonWrapper justify='flex-start'>
      {prevButton ? (
        <Button 
          size="small" 
          type="outlined" 
          onClick={prevButton.onClick} 
          text={lang.buttonPrev} 
          disabled={prevButton.disabled} 
          prepend={<NavigateBefore fontSize="inherit" />}
          className="button-prev"
        />
      ): <>{'\u00A0'}</>}
    </ButtonWrapper>
    <ProgressBarElement>
      <ProgressBarItems>
        {steps.map((title: string, index: number) => {
          const stepCurrent = index + 1;
          let stepTitle: string | JSX.Element = stepCurrent.toString();
          const props: StepProps = { isStep: true };
          const isDone = (stepCurrent < step) || finished;
          const isActive = (stepCurrent === step);
          if(isDone)
          {
            props.done = true;
            stepTitle = checkMark;
          }
          else if(stepCurrent === step)
          {
            props.active = true;
          }
          return (
            <Fragment key={`progress-bar-step-${index}`}>
              <Step className={`step ${isActive && `active`}`} skipped={bypassValidation && stepCurrent === 3} {...props}>{stepTitle}</Step>
              <Step text skipped={bypassValidation && stepCurrent === 3}>{title}</Step>
              {stepCurrent < 4 ? <Step separator>....</Step> : null}
            </Fragment>
          )
        })}
      </ProgressBarItems>
      {children}
    </ProgressBarElement>
    <ButtonWrapper justify='flex-end'>
      {nextButton ? (
        <Button 
          size="small" 
          onClick={nextButton.onClick} 
          text={lang.buttonNext} 
          disabled={nextButton.disabled} 
          type={nextButton.disabled ? 'text' : 'contained'}
          append={<NavigateNext />}
          className="button-next"
        />
      ) : <>{'\u00A0'}</>}
    </ButtonWrapper>
  </ProgressBarWrapper>
);