import React from 'react';
import styled from 'styled-components';
import PeopleAlt from '@material-ui/icons/PeopleAltOutlined';
import { theme } from './ui';

interface MappingOverviewProps
{
  title: string;
  titleReupload: string;
  onClick: () => void;
}

const OverviewWrapper = styled.div`
  padding: 55px 0 35px;
`;

const OverviewText = styled.div`
  text-align: center;
`;

const OverviewIcon = styled.div`
  vertical-align: middle;
  display: inline-block;
  margin-right: 6px;
`;

const OverviewLink = styled.span`
  border-bottom-width: 1px;
  border-color: ${theme.colors.veryLightGray};
  border-style: solid;
  color: ${theme.colors.blue};


  &:hover {
    border-color: ${theme.colors.blue};
    cursor: pointer;
  }
`;

export const MappingOverview: React.FC<MappingOverviewProps> = ({
  title,
  titleReupload,
  onClick
}) => {
  return (
    <OverviewWrapper>
      <OverviewText>
        <OverviewIcon><PeopleAlt /></OverviewIcon>
        {title}
        <OverviewLink onClick={onClick}>{titleReupload}</OverviewLink>
      </OverviewText>
    </OverviewWrapper>
  )
}