import React from 'react';
import styled from 'styled-components';

import { SpacingProps, applySpacingProps } from '../../utils';

interface LineProps {
  spacing?: SpacingProps;
}
const LineComponent = ({ spacing, ...props }: LineProps) => <hr {...props} />;

export const Line = styled(LineComponent)`
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  margin-left: 12px;
  margin-right: 12px;

  ${props => applySpacingProps(props.spacing)}
`;
