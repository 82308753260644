import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../ui/theme';
import ArrowDropDown from '@material-ui/icons/ArrowDropDownOutlined';
import { lang } from '../../../libs';
import { 
  Button, 
} from '../../Button';

interface UploaderTabSelectProps
{
  onSelect: (id: number) => void;
  onBack: () => void;
  tabsList: string[];
}

const LoadedWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 20px 0;
`;

const Title = styled.p`
  color: ${theme.colors.darkGray};
  font-weight: bold;
`;

const Description = styled(Title)`
  padding: 15px 0 7px;
  font-weight: normal;
  font-size: 13px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 15px;
  width: 100%;
  height: 70px;
  user-select: none;  
`;

const SelectContainer = styled.div`
  width: 100%;
`;

type SelectWrapperProps = {
  active?: boolean;
  onClick?: () => void;
}

const SelectWrapper: React.FC<SelectWrapperProps> = styled.div`
  width: 100%;
  padding: 9px 10px;
  background-color: ${(props: SelectWrapperProps) => props.active ? theme.colors.extraLightGray : theme.colors.superLightGray};
  cursor: pointer;
`;

const Select = styled.div`
  font-size: 13px;
  color: ${theme.colors.gray};
  display: flex;
  justify-content: space-between;
`;

type OptionsProps = {
}

const Options: React.FC<OptionsProps> = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  max-height: 50vh;
  background-color: ${theme.colors.white};
  overflow-y: auto;
  z-index: 2;
`;

type OptionWrapperProps = SelectWrapperProps & {
}

const OptionWrapper: React.FC<OptionWrapperProps> = styled(SelectWrapper)`
  margin-top: 1px;
`;

const Option = styled(Select)`
  color: ${theme.colors.mediumGray};
`;

const IconArrow: React.FC = styled.div`
  color: ${theme.colors.gray};
  &:hover {
    color: ${theme.colors.darkGray};
  }
`;

export const UploaderTabSelect: React.FC<UploaderTabSelectProps> = ({
  onSelect,
  onBack,
  tabsList,
}) => {
  const [tabSelected, setTabSelected] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const selectToggle = () => setIsOpen(!isOpen);

  const confirm = () => {
    onSelect(tabSelected);
  }

  const selectTab = (id: number) => {
    setTabSelected(id);
    setIsOpen(false);
  }

  return (
    <LoadedWrapper className="loaded-wrapper">
      <Title>{lang.titleMultipleTabs}</Title>
      <Description>{lang.descMultipleTabs}</Description>
      <SelectContainer>
        <SelectWrapper active={isOpen}>
          <Select
            onClick={selectToggle}
          >
            {tabsList[tabSelected]}
            <IconArrow>
              <ArrowDropDown fontSize="inherit" />
            </IconArrow>
          </Select>
        </SelectWrapper>
        <Options>{(isOpen && tabsList.map((tabName: string, index: number) => {
          return (
            <OptionWrapper key={`tab-${index}`} onClick={() => selectTab(index)}>
              <Option>{tabName}{index === tabSelected ? ` (Selected)` : ``}</Option>
            </OptionWrapper>
          );
        }))}</Options>
      </SelectContainer>
      <ActionsWrapper>
        <Button 
          onClick={onBack} 
          text={lang.buttonReupload}
          disabled={false}
          type="text"
          className="button-left"
        />
        <Button 
          onClick={confirm} 
          text={lang.buttonSelectTab} 
          disabled={false} 
          type="contained"
          className="button-right"
        />
      </ActionsWrapper>
    </LoadedWrapper>
  )
}