import * as React from 'react'
import { Fragment } from 'react'
import styled from 'styled-components';
import { BoxActive } from '../../../components'

export interface ValidatorProgressProps
{
  title: string | null;
  description: string | null;
}

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
`;

const Loader = styled.div`
  margin: 128px 0 26px;
`;

const Title = styled.p`
  text-align: center;
  margin-bottom: 8px;
  /* margin: 204px 0 128px; */
`;

const Description = styled(Title)`
  margin-bottom: 108px;
`;

export const ValidatorProgress: React.FC<ValidatorProgressProps> = ({
  title,
  description
}) => {
  return (
    <Fragment>
      <Wrapper>
        <Loader>
          <BoxActive />
        </Loader>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Wrapper>
    </Fragment>
  )
}