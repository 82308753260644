import * as React from 'react'
import { Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { RouteList } from '../../screens'
import {
  Header,
  Title,
  WrapperMain,
  UploaderWrapper,
  UploaderContent,
  UploaderProgress,
  UploaderLoaded,
  Container,
  Screen,
  ModalDeleteFile,
  notification,
} from '../../components'
import { 
  lang,
  langErrorMsg,
  StoreHR,
  UploadFile,
  AllowedMIMETypes,
  UploadedFile,
  pathPush,
  CLIENT_STORAGE_KEY,
  localStore,
  FILE_TRANSFER_STORAGE_KEY,
  isFileUploadPart,
  getClientId,
  setClientId,
} from '../../libs';
import { userKey } from '../../user';

interface WelcomeFileUploadProps extends RouteComponentProps<{
  organizationId: string;
}> 
{
}

interface WelcomeFileUploadState
{
  loading: boolean;
  loaded: boolean;
  dragging: boolean;
  uploadFile: null | UploadFile;
  uploadFileDelete: boolean;
  uploadFilename: null | string;
  uploadError: null | string;
}

const typeExtensions: Record<AllowedMIMETypes, string> = {
  'text/csv'                                                         : '.csv',
  'application/vnd.ms-excel'                                         : '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
  'application/zip'                                                  : '.zip'
}

export class WelcomeFileUpload extends React.Component<WelcomeFileUploadProps, WelcomeFileUploadState>
{
  private  dragCounter: number                   = 0;
  private  uploadArrayBuffer: boolean            = true;
  readonly uploadLimitMB: number                 = 2500;
  readonly uploadMIMEAllowed: AllowedMIMETypes[] = (Object.keys(typeExtensions) as AllowedMIMETypes[]);

  constructor(props: Readonly<WelcomeFileUploadProps>)
  {
    super(props);
    this.state = this.getDefaultState();
  }

  protected getDefaultState(): WelcomeFileUploadState
  {
    return {
      loading: false,
      loaded: false,
      dragging: false,
      uploadFile: null,
      uploadFilename: null,
      uploadFileDelete: false,
      uploadError: null,
    }
  }

  componentDidMount()
  {
    const { organizationId } = this.props.match.params;
    localStore.save(CLIENT_STORAGE_KEY, organizationId);
    localStore.save(FILE_TRANSFER_STORAGE_KEY, isFileUploadPart().toString());
    setClientId(organizationId);
    const data: UploadedFile | null = StoreHR.getUploadedFile();
    if(data !== null)
    {
      this.setState({loaded: true});
    }
  }

  private onUploaded = (file: UploadFile) =>
  {
    StoreHR.setUploadedFile((file as UploadedFile));
    this.setState({loaded: true, loading: false, dragging: false});
    pathPush(this.props.history, RouteList.finishFileUpload.path.replace(':organizationId', getClientId() as string));
  }

  private getUploadFile = (fileRef: File): UploadFile => 
  {
    const fileType  = fileRef.type;
    const nameParts = fileRef.name.split('.');
    const extension = nameParts.length ? nameParts[nameParts.length - 1] : '';
    nameParts.splice(nameParts.length - 1, 1);
    const uploadFile: UploadFile = {
      name: fileRef.name,
      namePart: nameParts.join('.'),
      extPart: extension,
      content: null,
      file: fileRef,
      type: (fileType as AllowedMIMETypes)
    }
    return uploadFile;
  }

  private readFile = (fileRef: File) =>
  {
    if(this.state.loading)
    {
      console.warn('dropHandler Bussy!');
      return;
    }
    const uploadFile: UploadFile = this.getUploadFile(fileRef);
    if(fileRef.size > (this.uploadLimitMB * 1000000))
    {
      notification(`File is bigger than ${this.uploadLimitMB} MB`, {type: `error`});
      this.dragCounter = 0;
      this.setState({loading: false, dragging: false, uploadError: langErrorMsg.invalidSize});
      return;
    }
    this.onUploaded(uploadFile);
  }

  private dropHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    event.preventDefault();
    if(this.state.loading) return;
    let droppedFile = null;
    if(event.dataTransfer.items)
    {
      for(let i = 0; i < event.dataTransfer.items.length; i++)
      {
        if(event.dataTransfer.items[i].kind !== 'file')
        {
          continue;
        }
        const file = event.dataTransfer.items[i].getAsFile();
        if(file === null)
        {
          continue;
        }
        droppedFile = file;
      }
    }
    else
    {
      for(let i = 0; i < event.dataTransfer.files.length; i++)
      {
        droppedFile = event.dataTransfer.files[i];
      }
    }
    if(droppedFile === null)
    {
      this.setState({dragging: false});
      return;
    }
    this.readFile(droppedFile);
  }

  private dragEnterHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    if(this.state.loading) return;
    if(this.dragCounter++ === 0) this.setState({dragging: true});
    event.preventDefault();
  }

  private dragExitHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    if(this.state.loading) return;
    if(--this.dragCounter === 0) this.setState({dragging: false});
    event.preventDefault();
    return;
  }

  private getUploderContent()
  {
    return (
      <UploaderContent 
        dragging={this.state.dragging}
        uploadError={this.state.uploadError}
        uploadLimitMB={this.uploadLimitMB}
        accept="*"
        readFile={this.readFile}
        onLoading={() => {
          this.setState({loading: true});
        }}
        uploadArrayBuffer={this.uploadArrayBuffer}
        loading={this.state.loading}
        title={lang.descUploadFile}
      />
    )
  }

  private getLoaderContent()
  {
    return (
      <UploaderProgress
        title={this.state.uploadFilename}
      />
    )
  }

  private getLoadedContent()
  {
    const uploadedFile: UploadedFile | null = StoreHR.getUploadedFile()!;
    const uploadedFileExt: string = uploadedFile.extPart;
    return (
      <Fragment>
        <UploaderLoaded 
          name={uploadedFile.name} 
          extension={uploadedFileExt} 
          onClick={() => {
            this.setState({uploadFileDelete: true});
          }}
        />
        {this.state.uploadFileDelete ? this.getDeleteFileModal() : null}
      </Fragment>
    )
  }

  private getDeleteFileModal()
  {
    const uploadedFile: UploadedFile | null = StoreHR.getUploadedFile()!;
    return (
      <ModalDeleteFile 
        name={uploadedFile.name}
        onCancel={() => this.setState({uploadFileDelete: false})}
        onConfirm={() => {
          StoreHR.resetUploadedFile();
          StoreHR.resetMapping();
          userKey.unsetDone();
          this.setState(this.getDefaultState());
        }}
        onClose={() => this.setState({uploadFileDelete: false})}
      />
    )
  }

  render()
  {
    const { organizationId } = this.props.match.params;
    const content = (this.state.loaded) ? this.getLoadedContent() : ((this.state.loading) ? this.getLoaderContent() : this.getUploderContent());
    return (
      <Container>
        <Header organizationId={organizationId} isFileTransfer />
        <Screen topOffset={false}>
        <WrapperMain>
          <Title title={`1. ${lang.progressStep1TitleTransfer}`}/>
          <UploaderWrapper 
            onDrop={this.dropHandler} 
            onDragEnter={this.dragEnterHandler} 
            onDragOver={(event: any) => event.preventDefault()}
            onDragLeave={this.dragExitHandler}
          >
            {content}
          </UploaderWrapper>
        </WrapperMain>
        </Screen>
    </Container>
    )
  }
}

