import { css } from 'styled-components';

interface TransitionOptions {
  duration?: string;
  timingFunction?: string;
  delay?: string;
}

export function transition(properties: string, options: TransitionOptions = {}) {
  return css`
    transition-property: ${properties};
    will-change: ${properties};
    transition-delay: ${options.delay};
    transition-duration: ${props => options.duration || props.theme.transition.duration};
    transition-timing-function: ${props => options.timingFunction || props.theme.transition.timingFunction};
  `;
}
