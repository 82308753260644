import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { TableContext } from './TableContext';

interface Props {
  className?: string;
}

export const TableBody: FC<Props> = observer(props => {
  const { scrollable } = useContext(TableContext);

  return <StyledTableBody {...props} scrollable={scrollable} />;
});

export const StyledTableBody = styled.tbody.withConfig<{ scrollable?: boolean }>({
  shouldForwardProp: prop => !['scrollable'].includes(prop),
})`
  display: block;

  ${props =>
    props.scrollable &&
    css`
      overflow-x: hidden;
      overflow-y: auto;
    `}
`;
