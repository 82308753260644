import React, { FC } from 'react';
import styled from 'styled-components';
import OfflineBolt from '@material-ui/icons/OfflineBoltOutlined';
import PeopleAlt from '@material-ui/icons/PeopleAltOutlined';
import { 
  Modal,
  ButtonProps,
} from '../../../components';
import { DataContainer, lang } from '../../../libs';
import { publicURL } from '../../../rest';

interface Prop {
  dataContainer: DataContainer;
  detectedColumns: string[] | null;
  onClose: () => any;
  leftButton: ButtonProps;
  rightButton: ButtonProps;
}

const Wrapper = styled.div`
  padding-left: 145px;
  width: 100%;
  min-height: 260px;
  background-image: url("${publicURL}/images/Import-HR-table-results.svg");
  background-repeat: no-repeat;
  background-position: left center;
`;

const Title = styled.h2`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 12px;
`;

const TitleDescription = styled.p`
  font-size: 13px;
  width: 280px;
  margin-bottom: 40px;
`;

const Block = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const TextPart = styled.div``;

const Icon = styled.div`
  width: 32px;
  height: 75px;
`;

const Header = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
`;

interface DecriptionProps
{
  last?: boolean;
}

const Decription: React.FC<DecriptionProps> = styled.p`
  font-size: 13px;
  margin-bottom: ${(props: DecriptionProps) => props.last ? `24` : `40`}px;
`;

export const ModalMapping: FC<Prop> = ({
  dataContainer,
  detectedColumns,
  onClose,
  leftButton,
  rightButton,
}) => {
  const data = dataContainer.getData();
  const employess = (data.length) ? (data.length - 1).toString() : lang.generalNo;
  const detectedColumnsCount = detectedColumns === null || detectedColumns.length === 0 ? lang.generalNo : detectedColumns.length.toString();
  const detectedColumnsColumns = detectedColumns === null || detectedColumns.length === 0 ? lang.generalNone : detectedColumns.join(', ');
  return (
    <Modal 
      title={lang.titleHRTableImported}
      leftButton={leftButton}
      rightButton={rightButton}
      justify="left"
      onClose={onClose}
      closeOnBackground={false}
    >
      <Wrapper>
        <Title>{lang.titleHRTableImportedColumns}</Title>
        <TitleDescription>{lang.descHRTableImportedColumns}</TitleDescription>
        <Block>
          <Icon>
            <PeopleAlt />
          </Icon>
          <TextPart>
            <Header>{lang.titleHRTableImportedEmployees.replace('%1', employess)}</Header>
            <Decription>{lang.descHRTableImportedEmployees}</Decription>
          </TextPart>
        </Block>
        <Block>
          <Icon>
            <OfflineBolt />
          </Icon>
          <TextPart>
            <Header>{lang.titleHRTableImportedDetected.replace('%1', detectedColumnsCount)}</Header>
            <Decription last>{lang.descHRTableImportedDetected.replace('%1', detectedColumnsColumns)}</Decription>
          </TextPart>
        </Block>
      </Wrapper>
    </Modal>
  )
}
