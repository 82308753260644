import React, { Ref } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { focus, applySpacingProps, SpacingProps, transition } from '../../utils';

interface Props {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  title?: string;
  onClick?: () => void;
  spacing?: SpacingProps;
}

export const BaseButton = observer<Props, HTMLAnchorElement | HTMLButtonElement>(
  (props, ref) => {
    const { className, children, disabled, title, onClick, spacing } = props;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      // Remove button focus after mouse click in chrome
      const isKeyboardClick = e.screenX === 0 && e.screenY === 0;
      if (!isKeyboardClick) {
        e.currentTarget.blur();
      }

      if (onClick) onClick();
    };

    return (
      <Button
        className={className}
        disabled={disabled}
        title={title}
        onClick={handleClick}
        spacing={spacing}
        ref={ref as Ref<HTMLButtonElement>}
      >
        {children}
      </Button>
    );
  },
  { forwardRef: true }
);

const Button = styled.button.withConfig<{ spacing?: SpacingProps }>({
  shouldForwardProp: prop => !['spacing'].includes(prop),
})`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  color: inherit;

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${props =>
    props.disabled &&
    css`
      &,
      &:hover {
        cursor: not-allowed;
      }
    `}

  ${props => applySpacingProps(props.spacing)}
  ${focus}
  ${transition('box-shadow')}
`;
