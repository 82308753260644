import React from 'react';
import styled, { css } from 'styled-components';

import { Theme, ThemeColor } from '../../theme';
import { SpacingProps, applySpacingProps } from '../../utils';

interface Props {
  color?: ThemeColor;
  size?: keyof Theme['fontSize'];
  align?: 'left' | 'center' | 'right';
  fontWeight?: keyof Theme['fontWeight'];
  fontStyle?: 'normal' | 'italic';
  inline?: boolean;
  ellipsis?: boolean;
  spacing?: SpacingProps;
  grow?: boolean;

  children?: React.ReactNode;
}

export const Text = styled.div.withConfig<Props>({
  shouldForwardProp: prop =>
    !['color', 'size', 'align', 'fontWeight', 'fontStyle', 'inline', 'ellipsis', 'spacing', 'grow'].includes(prop),
})`
  color: ${props => props.theme.getColor(props.color || 'gray')};
  font-size: ${props => props.theme.fontSize[props.size || 'small']};
  font-weight: ${props => props.theme.fontWeight[props.fontWeight || 'normal']};
  font-style: ${props => props.fontStyle || 'normal'};
  line-height: ${props => props.theme.lineHeight};

  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  ${props =>
    props.inline &&
    css`
      display: inline;
    `}

  ${props =>
    props.grow &&
    css`
      flex-grow: 1;
    `}

  ${props =>
    props.ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${props => applySpacingProps(props.spacing)};
`;
