import XLSX from 'xlsx';
import { dateCheckEmpty } from './validators'
import { transpose } from './utils'

type Props = {
  columnsLimit: number;
}

export class Reader 
{
  private  loaded      : boolean;
  private  workbook    : XLSX.WorkBook | undefined;
  readonly columnsLimit: number;

  constructor(props: Props)
  {
    this.columnsLimit = props.columnsLimit;
    this.loaded = false;
  }

  load(fileContent: ArrayBuffer | string): boolean
  {
    this.loaded = false;
    const type = ((typeof fileContent) === 'string') ? 'string' : 'array';
    try
    {
      this.workbook    = XLSX.read(fileContent, { type, cellDates: false, raw: true });
      this.isValidWorkbook(this.workbook);
      this.loaded = true;
    }
    catch(error)
    {
      this.loaded = false;
      throw new Error(error instanceof Error ? error.message : `Invalid file content!`);
    }
    return this.loaded;
  }

  getSheetCount(): number
  {
    return (this.loaded && this.workbook) ? this.workbook.SheetNames.length : 0;
  }

  getSheetNames(): string[]
  {
    if(!this.loaded)
    {
      console.warn('Not loaded');
      return [];
    }
    const workbook = this.workbook;
    return workbook!.SheetNames;
  }

  getSheetById(id: number, header: boolean = true): any[][]
  {
    if(!this.loaded || this.getSheetCount() < id)
    {
      console.warn('Not loaded');
      return [];
    }
    const optionsDefault      = { header: (header ? 1 : 0), defval: '', blankrows: false };
    const workbook            = this.workbook;
    const options             = { ...optionsDefault, raw: true };
    const wsname              = workbook!.SheetNames[id];
    const sheet               = workbook!.Sheets[wsname];
    const sheetColumns        = getSheetColumns(sheet);
    if(sheetColumns.length > this.columnsLimit) {
      throw new Error('Invalid columns count');
    }
    const sheetData: any[][]  = XLSX.utils.sheet_to_json(sheet, options);
    const emptyCols: number[] = [];
    if(sheetData.length > 0)
    {
      for(let col = 0, cols = sheetData[0].length; col < cols; col++)
      {
        if(!dateCheckEmpty(sheetData[0][col]))
        {
          continue;
        }
        emptyCols.push(col);
      }
    }
    return (emptyCols.length) ? this.removeColumns(sheetData, emptyCols) : sheetData;
  }

  isLoaded(): boolean
  {
    return !!this.loaded;
  }

  private isValidWorkbook(workbook: XLSX.WorkBook)
  {
    if(workbook.SheetNames.length < 1) throw new Error('No sheet in file!');
    const sheet               = this.workbook!.Sheets[workbook.SheetNames[0]];
    const sheetColumns        = getSheetColumns(sheet);
    if(sheetColumns.length > this.columnsLimit) {
      throw new Error('Invalid columns count');
    }
    const sheetData: any[][]  = XLSX.utils.sheet_to_json(sheet, {header: 1, defval: '', blankrows: false});
    if(sheetData.length < 2 || sheetData[0].length < 2) throw new Error('Invalid file - not enough fields!');
  }

  private removeColumns(data: any[][], colIndexes: number[]): any[][]
  {
    let   unnamedCounter = 1;
    const indexes        = colIndexes.sort((a,b) => b - a);
          data           = transpose(data);
    for(const colIndex of indexes)
    {
      let isEmpty = true;
      for(const value of data[colIndex])
      {
        if(!dateCheckEmpty(value) || (typeof value === 'number'))
        {
          isEmpty = false;
          break;
        }
      }
      if(!isEmpty)
      {
        data[colIndex][0] = `unnamed_${unnamedCounter++}`;
        continue;
      }
      data.splice(colIndex, 1);
    }
    return transpose(data);
  }
}

const getSheetColumns = (workbook: XLSX.WorkSheet): string[] => Array.from(new Set(Object.keys(workbook).filter(c => c.match(/^[A-Z]{1,}[0-9]{1,}$/)).map(c => c.replace(/[^A-Z]/g, ''))));