import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { SidebarLink } from './SidebarLink';

interface Props {
  label: string;
  href: string;
  onClick?: () => void;
  active?: boolean;
  secondary?: boolean;
  withBackground?: boolean;
  rightEl?: React.ReactNode;
}

export const SidebarSectionItem: FC<Props> = observer(props => {
  const { label, rightEl, href, onClick, active, secondary, withBackground } = props;

  return (
    <SidebarLink href={href} onClick={onClick} active={active} secondary={secondary} withBackground={withBackground}>
      <Label title={label}>{label}</Label>
      <RightEl>{rightEl}</RightEl>
    </SidebarLink>
  );
});

const Label = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const RightEl = styled.span`
  display: flex;
  margin-left: auto;
`;
