import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css, useTheme } from 'styled-components';

import { transition } from '../../utils';
import { IconChevronLeft } from '../icons';

import { SidebarLink } from './SidebarLink';
import { SidebarContext } from './SidebarContext';
import { SidebarOverlay } from './SidebarOverlay';
import { SidebarSide, SidebarSize, SidebarVariant, SidebarState } from './SidebarTypes';

import { Theme } from '../../theme'

interface Props {
  collapsible?: boolean;
  defaultCollapsed?: boolean;
  variant?: SidebarVariant;
  side?: SidebarSide;
  size?: SidebarSize;
  footer?: React.ReactNode;
  overlay?: React.ReactNode;
  children?: React.ReactNode;
  onClickOutsideOverlay?: () => void;
}

export const Sidebar: FC<Props> = observer(props => {
  const {
    collapsible,
    variant = 'normal',
    defaultCollapsed,
    side,
    size = 'normal',
    footer,
    overlay,
    children,
    onClickOutsideOverlay,
  } = props;
  const theme: Theme = (useTheme()) as Theme;

  const [sidebarState, setSidebarState] = useState<SidebarState>(defaultCollapsed ? 'collapsed' : 'open');

  const showSidebar = () => {
    setSidebarState('opening');
    window.setTimeout(() => setSidebarState('open'), theme.transition.durationMs);
  };

  const hideSidebar = () => {
    setSidebarState('collapsing');
    window.setTimeout(() => setSidebarState('collapsed'), theme.transition.durationMs);
  };

  useEffect(() => {
    setSidebarState(defaultCollapsed ? 'collapsed' : 'open');
  }, [defaultCollapsed]);

  const toggleSidebar = () => (sidebarState === 'collapsed' ? showSidebar() : hideSidebar());

  const sidebarContentVisible = sidebarState === 'open' || sidebarState === 'collapsing';

  return (
    <SidebarContext.Provider value={{ sidebarState, variant, sidebarContentVisible, toggleSidebar }}>
      <StyledSidebar isCollapsed={sidebarState === 'collapsed'} variant={variant} size={size}>
        <Content>{children}</Content>
        <Footer>
          {collapsible && (
            <SidebarLink
              onClick={toggleSidebar}
              icon={<StyledIconChevron side={side} isCollapsed={sidebarState === 'collapsed'} />}
              title={sidebarState === 'collapsed' ? 'Expand sidebar' : undefined}
            >
              Collapse
            </SidebarLink>
          )}
          {footer}
        </Footer>
      </StyledSidebar>
      {overlay && sidebarState === 'collapsed' && (
        <SidebarOverlay onClickOutside={onClickOutsideOverlay}>{overlay}</SidebarOverlay>
      )}
    </SidebarContext.Provider>
  );
});
// eslint-disable-next-line
interface StyledSidebarProps {
  isCollapsed?: boolean;
  variant: SidebarVariant;
  size: SidebarSize;
}

const StyledSidebar = styled.div<StyledSidebarProps>`
  background: ${props => props.theme.sidebar.background};
  padding-bottom: 18px;
  position: relative;
  flex-basis: ${props => props.theme.sidebar.normalWidth};
  width: ${props => props.theme.sidebar.normalWidth};
  transition: width, flex-basis ${props => props.theme.transition.duration} ${props =>
  props.theme.transition.timingFunction};
  will-change: width, flex-basis;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${props =>
    props.variant === 'light' &&
    css`
      background: ${props.theme.sidebar.backgroundLight};
    `}

  ${props =>
    props.variant === 'dark' &&
    css`
      background: ${props.theme.sidebar.backgroundDark};
    `}

  ${props =>
    props.size === 'wide' &&
    css`
      flex-basis: ${props.theme.sidebar.largeWidth};
      width: ${props.theme.sidebar.largeWidth};
    `}

  ${props =>
    props.isCollapsed &&
    css`
      flex-basis: ${props.theme.sidebar.smallWidth};
      width: ${props.theme.sidebar.smallWidth};
    `}
`;

const Footer = styled.div`
  flex-shrink: 0;
`;

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

const StyledIconChevron = styled(IconChevronLeft).withConfig<{ isCollapsed?: boolean; side?: SidebarSide }>({
  shouldForwardProp: prop => !['isCollapsed', 'side'].includes(prop),
})`
  transform: ${props => (props.isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)')};
  ${transition('transform', { duration: '0.2s' })}

  ${props =>
    props.side === 'right' &&
    css`
      transform: ${props.isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)'};
    `}
`;
