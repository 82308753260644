import React from 'react';
import styled, { css } from 'styled-components';
import { Text, theme } from '../../ui';

export interface OptionsWrapperProps
{
  isLeftAlign: boolean;
}

export const OptionsWrapper: React.FC<OptionsWrapperProps> = styled.div`
  position: relative;
  ${(props: OptionsWrapperProps) => css`left: ${props.isLeftAlign ? `0px` : `-196px`};`} 
`;

export const OptionsPart = styled.div`
  margin: 9px 5px;
  padding: 1px 7px;
  /*width: calc(100% - 14px);*/
  width: calc(220% - 14px);
  margin: 0;
  position: absolute;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;

export interface OptionProps extends React.HTMLAttributes<unknown>
{
  mapped ?: boolean;
  selected: boolean;
  allowed?: boolean;
  onClick : any;
}

export const Option: React.FC<OptionProps> = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
  padding: 12px 0 12px 12px;
  text-align: left;
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  cursor: pointer;
  line-height: 16px;
  vertical-align: top;

  &:hover {
    background-color: #EFF8E7;
  }

  ${(props: OptionProps) => props.mapped && css`
    background-color: #E7EFF8;
    color: ${theme.colors.darkGray};
  `}

  ${(props: OptionProps) => props.selected && css`
    background-color: #EFF8E7;
    color: ${theme.colors.black};
  `}

  ${(props: OptionProps) => props.allowed ? `` : css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.gray};
    cursor: default;
  `}
`;

export const OptionText = styled(Text)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OptionAdditional: React.FC = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
`;

export const OptionAdditionalTitle = styled.div`
  font-style: italic;
`;

export const OptionAdditionalIcon = styled.div`
  font-size: 17px;
  margin-left: 5px;
  margin-bottom: -3px;
`;