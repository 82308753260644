import { observer } from 'mobx-react-lite';
import React, { FC, useContext } from 'react';
import styled, { css } from 'styled-components';

import { transition } from '../../utils';
import { BaseButton } from '../BaseButton';

import { TimelineContext } from './TimelineContext';
import { TimelineGroupContainer } from './TimelineGroupContainer';

interface Props {
  label: React.ReactNode;
  className?: string;
  active?: boolean;
  disabled?: boolean;
  title?: string;
  children?: React.ReactElement | React.ReactElement[];
  onClick?: () => void;
}

interface GroupButtonContextType {
  groupActive?: boolean;
}

export const TimelineGroupButtonContext = React.createContext<GroupButtonContextType>({});

export const TimelineGroupButton: FC<Props> = observer(props => {
  const { className, label, active, disabled, title, children, onClick } = props;
  const { allActive } = useContext(TimelineContext);
  const childrenCount = React.Children.count(children);

  return (
    <TimelineGroupButtonContext.Provider value={{ groupActive: active }}>
      <StyledTimelineGroupContainer
        className={className}
        allActive={allActive}
        size={Math.max(childrenCount, 1)}
        disabled={disabled}
        withChildren={Boolean(children)}
      >
        <TimelineGroupButtonLabel
          active={active}
          allActive={allActive}
          disabled={disabled}
          title={title}
          withChildren={Boolean(children)}
          onClick={onClick}
        >
          {label}
        </TimelineGroupButtonLabel>
        {children && <TimelineButtons>{children}</TimelineButtons>}
      </StyledTimelineGroupContainer>
    </TimelineGroupButtonContext.Provider>
  );
});

const TimelineButtons = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  padding-bottom: ${props => props.theme.timeFilter.button.margin};
`;

const StyledTimelineGroupContainer = styled(TimelineGroupContainer).withConfig<{ allActive?: boolean }>({
  shouldForwardProp: prop => !['allActive'].includes(prop),
})`
  position: relative;

  ${props =>
    props.allActive &&
    css`
      background: ${props.theme.appColors.brandNormal};
    `}
`;
// eslint-disable-next-line
interface TimelineGroupButtonLabelProps {
  active?: boolean;
  allActive?: boolean;
  withChildren?: boolean;
}
export const TimelineGroupButtonLabel = styled(BaseButton)<TimelineGroupButtonLabelProps>`
  width: 100%;
  height: 100%;
  padding-bottom: ${props =>
    props.withChildren ? `calc(${props.theme.timeFilter.button.height} + ${props.theme.timeFilter.button.margin})` : 0};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.darkGray};
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: ${props => props.theme.fontSize.small};

  ${transition('background, box-shadow, color')}

  &:hover {
    background: ${props => props.theme.colors.extraLightGray};
  }

  ${props =>
    props.allActive &&
    css`
      color: ${props.theme.colors.white};

      &:hover {
        background: ${props.theme.appColors.brandDark};
      }
    `}

  ${props =>
    props.active &&
    css`
      &,
      &:hover {
        color: ${props.theme.colors.white};
        background: ${props.theme.appColors.brandNormal};
      }
    `}

  ${props =>
    props.title &&
    !props.disabled &&
    css`
      text-decoration: underline dashed ${props.theme.colors.veryLightGray};
      text-underline-position: under;
    `}

  ${props =>
    props.disabled &&
    css`
      &,
      &:hover {
        color: ${props.theme.colors.veryLightGray};
        background: transparent;
      }
    `}

  ${props =>
    props.disabled &&
    props.allActive &&
    css`
      &,
      &:hover {
        color: ${props.theme.colors.veryLightPurple};
      }
    `}
`;
