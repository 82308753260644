import { TargetColumnDefinition } from '../types'
import { emailCheckEmpty } from './validatorEmail';

const mainDepartmentPeopleSufficientLimitDefault = 3;

export class MainDepartment
{
  readonly mainDepartmentPeopleSufficientLimit: number;
  private  mainDepartmentPeople: Record<string, number[]> = {};
  private  mainDepartmentEmpty : number[]                 = [];

  constructor(mainDepartmentPeopleSufficientLimit?: number)
  {
    this.clean();
    this.mainDepartmentPeopleSufficientLimit = mainDepartmentPeopleSufficientLimit ? mainDepartmentPeopleSufficientLimit : mainDepartmentPeopleSufficientLimitDefault;
  }

  clean = () => {
    this.mainDepartmentPeople = {};
    this.mainDepartmentEmpty  = [];
  }

  empty = (row: any[], colIndex: number) => {
    const value = row[colIndex];
    return emailCheckEmpty(value);
  }

  processRow = (row: any[], rowIndex: number, mainDepartment: TargetColumnDefinition) => {
    if(this.empty(row, mainDepartment.sourceIndex!))
    {
      this.mainDepartmentEmpty.push(rowIndex);
      return;
    }

    const department = row[mainDepartment.sourceIndex!];
    if(!(department in this.mainDepartmentPeople))
    {
      this.mainDepartmentPeople[department] = [];
    }
    this.mainDepartmentPeople[department].push(rowIndex);
  }

  getInsufficientDepartmentsRows = (): number[] => {
    let insufficientDepartmentsRows: number[] = [];
    for(const department in this.mainDepartmentPeople)
    {
      
      if(!Object.prototype.hasOwnProperty.call(this.mainDepartmentPeople, department) ||
        this.mainDepartmentPeople[department].length >= this.mainDepartmentPeopleSufficientLimit)
      {
        continue;
      }
      insufficientDepartmentsRows = insufficientDepartmentsRows.concat(this.mainDepartmentPeople[department]);
    }
    return insufficientDepartmentsRows;
  }

  getMissingRows = (): number[] => {
    return this.mainDepartmentEmpty;
  }
}

