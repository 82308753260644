import XLSX from 'xlsx';
import {
  Anonymizer,
  AnonymizerHashing,
  AnonymizerEncryptor,
  Download,
  FileUploadGoogle,
  UploadedFile,
  ValidatorResults,
  DataContainer,
  DateConverter,
  UPLOAD_TYPE_CSV,
  // UPLOAD_TYPE_EMAIL_MAP,
  StoreHR,
} from '../libs';
import {
  TargetColumnDefinition,
  anonymizedAllowedColumns,
  anonymizedHashedColumns,
  dateType,
  targetColumnType,
  MappingDefinition,
  EmptyHanlder,
  AnonymizationSettingsDomains,
  UPLOAD_TYPE_EMAIL_MAP,
} from '../libs/types';
import { isDev } from '../rest';
import { fteNormalize } from './normalizator';


type PrepareDataType = 'none' | 'hash' | 'anonymize';

export interface WriterState
{
  progress: boolean;
  cancelHandlers: EmptyHanlder[];
}

export type CancelHandler = (handler: EmptyHanlder) => any;

export class Writer 
{
  private anonymized           : boolean;
  private state                : WriterState;
  private anonymizerOnceHashing: AnonymizerHashing;
  private anonymizerHashing    : AnonymizerHashing;
  private anonymizerEncryptor  : AnonymizerEncryptor | null;
  private hiddenColumns        : targetColumnType[];

  constructor(anonymized: boolean, anonymizationSalt: string, anonymizationKey: string, anonymizationPK: CryptoKey | null, anonymizationSettingsDomains: AnonymizationSettingsDomains | null)
  {
    if(anonymized && anonymizationPK === null)
    {
      throw new Error(`Writer Error - Anonymization required but PublicKey missing!`);
    }
    this.anonymized            = anonymized;
    this.anonymizerHashing     = new AnonymizerHashing(anonymizationKey, anonymizationSettingsDomains);
    this.anonymizerOnceHashing = new AnonymizerHashing(anonymizationSalt, anonymizationSettingsDomains);
    this.anonymizerEncryptor   = anonymized ? new AnonymizerEncryptor(anonymizationPK as CryptoKey) : null;
    this.state                 = {
      progress: false,
      cancelHandlers: []
    }
    this.hiddenColumns         = [];
  }

  private prepareData = async (type: PrepareDataType): Promise<any[][]> =>
  {
    return new Promise((resolve) =>
    {
      const dataContainer     = StoreHR.getData();
      const data              = dataContainer.getData();
      const mappingDefinition = StoreHR.getMapping();

      let anonymizerPromise;
      let onlyAnonymizedAllowedColumns = this.anonymized;
      /*
      Position and Department is not anonymized/replaced anymore
      https://app.clickup.com/t/6jv1b3
      */
      switch(type)
      {
        case 'hash': {
          const anonymizeColumns: targetColumnType[] = ['email_id', 'supervisor_email_id', 'alias_emails'];
          const replaceColumns: targetColumnType[] = ['first_name', 'middle_name', 'last_name', 'full_name'];
          // const anonymizer = this.anonymized ? new Anonymizer(this.anonymizerHashing, mappingDefinition!) : new Anonymizer(this.anonymizerOnceHashing, mappingDefinition!);
          const anonymizer = new Anonymizer(this.anonymizerOnceHashing, mappingDefinition!);
          anonymizerPromise = anonymizer.anonymize(data, anonymizeColumns, replaceColumns);
          onlyAnonymizedAllowedColumns = true;
          break;
        }

        case 'anonymize': {
          const anonymizeColumns: targetColumnType[] = ['email_id', 'supervisor_email_id', 'alias_emails'];
          const replaceColumns: targetColumnType[] = ['first_name', 'middle_name', 'last_name', 'full_name'];
          const anonymizer = new Anonymizer(this.anonymizerHashing, mappingDefinition!);
          // if(this.anonymizerEncryptor)
          // {
          //   anonymizer.setEncryptor(this.anonymizerEncryptor);
          // }
          anonymizerPromise = anonymizer.anonymize(data, anonymizeColumns, replaceColumns);
          break;
        }

        default: {
          anonymizerPromise = Promise.resolve(data);
          break;
        }
      }

      return anonymizerPromise.then((dataProcessed: any[][]) => {
        resolve(this.transformData(dataProcessed, onlyAnonymizedAllowedColumns));
      })
    })
  }

  private prepareEmailMap = async (): Promise<any[][]> => 
  {
    const anonymizerEncryptor = this.anonymizerEncryptor as AnonymizerEncryptor;
    const dataContainer       = StoreHR.getData();
    const data                = dataContainer.getData();
    const mappingDefinition   = StoreHR.getMapping() as MappingDefinition;
  
    const email               = mappingDefinition.targetColumns.find(o => o.name === 'email_id' && o.sourceIndex !== null);
    const emailSupervisor     = mappingDefinition.targetColumns.find(o => o.name === 'supervisor_email_id' && o.sourceIndex !== null);
    const emailAlias          = mappingDefinition.targetColumns.find(o => o.name === 'alias_emails' && o.sourceIndex !== null);
  
    if(!email || !emailSupervisor)
    {
      return Promise.reject('Unable to process Email map - missing required columns!');
    }

    const emailsUnique = new Set();
    const addEmailToSet = (emailValue: string) => {
      const emailValueTrimed = emailValue.trim().toLowerCase();
      if(emailValueTrimed === '') return;
      emailsUnique.add(emailValueTrimed)
    }
    for(let row = 1, rows = data.length; row < rows; row++)
    {
      const emailValue = data[row][email.sourceIndex as number];
      const emailSupervisorValue = data[row][emailSupervisor.sourceIndex as number];
      addEmailToSet(emailValue);
      addEmailToSet(emailSupervisorValue);
      if(emailAlias)
      {
        const emailValues = data[row][emailAlias.sourceIndex as number].split(','); // @TODO Email alias delimiter
        for(const emailValue of emailValues)
        {
          addEmailToSet(emailValue);
        }
      }
    }
    const emailsUniqueList = Array.from(emailsUnique) as string[];
    emailsUnique.clear();
    const emailMap: string[][] = [[`email_hashed`, `email_encrypted`]];
    for(const emailValue of emailsUniqueList)
    {
      const emailHashed = await this.anonymizerHashing.hashEmail(emailValue);
      const emailEncrypted = await anonymizerEncryptor.encryptEmail(emailValue);
      emailMap.push([emailHashed, emailEncrypted]);
    }
    emailsUniqueList.length = 0;
    return new Promise((resolve) =>
    {
      resolve(emailMap);
    })
  }

  private transformData = (data: any[][], isAnonymized: boolean): any[][] => {
    const validator                                 = StoreHR.getValidator();
    const mappingDefinition                         = StoreHR.getMapping();
    const validatorResults: ValidatorResults | null = (validator && validator.getResults()) || null;
    let   outputData: string[][]                    = [];
    const mappedIndexes: number[]                   = [];
    let   dateStartIndex: number | null             = null;
    let   fteIndex: number | null                   = null;
    const dateIndexes: number[]                     = [];
    const emailIndexes: number[]                    = [];

    // Prepare DateFormats
    const dateFormats = (new Array(mappingDefinition!.targetColumns.length)).fill(null);
    for(const targetColumn of mappingDefinition!.targetColumns)
    {
      const dateFormat = mappingDefinition!.dateFormats.find(dateFormat => dateFormat.name === targetColumn.name);
      if(dateFormat && targetColumn.sourceIndex !== null)
      {
        dateFormats[targetColumn.sourceIndex] = dateFormat.format;
      }
    }
    // Filter unmapped columns and store used indexes 
    // - unused Name fields from other nameType is removed by filtering on sourceIndex null
    const targetColumns: any[] = mappingDefinition!.targetColumns.filter(column => {
      if(column.sourceIndex !== null && (false === isAnonymized || anonymizedAllowedColumns.includes(column.name)) && !this.hiddenColumns.includes(column.name))
      {
        if(['start_date', 'end_date'].includes(column.name))
        {
          dateIndexes.push(column.sourceIndex);
          if(column.name === 'start_date')
          {
            dateStartIndex = column.sourceIndex;
          }
        }
        if(column.name === 'fte')
        {
          fteIndex = column.sourceIndex;
        }
        if(isAnonymized && anonymizedHashedColumns.includes(column.name))
        {
          emailIndexes.push(column.sourceIndex);
        }
        mappedIndexes.push(column.sourceIndex);
        return true;
      }
      return false;
    });

    // Header
    const header: string[] = [];
    for(let col = 0, cols = targetColumns.length; col < cols; col++)
    {
      const column = ('name' in targetColumns[col]) ? (targetColumns[col] as TargetColumnDefinition).name : targetColumns[col].source!;
      header.push(column);
    }
    outputData.push(header);

    // Data
    for(let row = 1, rows = data.length; row < rows; row++)
    {
      const rowLine: string[] = [];
      for(let col = 0, cols = targetColumns.length; col < cols; col++)
      {
        const index = targetColumns[col].sourceIndex!;
        const value = data[row][index];
        // FTE
        if(index === fteIndex)
        {
          const value = fteNormalize(data[row][index]);
          rowLine.push(value);
          continue;
        }
        const dateInvalid = (validatorResults && dateStartIndex === index && validatorResults.startDateFormat.includes(index));
        if(!dateIndexes.includes(index) || dateInvalid)
        {
          rowLine.push(value);
          continue;
        }
        // Date - valid
        const dateFormat = DataContainer.isUndectableDate(value) && dateFormats[index] !== null ? dateFormats[index] as dateType : undefined;
        const valueDate = dateFormats[index] !== null ? new DateConverter(value, dateFormat) : new DateConverter(value);
        rowLine.push(valueDate.isValid() ? valueDate.get() : value);
      }
      outputData.push(rowLine);
    }

    // Validator - statuses append
    if(!validator)
    {
      console.warn(`Finish::transformData - Validator not found!`);
    }
    else
    {
      outputData = validator!.getStatusesVerbose(false, 'validation_status', outputData);
    }
    return outputData;
  }

  sendCSVCancel()
  {
    if(!this.state.progress)
    {
      console.warn('Unable to cancel upload to Time is Ltd. - is not in progress');
      return;
    }
    for(const handler of this.state.cancelHandlers)
    {
      handler();
    }
  }

  private async prepareCSV(type: PrepareDataType, delimiter: string = ';')
  {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(await this.prepareData(type), {skipHeader: true, dateNF: 'YYYY-MM-DD'});
    return XLSX.utils.sheet_to_csv(worksheet, { FS: delimiter });
  }

  private async prepareEmailMapCSV(delimiter: string = ';')
  {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(await this.prepareEmailMap(), {skipHeader: true, dateNF: 'YYYY-MM-DD'});
    return XLSX.utils.sheet_to_csv(worksheet, { FS: delimiter });
  }

  setHiddenColumns = (hiddenColumns: targetColumnType[]) => 
  {
    this.hiddenColumns = hiddenColumns;
  }

  downloadCSV = async () =>
  {
    const uploadedFile: UploadedFile | null = StoreHR.getUploadedFile()!;
    Download(await this.prepareCSV(this.anonymized ? 'anonymize' : 'none'), `${uploadedFile.namePart}-EXPORT.csv`, 'text/csv;encoding:utf-8', false);
  }

  async sendCSV(file: File, queryUploadLink: string, cancelHandler: CancelHandler)
  {
    const gzip = isDev ? false : true;
    const csvFile: UploadedFile = {
      name: 'hr-table.csv',
      namePart: 'hr-table',
      extPart: 'csv',
      type: 'text/csv',
      file,
      content: await this.prepareCSV(this.anonymized ? 'anonymize' : 'none')
    };

    const uploadPromises: Promise<any>[] = [];

    const csvUpload = FileUploadGoogle(queryUploadLink, UPLOAD_TYPE_CSV, {}, csvFile, gzip, [], 
      null,
      cancelHandler
    );
    uploadPromises.push(csvUpload);

    if(this.anonymized)
    {
      const csvEmailMap: UploadedFile = {
        name: 'hr-table-email-map.csv',
        namePart: 'hr-table-email-map',
        extPart: 'csv',
        type: 'text/csv',
        file,
        content: await this.prepareEmailMapCSV()
      }
      const csvEmailMapUpload = FileUploadGoogle(queryUploadLink, UPLOAD_TYPE_EMAIL_MAP, {}, csvEmailMap, gzip, [], 
        null,
        cancelHandler
      );
      uploadPromises.push(csvEmailMapUpload);
    }

    return Promise.all(uploadPromises);
  }
}