import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import { RouteList } from '../../screens'
import {
  lang,
  FileUploadGoogleBlob,
  FileUploadProgressResult,
  UploadedFile,
  UploaderResponse,
  getFileSize,
  UPLOAD_TYPE_TRANSFER,
  StoreHR,
  pathPush,
  getClientId,
} from '../../libs'
import {
  Button,
  Container,
  Screen,
  Header,
  BoxLoader,
  Title,
  WrapperMain,
  WrapperFinish,
  UploaderWrapper,
  notification,
} from '../../components'
import { theme } from '../../components/ui/theme'
import {
  client,
  getQueryHQ,
  uploadLinkHQFiles,
  uploadDoneHQFile,
} from '../../rest'
import { EmptyHanlder } from '../../libs/types'
import { LoaderWrapper, LoaderCancel, Export, ExportTitle } from './finish'
import { userKey } from '../../user'

interface Uploading {
  progress: boolean
  cancelHandlers: EmptyHanlder[]
}

type FinishFileUploadProps = RouteComponentProps<{
  organizationId: string
}>

const LoaderFileSize = styled.p`
  color: ${theme.colors.purple};
  margin: 4px 0 16px;
  font-size: 13px;
  text-decoration: underline;
`;

const LoaderProgress = styled.p`
  margin: 10px 0;
  font-size: 13px;
`

interface FinishFileUploadState {
  uploading: Uploading
  uploadingProgress: null | FileUploadProgressResult
  exported: boolean
}

export class FinishFileUpload extends React.Component<
  FinishFileUploadProps,
  FinishFileUploadState
> {
  constructor(props: Readonly<FinishFileUploadProps>) {
    super(props)
    this.state = this.getDefaultState()
  }

  protected getDefaultState = (): FinishFileUploadState => {
    return {
      uploading: {
        progress: false,
        cancelHandlers: [],
      },
      uploadingProgress: null,
      exported: userKey.isDone(),
    }
  }

  private uploadFile = () => {
    this.setState({ uploading: { ...this.state.uploading, progress: true } })
    const gzip = false
    const uploadedFile: UploadedFile | null = StoreHR.getUploadedFile()!
    const query = getQueryHQ(uploadLinkHQFiles)

    const addCancelHandler = (handler: EmptyHanlder) => {
      const uploading = { ...this.state.uploading }
      uploading.cancelHandlers.push(handler)
      this.setState({ uploading })
    }

    const rawUpload = FileUploadGoogleBlob(
      query,
      UPLOAD_TYPE_TRANSFER,
      {},
      uploadedFile,
      gzip,
      [],
      (progress: FileUploadProgressResult) => {
        this.setState({ uploadingProgress: progress })
      },
      addCancelHandler
    )

    Promise.all([rawUpload])
      .then((results: UploaderResponse[]) => {
        this.uploadFileFinish(results)
      })
      .catch((errors) => {
        console.warn('RAW Upload Error', errors)
        notification(`Unable Upload files to Time is Ltd.`, { type: 'error' })
        this.setState({
          uploading: {
            ...this.state.uploading,
            progress: false,
            cancelHandlers: [],
          },
        })
      })
  }

  private uploadFileFinish = (results: UploaderResponse[]) => {
    let variables: any = {
      file_name: null,
      gs_link: null,
      customer_id: getClientId() as string,
    }
    let isCanceled: boolean = false
    for (const result of results) {
      if (result.status === 'cancelled') {
        isCanceled = true
        break
      }
      if (!result.type || ![UPLOAD_TYPE_TRANSFER].includes(result.type)) {
        continue
      }
      variables = {
        ...variables,
        file_name: result.fileName as string,
        gs_link: result.gsLink as string,
        upload_type: result.type,
      }
    }

    if (isCanceled) {
      this.setState({
        uploading: {
          ...this.state.uploading,
          progress: false,
          cancelHandlers: [],
        },
      })
      notification(`Upload files to Time is Ltd. cancelled`, {
        type: 'warning',
      })
      return
    }

    const query = getQueryHQ(uploadDoneHQFile)
    client
      .query({ query, variables })
      .then(() => {
        notification(`Upload files to Time is Ltd. successfull!`, {
          type: 'success',
        })
        userKey.setDone()
        this.setState({ exported: true })
      })
      .catch(() => {
        notification(`Unable Upload files to Time is Ltd.`, { type: 'error' })
        this.setState({
          uploading: {
            ...this.state.uploading,
            progress: false,
            cancelHandlers: [],
          },
          exported: false,
        })
      })
  }

  private uploadCancel = () => {
    if (!this.state.uploading.progress) {
      console.warn(
        'Unable to cancel upload to Time is Ltd. - is not in progress'
      )
      return
    }
    for (const handler of this.state.uploading.cancelHandlers) {
      handler()
    }
  }

  private prepareForNextFile = () => 
  {
    const { organizationId } = this.props.match.params;
    StoreHR.resetUploadedFile();
    StoreHR.resetMapping();
    userKey.unsetDone();
    const path = RouteList.initFileUpload.path;
    pathPush(this.props.history, path.replace(':organizationId', organizationId))
  }

  private getExportLoadedPart = (): JSX.Element => {
    const uploadedFile: UploadedFile | null = StoreHR.getUploadedFile()!
    return (
      <>
        {uploadedFile && (
          <LoaderFileSize>
            {lang.descFileUploadSize.replace(
              '%1',
              getFileSize(uploadedFile.file.size)
            )}
          </LoaderFileSize>
        )}
        <Button
          className="button-export"
          disabled={false}
          text={lang.buttonFinishUpload}
          onClick={this.uploadFile}
        />
      </>
    )
  }

  private getExportLoaderPart = (): JSX.Element => {
    const uploadProgress = this.state.uploadingProgress
    return (
      <WrapperFinish>
        <LoaderWrapper>
          <BoxLoader />
        </LoaderWrapper>
        {uploadProgress === null ? null : (
          <LoaderProgress>
            {lang.descFileUploadProgress
              .replace('%1', uploadProgress.percent.toString())
              .replace('%2', getFileSize(uploadProgress.remain))}
          </LoaderProgress>
        )}
        <LoaderCancel onClick={this.uploadCancel}>
          {lang.buttonFinishUploadCancel}
        </LoaderCancel>
      </WrapperFinish>
    )
  }

  private getExportScreen = (): JSX.Element => {
    const isDone = this.state.exported
    const title = isDone ? lang.descFileUploaded : lang.descFileUpload
    let exportPart = null
    if (!isDone) {
      exportPart = !this.state.uploading.progress
        ? this.getExportLoadedPart()
        : this.getExportLoaderPart()
    }
    return (
      <WrapperFinish>
        <Export
          done={isDone}
          className={`export ${isDone ? `export-done` : `export-waiting`}`}
        >
          <ExportTitle>{title}</ExportTitle>
          {exportPart}
        </Export>
        {isDone && <Button
          size="medium" 
          type="contained" 
          onClick={this.prepareForNextFile} 
          text={lang.buttonUploadNextFile}
          disabled={false} 
          className="button"
        />}
      </WrapperFinish>
    )
  }

  render() {
    const { organizationId } = this.props.match.params
    if (StoreHR.getUploadedFile() === null) {
      pathPush(
        this.props.history,
        RouteList.welcomeFileUpload.path.replace(
          ':organizationId',
          organizationId
        )
      )
      return null
    }

    return (
      <Container>
        <Header organizationId={organizationId} isFileTransfer />
        <Screen topOffset={false}>
          <WrapperMain>
            <Title title={`2. ${lang.progressStep4TitleTransfer}`} />
            <UploaderWrapper gap>{this.getExportScreen()}</UploaderWrapper>
          </WrapperMain>
        </Screen>
      </Container>
    )
  }
}
