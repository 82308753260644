export const lang: Record<string, string> = {
  title: `HR Table Tool`,
  // titleFileUpload: `File Transfer`,
  titleDeleteFile: `Delete file`,
  titleLogin: `Log in to your account`,
  titleLoading: `Loading…`,
  titleHRTableImported: `HR table has been successfully imported`,
  titleHRTableImportedColumns: `Map your HR table columns to ours`,
  titleHRTableImportedEmployees: `%1 employees have been imported from your HR table`,
  titleHRTableImportedDetected: `%1 columns were auto detected`,
  titleValidating: `Validating`,
  titlePleaseWait: `Please wait…`,
  titleValidateSummary: `Your file cannot be processed due to mistakes in your data or mapping.`,
  titleValidateSummaryError: `Reupload the table`,
  titleValidateSummaryWarning: `Reupload the table, or continue with the import as is.`,
  titleUndetectableDate: `Undetectable date format`,

  titleMappingNameType: `Change your name structure`,
  titleDownloadGuideLink: `Learn what your structure should look like`,
  titleDownloadHowToFix: `Learn how to fix warnings & errors`,
  titleAnonymizationKey: `Unlock by organization anonymization key`,
  titleMultipleTabs: `We notice that you have more tabs in your excel`,

  descUploadHRTable: `Drop your HR Table to import`,
  descUploadFile: `Drop your file for import`,

  descDeleteFile: `File which is already mapped will be deleted.`,
  descDownloadMapped: `Feel free to download. If you want to keep your mapped file.`,
  descUploaded: `HR table has successfully uploaded to our server.`,
  descUpload: `Just one last step. Upload safely your mapped table to our system.`,
  descMappingNameTypeWarning: `If you change the settings then your currently mapped column will be unmapped.`,
  descValidateMappingSaved: `Do not worry your mapping is saved.`,
  descValidateSummaryWarningsAfter: `You can continue with your HR file as-is, but might get more value out of the analysis if you correct these warnings.`,
  descFileUploaded: `Your file has successfully uploaded to our server.`,
  descFileUpload: `Just one last step. Upload safely your file to our system.`,
  descFileUploadSize: `File size: %1`,
  descFileUploadProgress: `%1% complete (%2 remain)`,
  descUndetectableDate: `Undetectable date format for column "%1" need select your correct format.`,
  descMultipleTabs: `Select different tab if your HR data are stored in another tab.`,
  descHRTableImportedColumns: `We recommend to map all columns if your table contains these data.`,
  descHRTableImportedEmployees: `If you find any mistake go back and reupload HR table.`,
  descHRTableImportedDetected: `%1 columns were filled.`,

  mappingSummary: `Employess in your HR table. If you find any mistake`,

  askDeleteFile: `Do you wish to delete file `,

  buttonPrev: `Previous step`,
  buttonNext: `Next step`,
  buttonCancel: `Cancel`,
  buttonSave: `Save`,
  buttonDelete: `Delete`,
  buttonMappingStart: `Start with mapping`,
  buttonMappingReimport: `Go back and reimport`,
  buttonMappingReset: `Reset all mapped columns`,
  buttonMappingReupload: `go back and reupload HR table.`,
  buttonValidateStatusFile: `Download validation file`,
  buttonValidateReimport: `Reupload table`,
  buttonValidateContinue: `Finalize import`,
  buttonFinishUpload: `Upload to Time is Ltd.`,
  buttonFinishUploadCancel: `Cancel upload`,
  buttonFinishDownloadMapped: `Download mapped file (.CSV)`,
  buttonAnonymizationKeyUpload: `Select Anonymization key file`,
  buttonReupload: `Go back and reupload`,
  buttonSelectTab: `Select and continue`,
  buttonUploadNextFile: `Upload next file`,

  progressStep1: `Import`,
  progressStep2: `Map`,
  progressStep3: `Validate`,
  progressStep4: `Upload & Export`,
  progressStep1Title: `Import your HR table`,
  progressStep1TitleTransfer: `Import your file`,
  progressStep1TitleAnonymization: `Unlock & Upload your HR table`,
  progressStep2Title: `Map all our columns to yours`,
  progressStep3Title: `Check your data import`,
  progressStep4Title: `Upload & Export`,
  progressStep4TitleTransfer: `Upload`,

  generalNo: 'No',
  generalNone: 'None'
}

export const langErrorMsg: Record<string, string> = {
  invalidFile: `**Error while loading file.** Please check file and try to reupload your again or contact us at [support@timeisltd.com](mailto:support@timeisltd.com)`,
  invalidType: `**No columns were detected in the uploaded file.** Please try to reupload your again or contact us at [support@timeisltd.com](mailto:support@timeisltd.com)`,
  invalidSize: `**Size of file you uploaded is bigger than limit.** Please check file and try to reupload your again or contact us at [support@timeisltd.com](mailto:support@timeisltd.com)`,
  invalidContentMissingEmail: `**It looks like there is no column with employees' email addresses.** Please review your data and upload it again or contact us at [support@timeisltd.com](mailto:support@timeisltd.com)`,
  invalidAuthorizationKey: `The entered hash code doesn't match. Try again or contact your security administrator.`,
  invalidColumnsCount: `**Columns count of file you uploaded is bigger than %1.** Please check file and try to reupload your again or contact us at [support@timeisltd.com](mailto:support@timeisltd.com)`,
  invalidConfigEmptyPK: `**Initialization error: missing PublicKey** Please contact us at [support@timeisltd.com](mailto:support@timeisltd.com)`,
  invalidConfigInvalidPK: `**Initialization error: invalid PublicKey** Please contact us at [support@timeisltd.com](mailto:support@timeisltd.com)`,
  unableInitialize: `Unable to initialize`,
  unableLogin: `Unable to initialize`,
  unableDownloadStatus: `Unable to download status file`,
  unableUploadAuthorization: `You cannot upload your HR table until you insert correct hash code.`,
  pageNotFound: `Page not found`,
  browserNotSupported: `Sorry, but Internet Explorer browser is not supported.`,
  anonymizationKeyInitEmptyPK: `Initialization error - empty key`,
  anonymizationKeyInitInvalidPK: `Initialization error - invalid key`,
  anonymizationKeyInitMissmatchPK: `Initialization error - invalid public key`,
  anonymizationKeyFileStructure: `Error while processing file - missing required keys`,
  anonymizationKeyFileMissmatchPK: `Error while processing file - keys not match`,
  anonymizationKeyFileJSON: `Error while processing file - invalid JSON`,
}

export const langValidationMsg: Record<string, string> = {
  titleError: `Your HR table table was not mapped successfully. Please fix the %1 error.`,
  titleErrors: `Your HR table table was not mapped successfully. Please fix the %1 errors.`,
  titleWarning: `You have successfully mapped your HR table with %1 warning.`,
  titleWarnings: `You have successfully mapped your HR table with %1 warnings.`,
  titleBoth: `Your HR table table was not mapped successfully.`,

  postfixError: `Download the validation results file below to see the details on how to fix them.`,
  postfixWarning: `You can continue with your HR file as-is, but might get more value out of the analysis if you correct these warnings.`,
  descError: `%1 error need to be fixed to continue.`,
  descErrors: `%1 errors need to be fixed to continue.`,
  descWarning: `%1 warning are optional to be fixed, but you might get more value out of the analysis if you correct these warnings.`,
  descWarnings: `%1 warnings are optional to be fixed, but you might get more value out of the analysis if you correct these warnings.`,
}

type ValidationMessageProps = (type: 'warning' | 'error' | 'both', count: number) => string;

export const getValidationTitle: ValidationMessageProps = (type, count): string => {
  let message: string = '';
  if(type === 'error') {
    message = count === 1 ? langValidationMsg.titleError : langValidationMsg.titleErrors;
  } else if(type === 'warning') {
    message = count === 1 ? langValidationMsg.titleWarning : langValidationMsg.titleWarnings;
  } else {
    message = langValidationMsg.titleBoth;
  }
  return message.replace('%1', count.toString());
}

export const getValidationBothDesc = (errors: number, warnings: number): string[] => {
  const partErrors: string = errors === 1 ? langValidationMsg.descError : langValidationMsg.descErrors;
  const partWarnings: string = warnings === 1 ? langValidationMsg.descWarning : langValidationMsg.descWarnings;
  return [
    partErrors.replace('%1', errors.toString()),
    partWarnings.replace('%1', warnings.toString()),
  ];
}
