import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import styled, { css } from 'styled-components'
import { RouteList } from '../../screens'
import {
  lang,
  Writer,
  UploaderResponse,
  UPLOAD_TYPE_CSV,
  UPLOAD_TYPE_EMAIL_MAP,
  StoreHR,
  DataContainer,
  UploadedFile,
  pathPush,
  getClientId,
} from '../../libs'
import {
  Button,
  Container,
  Screen,
  Header,
  BoxLoader,
  UploaderWrapper,
  WrapperMain,
  WrapperFinish,
  Title,
  ProgressBar,
  notification,
} from '../../components'
import { theme } from '../../components/ui/theme'
import {
  publicURL,
  client,
  bypassValidation,
  uploadLinkHQHrTable,
  getQueryHQ,
  uploadDoneHQHrTable,
} from '../../rest'
import { ExportStatus, ExportStatusFile, EmptyHanlder } from '../../libs/types'

import { userKey, UserProfile, projectConfig } from '../../user'

interface Uploading {
  progress: boolean
  cancelHandlers: EmptyHanlder[]
}

type FinishProps = RouteComponentProps<{
  organizationId: string
}>

export const LoaderWrapper = styled.div`
  margin: 2px 0 7px;
`

export const LoaderCancel = styled.p`
  font-size: 13px;
  color: ${theme.colors.red};
  margin-bottom: 0px;
  padding-bottom: 2px;
  cursor: pointer;
`

interface ExportProps {
  done?: boolean
  className: string
}

export const Export: React.FC<ExportProps> = styled.div`
  background-image: url('${publicURL}/images/Upload.svg');
  background-size: initial;
  background-repeat: no-repeat;
  background-position: top center;
  width: 255px;
  padding-top: 135px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props: ExportProps) =>
    props.done &&
    css`
      background-image: url('${publicURL}/images/Upload-done.svg');
    `}
`

export const ExportTitle = styled.p`
  font-size: 15px;
  margin-bottom: 5px;
  text-align: center;
  font-weight: normal;
`

export const UploadTitle = styled.p`
  font-size: 13px;
  margin-bottom: 13px;
  text-align: center;
  font-weight: normal;
`

export const Separator = styled.div`
  width: 115px;
  padding-top: 30px;
  margin-bottom: 33px;
  border: 1px solid ${theme.colors.extraLightGray};
  border-width: 0 0 1px 0;
`

interface FinishState {
  uploading: Uploading
  dataContainer: DataContainer | null
  exported: boolean
}

export class Finish extends React.Component<FinishProps, FinishState> {
  private writer: Writer

  constructor(props: Readonly<FinishProps>) {
    super(props)
    this.state = this.getDefaultState()

    const user = UserProfile.getUser(getClientId() as string)
    const anonymizationRequired = !!(user && user.anonymizationRequired)
    const anonymizationKey = userKey.getAnomymizationKey()
    const anonymizationPK = userKey.getAnomymizationPK()
    const anonymizationSettingsDomains =
      userKey.getAnonymizationSettingsDomains()
    const hashKeyOnTheFly = Math.random().toString(36).substr(2) // @TODO! move to Store on 1-step

    this.writer = new Writer(
      anonymizationRequired,
      hashKeyOnTheFly,
      anonymizationKey,
      anonymizationPK,
      anonymizationSettingsDomains
    )
    this.writer.setHiddenColumns(projectConfig.getConfig().hiddenColumns ?? [])
  }

  protected getDefaultState = (): FinishState => {
    return {
      uploading: {
        progress: false,
        cancelHandlers: [],
      },
      dataContainer: StoreHR.getData(),
      exported: userKey.isDone(),
    }
  }

  private sendCSV = async () => {
    const uploadedFile = StoreHR.getUploadedFile() as UploadedFile
    const query = getQueryHQ(uploadLinkHQHrTable)

    const cancelHandler = (handler: EmptyHanlder) => {
      const state = { ...this.state }
      state.uploading.cancelHandlers.push(handler)
      this.setState(state)
    }

    this.setState({ uploading: { ...this.state.uploading, progress: true } })

    try {
      const results = await this.writer.sendCSV(
        uploadedFile.file,
        query,
        cancelHandler
      )
      await this.sendCSVFinish(results)
      userKey.setDone()
      notification(`Upload files to Time is Ltd. successfull!`, {
        type: 'success',
      })
      this.setState({
        exported: true,
        uploading: {
          ...this.state.uploading,
          progress: false,
          cancelHandlers: [],
        },
      })
    } catch (_e) {
      notification(`Unable Upload files to Time is Ltd.`, { type: 'error' })
      this.setState({
        uploading: {
          ...this.state.uploading,
          progress: false,
          cancelHandlers: [],
        },
        exported: false,
      })
    }
  }

  private sendCSVFinish = async (results: UploaderResponse[]) => {
    const variables: ExportStatus = {
      csv: null,
      customer_id: getClientId() as string,
    }
    const query = getQueryHQ(uploadDoneHQHrTable)
    for (const result of results) {
      if (
        !result.type ||
        ![UPLOAD_TYPE_CSV, UPLOAD_TYPE_EMAIL_MAP].includes(result.type)
      ) {
        continue
      }
      const value: ExportStatusFile = {
        file_name: result.fileName as string,
        gs_link: result.gsLink as string,
        upload_type: result.type,
      }
      switch (result.type) {
        case UPLOAD_TYPE_EMAIL_MAP: {
          variables['rsa_map'] = value
          break
        }
        default: {
          variables['csv'] = value
          break
        }
      }
    }

    await client.query({ query, variables })
  }

  private sendCSVCancel = () => {
    if (!this.state.uploading.progress) {
      console.warn(
        'Unable to cancel upload to Time is Ltd. - is not in progress'
      )
      return
    }
    for (const handler of this.state.uploading.cancelHandlers) {
      handler()
    }
  }

  private getExportScreen = (): JSX.Element => {
    const isDone = this.state.exported
    const title = isDone ? lang.descUploaded : lang.descUpload
    const exportPart = !this.state.uploading.progress ? (
      <Button
        className="button-export"
        disabled={false}
        text={lang.buttonFinishUpload}
        onClick={this.sendCSV}
      />
    ) : (
      <WrapperFinish>
        <LoaderWrapper>
          <BoxLoader />
        </LoaderWrapper>
        {this.state.uploading.progress ? (
          <LoaderCancel onClick={this.sendCSVCancel}>
            {lang.buttonFinishUploadCancel}
          </LoaderCancel>
        ) : null}
      </WrapperFinish>
    )
    return (
      <WrapperFinish>
        <Export
          done={isDone}
          className={`export ${isDone ? `export-done` : `export-waiting`}`}
        >
          <ExportTitle>{title}</ExportTitle>
          {!isDone ? <>{exportPart}</> : null}
          <Separator />
          <UploadTitle>{lang.descDownloadMapped}</UploadTitle>
          <Button
            className="button-download-csv"
            disabled={false}
            text={lang.buttonFinishDownloadMapped}
            onClick={this.writer.downloadCSV}
            type="text"
          />
        </Export>
      </WrapperFinish>
    )
  }

  render() {
    const { organizationId } = this.props.match.params
    if (
      this.state.dataContainer &&
      this.state.dataContainer.getData() === null
    ) {
      pathPush(
        this.props.history,
        RouteList.excel.path.replace(':organizationId', organizationId)
      )
      return null
    }

    const prevButton = {
      onClick: () => {
        const path = bypassValidation
          ? RouteList.excel.path
          : RouteList.validate.path
        pathPush(
          this.props.history,
          path.replace(':organizationId', organizationId)
        )
      },
      disabled: false,
    }

    return (
      <Container>
        <Header organizationId={organizationId} />
        <ProgressBar
          step={4}
          prevButton={prevButton}
          finished={this.state.exported}
        />
        <Screen>
          <WrapperMain>
            <Title title={`4. ${lang.progressStep4Title}`} />
            <UploaderWrapper gap>{this.getExportScreen()}</UploaderWrapper>
          </WrapperMain>
        </Screen>
      </Container>
    )
  }
}
