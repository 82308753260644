import { 
  PRESET_STORAGE_KEY,
  AnonymizationSettingsDomains,
  targetColumnType,
  targetColumnTypeList,
  OverriddedRule,
  OverriddedRuleType,
  Config,
} from '../libs/types';

export interface User
{
	identity             : string;
	organizationName     : string;
  anonymizationRequired: boolean;
  anonymizationPK     ?: CryptoKey | null;
}

export class UserKey
{
	private key             : string                                  = '';
	private organizationId  : string                                  = '';
	private anonymizationKey: string                                  = '';
	private anonymizationPK : CryptoKey | null                        = null;
	private anonymizationDomains: AnonymizationSettingsDomains | null = null;
	private user            : User | null                             = null;
	private done            : boolean                                 = false;

	constructor()
	{
		this.clear();
	}

	getKey()
	{
		return this.key;
	}

	setKey(key: string)
	{
		this.key = key;
	}

	getOrganizationId()
	{
		return this.organizationId;
	}

	setOrganizationId(organizationId: string)
	{
		this.organizationId = organizationId;
  }
  
  getAnomymizationKey(): string
  {
    return this.anonymizationKey;
  }

  setAnomymizationKey(anonymizationKey: string)
  {
    this.anonymizationKey = anonymizationKey;
  }

  getAnomymizationPK(): CryptoKey | null
  {
    return this.anonymizationPK;
  }

  setAnomymizationPK(anonymizationPK: CryptoKey)
  {
    this.anonymizationPK = anonymizationPK;
  }

  getAnonymizationSettingsDomains(): AnonymizationSettingsDomains | null
  {
    return this.anonymizationDomains;
  }

  setAnonymizationSettingsDomains(anonymizationDomains: AnonymizationSettingsDomains)
  {
    this.anonymizationDomains = anonymizationDomains;
  }

	getUser(): User | null
	{
		return this.user;
	}

	setUser(user: User)
	{
		this.user = user;
	}

	isDone(): boolean
	{
		return this.done;
	}

	setDone()
	{
		this.done = true;
	}

	unsetDone()
	{
		this.done = false;
	}

	clear()
	{
		this.key                  = '';
		this.organizationId       = '';
		this.anonymizationKey     = '';
		this.anonymizationPK      = null;
		this.anonymizationDomains = null;
		this.user                 = null;
		this.done                 = false;
	}
}

export const userKey = new UserKey();

export class UserProfile
{
	static keyLoggedIn      = 'TIL-HR-loggedIn';
	static keyOrganizations = 'TIL-HR-organizations';
	static keyLogout        = 'TIL-HR-logout';
	static keyLastUrl       = 'lastUrl';
  static keyPreset        = PRESET_STORAGE_KEY;
  static flagLoggedIn     = '1';
	
	static isLoggedIn(organizationId: string): boolean
	{
		return (!!localStorage.getItem(organizationId));
	}

	static logout(organizationId: string): void
	{
		localStorage.removeItem(organizationId);
	}

	static login(organizationId: string, user: User): void
	{
		localStorage.setItem(organizationId, JSON.stringify(user));
	}

	static getUser(organizationId: string): User | null
	{
		const user = localStorage.getItem(organizationId);
		if(!user)
		{
			return null;
		}
		try
		{
			const userData = (JSON.parse(user) as User);
			return {
				identity             : userData.identity,
				organizationName     : userData.organizationName,
				anonymizationRequired: userData.anonymizationRequired
			}
		}
		catch(e)
		{
			return null;
		}
	}

	static clearLastUrl(): void
	{
		localStorage.removeItem(this.keyLastUrl);	
	}

	static getLastUrl(): string | null
	{
		return localStorage.getItem(this.keyLastUrl);	
	}

	static setLastUrl(lastUrl: string): void
	{
		localStorage.setItem(this.keyLastUrl, lastUrl);	
	}

	static clearLogoutUrl(organizationId: string)
	{
		localStorage.removeItem(`${organizationId}-logout`);
	}

	static getLogoutUrl(organizationId: string): string | null
	{
		return localStorage.getItem(`${organizationId}-logout`);
	}

	static setLogoutUrl(organizationId: string, logoutUrl: string)
	{
		localStorage.setItem(`${organizationId}-logout`, logoutUrl);
  }

  static getPresetKey(organizationId: string): string
  {
    return `${this.keyPreset}-${organizationId}`;
  }

  static clearPreset(organizationId: string): void
  {
    localStorage.removeItem(this.getPresetKey(organizationId));
  }

  static getPreset(organizationId: string): string | null
  {
    let preset = localStorage.getItem(this.getPresetKey(organizationId));
    if(!preset) // Fallback - old Preset w/o organizationId
    {
      preset = localStorage.getItem(this.keyPreset);
    }
    return preset;
  }

  static setPreset(organizationId: string, preset: string): void
  {
    return localStorage.setItem(this.getPresetKey(organizationId), preset);
  }
}



export class ProjectConfig
{
  private rules: OverriddedRule[] = [];
  private hiddenColumns: targetColumnType[] = [];
  private additionalRequiredColumns: targetColumnType[] = [];
  private allowedOverridedRules: string[] = ['mainDepartmentInsufficient'];
  private allowedRuleTypes: OverriddedRuleType[] = ['error', 'warning'];

  constructor()
  {
    this.clear();
  }

  setConfig(config: any)
  {
    this.clear();
    if(typeof config !== 'object')
    {
      return;
    }
    if('overriddenRules' in config)
    {
      this.setConfigRules(config.overriddenRules)
    }
    if(('hiddenColumns' in config) && Array.isArray(config.hiddenColumns))
    {
      this.hiddenColumns = config.hiddenColumns.filter((column: any) => targetColumnTypeList.includes(column));
    }
    if('additionalRequiredColumns' in config)
    {
      this.additionalRequiredColumns = config.additionalRequiredColumns.filter((column: any) => targetColumnTypeList.includes(column));
    }
  }

  setConfigRules(configRules: any[])
  {
    configRules.forEach(row => {
      if(!this.checkConfigRuleKeys(row)) return;
      switch(row.rule)
      {
        case 'mainDepartmentInsufficient': {
          if(row.options.peopleSufficientLimit >= 0 && row.options.peopleSufficientLimit <= Number.MAX_SAFE_INTEGER)
          {
            this.rules.push({ rule: 'mainDepartmentInsufficient', peopleSufficientLimit: row.options.peopleSufficientLimit, type: row.type });
          }
        }
      }
    })
  }

  getConfig(): Config
  {
    return {
      rules: this.rules,
      hiddenColumns: this.hiddenColumns,
      additionalRequiredColumns: this.additionalRequiredColumns
    }
  }

  addHiddenColumn(targetColumnName: targetColumnType)
  {
    if(!this.isColumnHiddenByName(targetColumnName))
    {
      this.hiddenColumns.push(targetColumnName);
    }
  }

  isColumnHiddenByName(targetColumnName: targetColumnType): boolean
  {
    return this.hiddenColumns.includes(targetColumnName);
  }

  getConfigRules(): OverriddedRule[]
  {
    return this.rules;
  }

  private checkConfigRuleKeys(row: any): boolean
  {
    if(!('rule' in row) || !('options' in row) || !('type' in row))
    {
      return false;
    }
    if(!this.allowedOverridedRules.includes(row.rule) || !this.allowedRuleTypes.includes(row.type) || typeof row.options !== 'object')
    {
      return false;
    }
    switch(row.rule)
    {
      case 'mainDepartmentInsufficient': {
        if(!('peopleSufficientLimit' in row.options))
        {
          return false;
        }
      }
    }
    return true;
  }

  clear()
  {
    this.rules = [];
    this.hiddenColumns = [];
    this.additionalRequiredColumns = [];
  }
}

export const projectConfig = new ProjectConfig();