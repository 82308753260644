import React, { Ref } from 'react';
import { observer } from 'mobx-react-lite';

import { BaseButton } from '../BaseButton';
import { BaseLink } from '../BaseLink';
import { SpacingProps } from '../../utils';

interface Props {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  href?: string;
  title?: string;
  onClick?: () => void;
  spacing?: SpacingProps;
}

export const ButtonLink = observer<Props, HTMLAnchorElement | HTMLButtonElement>(
  (props, ref) => {
    const { className, children, disabled, title, spacing, href, onClick } = props;
    const commonProps = { className, children, disabled, title, spacing, onClick };

    if (href !== undefined) {
      return <BaseLink {...commonProps} href={href} ref={ref as Ref<HTMLAnchorElement>} />;
    }

    return <BaseButton {...commonProps} ref={ref as Ref<HTMLButtonElement>} />;
  },
  { forwardRef: true }
);
