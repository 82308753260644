import * as patterns from './patterns'

const fteCheckPercentageFormat = (value: any) => ((typeof value === 'number') && value > 2 && value <= 200);
const fteCheckPercentageStringFormat = (value: any) => (typeof value === 'string' && patterns.numberPercentFTERE.test(value.trim()));

export const fteNormalize = (value: any) => {
  const valueNumber = (fteCheckPercentageStringFormat(value)) ? parseInt(value, 10) : value;
  if(typeof valueNumber !== 'number') return value;
  return fteCheckPercentageFormat(valueNumber) ? valueNumber / 100 : value;
}
