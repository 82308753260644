import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import { applySpacingProps, SpacingProps } from '../../utils';

interface Props {
  className?: string;
  spacing?: SpacingProps;
}

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading: FC<Props> = styled.div.withConfig<Props>({
  shouldForwardProp: prop => !['spacing'].includes(prop),
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: ${props => props.theme.appColors.brandNormal};
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDE0IDI0Ij4KICA8ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgPHBvbHlnb24gcG9pbnRzPSIxNCAyNCA3LjAyNiAxMiAwIDI0Ii8+CiAgICA8cG9seWdvbiBwb2ludHM9IjAgMCA3LjAyNiAxMiAxNCAwIi8+CiAgPC9nPgo8L3N2Zz4K);
  background-size: 30%;

  animation: ${loaderAnimation} 2s infinite;

  ${props => applySpacingProps(props.spacing)}
`;
