import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { applySpacingProps, SpacingProps } from '../../utils';

interface Props {
  children: React.ReactNode;
  relative?: boolean;
  size?: 'medium';
  spacing?: SpacingProps;
}

export const Card: FC<Props> = styled.div.withConfig<Props>({
  shouldForwardProp: prop => !['relative', 'size', 'spacing'].includes(prop),
})`
  background: ${props => props.theme.colors.white};
  width: ${props => props.theme.card.width[props.size || 'medium']};
  box-shadow: ${props => props.theme.card.boxShadow};
  padding: ${props => props.theme.card.padding[props.size || 'medium']};

  ${props =>
    props.relative &&
    css`
      position: relative;
    `}

  ${props => applySpacingProps(props.spacing)}
`;
