import { cyrb43Hash } from '../utils';
import {
  DateConverter
} from '../dateConverter'
import {
  localDebug
} from '../../rest'
import {
  TargetColumnDefinition,
  targetColumnType
} from '../types'

interface TargetColumnsDate 
{
  start_date: number;
  end_date  : number;
}

interface EdgeDate 
{
  start_date: string;
  end_date  : string;
}

interface DateRange
{
  range: DateConverter[];
  index: number;
}

interface DuplicateRowsResult
{
  duplicate: number[];
  overlaped: number[];
}

type HashMap = Record<string, number[]>;

export class DuplicateRows
{
  private data                        : any[][];
  private processed                   : boolean;
  private targetColumns               : TargetColumnDefinition[];
  private dataHashedDuplicate         : HashMap = {};
  private dataHashedOverlaped         : HashMap = {};
  private targetColumnsDate           : null | TargetColumnsDate;
  private edgeDate                    : EdgeDate;
  private result                      : DuplicateRowsResult;

  readonly hashSeed = parseInt((Math.random() * 100).toString(10), 10);
  readonly targetColumnsDuplicate: targetColumnType[] = ['email_id', 'supervisor_email_id', 'main_department', 'sub_department_0'];
  readonly targetColumnsOverlaped: targetColumnType[] = ['email_id', 'supervisor_email_id'];
  
  constructor(targetColumns: TargetColumnDefinition[], data: any[][])
  {
    let   sourceIndexStartDate                          = null;
    let   sourceIndexEndDate                            = null;
    const targetColumnsMapped: TargetColumnDefinition[] = [];
    this.data              = data;
    this.processed         = false;
    this.targetColumnsDate = null;
    this.edgeDate          = {
      start_date: '1970-01-01',
      end_date  : '2099-12-31',
    }
    this.result = {
      duplicate: [],
      overlaped: []
    }
    for(let i = 0, len = targetColumns.length; i < len; i++)
    {
      const targetColumn = targetColumns[i];
      if(!targetColumn || targetColumn.sourceIndex === null)
      {
        continue;
      }
      targetColumnsMapped.push(targetColumn);
      sourceIndexStartDate = (targetColumn.name === 'start_date') ? targetColumn.sourceIndex : sourceIndexStartDate;
      sourceIndexEndDate   = (targetColumn.name === 'end_date') ? targetColumn.sourceIndex : sourceIndexEndDate;
    }
    this.targetColumns = targetColumnsMapped;
    if(sourceIndexStartDate && sourceIndexEndDate)
    {
      this.targetColumnsDate = {
        start_date: sourceIndexStartDate,
        end_date: sourceIndexEndDate
      }
    }
  }

  private isPairDateConverter = (datePair: any[]): boolean => {
    return (datePair.length === 2 && datePair[0] instanceof DateConverter && datePair[1] instanceof DateConverter);
  }

  private isOverlapDatePairs = (datePairSelected: DateConverter[], datePair: DateConverter[]): boolean => {
    return (!datePair[0].isBiggerThan(datePairSelected[0]) && datePair[1].isBiggerThan(datePairSelected[0])) || 
           (!datePair[0].isBiggerThan(datePairSelected[1]) && datePair[1].isBiggerThan(datePairSelected[1]));
  }

  private isEmptyDate = (date: any): boolean => {
    return (date === null || (typeof date === 'string' && date.trim() === ''));
  }

  private checkForOverlaping = (values: DateRange[]): number[] => {
    const overlaping: Set<number>    = new Set();
    let isOverlaping               = false;
    const invalidPairs: any[]        = [];
    const indexesProcessed: number[] = [];
    for(let i = 0, len = values.length; i < len; i++)
    {
      const datePairIndex: number     = values[i].index;
      const datePair: DateConverter[] = values[i].range;
      if(datePair.length !== 2)
      {
        if(localDebug) console.warn(`checkForOverlaping failed - invalid pair ${JSON.stringify(datePair)} in set`, values);
        isOverlaping = true;
        break;
      }
      if(!this.isPairDateConverter(datePair))
      {
        if(localDebug) console.warn(`checkForOverlaping "${i} not DateConverter pair!`, datePair);
        break;
      }
      if(!datePair[1].isBiggerThan(datePair[0]))
      {
        if(localDebug) console.warn(`checkForOverlaping failed - overlaping pair ${JSON.stringify(datePair)} in set`, values);
        isOverlaping = true;
        overlaping.add(datePairIndex);
        break;
      }
      const valuesFiltered = values.filter((dateRange: DateRange, index: number) => indexesProcessed.includes(index));
      for(let index = 0, lenFiltered = valuesFiltered.length; index < lenFiltered; index++)
      {
        const dateRangeItem: DateRange = valuesFiltered[index];
        if(isOverlaping) continue;
        if(!this.isPairDateConverter(dateRangeItem.range))
        {
          invalidPairs.push(dateRangeItem.range);
          continue;
        }
        if(this.isOverlapDatePairs(datePair, dateRangeItem.range))
        {
          if(localDebug) console.warn(`checkForOverlaping failed - overlaping pair ${JSON.stringify(datePair)} and ${JSON.stringify(dateRangeItem.range)} in sets`, JSON.stringify(dateRangeItem.range), JSON.stringify(datePair));
          isOverlaping = true;
          overlaping.add(datePairIndex);
          overlaping.add(dateRangeItem.index);
          continue;
        }
      }
      if(invalidPairs.length)
      {
        if(localDebug) console.warn(`checkForOverlaping inner not DateConverter pair`, JSON.stringify(invalidPairs));
      }
      indexesProcessed.push(i);
    }
    return Array.from(overlaping);
  }

  private analyzeDateOverlaping = (rows: number[]): number[] => {
    // eslint-disable-next-line
    if(localDebug) console.log('- analyzeDateOverlaping', rows);
    if(this.targetColumnsDate === null)
    {
      if(localDebug) console.warn('analyzeDateOverlaping failed - dates not mapped', rows);
      return rows;
    }

    let invalidDates = 0;
    const values: DateRange[] = [];
    for(let i = 0, len = rows.length; i < len; i++)
    {
      const row            = this.data[rows[i]];
      const   startDateValue = !this.isEmptyDate(row[this.targetColumnsDate.start_date]) ? row[this.targetColumnsDate.start_date] : this.edgeDate.start_date;
      const   endDateValue   = !this.isEmptyDate(row[this.targetColumnsDate.end_date]) ? row[this.targetColumnsDate.end_date] : this.edgeDate.end_date;
      /* DEBUG START */
      if(localDebug && row[this.targetColumnsDate.start_date] !== startDateValue) console.error(`START DATE CHANGED FROM "${row[this.targetColumnsDate.start_date]}" TO "${startDateValue}"`);
      if(localDebug && row[this.targetColumnsDate.end_date]   !== endDateValue  ) console.error(`END DATE CHANGED FROM   "${row[this.targetColumnsDate.end_date]}"   TO "${endDateValue}"  `);
      /* DEBUG END */
      const startDate      = new DateConverter(startDateValue);
      const endDate        = new DateConverter(endDateValue);
      if(startDate.isValid() && endDate.isValid()/* && endDate.isBiggerThan(startDate)*/)
      {
        values.push({range: [ startDate, endDate ], index: rows[i]});
      }
      else
      {
        if(localDebug) console.error(`Invalid date!!`, [startDateValue, endDateValue]);
        if(invalidDates++ === 1) break;
      }
    }
    if(invalidDates > 1)
    {
      const dates: any[] = [];
      for(let i = 0, len = rows.length; i < len; i++)
      {
        const row       = this.data[rows[i]];
        const startDate = (row[this.targetColumnsDate.start_date] instanceof Date) ? row[this.targetColumnsDate.start_date].toISOString().split('T')[0] : row[this.targetColumnsDate.start_date];
        const endDate   = (row[this.targetColumnsDate.end_date] instanceof Date) ? row[this.targetColumnsDate.end_date].toISOString().split('T')[0] : row[this.targetColumnsDate.end_date];
        dates.push([startDate, endDate]);
      }
      if(localDebug) console.warn(`analyzeDateOverlaping failed - invalid dates "${JSON.stringify(dates)}" for rows`, rows);
      return rows;
    }
    const overlapingRows = this.checkForOverlaping(values);
    return overlapingRows;
  }

  private hashRowByTargetColums = (row: any[], rowIndex: number, targetColumns: targetColumnType[], hashMap: HashMap): void =>
  {
    const colsMapped: string[] = [];
    const targetColums = this.targetColumns.filter(targetColumn => targetColumns.includes(targetColumn.name));
    targetColums.forEach((targetColumn: TargetColumnDefinition) => {
      const value = row[targetColumn.sourceIndex!].trim && (row[targetColumn.sourceIndex!] as string).trim().toLowerCase();
      colsMapped.push(value === '' ? '-empty-' : value);
    });
    const hash = cyrb43Hash(colsMapped.join(''), this.hashSeed);
    if(hash in hashMap)
    {
      hashMap[hash].push(rowIndex);
      return;
    }
    hashMap[hash] = [rowIndex];
  }

  hashRow = (row: any[], rowIndex: number): void => 
  {
    this.processed = false;
    const colsMappedDuplicate: string[] = [];
    const colsMappedOverlaped: string[] = [];

    const targetColumsDuplicate = this.targetColumns.filter(targetColumn => this.targetColumnsDuplicate.includes(targetColumn.name));
    const targetColumsOverlaped = this.targetColumns.filter(targetColumn => this.targetColumnsOverlaped.includes(targetColumn.name));

    targetColumsDuplicate.forEach((targetColumn: TargetColumnDefinition) => {
      const value = row[targetColumn.sourceIndex!].trim && (row[targetColumn.sourceIndex!] as string).trim().toLowerCase();
      colsMappedDuplicate.push(value === '' ? '-empty-' : value);
    });

    targetColumsOverlaped.forEach((targetColumn: TargetColumnDefinition) => {
      const value = row[targetColumn.sourceIndex!].trim && (row[targetColumn.sourceIndex!] as string).trim().toLowerCase();
      colsMappedOverlaped.push(value === '' ? '-empty-' : value);
    });

    this.hashRowByTargetColums(row, rowIndex, this.targetColumnsDuplicate, this.dataHashedDuplicate);
    this.hashRowByTargetColums(row, rowIndex, this.targetColumnsOverlaped, this.dataHashedOverlaped);
  }

  process = (): void => {
    if(this.processed)
    {
      throw new Error(`validatorDuplicateRows - process already processed and data freed`);
    }
    let duplicate: number[] = [];
    let overlaped: number[] = [];
    // Process duplicate rows
    for(const hash in this.dataHashedDuplicate)
    {
      
      if(!Object.prototype.hasOwnProperty.call(this.dataHashedDuplicate, hash) || this.dataHashedDuplicate[hash].length === 1)
      {
        continue;
      }
      duplicate = duplicate.concat(this.dataHashedDuplicate[hash]);
    }

    // Process overlaped rows
    for(const hash in this.dataHashedOverlaped)
    {
      if(!Object.prototype.hasOwnProperty.call(this.dataHashedOverlaped, hash) || this.dataHashedOverlaped[hash].length === 1)
      {
        continue;
      }
      if(this.targetColumnsDate === null)
      {
        continue;
      }
      const overlapedPart = this.analyzeDateOverlaping(this.dataHashedOverlaped[hash]);
      overlaped = overlaped.concat(overlapedPart);
    }
    this.result              = { duplicate, overlaped };
    this.dataHashedDuplicate = {};                        // Free memory
    this.dataHashedOverlaped = {};                        // Free memory
    this.processed           = true;
  }

  getDuplicateRows = (): number[] => {
    if(!this.processed)
    {
      throw new Error(`validatorDuplicateRows - getDuplicateRows not processed yet!`);
    }
    return this.result.duplicate;
  }

  getOverlapedRows = (): number[] => {
    if(!this.processed)
    {
      throw new Error(`validatorDuplicateRows - getOverlapedRows not processed yet!`);
    }
    return this.result.overlaped;
  }
}





