import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { ButtonLink } from '../ButtonLink';

import { SidebarContext } from './SidebarContext';

type Props = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  rightEl?: React.ReactNode;
  disabled?: boolean;
  active?: boolean;
  secondary?: boolean;
  withBackground?: boolean;
  className?: string;
  title?: string;
  href?: string;
  onClick?: () => void;
};

type GapSize = 'small' | 'normal' | 'large';

export const SidebarLink = observer<Props, HTMLAnchorElement | HTMLButtonElement>(
  (props, ref) => {
    const { children, icon, rightEl, secondary, href, onClick, ...rest } = props;

    const { variant, sidebarContentVisible } = useContext(SidebarContext);
    const hideIcon = secondary && !icon;
    const gapSize = secondary && icon ? 'small' : variant === 'light' ? 'normal' : 'large';
    const inputIndentation = Boolean(secondary && icon);
    const isClickable = Boolean(href || onClick);

    return (
      <StyledButtonLink
        {...rest}
        href={href}
        onClick={onClick}
        isClickable={isClickable}
        secondary={secondary}
        gapSize={gapSize}
        light={variant === 'light'}
        inputIndentation={inputIndentation}
        ref={ref}
      >
        {!hideIcon && <Icon>{icon}</Icon>}
        <Content gapSize={gapSize} hidden={!sidebarContentVisible}>
          {children}
        </Content>
        {rightEl && <RightEl hidden={!sidebarContentVisible}>{rightEl}</RightEl>}
      </StyledButtonLink>
    );
  },
  { forwardRef: true }
);

type StyledButtonLinkProps = React.ComponentProps<typeof ButtonLink> & {
  active?: boolean;
  withBackground?: boolean;
  secondary?: boolean;
  light?: boolean;
  inputIndentation?: boolean;
  isClickable?: boolean;
  gapSize?: GapSize;
};

const ButtonLinkComponent = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, StyledButtonLinkProps>(
  ({ active, secondary, withBackground, light, inputIndentation, isClickable, gapSize, ...props }, ref) => (
    <ButtonLink {...props} ref={ref} />
  )
);
const StyledButtonLink = styled(ButtonLinkComponent)`
  position: relative;
  padding: ${props => props.theme.sidebar.padding};
  min-height: ${props => props.theme.sidebar.lineHeight};
  font-size: ${props => props.theme.fontSize.small};
  font-weight: ${props => props.theme.fontWeight.medium};
  line-height: ${props => props.theme.lineHeight};
  width: 100%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white75};
  background: none;
  user-select: none;
  border: none;
  outline: none;
  text-align: left;

  ${props =>
    props.isClickable &&
    css`
      cursor: pointer;

      &:hover,
      &:focus {
        color: ${props => props.theme.colors.white};
        box-shadow: none !important;
      }
    `}

  ${props =>
    props.inputIndentation &&
    css`
      padding-left: 51px;
      font-weight: ${props.theme.fontWeight.normal};
    `}

  ${props =>
    props.light &&
    css`
      & {
        color: ${props.theme.colors.mediumGray};
      }
      &:hover,
      &:focus {
        color: ${props.isClickable ? props.theme.colors.darkGray : props.theme.colors.mediumGray};
      }
    `}

  ${props =>
    props.secondary &&
    !props.inputIndentation &&
    css`
      padding-left: 0;
      margin-left: 66px;
      width: calc(100% - 66px);

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
        background: ${props.active ? 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 255, 255, 0.2)'};
      }
    `}

  ${props =>
    props.active &&
    css`
      color: ${props.theme.colors.white};
    `}

  ${props =>
    props.active &&
    props.withBackground &&
    css`
      background: ${props.theme.appColors.brandLight};
    `}

  ${props =>
    props.disabled &&
    css`
      &,
      &:hover,
      &:focus {
        outline: none;
        cursor: not-allowed;
        color: ${props.light ? props.theme.colors.veryLightGray : props.theme.colors.white50};
      }
    `}
`;

const Icon = styled.div`
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Content = styled.div<{ hidden?: boolean; gapSize?: GapSize }>`
  min-width: 0px;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-grow: 1;

  ${props =>
    props.gapSize === 'small' &&
    css`
      margin-left: 2px;
    `}

  ${props =>
    props.gapSize === 'normal' &&
    css`
      margin-left: 6px;
    `}

  ${props =>
    props.gapSize === 'large' &&
    css`
      margin-left: 12px;
    `}
`;

const RightEl = styled.div<{ hidden?: boolean }>`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-shrink: 0;
`;
