import styled from 'styled-components';
import { theme } from '../../ui/theme';

export const WrapperMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  background-color: ${theme.colors.ultraLightGray};
  height: 100%;
  overflow: auto;
`;
