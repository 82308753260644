import { userKey } from "../user";

type MethodPost = 'POST';
type MethodGet  = 'GET';

type Method = MethodGet | MethodPost;

const XMLHttpResponseOK = 200;

export interface XHRError
{
  status : number;
  message: any;
  data   : any;
}

const buildErrorResponse = (status: number, message: any, data: any): XHRError => {
  return { status, message, data }
}

const XHRRequestMiddleware: ((response: any) => any)[] = [];
const XHRErrorRequestMiddleware: ((status: number) => any)[] = [];

const getResponse = (json: string): any => {
  let response = null;
  try
  {
    response = JSON.parse(json);
  }
  catch(e)
  {
    // bellow
  }
  return response;
}

export const addXHRRequestMiddleware = (middleware: (response: any) => any) => {
  XHRRequestMiddleware.push(middleware);
}

export const addXHRErrorRequestMiddleware = (middleware: (status: number) => any) => {
  XHRErrorRequestMiddleware.push(middleware);
}

export const XHRRequestHQ = (url: string, request: any, jwtoken: string, method: Method = 'POST', bypassMiddleware: boolean = false) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.onreadystatechange = () => 
    {
      if(xhr.readyState !== XMLHttpRequest.DONE) 
      {
        return;
      }

      if(xhr.status !== XMLHttpResponseOK)
      {
        const response = getResponse(xhr.responseText);
        if(!bypassMiddleware) XHRErrorRequestMiddleware.map(middleware => middleware(xhr.status));
        reject(buildErrorResponse(xhr.status, 'Unable to process request - failed', response));
        return;
      }

      const response = getResponse(xhr.responseText);
      if(response === null)
      {
        reject(buildErrorResponse(500, `Unable to upload file - JSON error`, null));
        return;
      }
      if(!bypassMiddleware) XHRRequestMiddleware.map(middleware => middleware(response));
      resolve(response);
    }
    xhr.setRequestHeader('Content-Type', `application/json; charset=utf-8`);
    xhr.setRequestHeader('Authorization', 'Bearer ' + jwtoken);
    xhr.withCredentials = true;
    xhr.send(JSON.stringify(request));
  })
}

export const XHRRequest = (url: string, request: any, method: Method = 'POST', bypassMiddleware: boolean = false) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.onreadystatechange = () => 
    {
      if(xhr.readyState !== XMLHttpRequest.DONE) 
      {
        return;
      }

      if(xhr.status !== XMLHttpResponseOK)
      {
        const response = getResponse(xhr.responseText);
        if(!bypassMiddleware) XHRErrorRequestMiddleware.map(middleware => middleware(xhr.status));
        reject(buildErrorResponse(xhr.status, 'Unable to process request - failed', response));
        return;
      }

      const response = getResponse(xhr.responseText);
      if(response === null)
      {
        reject(buildErrorResponse(500, `Unable to upload file - JSON error`, null));
        return;
      }
      if(!bypassMiddleware) XHRRequestMiddleware.map(middleware => middleware(response));
      resolve(response);
    }
    xhr.setRequestHeader('Content-Type', `application/json; charset=utf-8`);
    xhr.setRequestHeader(`TIL-Auth`.toLowerCase(), userKey.getKey());
    xhr.withCredentials = true;
    xhr.send(JSON.stringify(request));
  })
}
