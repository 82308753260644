import React, { FC } from 'react';
import styled from 'styled-components';
import ExitToApp from '@material-ui/icons/ExitToAppOutlined';
import { theme } from '../../ui/theme';
import { publicURL } from '../../../rest';
import { userKey } from '../../../user';
import { useAuth } from '../../../libs';

interface Prop {
  organizationId: string;
  isFileTransfer?: boolean;
}

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 54px;
  position: fixed;
  justify-content: space-between;
  background: ${theme.appColors.brandDark};
  z-index: 100;
  top: 0;
`;

interface HeaderLogoProps
{
  logo: string;
}

const HeaderLogo: React.FC<HeaderLogoProps> = styled.div`
  width: 50%;
  height: 100%;
  background-image: url("${publicURL}/images/${(props: HeaderLogoProps) => props.logo}");
  background-repeat: no-repeat;
  background-position: 8px 10px;
`;

const LogoutButton = styled.div`
  cursor: pointer;
  font-size: 18px;
  height: 18px;
  margin: 18px 21px;
`;

export const Header: FC<Prop> = ({ isFileTransfer = false }) => {
  const logo = isFileTransfer ? 'TIL-file-transfer-logo2020-white.svg' : 'TIL-logo2020-white.svg';
  const { logout } = useAuth();

  const handleLogout = () => {
    userKey.clear();
    logout();
  }

  return (
    <HeaderWrapper>
      <HeaderLogo logo={logo} />
      <LogoutButton onClick={handleLogout}>
        <ExitToApp htmlColor={theme.colors.white} fontSize="inherit" />
      </LogoutButton>
    </HeaderWrapper>
  )
}