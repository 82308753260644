import { observer } from 'mobx-react-lite';
import React, { FC, useContext } from 'react';
import styled, { css } from 'styled-components';

import { transition } from '../../utils';

import { TimelineContext } from './TimelineContext';

interface Props {
  className?: string;
  children?: React.ReactNode;
  size?: number;
  disabled?: boolean;
  withChildren?: boolean;
}

export const TimelineGroupContainer: FC<Props> = observer(({ className, children, size, disabled, withChildren }) => {
  const { allActive, dark, vertical } = useContext(TimelineContext);
  const childrenCount = React.Children.count(children);

  return (
    <Container
      className={className}
      size={size || childrenCount}
      allActive={allActive}
      disabled={disabled}
      dark={dark}
      vertical={vertical}
      withChildren={withChildren}
    >
      {children}
    </Container>
  );
});

interface ContainerProps {
  allActive?: boolean;
  disabled?: boolean;
  size?: number;
  dark?: boolean;
  vertical?: boolean;
  withChildren?: boolean;
}
const Container = styled.div.withConfig<ContainerProps>({
  shouldForwardProp: prop => !['allActive', 'disabled', 'size', 'dark', 'vertical', 'withChildren'].includes(prop),
})`
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  margin-right: ${props => props.theme.timeFilter.group.margin};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${props => props.size};
  background: ${props => (props.dark ? props.theme.colors.veryLightGray : props.theme.colors.white)};
  box-shadow: 0px 1px 2px rgba(69, 90, 100, 0.16);

  ${transition('box-shadow')}

  &:hover {
    box-shadow: 0px 1px 4px rgba(44, 47, 70, 0.5);
  }

  &:last-child {
    margin-right: 0;
  }

  ${props =>
    props.allActive &&
    css`
      &:not(:last-child):before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: -${props => props.theme.timeFilter.group.margin};
        bottom: 0;
        background: ${props.theme.appColors.brandNormal};
        box-shadow: 4px 1px 4px rgba(44, 47, 70, 0.5);
      }

      &:last-child {
        box-shadow: 2px 1px 4px rgba(44, 47, 70, 0.5);
      }
    `}

  ${props =>
    props.disabled &&
    css`
      &:hover {
        box-shadow: none;
      }
    `}

  ${props =>
    props.vertical &&
    css`
      margin-right: 0;
      margin-bottom: 10px;
      height: ${props.withChildren ? props.theme.timeFilter.largeHeight : props.theme.timeFilter.smallHeight};

      ${props.allActive &&
      css`
        &:not(:last-child):before {
          right: 0;
          box-shadow: none;
        }
      `}

      ${props.dark &&
      !props.withChildren &&
      css`
        background: none;
      `}

      &:last-child {
        margin-bottom: 0;
      }
    `}
`;
