import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import Close from '@material-ui/icons/CloseOutlined';
import { 
  Button, 
} from '../components';
import {
  ButtonType
} from '../components/ui';
import { theme } from './ui/theme';

export interface ButtonProps {
  onClick?: () => any;
  title: string;
  type?: ButtonType;
  disabled?: boolean;

}

type JustifyContent = 'left' | 'right' | 'center';

interface Prop {
  onClose?: () => any;
  title?: string;
  leftButton?: ButtonProps;
  rightButton?: ButtonProps;
  closeOnBackground?: boolean;
  justify?: JustifyContent;
  className?: string;
}

interface ContentWrapperProps
{
  justifyContent?: JustifyContent;
}

let refModalWrapper: any = null;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

const ModalElement = styled.div`
  width: 600px;
  background-color: ${theme.colors.white};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.extraLightGray};
  height: 62px;
  user-select: none;

`;

const Title = styled.p`
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
`;

const CloseElement = styled.div`
  cursor: pointer;
`;

const ContentWrapper: React.FC<ContentWrapperProps> = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${({ justifyContent }: ContentWrapperProps) => justifyContent ? `justify-content: ${justifyContent};` : ''}
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 20px 15px;
  width: 100%;
  background-color: ${theme.colors.white};
  height: 70px;
  user-select: none;  
`;

const ActionPlaceholder = styled.div``;

export const Modal: FC<Prop> = ({
  onClose = null,
  title,
  leftButton,
  rightButton,
  closeOnBackground = true,
  justify = 'center',
  className = 'modal',
  children 
}) => {
  return (
    <Fragment>
      <ModalWrapper
        className={className}
        ref={(ref:any) => refModalWrapper = ref} 
        onClick={(e:any) => {
          if(e.target === refModalWrapper && closeOnBackground && onClose)
          {
            onClose();
          }
        }}
      >
        <ModalElement>
          {title ? (
            <TitleWrapper>
              <Title className="title">{title}</Title>
              {onClose && <CloseElement className="close" onClick={onClose}>
                <Close htmlColor={theme.colors.lightGray} />
              </CloseElement>}
            </TitleWrapper>
          ) : null}
          <ContentWrapper justifyContent={justify}>
            {children}
          </ContentWrapper>
          {(leftButton || rightButton) ? (
            <ActionsWrapper>
              <>
                {leftButton ? (
                  <Button 
                    onClick={leftButton.onClick ? leftButton.onClick : undefined} 
                    text={leftButton.title} 
                    disabled={leftButton.disabled}
                    type={leftButton.type ? leftButton.type : 'contained'}
                    className="button-left"
                  />
                ) : <ActionPlaceholder />}
              </>
              <>
                {rightButton ? (
                  <Button 
                    onClick={rightButton.onClick ? rightButton.onClick : undefined} 
                    text={rightButton.title} 
                    disabled={rightButton.disabled} 
                    type={rightButton.type ? rightButton.type : 'contained'}
                    className="button-right"
                  />
                ) : <ActionPlaceholder />}
              </>
            </ActionsWrapper>
          ) : null}
        </ModalElement>
      </ModalWrapper>
    </Fragment>
  
  )
}