import React, { FC } from 'react';
import styled from 'styled-components';

import { BaseButton } from '../BaseButton';

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  title?: string;
}

export const IconButton: FC<Props> = ({ children, onClick, className, title }) => (
  <StyledIconButton onClick={onClick} className={className} title={title}>
    {children}
  </StyledIconButton>
);

const StyledIconButton = styled(BaseButton)`
  display: flex;
  justify-content: center;
`;
