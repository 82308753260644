import * as React from 'react'
import { Fragment } from 'react'
import styled from 'styled-components';
import { BoxLoader } from '../../../components'

export interface UploaderProgressProps
{
  title: string | null;
}

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
`;

const Loader = styled.div`
  margin: 128px 0 26px;
`;

const Title = styled.p`
  text-align: center;
  margin-bottom: 128px;
`;

export const UploaderProgress: React.FC<UploaderProgressProps> = ({
  title
}) => {
  return (
    <Fragment>
      <Wrapper>
        <Loader>
          <BoxLoader />
        </Loader>
        <Title>{title}</Title>
      </Wrapper>
    </Fragment>
  )
}