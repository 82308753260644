import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
}

export const DropdownLabel: FC<Props> = observer(({ children }) => (
  <StyledDropdownLabel onClick={e => e.stopPropagation()}>{children}</StyledDropdownLabel>
));

const StyledDropdownLabel = styled.div`
  padding: 16px 18px 12px;
  border-bottom: 1px solid #e7e7ed;
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: ${props => props.theme.fontSize.small};
  line-height: ${props => props.theme.lineHeight};
  color: ${props => props.theme.colors.darkGray};
`;
