import React from 'react';

import { SidebarState, SidebarVariant } from './SidebarTypes';

interface SidebarContextType {
  sidebarState: SidebarState;
  variant: SidebarVariant;
  sidebarContentVisible: boolean;
  toggleSidebar: () => void;
}

export const SidebarContext = React.createContext<SidebarContextType>({
  sidebarState: 'collapsed',
  sidebarContentVisible: false,
  variant: 'normal',
  toggleSidebar: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});
