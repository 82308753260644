import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../ui/theme';
import { publicURL } from '../../../rest';

export type LoginProvider = 'google' | 'ms';

type LoginButtonProps = {
  provider: LoginProvider
  onClick: (event: any) => void;
}

interface LoginButtonWrapperProps
{
  provider: LoginProvider;
  onClick: any;
}

const LoginButtonWrapper: React.FC<LoginButtonWrapperProps> = styled.div`
  padding: 5px;
  padding-left: 54px;
  width: calc(300px - 54px - 5px);
  display: inline-block;
  position: relative;
  background-color: ${theme.colors.white};
  cursor: pointer;
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 0;
  font-weight: bold;
  color: ${theme.colors.veryLightGray};
  background-repeat: no-repeat;
  background-size: 19px 19px;
  background-position: 25px 12px;

  &:hover {
    color: ${theme.colors.gray};
    background-color: ${theme.colors.extraLightGray};
    border-color: ${theme.colors.gray};
  }

  ${(props: LoginButtonWrapperProps) => props.provider === 'google' && css`
    background-image: url("${publicURL}/images/Google__G__Logo.svg");
  `}

  ${(props: LoginButtonWrapperProps) => props.provider === 'ms' && css`
    background-image: url("${publicURL}/images/Microsoft_logo.svg");
  `}
`;

const LoginButtonTitle = styled.p`
  margin: 9px 5px;
`;

const getTitleByProvider = (provider: LoginProvider): string => {
  let postfix = ``;
  switch(provider)
  {
    case 'ms':
        postfix = 'Microsoft';
        break;
    default:
        postfix = 'Google';
  }
  return `Log in with ${postfix}`;
}

export const LoginButton: React.FC<LoginButtonProps> = ({ 
  onClick,
  provider
}) => {
  return (
    <Fragment>
      <LoginButtonWrapper
        onClick={onClick}
        provider={provider}
      >
        <LoginButtonTitle>{getTitleByProvider(provider)}</LoginButtonTitle>
      </LoginButtonWrapper>
    </Fragment>
  )
}