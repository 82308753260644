import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { IconClose, IconSearch } from '../icons';
import { Input, InputProps } from '../Input';
import { IconButton } from '../IconButton';

interface Props extends Omit<InputProps, 'icon'> {}

export const SearchField = observer<Props, HTMLInputElement>(
  (props, ref) => {
    const { value, onChange, placeholder } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClear = () => {
      onChange('');
      if(inputRef.current)
      {
        inputRef.current.focus();
      }
    };

    return (
      <Input
        {...props}
        ref={ref}
        icon={
          value ? (
            <StyledIconButton onClick={handleClear} title='Clear'>
              <IconClose />
            </StyledIconButton>
          ) : (
            <IconSearch />
          )
        }
        placeholder={placeholder || 'Search'}
      />
    );
  },
  { forwardRef: true }
);

const StyledIconButton = styled(IconButton)`
  &:hover {
    color: ${props => props.theme.colors.white};
  }
`;
