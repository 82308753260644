import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { transition } from '../../utils';
import { IconArrowDown } from '../icons';
import { CollapsiblePanel } from '../CollapsiblePanel';

import { SidebarLink } from './SidebarLink';

interface Props {
  isActive?: boolean;
  isCollapsed?: boolean;
  label: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
  onToggle?: () => void;
}

export const SidebarSection: FC<Props> = observer(props => {
  const { isCollapsed, isActive, label, icon, children, onToggle } = props;

  return (
    <Wrapper>
      <SidebarLink
        icon={icon}
        rightEl={<StyledArrow isCollapsed={isCollapsed} />}
        active={isActive}
        title={label}
        onClick={onToggle}
      >
        <Label>{label}</Label>
      </SidebarLink>
      <CollapsiblePanel isCollapsed={isCollapsed}>{children}</CollapsiblePanel>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  padding: 8px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const Label = styled.div`
  line-height: 20px;
  text-align: left;
  align-self: center;
`;

const StyledArrow = styled(IconArrowDown).withConfig<{ isCollapsed?: boolean }>({
  shouldForwardProp: prop => !['isCollapsed'].includes(prop),
})`
  transform: ${props => (props.isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
  ${transition('transform')}
`;
