import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { applySpacingProps, SpacingProps } from '../../utils';
import { Container } from '../Container';

interface Props {
  className?: string;
  spacing?: SpacingProps;
}

export const TopBar: FC<Props> = observer(props => {
  return <StyledContainer horizontal alignItems='center' justifyContent='space-between' grow={false} {...props} />;
});

const StyledContainer = styled(Container)`
  flex-shrink: 0;
  height: ${props => props.theme.topBar.height};
  padding: 0 ${props => props.theme.topBar.paddingX};
  background: ${props => props.theme.topBar.background};
  border-bottom: 1px solid ${props => props.theme.topBar.borderColor};

  ${props => applySpacingProps(props.spacing)}
`;
