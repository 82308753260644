export const base64StringToArrayBuffer = (b64str: string): ArrayBuffer => {
  const byteStr = window.atob(b64str)
  const bytes = new Uint8Array(byteStr.length)
  for (let i = 0; i < byteStr.length; i++) {
    bytes[i] = byteStr.charCodeAt(i)
  }
  return bytes.buffer
}

export const arrayBufferToBase64String = (buffer: ArrayBuffer, urlSafeEncoding: boolean = false): string => {
  let byteStr: string = '';
  (new Uint8Array(buffer)).forEach((byte: number) => {
    byteStr += String.fromCharCode(byte);
  })
  return urlSafeEncoding ? urlEncode(window.btoa(byteStr)) : window.btoa(byteStr);
}

// Unable using encodeURIComponent because Azure bug. More info in Slack discussion: https://timeisltd.slack.com/archives/C01J0R9SYLF/p1613998329003500
export const urlEncode = (toEncode: string): string => {
  return toEncode
    .replace(/\+/g, '-') // Convert '+' to '-'
    .replace(/\//g, '_') // Convert '/' to '_'
    .replace(/=+$/, ''); // Remove ending '='
}

export const urlDecode = (toDecode: string): string => {
  // Add removed at end '='
  toDecode += Array(5 - toDecode.length % 4).join('=');
  toDecode = toDecode
    .replace(/-/g, '+') // Convert '-' to '+'
    .replace(/_/g, '/'); // Convert '_' to '/'
  return toDecode
}

const toPem = (str: string): string => str.replace(/\\n/gm, '\n');
export const stripKey = (key: string): string => (toPem(key).replace(/\n{0,1}-{5}(BEGIN|END)\s(PUBLIC|PRIVATE)\sKEY-{5}\n{0,1}/g, '').replace(/\n/g, ''));

export const importPublicKey = async (pemContents: string): Promise<CryptoKey> => {
  const publicKeyAlgorithm = {
    name: "RSA-OAEP",
    hash: "SHA-512"
  }
  pemContents = stripKey(pemContents);
  const pemBuffer = base64StringToArrayBuffer(pemContents);
  return await window.crypto.subtle.importKey("spki", pemBuffer, publicKeyAlgorithm, true, ["encrypt"]);
}

export const exportPublicKey = async (publicKey: CryptoKey): Promise<string> => {
  const exported = await window.crypto.subtle.exportKey("spki", publicKey);
  let pemContents = arrayBufferToBase64String(exported);
  pemContents = stripKey(pemContents);
  return pemContents;
}

export const encryptRSA = async (message: string, publicKey: CryptoKey): Promise<string> => {
  const messageEncoded = (new TextEncoder()).encode(message);
  const messageEncrypted = await window.crypto.subtle.encrypt({ name: "RSA-OAEP" }, publicKey, messageEncoded);
  return arrayBufferToBase64String(messageEncrypted);
}