import { useState } from 'react'
import PropTypes from 'prop-types';
import { fetchQuery, fetchQueryCached } from './fetchRequest'

declare type OperationVariables = Record<string, any>;

interface QueryData {
  query: string;
  variables?: any;
  cached?: boolean;
}

export const client = {
  query: (queryData: QueryData): any => {
    const method = (queryData.cached) ? fetchQueryCached : fetchQuery;
    return method(queryData.query, queryData.variables);
  }
}
// eslint-disable-next-line
export interface QueryResult<TData = any, TVariables = OperationVariables> {
  data: TData | undefined;
  error?: any | null;
  loading: boolean;
  refetch: (loading?: boolean) => void;
  // networkStatus: any;
  // called: boolean;
}
// eslint-disable-next-line
export interface QueryFunctionOptions<TData = any, TVariables = OperationVariables> {
  displayName?: string;
  skip?: boolean;
  onCompleted?: (data: TData) => void;
  onError?: (error: any) => void;
}

export interface QueryComponentOptions<
  TData = any,
  TVariables = any
> {
  children: (result: QueryResult<TData, TVariables>) => JSX.Element | null;
  query: any;
  variables: any;
  cached?: boolean;
}

export interface QueryResultState {
  loading: boolean;
  processed: boolean;
  data: any;
  error: any;
}

export function Query<TData = any, TVariables = any>(
  props: QueryComponentOptions<TData, TVariables>
) {
  const { children, query, cached, variables } = props;
  
  const [processed, setProcessed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<any>(null)

  const refetch = (loading?: boolean) => {
    setProcessed(false);
    if(loading) setLoading(true);
  }

  if(!processed)
  {
    setProcessed(true);
    const method = (cached) ? fetchQueryCached : fetchQuery;
    method(query, variables).then((result: any) => {
      setData(result);
    }).catch((error: string) => {
      setError(error);
    }).finally(() => {
      setLoading(false);
    })
  }

  return children({loading, data, error, refetch});
}

Query.propTypes = {
  children: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  variables: PropTypes.object,
  refetch: PropTypes.number
};

