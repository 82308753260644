import React, { FC, useRef, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { Container } from '../Container';
import { applySpacingProps, SpacingProps } from '../../utils';
import { useOnClickOutside } from '../../hooks';
import { SidebarVariant } from './SidebarTypes';
import { SidebarContext } from './SidebarContext';

interface Props {
  className?: string;
  children?: React.ReactNode;
  spacing?: SpacingProps;
  onClickOutside?: () => void;
}

export const SidebarOverlay: FC<Props> = observer(props => {
  const { className, children, spacing, onClickOutside } = props;
  const { variant } = useContext(SidebarContext);

  const panelRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(panelRef, () => (onClickOutside ? onClickOutside() : undefined));

  return (
    <StyledContainer ref={panelRef} className={className} spacing={spacing} variant={variant}>
      {children}
    </StyledContainer>
  );
});

const StyledContainer = styled(Container).withConfig<{ variant?: SidebarVariant }>({
  shouldForwardProp: prop => !['variant'].includes(prop),
})`
  position: absolute;
  height: 100vh;
  width: ${props => props.theme.overlay.width};
  padding: ${props => props.theme.overlay.padding};
  left: ${props => props.theme.sidebar.smallWidth};
  z-index: ${props => props.theme.zIndex.sidebarOverlay};
  border-left: 1px solid ${props => props.theme.colors.white30};
  background: ${props => props.theme.sidebar.background};
  ${props => applySpacingProps(props.spacing)};

  ${props =>
    props.variant === 'light' &&
    css`
      background: ${props.theme.sidebar.backgroundLight};
    `}

  ${props =>
    props.variant === 'dark' &&
    css`
      background: ${props.theme.sidebar.backgroundDark};
    `}
`;
