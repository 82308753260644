// import * as patterns from './patterns'
import {
  targetColumnType
} from './types'
// import {
//   normalizeColumnName
// } from './utils'
import * as validators from './validators';

export interface DetectorResult
{
  type   : targetColumnType;
  index  : number;
  name   : string;
  unique?: number;
}

export interface DetectorAllowed
{
  type : targetColumnType;
  index: number[];
}

class MapNumeric
{
  protected map: Map<number, number>;

  constructor()
  {
    this.map = new Map();
  }

  add(key: number, value: number = 1)
  {
    const currentValue: number = this.map.has(key) ? this.map.get(key)! : 0;
    this.map.set(key, currentValue + value);
  }

  get(): Map<number, number>
  {
    return this.map;
  }

  clear()
  {
    this.map.clear();
  }
}


export class Detector
{
  protected data: any[][]                  = [];
  protected dataRows: number               = 0;
  protected dataHeaders: string[]          = [];
  protected detectedDate: MapNumeric       = new MapNumeric();
  protected detectedEmail: MapNumeric      = new MapNumeric();
  protected detectedEmailAlias: MapNumeric = new MapNumeric();
  protected emptyCols: number[]            = [];
  protected uniqueEmails: Map<number,       Set<string>> = new Map();

  protected isDetected: boolean = false;
  protected results: DetectorResult[] = [];
  protected allowed: DetectorAllowed[] = [];

  constructor(data: any[][])
  {
    this.data        = data;
    this.dataRows    = data.length - 1;
    this.dataHeaders = {...[], ...data[0]};
    this.reset();
  }

  detect()
  {
    this.isDetected = false;
    this.reset();
    if(this.dataRows < 2) return;
    const rows = this.dataRows; const cols = this.data[0].length;
    const validatorIsEmpty = validators.Email.empty;
    for(let rowIndex = 1; rowIndex <= rows; rowIndex++)
    {
      const row = this.data[rowIndex];
      for(let colIndex = 0; colIndex < cols; colIndex++)
      {
        const value = row[colIndex];
        if(validatorIsEmpty(row, colIndex))
        {
          ++this.emptyCols[colIndex];
          continue;
        } 
        // Email alias
        if(validators.EmailAlias.format(row, colIndex))
        {
          this.detectedEmailAlias.add(colIndex);
        }

        // Email
        if(validators.Email.format(row, colIndex))
        {
          this.detectedEmail.add(colIndex);
          this.addUniqueEmail(colIndex, value);
          continue;
        }
        // Date
        if(validators.StartDate.format(row, colIndex))
        {
          this.detectedDate.add(colIndex);
          continue;
        }
      }
    }
    this.postprocessData();
    this.isDetected = true;
  }

  getResults(): DetectorResult[]
  {
    if(!this.isDetected) console.warn('Detector - is not detected already!');
    return this.results;
  }

  getAllowed(): DetectorAllowed[]
  {
    if(!this.isDetected) console.warn('Detector - is not detected already!');
    return this.allowed;
  }

  private postprocessData()
  {
    const rows                           = this.data.length - 1;
    const rowsHalf                       = rows / 2;
    const usedIndexes: number[]          = []
    const detectedEmail                  = this.mapSort(this.detectedEmail.get());
    const detectedEmailAlias             = this.mapSort(this.detectedEmailAlias.get());
    const detectedDate                   = this.mapSort(this.detectedDate.get());
    const uniqueEmails                   = Array.from(this.uniqueEmails.entries()).sort((entry1: [number, Set<string>], entry2: [number, Set<string>]) => entry2[1].size - entry1[1].size);
    const ratioEmail: [number, number][] = [];

    // calculate emailTotal / emailUnique ratio
    for(let i = 0, len = uniqueEmails.length; i < len; i++)
    {
      const entry = uniqueEmails[i];
      const index = entry[0];
      const uniqueValues = entry[1].size;
      ratioEmail.push([index, uniqueValues / detectedEmail[i][1]]);
    }
    ratioEmail.sort((entry1: [number, number], entry2: [number, number]) => entry2[1] - entry1[1]);

    /* RESULTS */
    // Emails
    if(detectedEmail.length)
    {
      // email_id
      const emailEmployee = ratioEmail.filter((entry: [number, number]) => !usedIndexes.includes(entry[0]) && entry[1] > 0.4);
      if(emailEmployee.length)
      {
        const entry = emailEmployee[0];
        this.addToResults('email_id', entry[0]);
        usedIndexes.push(entry[0]);
      }

      // supervisor_email_id
      const emailSupervisor = ratioEmail.filter((entry: [number, number]) => !usedIndexes.includes(entry[0]) && entry[1] > 0.1);
      if(emailSupervisor.length)
      {
        const entry = emailSupervisor[0];
        this.addToResults('supervisor_email_id', entry[0]);
        usedIndexes.push(entry[0]);
      }
    }

    // alias_emails
    this.emptyCols.forEach((count: number, colIndex: number) => { // Add empty colums to emailAlias detected
      if(count === rows) this.detectedEmailAlias.add(colIndex);
    });

    detectedEmailAlias.forEach((entry: [number, number]) => {
      this.addToResults('alias_emails', entry[0])
      usedIndexes.push(entry[0]);
    });

    if(detectedDate.length)
    {
      const startDate = detectedDate.filter((entry: [number, number]) => entry[1] > rowsHalf && !usedIndexes.includes(entry[0]));
      if(startDate.length)
      {
        // start_date
        const entry = startDate[0];
        this.addToResults('start_date', entry[0]);
        usedIndexes.push(entry[0]);
        
        // end_date
        const endDate = detectedDate.filter((entry: [number, number]) => !usedIndexes.includes(entry[0]));
        if(endDate.length)
        {
          const entry = endDate[0];
          this.addToResults('end_date', entry[0]);
          usedIndexes.push(entry[0]);
        }
      }
    }

    /* ALLOWED */
    const emailIndexes      = Array.from(this.detectedEmail.get()).map((entry: [number, number]) => entry[0]);
    const emailAliasIndexes = Array.from(this.detectedEmailAlias.get()).map((entry: [number, number]) => entry[0]);
    const dateIndexes       = Array.from(this.detectedDate.get()).map((entry: [number, number]) => entry[0]);

    (['email_id', 'supervisor_email_id'] as targetColumnType[]).forEach((type: targetColumnType) => {
      this.allowed.push({ type, index: {...[], ...emailIndexes} });
    });
    
    (['alias_emails'] as targetColumnType[]).forEach((type: targetColumnType) => {
      this.allowed.push({ type, index: {...[], ...emailAliasIndexes} });
    });

    this.allowed.push({ type: 'start_date', index: {...[], ...dateIndexes} });
  }

  private addToResults(type: targetColumnType, index: number)
  {
    this.results.push({ type, index, name: this.dataHeaders[index] });
  }

  private mapSort(map: Map<number, number>): [number, number][]
  {
    return Array.from(map.entries()).sort((entry1: number[], entry2: number[]) => entry2[1] - entry1[1]);
  }

  private addUniqueEmail(colIndex: number, value: string)
  {
    if(!this.uniqueEmails.has(colIndex))
    {
      this.uniqueEmails.set(colIndex, new Set());
    }
    this.uniqueEmails.get(colIndex)!.add(value);
  }

  private reset()
  {
    this.detectedDate       = new MapNumeric();
    this.detectedEmail      = new MapNumeric();
    this.detectedEmailAlias = new MapNumeric();
    this.uniqueEmails       = new Map();
    this.results            = [];
    this.allowed            = [];
    this.emptyCols          = new Array(this.dataHeaders.length).fill(0);
  }
}
