import { isDate, dateCheckEmpty } from './validatorStartDate';
import { dates } from '../patterns';
import { dateType } from '../types';

const datePatterns = Object.values(dates) as RegExp[];

export class EndDate
{
  static format = (row: any[], colIndex: number, forceDateType?: dateType | null): boolean => {
    const value = row[colIndex];
    if(forceDateType && !dateCheckEmpty(value))
    {
      return (typeof value === 'string' && dates[forceDateType].test(value));
    }
    if(dateCheckEmpty(value) || isDate(value))
    {
      return true;
    }
    for(const datePattern of datePatterns)
    {
      if(datePattern.test(value))
      {
        return true;
      }
    }
    return false;
  }
}