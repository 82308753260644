import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { applySpacingProps, SpacingProps } from '../../utils';
import { Avatar } from '../Avatar';
import { Container } from '../Container';
import { ScrollableContainer } from '../ScrollableContainer';
import { Text } from '../Text';

type LabelSize = 'small' | 'medium';
interface Props {
  className?: string;

  labelSize?: LabelSize;
  label?: string;
  icon?: React.ReactNode;
  rightEl?: React.ReactNode;
  center?: boolean;
  fullscreen?: boolean;
  scrollable?: boolean;
  noPadding?: boolean;
  spacing?: SpacingProps;
}

export const Widget: FC<Props> = observer(props => {
  const {
    className,
    children,
    icon,
    rightEl,
    labelSize = 'small',
    label,
    center = false,
    fullscreen = false,
    scrollable = false,
    noPadding = false,
    ...rest
  } = props;

  const displayHeader = !fullscreen && Boolean(rightEl || label);

  return (
    <StyledWidget className={className} relative fullscreen={fullscreen} {...rest}>
      {displayHeader && (
        <Header labelSize={labelSize}>
          {icon && (
            <Avatar size={30} background='transparent' color='brandDark' spacing={{ ml: -3, mr: 2 }}>
              {icon}
            </Avatar>
          )}
          <Text fontWeight='medium' size={labelSize} ellipsis grow title={label}>
            {label}
          </Text>
          {rightEl}
        </Header>
      )}

      <Content center={center} scrollable={scrollable} paddingTop={!displayHeader} noPadding={noPadding}>
        {children}
      </Content>
    </StyledWidget>
  );
});

const StyledWidget = styled(Container).withConfig<Pick<Props, 'fullscreen'>>({
  shouldForwardProp: prop => !['fullscreen'].includes(prop),
})`
  overflow: hidden;

  ${props =>
    !props.fullscreen &&
    css`
      background-color: #fff;
      box-shadow: 0 2px 12px rgba(69, 90, 100, 0.16);
      margin: 8px;
    `}

  ${props => applySpacingProps(props.spacing)}
`;

const Header = styled.div<{ labelSize: LabelSize }>`
  display: flex;
  align-items: center;
  height: ${props => props.theme.widget.headerHeight[props.labelSize]};
  flex-shrink: 0;

  padding: ${props => props.theme.widget.padding.y} ${props => props.theme.widget.padding.x};
  padding-right: 34px;
  padding-bottom: 8px;
`;

interface ContentProps {
  center: boolean;
  scrollable: boolean;
  paddingTop: boolean;
  noPadding: boolean;
}
const Content: FC<ContentProps> = observer(props => {
  const { scrollable } = props;

  if (!scrollable) {
    return <StyledContent {...props} />;
  }

  return (
    <StyledScrollableContainer variant='light' size='small'>
      <StyledContent {...props} />
    </StyledScrollableContainer>
  );
});

const StyledScrollableContainer = styled(ScrollableContainer)`
  padding-right: 2px;
`;

const StyledContent = styled.div.withConfig<ContentProps>({
  shouldForwardProp: prop => !['center', 'scrollable', 'paddingTop', 'noPadding'].includes(prop),
})`
  position: relative;
  padding: ${props => props.theme.widget.padding.y} ${props => props.theme.widget.padding.x};

  ${props =>
    props.center &&
    css`
      margin: auto;
    `}

  ${props =>
    !props.paddingTop &&
    css`
      padding-top: 0;
    `}

  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `}

  ${props =>
    !props.scrollable &&
    css`
      overflow: hidden;
    `}
`;
