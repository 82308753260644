import styled from 'styled-components';

export const Scrollbar = styled.div`
  width: calc(100vw - 46px);
  margin: 0 20px 5px 20px;
  background: #eaeaea;
  height: 10px;
  border-radius: 5px;
`;

export const ScrollbarPointer = styled.div`
  position: relative;
  width: 60px;
  height: 10px;
  background: #626262;
  cursor: pointer;
  left: 0px;
  border-radius: 5px;
`;
