import { FC } from 'react';
import styled from 'styled-components';

import { SpacingProps, applySpacingProps } from '../../utils';

interface Props {
  spacing?: SpacingProps;
}

export const Flag: FC<Props> = styled.span.withConfig<Props>({
  shouldForwardProp: prop => !['spacing'].includes(prop),
})`
  display: inline-block;
  font-size: ${props => props.theme.fontSize.xsmall};
  font-weight: ${props => props.theme.fontWeight.medium};
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 0.75em;
  background: ${props => props.theme.appColors.viewIconBackground};
  color: ${props => props.theme.appColors.viewIconColor};
  padding: 0 0.75em;
  vertical-align: middle;
  cursor: default;

  ${props => applySpacingProps(props.spacing)}
`;
