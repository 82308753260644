import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react-lite';

import { useMeasure } from '../../hooks';
import { applySpacingProps, SpacingProps } from '../../utils';

interface Props {
  isCollapsed?: boolean;
  spacing?: SpacingProps;
}

export const CollapsiblePanel: FC<Props> = observer(({ isCollapsed, children, spacing }) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  return (
    <Panel height={height} isCollapsed={isCollapsed}>
      <Inner ref={ref} spacing={spacing}>
        {children}
      </Inner>
    </Panel>
  );
});

const Panel = styled.div.withConfig<{ height: number | null; isCollapsed?: boolean }>({
  shouldForwardProp: prop => !['height', 'isCollapsed'].includes(prop),
})`
  overflow: hidden;
  max-height: ${props => (props.height === null ? 'none' : `${props.height}px`)};
  transition: max-height ${props => props.theme.transition.duration} ${props => props.theme.transition.timingFunction};
  will-change: max-height;

  ${props =>
    props.isCollapsed &&
    css`
      max-height: 0;
    `}
`;

const Inner = styled.div.withConfig<{ spacing?: SpacingProps }>({
  shouldForwardProp: prop => !['spacing'].includes(prop),
})`
  display: flex;
  flex-direction: column;
  ${props => applySpacingProps(props.spacing)}
`;
