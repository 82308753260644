import * as React from 'react'
import { Fragment } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from '../constants';
import { PrivateRoute, PrivateRouteProps } from './private-route'
import { Welcome, WelcomeFileUpload } from './welcome'
import { Excel } from './excel'
import { Validate } from './validate'
import { Finish, FinishFileUpload } from './finish'
import { Auth, Init, Login, Empty } from './auth'
import { IE } from './ie'
import { AuthProvider, getClientId, isFileUploadPart } from '../libs'
import { AUTH_URL } from '../rest';

export interface RouteDefinition {
  name: string;
  path: string;
  parent: null | string;
}

export const isIE = (window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv:11./));

export const RouteList: { [key: string]: RouteDefinition; } = {
  auth: {
    path: '/auth',
    name: 'Auth',
    parent: null
  },
  init: {
    path: '/:organizationId',
    name: 'Init',
    parent: null
  },
  initHRTable: {
    path: '/:organizationId/hr_table',
    name: 'Init',
    parent: null
  },
  initFileUpload: {
    path: '/:organizationId/other',
    name: 'Init',
    parent: null
  },
  login: {
    path: '/:organizationId/hr_table/login',
    name: 'Login',
    parent: null
  },
  loginFileUpload: {
    path: '/:organizationId/other/login',
    name: 'Login',
    parent: null
  },
  excel: {
    path: '/:organizationId/hr_table/excel',
    name: 'Excel',
    parent: null
  },
  welcome: {
    path: '/:organizationId/hr_table/import',
    name: 'Welcome',
    parent: null
  },
  welcomeFileUpload: {
    path: '/:organizationId/other/import',
    name: 'WelcomeFileUpload',
    parent: null
  },
  finish: {
    path: '/:organizationId/hr_table/finish',
    name: 'Finish',
    parent: null
  },
  finishFileUpload: {
    path: '/:organizationId/other/finish',
    name: 'FinishFileUpload',
    parent: null
  },
  validate: {
    path: '/:organizationId/hr_table/validate',
    name: 'Validate',
    parent: null
  },
  empty: {
    path: '/',
    name: 'Empty',
    parent: null
  },
  debug: {
    path: '/debug',
    name: 'Debug',
    parent: null
  },
}

const defaultPrivateRouteProps: PrivateRouteProps = {
  authenticationPath: isFileUploadPart() ? `/:organizationId/other/login` : `/:organizationId/hr_table/login`,
};

const loginPath = RouteList.login.path.replace(':organizationId', getClientId() as string)

const Routes: React.FC = () => {
  if(!isIE)
  {
    return (
      <AuthProvider
        authUrl={AUTH_URL}
        authPagePath={RouteList.auth.path}
        loginPagePath={loginPath}
        demoMode={false}
      >
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Fragment>
              <Switch>
                <PrivateRoute 
                  {...defaultPrivateRouteProps}
                  exact
                  path={RouteList.welcome.path}
                  component={Welcome} 
                />
                <PrivateRoute 
                  {...defaultPrivateRouteProps}
                  exact
                  path={RouteList.welcomeFileUpload.path}
                  component={WelcomeFileUpload} 
                />
                <PrivateRoute 
                  {...defaultPrivateRouteProps}
                  path={RouteList.excel.path}
                  component={Excel} 
                />
                <PrivateRoute
                  {...defaultPrivateRouteProps}
                  path={RouteList.validate.path}
                  component={Validate}
                />
                <PrivateRoute 
                  {...defaultPrivateRouteProps}
                  path={RouteList.finish.path}
                  component={Finish} 
                />
                <PrivateRoute 
                  {...defaultPrivateRouteProps}
                  path={RouteList.finishFileUpload.path}
                  component={FinishFileUpload} 
                />
                <Route 
                  path={RouteList.auth.path}
                  component={Auth} 
                />
                <Route 
                  exact
                  path={RouteList.login.path}
                  component={Login} 
                />
                <Route 
                  exact
                  path={RouteList.loginFileUpload.path}
                  component={Login} 
                />
                <Route 
                  path={RouteList.initHRTable.path}
                  component={Init} 
                />
                <Route 
                  path={RouteList.initFileUpload.path}
                  component={Init} 
                />
                <Route 
                  path={RouteList.init.path}
                  component={Init} 
                />
                <Route 
                  path={RouteList.empty.path}
                  component={Empty} 
                />
                <Redirect path="*" to={RouteList.welcome.path} />
              </Switch>
            </Fragment>
          </BrowserRouter>
          <GlobalStyle />
        </ThemeProvider>
      </AuthProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Fragment>
          <Switch>
            <Route path="/" component={IE} />
            <Redirect path="*" to="/" />
          </Switch>
        </Fragment>
      </BrowserRouter>
      <GlobalStyle />
    </ThemeProvider>
  )
}

export const Router = () => {
  return (
    <Routes />
  )
}
