import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../Modal';
import { lang } from '../../../libs';

interface ModalDeleteFileProps
{
  name     : string;
  onCancel : () => void;
  onConfirm: () => void;
  onClose  : () => void;
}

const Wrapper = styled.div``;

const Title = styled.h2`
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 22px;
`;

const Description = styled.p`
  font-size: 15px;
  font-weight: bold;
`;

export const ModalDeleteFile: React.FC<ModalDeleteFileProps> = ({
  name,
  onCancel,
  onConfirm,
  onClose
}) => {
  return (
    <Modal
      className="modal-delete-file"
      title={lang.titleDeleteFile}
      leftButton={{title: lang.buttonCancel, type: 'outlined', onClick: onCancel}}
      rightButton={{title: lang.buttonDelete, onClick: onConfirm}}
      justify="left"
      onClose={onClose}
    >
      <Wrapper>
        <Title>{lang.descDeleteFile}</Title>
        <Description>{`${lang.askDeleteFile}${name}’?`}</Description>
      </Wrapper>
    </Modal>
  );
}
