import * as React from 'react';
import styled from 'styled-components';
import { 
  IEContainer
} from '../../components';
import { langErrorMsg } from '../../libs';

export const Title = styled.p`
  font-size: 17px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  margin: 27px 0;
`;

export const IE: React.FC = () => {
  return (
    <IEContainer background="#FFFFFF">
      <Title>{langErrorMsg.browserNotSupported}</Title>
    </IEContainer>
  );
}
