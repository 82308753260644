import styled, { css } from 'styled-components';
import { theme } from '../../ui/theme';

interface CellProps
{
  isData?: boolean;
  withoutBorder?: boolean;
  withoutData?: boolean;
}

export const Cell = styled.td`
  background-color: ${theme.colors.white};
  width: 200px;
  
  ${(props: CellProps) => props.isData && css`
    background-color: ${theme.colors.veryLightGray};
    font-size: 13px;
    padding: 14px 1px;
    text-align: center;
  `}

  ${(props: CellProps) => props.withoutData && css`
    color: ${theme.colors.gray};
  `}

  &:not(:last-child) {
    border-right: 1px solid ${(props: CellProps) => props.withoutBorder ? `transparent` : theme.colors.extraLightGray};
  }

  &:last-child {
    border-right: none;
  }

  ${(props: CellProps) => props.withoutBorder && css`
    border-right: 1px solid transparent;
  `}
`;