import { emailCheckEmpty } from './validatorEmail';
import * as moment from 'moment-timezone';

export const timeZoneCheckFormat = (value: any) => (typeof value === 'string') && moment.tz.zone(value.trim());
export const timeZoneCheckEmpty = emailCheckEmpty;

export class TimeZone
{
  static empty = (row: any[], colIndex: number) => {
    const value = row[colIndex];
    return timeZoneCheckEmpty(value);
  }
  
  static format = (row: any[], colIndex: number) => {
    const value = row[colIndex];
    return timeZoneCheckEmpty(value) || timeZoneCheckFormat(value);
  }
}