import React, { FC } from 'react';
import styled from 'styled-components';

interface ScreenProps {
  topOffset?: boolean;
}

interface ScreenWrapperProps
{
  topOffset?: boolean;
}

const ScreenWrapper: React.FC<ScreenWrapperProps> = styled.div`
  ${(props: ScreenWrapperProps) => `
    width: 100%;
    height: calc(100% - ${props.topOffset ? `95` : `54`}px);
    position: absolute;
    top: ${props.topOffset ? `95` : `54`}px;
  `}
`;

export const Screen: FC<ScreenProps> = ({ 
  topOffset = true,
  children 
}) => (
  <ScreenWrapper topOffset={topOffset}>
    {children}
  </ScreenWrapper>
);