import React, { FC } from 'react';
import _RCDropdown from 'rc-dropdown';
import styled, { css } from 'styled-components';

type AlignPoint = 'bc' | 'bl' | 'br' | 'cc' | 'cl' | 'cr' | 'tc' | 'tl' | 'tr';

interface DropdownProps {
  children: React.ReactElement;
  overlay: React.ReactElement;
  align?: {
    points: [AlignPoint, AlignPoint];
    offset: [number, number];
  };
  placement?: 'bottomRight' | 'topRight' | 'bottomLeft' | 'topLeft';
  withArrow?: boolean;
  onVisibleChange?: (visible: boolean) => void;
}

// TODO make our Interface and translate it to rc-dropdown props
export const Dropdown: FC<DropdownProps> = props => {
  const { overlay, withArrow, ...rest } = props;

  return (
    <StyledRCDropdown
      trigger='click'
      minOverlayWidthMatchTrigger={false}
      overlay={<Overlay withArrow={withArrow}>{overlay}</Overlay>}
      {...rest}
    />
  );
};

const RCDropdown: FC<React.ComponentProps<typeof _RCDropdown> & { className?: string }> = ({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-pascal-case
  <_RCDropdown {...props} overlayClassName={className} />
);

const StyledRCDropdown = styled(RCDropdown)`
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: ${props => props.theme.zIndex.dropdown};
  display: block;

  &.rc-dropdown-hidden {
    display: none;
  }
`;

const Overlay = styled.div<{ withArrow?: boolean }>`
  background: ${props => props.theme.dropdown.background};
  width: ${props => props.theme.dropdown.width};
  box-shadow: ${props => props.theme.dropdown.boxShadow};

  ${props =>
    props.withArrow &&
    css`
      &:before {
        content: '';
        position: absolute;
        bottom: 20px;
        left: -${props => props.theme.dropdown.arrowSize * 2}px;
        z-index: 1;
        border: solid ${props => props.theme.dropdown.arrowSize}px transparent;
        border-right-color: ${props => props.theme.dropdown.background};
      }
    `}
`;
