import React from 'react';
import styled, { css } from 'styled-components';
import { Table } from './Table';

interface TableMappingProps
{
  ref?: (element: any) => void | undefined;
  w: number;
  h: number;
  t: number;
  l: number;
}

export const TableMapping: React.FC<TableMappingProps> = styled(Table)`
  &::after {
    position: absolute;
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    height: 200px;
    content: '';
    pointer-events: none;
    ${(props: TableMappingProps) => css`
      width: ${props.w}px;
      top: ${props.t + props.h - 200 + 5}px;
      left: ${props.l}px;
    `}
  }
`;