import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { 
  nameType,
  nameTypes,
  lang,
} from '../../../libs';
import {
  Button
} from  '../../../components';
import { theme } from '../../ui/theme';


interface Prop {
  nameType: nameType;
  mapped: boolean;
  scrollLeft: number;
  onSave: (nameType: nameType) => any;
  onCancel: () => any;
}

interface NameTypeContainerProps
{
  leftOffset: number;
}

const NameTypeContainer: React.FC<NameTypeContainerProps> = styled.div`
  position: absolute;
  z-index: 1;
  width: 276px;
  min-height: 272px;
  margin-left: ${(props: NameTypeContainerProps) => props.leftOffset}px;
  margin-top: 2px;
  background-color: ${theme.colors.white};
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
`;

const Wrapper = styled.div`
  padding: 18px 12px 0 12px;
`;

const Header = styled.h2`
  font-size: 15px;
  padding: 0;
  margin-bottom: 10px;
  text-align: left;
`;


const List = styled.ul`
  list-style: none;
  margin-top: 10px;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: left;
  margin-bottom: 21px;
  cursor: pointer;
`;

interface RadioProps
{
  active: boolean;
}

const Radio: React.FC<RadioProps> = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid ${theme.colors.extraLightGray};
  border-radius: 100%;
  margin-bottom: 16px;
  margin-right: 6px;
  ${(props: RadioProps) => props.active && css`
    border: 7px solid ${theme.colors.blue};
    cursor: default;
  `}
`;

const Select = styled.div`
  display: inline-block;
`;

const SelectTitle = styled.p`
  text-align: left;
  margin-bottom: 6px;
`;

const SelectDescription = styled.p`
  text-align: left;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${theme.colors.ultraLightGray};
  padding: 15px 12px;
  height: 68px;
  width: 100%;
`;

const Warning = styled.div`
  background: ${theme.colors.red};
  border-radius: 4px;
  padding: 10px;
`;

interface NameDesc 
{ 
  name: string;
  desc: string;
}

const namesList: Record<nameType, NameDesc> = {
  'full': {
    name: `Full name`,
    desc: `(1 column to map)`
  },
  'first_last': {
    name: `First name, Last name`,
    desc: `(2 columns to map)`
  },
  'first_middle_last': {
    name: `First name, Middle name, Last name`,
    desc: `(3 columns to map)`
  }
}

const names = (Object.keys(nameTypes) as nameType[]);

export const SubModalMappingName: FC<Prop> = ({
  nameType,
  mapped,
  scrollLeft,
  onCancel,
  onSave
}) => {

  const [type, setType] = useState<nameType>(nameType);

  return (
    <NameTypeContainer leftOffset={(75 + scrollLeft) * -1}>
      <Wrapper>
        <Header>{lang.titleMappingNameType}</Header>
        {mapped ? (
          <Warning>{lang.descMappingNameTypeWarning}</Warning>
        ): null}
        <List>
          {names.map(name => {
            return (
              <ListItem key={`name-type-${name}`} onClick={() => {
                setType(name);
              }}>
                <Radio active={name === type} />
                <Select>
                  <SelectTitle>{namesList[name].name}</SelectTitle>
                  <SelectDescription>{namesList[name].desc}</SelectDescription>
                </Select>
              </ListItem>
            )
          })}
        </List>
      </Wrapper>
      <Actions>
        <Button 
          text={`\u00A0${lang.buttonCancel}\u00A0`} 
          disabled={false}
          onClick={onCancel} 
          type="outlined"
        />
        <Button 
          text={`\u00A0${lang.buttonSave}\u00A0`} 
          disabled={nameType === type}
          onClick={() => {
            onSave(type);
          }} 
        />
      </Actions>
    </NameTypeContainer>
  );
}
