import React from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { ThemeColor } from '../../theme';

import { IconCircle, IconCheck } from '../icons';

type CheckboxType = 'checkbox' | 'radio';

export interface Props {
  checked?: boolean;
  type?: CheckboxType;
  disabled?: boolean;
  inverted?: boolean;
  className?: string;
  color?: ThemeColor;
  onChange?: () => void;
}

export const Checkbox = observer<Props, HTMLInputElement>(
  (props, ref) => {
    const { checked, type = 'checkbox', color, inverted, disabled, className, onChange } = props;

    const handleOnClick = onChange
      ? (e: React.MouseEvent<HTMLInputElement>) => {
          e.stopPropagation();
          onChange();
        }
      : undefined;

    return (
      <CheckboxWrapper className={className}>
        <HiddenCheckbox
          checked={checked}
          disabled={disabled}
          type={type}
          onClick={handleOnClick}
          ref={ref}
          tabIndex={onChange ? undefined : -1}
          readOnly
        />
        <StyledCheckbox active={checked} color={color} disabled={disabled} inverted={inverted} type={type}>
          {checked && (type === 'checkbox' ? <StyledIconCheck /> : <StyledIconCircle />)}
        </StyledCheckbox>
      </CheckboxWrapper>
    );
  },
  { forwardRef: true }
);

const CheckboxWrapper = styled.div`
  position: relative;
`;

const HiddenCheckbox = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
`;

const StyledIconCircle = styled(IconCircle)`
  width: 100%;
  height: 100%;
`;

const StyledIconCheck = styled(IconCheck)`
  width: 100%;
  height: 100%;
`;

interface StyledCheckboxProps {
  active?: boolean;
  color?: ThemeColor;
  disabled?: boolean;
  inverted?: boolean;
  type?: CheckboxType;
}
const StyledCheckbox = styled.div.withConfig<StyledCheckboxProps>({
  shouldForwardProp: prop => !['active', 'color', 'disabled', 'inverted', 'type'].includes(prop),
})`
  display: flex;
  width: ${props => props.theme.checkbox.size};
  height: ${props => props.theme.checkbox.size};
  transition: box-shadow ${props => props.theme.transition.duration};
  color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.veryLightGray};

  ${HiddenCheckbox}:focus + & {
    box-shadow: ${props => props.theme.focus.boxShadow};
  }

  ${({ theme, ...props }) =>
    props.type === 'radio' &&
    css`
      && {
        border-radius: 50%;
        padding: 1px;
        color: ${theme.getColor(props.color, theme.checkbox.color)};
      }
    `}

  ${({ theme, ...props }) =>
    props.active &&
    css`
      border: ${props.type === 'radio' ? 2 : 1}px solid ${theme.getColor(props.color, theme.checkbox.color)};
      background: ${props.type === 'checkbox' ? theme.getColor(props.color, theme.checkbox.color) : 'initial'};
    `}

  ${({ theme, ...props }) =>
    props.inverted &&
    css`
      ${props.active &&
      css`
        && {
          border: 1px solid ${theme.colors.white};
          background: ${theme.colors.white};
          color: ${theme.getColor(props.color, theme.checkbox.color)};
        }
      `}
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: ${props.theme.inputs.disabledOpacity};
    `}
`;
