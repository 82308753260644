import { userKey } from '../user/user-profile'
import {
  UploadedFile,
  UploaderStatus,
  GenericObject,
  UploadInitResponse,
  FileUploadProgressResult,
  CallbackPromise,
  UploaderResponse,
  CancelCallback,
  ProgressCallback,
  ResponseCallback,
  RejectCallback,
  Logger,
  UploadType,
} from './types'
import { isDev } from '../rest'
import { gzip as nodeGzip } from 'node-gzip'
import { getClientId } from './clientId'

const DEBUG = false
const minChunkSize = 262144
const maxChunkSize = 262144 * 8
const CHUNK_RETRY_TIMEOUT = 1000 // Timeout when retry next attemp
const CHUNK_RETRY_LIMIT = 5 // Max attemps when chunk upload failed

const HTTP_STATUS_OK = 200
const HTTP_STATUS_CREATED = 201
const HTTP_STATUS_RESUME_INCOMPLETE = 308
const HTTP_STATUS_DELETED = 499

const METHOD_INIT = 'POST'
const METHOD_CHUNK = 'PUT'
const METHOD_DELETE = 'DELETE'

const loggerDefault: Logger = {
  log: (msg: any) => {
    // eslint-disable-next-line
    console.log(msg)
  },
}

const progressCallbackDefault = (progress: FileUploadProgressResult) => {
  // eslint-disable-next-line
  if (isDev) console.log('upload progress', progress)
}

const buildResponse = (
  status: UploaderStatus,
  message: string,
  fileName: string,
  gsLink: string,
  type: UploadType
): UploaderResponse => {
  return { status, message, gsLink, fileName, type }
}

const buildErrorResponse = (
  status: UploaderStatus,
  message: string
): UploaderResponse => {
  return { status, message }
}

export class Uploader {
  // private url: string;
  private urlConfig: UploadInitResponse

  private file: UploadedFile
  private headers: string[][]
  private chunkSize: number

  private totalSize: number
  private parts: number

  private from: number
  private part: number
  private sizeLeft: number

  private callback: CallbackPromise | null
  private logger: Logger | null
  private progressCallback: ProgressCallback | null
  private cancelCallback: CancelCallback | null

  private chunkRetry: number
  private cancelRequested: boolean

  constructor(
    urlConfig: UploadInitResponse,
    file: UploadedFile,
    headers: string[][],
    chunkSize: number,
    progressCallback?: ProgressCallback | null,
    cancelCallback?: CancelCallback | null,
    logger?: Logger | null
  ) {
    this.urlConfig = urlConfig
    this.file = file
    this.headers = headers
    this.chunkSize = chunkSize

    this.totalSize =
      typeof file.content === 'string'
        ? file.content.length
        : file.content.byteLength
    this.parts = Math.ceil(this.totalSize / chunkSize)
    this.from = 0
    this.part = 0
    this.sizeLeft = this.totalSize

    this.chunkRetry = 0
    this.cancelRequested = false

    this.callback = null
    this.progressCallback =
      progressCallback || (DEBUG ? progressCallbackDefault : null)
    this.cancelCallback = cancelCallback || null
    this.logger = logger || (DEBUG ? loggerDefault : null)
  }

  upload(resolve: ResponseCallback, reject: RejectCallback) {
    this.callback = { resolve, reject }
    if (this.cancelCallback) {
      this.cancelCallback(() => {
        // eslint-disable-next-line
        if (isDev) console.log('Cancel Called!', this)
        this.cancelRequested = true
      })
    }
    this.processChunk()
  }

  private processProgress(): void {
    if (!this.progressCallback) {
      return
    }
    const percent = ((this.totalSize - this.sizeLeft) / this.totalSize) * 100
    const progress = {
      size: this.totalSize,
      processed: this.totalSize - this.sizeLeft,
      remain: this.sizeLeft,
      percent: parseFloat(percent.toFixed(1)),
    }
    if (this.progressCallback) this.progressCallback(progress)
  }

  private processChunk() {
    const size = this.sizeLeft > this.chunkSize ? this.chunkSize : this.sizeLeft
    const to = this.from + size
    const chunk = (this.file.content as string).slice(this.from, to)
    const blob = new Uint8Array(Buffer.from(chunk, 'binary'))
    if (this.logger)
      this.logger.log(
        `- Processing Chunk range ${this.from}-${to}, size: ${blob.length}`
      )
    const xhr = new XMLHttpRequest()
    xhr.open(METHOD_CHUNK, this.urlConfig.url, true)
    for (let i = 0, len = this.headers.length; i < len; i++) {
      const header: string[] = this.headers[i]
      xhr.setRequestHeader(header[0], header[1])
    }
    xhr.setRequestHeader('Content-Type', `application/octet-stream`)
    const length =
      typeof this.file.content === 'string'
        ? this.file.content.length
        : this.file.content.byteLength
    if (this.parts > 1)
      xhr.setRequestHeader(
        'Content-Range',
        `bytes ${this.from}-${to - 1}/${length}`
      )
    xhr.withCredentials = true
    xhr.onreadystatechange = () => {
      this.processChunkResponse(xhr, size)
    }
    xhr.send(blob)
  }

  processCancel() {
    const xhr = new XMLHttpRequest()
    xhr.open(METHOD_DELETE, this.urlConfig.url, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return
      }
      if ([HTTP_STATUS_DELETED].includes(xhr.status)) {
        this.callback!.resolve(
          buildErrorResponse('cancelled', `Upload canceled`)
        )
        return
      }
      this.callback!.reject(
        buildErrorResponse('failed', `Unable to canceled upload`)
      )
    }
    xhr.setRequestHeader('Content-Type', `application/octet-stream`)
    xhr.withCredentials = true
    xhr.send('')
  }

  private processChunkResponse(xhr: XMLHttpRequest, size: number) {
    const filename = this.file.name
    if (xhr.readyState !== XMLHttpRequest.DONE) {
      return
    }

    if (xhr.status === HTTP_STATUS_RESUME_INCOMPLETE) {
      if (this.logger)
        this.logger.log(`File ${filename} chunk from ${this.from} upload ok`)
      this.chunkRetry = 0
      this.part++
      this.from +=
        this.sizeLeft > this.chunkSize ? this.chunkSize : this.sizeLeft
      this.sizeLeft -= size
      this.processProgress()
      if (!this.cancelRequested) {
        this.processChunk()
      } else {
        // cancel request detected
        this.processCancel()
      }
      return
    }

    if ([HTTP_STATUS_OK, HTTP_STATUS_CREATED].includes(xhr.status)) {
      if (this.logger) this.logger.log(`File ${filename} uploaded successfully`) // https://stackoverflow.com/questions/38874928/operator-in-typescript-after-object-method
      this.callback!.resolve(
        buildResponse(
          'uploaded',
          `File uploaded successfully`,
          filename,
          this.urlConfig.gsLink,
          this.urlConfig.type
        )
      )
      return
    }

    if (++this.chunkRetry >= CHUNK_RETRY_LIMIT) {
      if (this.logger)
        this.logger.log(
          `File ${filename} chunk from ${this.from} unable to upload, HTTP status ${xhr.status}, retry attemps reached`
        ) // https://stackoverflow.com/questions/38874928/operator-in-typescript-after-object-method
      this.callback!.reject(
        buildErrorResponse('failed', `Unable to upload file`)
      )
      return
    }

    // Retry invalid chunk
    const chunkRetryTimeoutSec = Math.ceil(CHUNK_RETRY_TIMEOUT / 1000)
    if (this.logger)
      this.logger.log(
        `File ${filename} chunk from ${this.from} upload error - retry in ${chunkRetryTimeoutSec} seconds`
      )
    if (this.cancelRequested) {
      // cancel request detected
      this.processCancel()
      return
    }
    window.setTimeout(() => {
      this.processChunk()
    }, CHUNK_RETRY_TIMEOUT)
  }
}

const UploaderInit = (
  url: string,
  type: UploadType,
  body: GenericObject,
  file: UploadedFile,
  gzip: boolean
): Promise<UploadInitResponse> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    const bodyInit: GenericObject = { ...body }
    bodyInit.customer_id = getClientId() as string
    bodyInit.upload_type = type
    bodyInit.file_name = file.name
    bodyInit.file_type = file.type
    bodyInit.gzip = gzip
    bodyInit.origin = window.location.origin
    xhr.open(METHOD_INIT, url, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return
      }

      if (xhr.status !== 200) {
        reject(
          buildErrorResponse('failed', `Unable to upload file - invalid status`)
        )
        return
      }
      try {
        const response = JSON.parse(xhr.responseText)
        if (
          typeof response !== 'object' ||
          !Object.prototype.hasOwnProperty.call(response, 'gs_link') ||
          !Object.prototype.hasOwnProperty.call(response, 'upload_url')
        ) {
          reject(
            buildErrorResponse(
              'failed',
              `Unable to upload file - initialization failed`
            )
          )
          return
        }
        resolve({
          url: response.upload_url,
          gsLink: response.gs_link,
          type,
        })
      } catch (error) {
        reject(
          buildErrorResponse('failed', `Unable to upload file - JSON error`)
        )
      }
    }
    xhr.setRequestHeader('Content-Type', `application/json; charset=utf-8`)
    xhr.setRequestHeader('Authorization', `Bearer ${userKey.getKey()}`)
    xhr.withCredentials = true
    xhr.send(JSON.stringify(bodyInit))
  })
}

const getOptimalChunkSize = (totalSize: number): number => {
  let chunkBlocks = 2
  let chunkSize = 0
  while (chunkSize < maxChunkSize) {
    chunkSize = minChunkSize * chunkBlocks
    if (chunkSize > totalSize) {
      chunkBlocks /= 2
      chunkSize = minChunkSize * chunkBlocks
      break
    }
    chunkBlocks *= 2
  }
  return chunkSize
}

export const FileUploadGoogle = (
  url: string,
  type: UploadType,
  body: GenericObject,
  file: UploadedFile,
  gzip: boolean,
  headers?: string[][],
  progressCallback?: ProgressCallback | null,
  cancelCallback?: CancelCallback | null,
  logger?: Logger | null
): Promise<UploaderResponse> => {
  return new Promise((resolve, reject) => {
    UploaderInit(url, type, body, file, gzip)
      .then((response) => {
        headers = headers || []
        let chunkSize = getOptimalChunkSize(
          typeof file.content === 'string'
            ? file.content.length
            : file.content.byteLength
        )
        if (gzip) {
          nodeGzip(file.content!).then((compressed: any) => {
            file.content = compressed as string
            chunkSize = file.content.length
            const uploader = new Uploader(
              response,
              file,
              headers!,
              chunkSize,
              progressCallback,
              cancelCallback,
              logger
            )
            uploader.upload(resolve, reject)
          })
        } else {
          const uploader = new Uploader(
            response,
            file,
            headers,
            chunkSize,
            progressCallback,
            cancelCallback,
            logger
          )
          uploader.upload(resolve, reject)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
