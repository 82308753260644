import React from 'react';
import styled, { css } from 'styled-components';
import { Cell } from './Cell';
import { theme } from '../../ui/theme';

interface CellTitleProps
{
  withoutBorder?: boolean;
  required?: boolean;
  onNameTypeChange?: () => any;
}

interface CellTitleElementProps
{
  withoutBorder?: boolean;
}

const CellTitleElement: React.FC<CellTitleElementProps> = styled(Cell)`
  padding: 16px 5px 10px;
  text-align: center;
  ${(props: CellTitleElementProps) => props.withoutBorder && css`
    border-right: 1px solid transparent;
    &:not(:last-child) {
      border-right: 1px solid transparent;
    }
  `}
`;

const SpanRequired = styled.span`
  content: '★';
  color: ${theme.colors.red};
  font-size: 70%;
  vertical-align: super;
  margin-left: 2px;
`;

const SpanNameTypeChange = styled.span`
  color: ${theme.colors.blue};
  border-bottom-width: 1px;
  border-color: ${theme.colors.extraLightGray};
  border-style: solid;
  cursor: pointer;
  margin-left: 4px;

  &:hover {
    border-color: ${theme.colors.blue};
  }

`;

export const CellTitle: React.FC<CellTitleProps> = ({
  withoutBorder,
  required,
  onNameTypeChange,
  children
}) => {
  return (
    <CellTitleElement withoutBorder={withoutBorder}>
      {children}
      {required ? (
        <SpanRequired>★</SpanRequired>
      ) : null}
      {onNameTypeChange ? (
        <SpanNameTypeChange onClick={onNameTypeChange}>Change</SpanNameTypeChange>
      ) : null}
    </CellTitleElement>
  )

}
