import { addXHRErrorRequestMiddleware, XHRRequestHQ } from './xhrrequest'
import { Cache } from './cache'
import { notification } from '../components'
import { userKey } from '../user';

const cacheTimeout = 5 * 60 * 1000; // 5min 

const cache = new Cache(cacheTimeout);

addXHRErrorRequestMiddleware((status: number) => {
  let message = 'Unknown error!';
  switch(status)
  {
    case 403:
      message = 'Action not allowed!';
      notification(message, { type: 'error' });
      break;
  }
  // @DISABLED Unknow error notifications
  // notification(message, { type: 'error' });
})


const getCacheKey = (query: any, options: any): string => {
  const queryPart = (query as string);
  const optionsPart = JSON.stringify(options);
  return btoa(`${queryPart}${optionsPart}`);
}

export const fetchQueryCached = (query: any, options: any): any => {
  return new Promise((resolve, reject) => {
    const key = getCacheKey(query, options);
    const resultCache = cache.getItem(key);
    if(resultCache)
    {
      resolve(resultCache.value);
      return;
    }
    fetchQuery(query, options).then((result: any) => {
      cache.setItem(key, result);
      resolve(result);
    }).catch((error: any) => {
      reject(error);
    })
  })
}

export const fetchQuery = (query: any, options: any): any => {
  options = (options !== undefined) ? options : {};
  return XHRRequestHQ(query as string, options, userKey.getKey());
}

export const clearCache = () => {
  return new Promise<void>((resolve) => {
    cache.clear();
    resolve();
  })
}
