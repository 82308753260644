type ColorsType = keyof typeof COLORS;
type AppColorsType = keyof typeof APP_COLORS;
export type ThemeColor = ColorsType | AppColorsType;

const COLORS = {
  // Gray
  gray: '#212121',
  darkGray: '#2C2F46',
  mediumGray: '#545671',
  lightGray: '#83869C',
  veryLightGray: '#B2B3C1',
  extraLightGray: '#E6E7F1',
  superLightGray: '#F2F2F2',
  ultraLightGray: '#F8F8Fb',

  // Blue
  blue: '#1976D2',
  lightBlue: '#54A0E5',
  veryLightBlue: '#C2E0FB',

  // Green
  green: '#61BE19',

  // Red
  lightRed: '#FFD9D9',
  red: '#FF0000',
  darkRed: '#BB3C38',

  // Purple
  purple: '#4D009D',
  darkPurple: '#36006F',
  lightPurple: '#6D2EAE',
  veryLightPurple: '#B899D8',

  // Yellow
  darkYellow: '#EDCA2C',

  // Black
  black: '#000',
  black50: 'rgba(0, 0, 0, .5)',

  // White
  white: '#FFFFFF',
  whiteTransparent: '#FFFFFF00',
  whiteGray: '#F1F5F7',
  whiteGrayTransparent: '#F1F5F700',
  white20: 'rgba(255, 255, 255, .20)',
  white30: 'rgba(255, 255, 255, .30)',
  white45: 'rgba(255, 255, 255, .45)',
  white50: 'rgba(255, 255, 255, .5)',
  white60: 'rgba(255, 255, 255, .6)',
  white75: 'rgba(255, 255, 255, .75)',
  white85: 'rgba(255, 255, 255, .85)',
  transparent: 'transparent',

  // Success
  success: '#287D3C',
  successBorder: '#5ACA75',
  successLight: '#EDF9F0',

  // Success
  warning: '#B95000',
  warningBorder: '#FF8F39',
  warningLight: '#FFF4EC',

  // Success
  error: '#DA1414',
  errorBorder: '#F48989',
  errorLight: '#FEEFEF',

  // Success
  info: '#2E5AAC',
  infoBorder: '#89A7E0',
  infoLight: '#EEF2FA',
};

const APP_COLORS = {
  brandNormal: COLORS.purple,
  brandDark: COLORS.darkPurple,
  brandLight: COLORS.lightPurple,
  viewIconBackground: COLORS.darkYellow,
  viewIconColor: COLORS.gray,
  backgroundColor: COLORS.whiteGray,
  backgroundColorTransparent: COLORS.whiteGrayTransparent,
};

export const theme = {
  colors: COLORS,
  appColors: APP_COLORS,
  getColor: (key?: ColorsType | AppColorsType, defaultColor?: string) => {
    if (isColorsType(key)) return COLORS[key];
    if (isAppColorsType(key)) return APP_COLORS[key];
    return defaultColor;
  },
  lineHeight: 20 / 14,
  contentMargin: '1.5rem',
  fontSize: {
    xxsmall: '.65rem',
    xsmall: '.75rem',
    small: '.875rem',
    regular: '1rem',
    medium: '1.125rem',
    large: '1.25rem',
    xlarge: '1.5rem',
    xxlarge: '2rem',
    xxxlarge: '2.5rem',
  },
  fontWeight: {
    normal: 400,
    medium: 500,
  },
  transition: {
    duration: '.25s',
    durationMs: 250,
    timingFunction: 'ease-out',
  },
  focus: {
    color: COLORS.veryLightPurple,
    boxShadow: `0 0 0 1px white, 0 0 0 3px ${COLORS.veryLightPurple};`,
  },
  sidebar: {
    smallWidth: '72px',
    normalWidth: '260px',
    largeWidth: '320px',
    lineHeight: '40px',
    padding: '0 25px 0 18px',
    background: APP_COLORS.brandDark,
    backgroundLight: APP_COLORS.backgroundColor,
    backgroundDark: COLORS.darkGray,
  },
  overlay: {
    width: '320px',
    padding: '22px 0',
  },
  timeFilter: {
    smallHeight: '40px',
    largeHeight: '70px',
    group: {
      margin: '12px',
    },
    button: {
      height: '32px',
      margin: '4px',
    },
  },
  inputs: {
    disabledOpacity: 0.3,
  },
  button: {
    height: {
      small: '32px',
      medium: '40px',
      large: '50px',
    },
    paddingX: '10px',
  },
  card: {
    boxShadow: '0px 3px 6px rgba(69, 90, 100, 0.16)',
    padding: {
      medium: '40px',
    },
    width: {
      medium: '440px',
    },
  },
  checkbox: {
    size: '16px',
    color: APP_COLORS.brandNormal,
  },
  dropdown: {
    arrowSize: 9,
    background: COLORS.white,
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
    width: '210px',
  },
  link: {
    color: {
      normal: '#2F2BAD',
      white: COLORS.white,
    },
    borderColor: {
      normal: COLORS.extraLightGray,
      white: COLORS.white20,
    },
  },
  zIndex: {
    sociomapText: 10,
    scrollableGradient: 10,
    scrollableTrack: 20,
    sidebarOverlay: 30,
    tooltip: 99,
    blockFullscreen: 500,
    modal: 999,
    exitFullscreenButton: 1000,
    dropdown: 1070,
    popup: 1100,
  },
  fullscreen: {
    header: {
      height: '64px',
    },
    blockSidebar: {
      width: '345px',
    },
  },
  block: {
    container: {
      background: COLORS.white,
      boxShadow: '0 2px 12px rgba(69, 90, 100, 0.16)',
      margin: '8px',
    },
    chart: {
      paddingX: (fullscreen?: boolean) => (fullscreen ? '20px' : '12px'),
      paddingY: '10px',
      headerHeight: '28px',
      pieChartRatio: (fullscreen?: boolean) => (fullscreen ? 0.6 : 0.5),
    },
  },
  widget: {
    padding: {
      x: '12px',
      y: '10px',
    },
    headerHeight: {
      small: '36px',
      medium: '42px',
    },
  },
  topBar: {
    height: '80px',
    paddingX: '30px',
    background: 'transparent',
    borderColor: COLORS.white30,
  },
};

function isColorsType(key?: string): key is ColorsType {
  return key !== undefined && key in COLORS;
}
function isAppColorsType(key?: string): key is AppColorsType {
  return key !== undefined && key in APP_COLORS;
}

export type Theme = typeof theme;