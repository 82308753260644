import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import throttle from 'lodash.throttle';
import styled, { css } from 'styled-components';
import Scrollbar, { ScrollbarProps } from 'react-scrollbars-custom';

import { transition, SpacingProps, applySpacingProps } from '../../utils';

type Variant = 'normal' | 'light' | 'white' | 'dark';
type Size = 'small' | 'medium' | 'large';

interface Props {
  className?: string;
  children?: React.ReactNode;
  variant?: Variant;
  size?: Size;
  spacing?: SpacingProps;
}

export const ScrollableContainer: FC<Props> = observer(props => {
  const { className, children, variant = 'normal', size = 'large', spacing } = props;

  const [displayTopGradient, setDisplayTopGradient] = useState(false);
  const [displayBottomGradient, setDisplayBottomGradient] = useState(true);

  const handleScroll: ScrollbarProps['onUpdate'] = throttle(scrollValues => {
    const { scrollTop, clientHeight, scrollHeight } = scrollValues;
    setDisplayTopGradient(scrollTop > 0);
    setDisplayBottomGradient(scrollTop + clientHeight !== scrollHeight);
  }, 150);

  return (
    <ScrollbarContainer className={className} spacing={spacing}>
      {displayTopGradient && <GradientOverlay variant={variant} size={size} />}
      <StyledScrollbar
        onUpdate={handleScroll}
        disableTrackYWidthCompensation
        trackYProps={{
          renderer: props => {
            const { elementRef, as, style, ...restProps } = props;
            return <Track {...restProps} ref={elementRef} />;
          },
        }}
        thumbYProps={{
          renderer: props => {
            const { elementRef, as, style, ...restProps } = props;
            return <Thumb {...restProps} ref={elementRef} variant={variant} />;
          },
        }}
        contentProps={{
          renderer: props => {
            const { elementRef, as, ...restProps } = props;
            return <Content {...restProps} ref={elementRef} />;
          },
        }}
      >
        {children}
      </StyledScrollbar>
      {displayBottomGradient && <GradientOverlayBottom variant={variant} size={size} />}
    </ScrollbarContainer>
  );
});

const GradientOverlay = styled.div.withConfig<{ variant: Variant; size: Size }>({
  shouldForwardProp: prop => !['variant', 'size'].includes(prop),
})`
  pointer-events: none;
  position: absolute;
  top: -3px;
  width: 100%;
  height: ${props => (props.size === 'small' ? 10 : props.size === 'medium' ? 24 : 50)}px;
  background: linear-gradient(180deg, #36006f 0%, rgba(54, 0, 111, 0) 100%);
  z-index: ${props => props.theme.zIndex.scrollableGradient};

  ${props =>
    props.variant === 'light' &&
    css`
      background: linear-gradient(
        180deg,
        ${props.theme.appColors.backgroundColor} 0%,
        ${props.theme.appColors.backgroundColorTransparent} 100%
      );
    `}

  ${props =>
    props.variant === 'white' &&
    css`
      background: linear-gradient(180deg, ${props.theme.colors.white} 0%, ${props.theme.colors.whiteTransparent} 100%);
    `}

  ${props =>
    props.variant === 'dark' &&
    css`
      background: linear-gradient(180deg, ${props.theme.colors.darkGray} 0%, transparent 100%);
    `}
`;

const GradientOverlayBottom = styled(GradientOverlay)`
  bottom: -3px;
  top: initial;
  background: linear-gradient(180deg, rgba(54, 0, 111, 0) 0%, #36006f 100%);

  ${props =>
    props.variant === 'light' &&
    css`
      background: linear-gradient(
        180deg,
        ${props.theme.appColors.backgroundColorTransparent} 0%,
        ${props.theme.appColors.backgroundColor} 100%
      );
    `}

  ${props =>
    props.variant === 'white' &&
    css`
      background: linear-gradient(180deg, ${props.theme.colors.whiteTransparent} 0%, ${props.theme.colors.white} 100%);
    `}

  ${props =>
    props.variant === 'dark' &&
    css`
      background: linear-gradient(180deg, transparent 0%, ${props.theme.colors.darkGray} 100%);
    `}
`;

const ScrollbarContainer = styled.div.withConfig<{ spacing?: SpacingProps }>({
  shouldForwardProp: prop => !['spacing'].includes(prop),
})`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;

  ${props => applySpacingProps(props.spacing)}
`;

const Track = styled.span`
  position: absolute;
  overflow: hidden;
  border-radius: 4px;
  user-select: none;
  width: 10px;
  height: 100%;
  right: 0px;
  z-index: ${props => props.theme.zIndex.scrollableTrack};
`;

const Thumb = styled.span.withConfig<{ variant: Variant }>({
  shouldForwardProp: prop => !['variant'].includes(prop),
})`
  display: block;
  border-radius: 4px;
  width: 8px;
  cursor: pointer;
  margin-left: 1px;
  background-color: ${props => props.theme.colors.white45};
  ${transition('background')}

  &:hover {
    background-color: ${props => props.theme.colors.white60};
  }

  ${props =>
    props.variant === 'light' &&
    css`
      & {
        background-color: ${props => props.theme.colors.veryLightGray};
      }
      &:hover {
        background-color: ${props => props.theme.colors.lightGray};
      }
    `}
`;

const StyledScrollbar = styled(Scrollbar)`
  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
