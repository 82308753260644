import React, { FC } from 'react';

import { IconProps, SVG } from './utils';

export const IconPlay: FC<IconProps> = props => (
  <SVG {...props} width='24' height='24' viewBox='0 0 24 24'>
    <g opacity='0.85'>
      <path d='M8 5V19L19 12L8 5Z' fill='currentColor' />
    </g>
  </SVG>
);
