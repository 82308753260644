import React, { Ref } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { focus, applySpacingProps, SpacingProps, transition } from '../../utils';

interface Props {
  children: React.ReactNode;
  href: string;
  className?: string;
  disabled?: boolean;
  external?: boolean;
  title?: string;
  onClick?: () => void;
  spacing?: SpacingProps;
}

export const BaseLink = observer<Props, HTMLAnchorElement | HTMLButtonElement>(
  (props, ref) => {
    const { className, children, external, disabled, title, href, spacing } = props;

    const onClick = disabled ? (e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault() : props.onClick;
    const linkProps = { className, disabled, title, spacing, onClick, ref: ref as Ref<HTMLAnchorElement> };

      const externalProps = external ? { target: '_blank', rel: 'noopener noreferrer' } : {};

      return (
        <Anchor {...linkProps} {...externalProps} href={href}>
          {children}
        </Anchor>
      );
  },
  { forwardRef: true }
);

const Anchor = styled.a.withConfig<{ disabled?: boolean; spacing?: SpacingProps }>({
  shouldForwardProp: prop => !['disabled', 'spacing'].includes(prop),
})`
  ${props =>
    props.disabled &&
    css`
      &,
      &:hover {
        cursor: not-allowed;
      }
    `}

  ${props => applySpacingProps(props.spacing)}
  ${focus}
  ${transition('box-shadow')}
`;
