import { createGlobalStyle } from 'styled-components';

import { normalizeCSS } from './normalizeCss';

export const GlobalStyle = createGlobalStyle`
  ${normalizeCSS};

  html {
    box-sizing: border-box;
    min-width: 960px;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    background: #f8f9fb;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
  }
  body.overlay {
    overflow: hidden;
  }
`;
