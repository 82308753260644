import React from 'react';
import styled from 'styled-components';
import { theme } from '../../ui/theme';
import { lang } from '../../../libs';

interface UploaderLoadedProps
{
  onClick: () => void;
  name: string;
  extension: string;
}

const LoadedWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
`;

const Loaded = styled.div`
  margin: 106px 0 15px;
`;

interface ExtensionProps extends React.HTMLAttributes<unknown>
{
  length: number;
}

const Extension: React.FC<ExtensionProps> = styled.div`
  background-color: ${theme.appColors.brandNormal};
  color: white;
  padding: 26px ${(props: ExtensionProps) => props.length === 4 ? `20` : `15`}px;
  text-transform: uppercase;
`;

const FileNameParent = styled.div`
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid ${theme.colors.extraLightGray};
`;

const FileName = styled(FileNameParent)`
  color: ${theme.appColors.brandNormal};
  margin-bottom: 25px;
  padding-bottom: 3px;
`;

const FileNameDelete = styled(FileNameParent)`
  font-size: 13px;
  color: ${theme.colors.red};
  margin-bottom: 107px;
  padding-bottom: 2px;
  cursor: pointer;
`;

export const UploaderLoaded: React.FC<UploaderLoadedProps> = ({
  onClick,
  name,
  extension
}) => {
  return (
    <LoadedWrapper className="loaded-wrapper">
      <Loaded>
        <Extension className="filename-extension" length={extension.length}>{extension}</Extension>
      </Loaded>
      <FileName className="filename">{name}</FileName>
      <FileNameDelete
        className="filename-delete"
        onClick={onClick}
      >
        {lang.titleDeleteFile}
      </FileNameDelete>
    </LoadedWrapper>
  )
}