import { FC, RefAttributes } from 'react';
import styled, { css } from 'styled-components';

import { ThemeColor } from '../../theme';
import { SpacingProps, applySpacingProps } from '../../utils';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  horizontal?: boolean;
  relative?: boolean;
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-around' | 'space-between' | 'space-evenly';
  grow?: boolean;
  reverse?: boolean;
  spacing?: SpacingProps;
  background?: ThemeColor;
}

export const Container: FC<Props & RefAttributes<HTMLDivElement>> = styled.div.withConfig<Props>({
  shouldForwardProp: prop =>
    !['horizontal', 'relative', 'alignItems', 'justifyContent', 'grow', 'spacing', 'background'].includes(prop),
})`
  display: flex;
  flex-grow: ${props => ((props.grow !== undefined ? props.grow : true) ? 1 : 'initial')};

  align-items: ${props => props.alignItems || 'initial'};
  justify-content: ${props => props.justifyContent || 'initial'};

  position: ${props => (props.relative ? 'relative' : 'initial')};
  background: ${props => props.theme.getColor(props.background || 'transparent')};

  ${props =>
    props.horizontal &&
    css`
      height: 100%;
    `}

  ${props =>
    !props.horizontal &&
    css`
      flex-direction: column;
    `}

  ${props =>
    props.reverse &&
    css`
      flex-direction: ${!props.horizontal ? 'column-reverse' : 'row-reverse'};
    `}

  ${props => applySpacingProps(props.spacing)}
`;
