import { observer } from 'mobx-react-lite';
import React, { FC, useContext } from 'react';
import styled, { css } from 'styled-components';

import { transition } from '../../utils';
import { BaseButton } from '../BaseButton';

import { TimelineContext } from './TimelineContext';
import { TimelineGroupButtonLabel, TimelineGroupButtonContext } from './TimelineGroupButton';

interface Props {
  label: React.ReactNode;
  className?: string;
  active?: boolean;
  disabled?: boolean;
  title?: string;
  onClick?: () => void;
}

export const TimelineButton: FC<Props> = observer(props => {
  const { className, active, disabled, label, title, onClick } = props;
  const { allActive } = useContext(TimelineContext);
  const { groupActive } = useContext(TimelineGroupButtonContext);

  return (
    <StyledButton
      active={active}
      className={className}
      disabled={disabled}
      inActiveGroup={groupActive}
      allActive={allActive}
      title={title}
      onClick={onClick}
    >
      {label}
    </StyledButton>
  );
});
// eslint-disable-next-line
interface StyledButtonProps {
  active?: boolean;
  inActiveGroup?: boolean;
  allActive?: boolean;
}
const StyledButton = styled(BaseButton)<StyledButtonProps>`
  height: ${props => props.theme.timeFilter.button.height};
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.darkGray};
  background: ${props => props.theme.colors.extraLightGray};
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: ${props => props.theme.fontSize.small};
  border: 1px solid transparent;

  ${transition('background, border, box-shadow, color')}

  &:hover {
    background: ${props => props.theme.colors.veryLightGray};
  }

  margin-right: ${props => props.theme.timeFilter.button.margin};
  &:first-child {
    margin-left: ${props => props.theme.timeFilter.button.margin};
  }

  ${props =>
    !(props.active || props.inActiveGroup || props.allActive || props.disabled) &&
    css`
      ${TimelineGroupButtonLabel}:hover + * & {
        border-color: ${props => props.theme.colors.veryLightGray};
      }
    `}

  ${props =>
    props.active &&
    css`
      &,
      &:hover {
        color: ${props.theme.colors.white};
        background: ${props.theme.appColors.brandNormal};
      }
    `}

  ${props =>
    (props.inActiveGroup || props.allActive) &&
    css`
      color: ${props.theme.colors.white};
      background: ${props.theme.appColors.brandLight};

      &:hover {
        background: ${props.theme.appColors.brandDark};
      }
    `}

  ${props =>
    props.title &&
    !props.disabled &&
    css`
      text-decoration: underline dashed ${props.theme.colors.veryLightGray};
      text-underline-position: under;
    `}

  ${props =>
    props.disabled &&
    css`
      &,
      &:hover {
        color: ${props.theme.colors.veryLightGray};
        background: ${props.theme.colors.ultraLightGray};
      }
    `}

  ${props =>
    props.disabled &&
    (props.inActiveGroup || props.allActive) &&
    css`
      border: 1px solid rgba(255, 255, 255, 0.2);

      &,
      &:hover {
        color: ${props.theme.colors.veryLightPurple};
        background: transparent;
      }
    `}
`;
