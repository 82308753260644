import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { theme } from '../../components/ui/theme';
import {
  LoginTitle,
  LoginContainer
} from '../../components';
import {
  isFileUploadPart,
  langErrorMsg
} from '../../libs';

type EmptyProps = RouteComponentProps<{
}>

export const Empty: React.FC<EmptyProps> = () => {
  return (
    <LoginContainer background={theme.colors.white} isFileTransfer={isFileUploadPart()}>
      <LoginTitle>{langErrorMsg.pageNotFound}</LoginTitle>
    </LoginContainer>
  );
}
