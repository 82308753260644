import { dates } from '../patterns'
import { dateType } from '../types';

export const isDate         = (input: any) => (typeof input === "object" && Object.prototype.toString.call(input) === '[object Date]');
export const dateCheckEmpty = (value: string): boolean => {
  const type = typeof value;
  if(!['string', 'object'].includes(type)) return true;
  if(type === 'string') return (value.trim() === '');
  return !isDate(value);
}

const datePatternTypes = Object.keys(dates) as dateType[];

export class StartDate
{
  static empty = (row: any[], colIndex: number): boolean => {
    const value = row[colIndex];
    return dateCheckEmpty(value);
  }

  static format = (row: any[], colIndex: number, forceDateType?: dateType | null): dateType | boolean => {
    const value = row[colIndex];
    if(forceDateType)
    {
      return (typeof value === 'string' && dates[forceDateType].test(value));
    }
    if(isDate(value))
    {
      return true;
    }
    for(const dateType of datePatternTypes)
    {
      const datePattern = dates[dateType];
      if(datePattern.test(value))
      {
        return dateType;
      }
    }
    return false;
  }
}