import * as React from 'react'
import { Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { RouteList } from '../../screens'
import {
  Header,
  ProgressBar,
  Title,
  WrapperMain,
  UploaderWrapper,
  UploaderContent,
  UploaderProgress,
  UploaderLoaded,
  UploaderAnonymizationOverlay,
  DownloadExample,
  UploaderTabSelect,
  ModalDeleteFile,
  Container,
  Screen,
  AnonymizationKey,
  notification,
} from '../../components'
import { 
  lang,
  langErrorMsg,
  StoreHR,
  UploadFile,
  Reader,
  AllowedMIMETypes,
  UploadedFile,
  getEncodingFromBrowser,
  isNotUTF8,
  prependArrayBufferBOM,
  Detector,
  DataContainer,
  DetectorResult,
  pathReplace,
  pathPush,
  localStore,
  isFileUploadPart,
  getClientId,
  setClientId,
} from '../../libs';
import {
  statusColumnTitle,
  AnonymizationSettings,
  CLIENT_STORAGE_KEY,
  FILE_TRANSFER_STORAGE_KEY
} from '../../libs/types';
import {
  publicURL,
} from '../../rest'
import { userKey } from '../../user';

interface WelcomeProps extends RouteComponentProps<{
  organizationId: string;
}> 
{
}

type AnonymizationKeyProcessingType = 'disabled' | 'waiting' | 'processed';

interface WelcomeState
{
  loading: boolean;
  loaded: boolean;
  dragging: boolean;
  uploadFile: null | UploadFile;
  uploadFileDelete: boolean;
  uploadFilename: null | string;
  uploadError: null | string;
  tabsList: null | string[];
  tabSelectPrompt: boolean;
  anonymizationIndex: number;
  anonymizationKeyProcessing: AnonymizationKeyProcessingType;
  anonymizationKeyError: null | boolean;
}

const typeExtensions: Record<AllowedMIMETypes, string> = {
  'text/csv'                                                         : '.csv',
  'application/vnd.ms-excel'                                         : '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
  'application/zip'                                                  : '.zip',
}

export class Welcome extends React.Component<WelcomeProps, WelcomeState>
{
  private  dragCounter: number                   = 0;
  private  uploadArrayBuffer: boolean            = true;
  private  uploadEncodig: string                 = 'UTF-8';
  readonly uploadLimitMB: number                 = 25;
  readonly columnsLimit: number                  = 100;
  readonly uploadMIMEAllowed: AllowedMIMETypes[] = (Object.keys(typeExtensions) as AllowedMIMETypes[]);
  private  loadingTimer: Date                    = new Date();
  private  loadingTimerLimit: number             = 1500;

  private  file: any                             = null;
  private  uploadFile: UploadFile | null         = null;
  
  private  uploadingReadingErrorTimer: any       = null;
  readonly uploadingTimerLimit: number           = 5 * 60 * 1000; // 5 minutes uploading limit
  private  uploadingTimerBreak: boolean          = false;

  private  tabSelected: number                   = 0;

  constructor(props: Readonly<WelcomeProps>)
  {
    super(props);
    this.state = this.getDefaultState();
  }

  protected getDefaultState(): WelcomeState
  {
    return {
      loading: false,
      loaded: false,
      dragging: false,
      uploadFile: null,
      uploadFilename: null,
      uploadFileDelete: false,
      uploadError: null,
      tabsList: null,
      tabSelectPrompt: false,
      anonymizationIndex: 0,
      anonymizationKeyProcessing: 'disabled',
      anonymizationKeyError: null
    }
  }

  componentDidMount()
  {
    const { organizationId } = this.props.match.params;
    localStore.save(CLIENT_STORAGE_KEY, organizationId);
    localStore.save(FILE_TRANSFER_STORAGE_KEY, isFileUploadPart().toString());
    setClientId(organizationId);
    const userData = userKey.getUser();
    if(!userData) {
      pathReplace(this.props.history, RouteList.init.path.replace(':organizationId', organizationId));
      return;
    }
    const data: UploadedFile | null = StoreHR.getUploadedFile();
    const loaded = (data !== null);
    const anonymizationKeyProcessing = (userData && userData.anonymizationRequired) ? 'waiting' : 'disabled';
    this.setState({ anonymizationKeyProcessing, loaded });
  }

  private resetFile = () =>
  {
    StoreHR.resetUploadedFile();
    StoreHR.resetMapping();
    userKey.unsetDone();
    this.file = null;
    this.uploadFile = null;
    this.setState(this.getDefaultState());
  }

  private setReadingErrorTimeout = () =>
  {
    this.uploadingTimerBreak = false;
    this.uploadingReadingErrorTimer = window.setTimeout(() => {
      notification(`Unable to load file, check uploaded file or contact Time is Ltd.`, {type: `error`});
      this.setErrorState(langErrorMsg.invalidFile);
      this.uploadingTimerBreak = true;
    }, this.uploadingTimerLimit);
  }

  private resetReadingErrorTimeout = () =>
  {
    if(this.uploadingReadingErrorTimer)
    {
      window.clearTimeout(this.uploadingReadingErrorTimer);
    }
  }

  private processTabs = () =>
  {
    const file = this.uploadFile;
    if(file === null) // @File missing
    {
      this.resetReadingErrorTimeout();
      this.setState(this.getDefaultState());
      return;
    }
    this.tabSelected = 0;
    const contentReader = new Reader({ columnsLimit: this.columnsLimit });
    try {
      contentReader.load(file.content!);
    } catch(e) {
      const errorMsg = (e instanceof Error && e.message === 'Invalid columns count') ? langErrorMsg.invalidColumnsCount.replace('%1', this.columnsLimit.toString()) : langErrorMsg.invalidFile;
      this.setErrorState(errorMsg);
      return;
    }
    if(contentReader.getSheetCount() === 0)
    {
      // @No Tabs found - reset
      this.resetReadingErrorTimeout();
      this.setState({loading: false, dragging: false, tabSelectPrompt: false, tabsList: null, uploadError: langErrorMsg.invalidFile});
      return;
    }
    if(contentReader.getSheetCount() === 1)
    {
      this.resetReadingErrorTimeout();
      this.onUploaded(file);
      return;
    }
    this.resetReadingErrorTimeout();
    const tabsList = contentReader.getSheetNames();
    this.setState({ tabsList, tabSelectPrompt: true })
  }

  private selectTab = (id: number) => 
  {
    const file = this.uploadFile;
    if(file === null) // @File missing
    {
      this.setState(this.getDefaultState());
      return;
    }
    this.tabSelected = id;
    this.onUploaded(file);
  }

  private onUploaded = (file: UploadFile) =>
  {
    const cleanStatusColumnTitle = (row: any[]): any[] => {
      row.splice(0, 1);
      return row;
    }

    const contentReader = new Reader({ columnsLimit: this.columnsLimit });
    let data;
    try {
      contentReader.load(file.content!);
      data = contentReader.getSheetById(this.tabSelected, true);
    } catch(e: unknown) {
      const errorMsg = (e instanceof Error && e.message === 'Invalid columns count') ? langErrorMsg.invalidColumnsCount.replace('%1', this.columnsLimit.toString()) : langErrorMsg.invalidFile;
      notification(errorMsg, {type: `error`});
      this.dragCounter = 0;
      this.setErrorState(errorMsg);
      return;
    }
    if(data.length && data[0].length && data[0][0] === statusColumnTitle) // Filter first column when detected Status file type
    {
      data = data.map(cleanStatusColumnTitle);
    }
    /* DETECT NOT EXISTS EMAIL COLUMN */
    const detector = new Detector(data);
    detector.detect();
    const colsDetected: DetectorResult[] | null = detector.getResults();
    const isEmailDetected = !!(colsDetected && colsDetected.find(col => col.type === 'email_id' || col.type === 'alias_emails'));
    if(!isEmailDetected)
    {
      notification(`Unable to detect employees emails in uploaded file`, {type: `error`});
      this.dragCounter = 0;
      this.setErrorState(langErrorMsg.invalidContentMissingEmail);
      return;
    }
    StoreHR.setUploadedFile((file as UploadedFile));
    StoreHR.setData(new DataContainer(data));   
    if(this.uploadingTimerBreak)
    {
      StoreHR.resetUploadedFile();
      StoreHR.resetData();
      return;
    }
    this.resetReadingErrorTimeout();
    pathPush(this.props.history, RouteList.excel.path.replace(':organizationId', getClientId() as string))
  }

  private getUploadFile = (fileRef: File): UploadFile => 
  {
    const nameParts = fileRef.name.split('.');
    let extension = '';
    if(nameParts.length > 1)
    {
      extension = nameParts[nameParts.length - 1];
      nameParts.pop();
    }
    const uploadFile: UploadFile = {
      name: fileRef.name,
      namePart: nameParts.join('.'),
      extPart: extension,
      file: fileRef,
      content: null,
      type: null
    }
    switch(extension.toLowerCase())
    {
      case 'xls':
        uploadFile.type = 'application/vnd.ms-excel';
        break;

      case 'xlsx':
        uploadFile.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;

      case 'csv':
        uploadFile.type = 'text/csv';
        break;
    }
    return uploadFile;
  }

  private readFile = (fileRef: File) =>
  {
    if(this.state.loading)
    {
      console.warn('dropHandler Bussy!');
      return;
    }
    this.setReadingErrorTimeout();
    /*
    this.uploadingTimerBreak = false;
    this.uploadingTimer = window.setTimeout(() => {
      notification(`Unable to load file, check uploaded file or contact Time is Ltd.`, {type: `error`});
      this.setState({loading: false, dragging: false, uploadError: langErrorMsg.invalidFile});
      this.uploadingTimerBreak = true;
    }, this.uploadingTimerLimit);
    */
    this.file = fileRef;
    const uploadFile: UploadFile = this.getUploadFile(fileRef);
    if(!this.uploadMIMEAllowed.includes(uploadFile.type as AllowedMIMETypes))
    {
      notification(`Unable to upload other than Excel files`, {type: `error`});
      this.dragCounter = 0;
      this.setErrorState(langErrorMsg.invalidType);
      return;
    }
    if(fileRef.size > (this.uploadLimitMB * 1000000))
    {
      notification(`File is bigger than ${this.uploadLimitMB} MB`, {type: `error`});
      this.dragCounter = 0;
      this.setErrorState(langErrorMsg.invalidSize);
      return;
    }
    const isCSV = uploadFile.type === 'text/csv';
    const reader = new FileReader();
    reader.onload = () => {
      if(this.uploadArrayBuffer)
      {
        const buffer = (reader.result as ArrayBuffer);
        if(isCSV)
        {
          this.uploadArrayBuffer = false;
          this.uploadEncodig = (isNotUTF8(buffer)) ? getEncodingFromBrowser(buffer) : 'UTF-8';
          this.setState({loading: false});
          this.resetReadingErrorTimeout();
          this.readFile(this.file);
          return;
        }
        uploadFile.content = (!isCSV || isNotUTF8(buffer)) ? new Uint8Array(buffer) : prependArrayBufferBOM(buffer);
      }
      else
      {
        uploadFile.content = reader.result;
      }
      const diff = (new Date().getTime()) - this.loadingTimer.getTime();
      const delay = (diff <= this.loadingTimerLimit) ? this.loadingTimerLimit - diff : 1;
      window.setTimeout(() => {
        this.uploadFile = uploadFile;
        this.processTabs();
      }, delay);
    }
    if(this.uploadArrayBuffer)
    {
      reader.readAsArrayBuffer(fileRef);
    }
    else
    {
      reader.readAsText(fileRef, this.uploadEncodig);
    }
    this.loadingTimer = new Date();
    this.setState({ loading: true, loaded: false, uploadError: null, uploadFilename: fileRef.name });
  }

  private dropHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    event.preventDefault();
    if(this.state.loading || this.state.anonymizationKeyProcessing === 'waiting') return;
    let droppedFile = null;
    if(event.dataTransfer.items)
    {
      for(let i = 0; i < event.dataTransfer.items.length; i++)
      {
        if(event.dataTransfer.items[i].kind !== 'file')
        {
          continue;
        }
        const file = event.dataTransfer.items[i].getAsFile();
        if(file === null)
        {
          continue;
        }
        droppedFile = file;
      }
    }
    else
    {
      for(let i = 0; i < event.dataTransfer.files.length; i++)
      {
        droppedFile = event.dataTransfer.files[i];
      }
    }
    if(droppedFile === null)
    {
      this.setState({dragging: false});
      return;
    }
    this.readFile(droppedFile);
  }

  private dragEnterHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    if(this.state.loading || this.state.anonymizationKeyProcessing === 'waiting') return;
    if(this.dragCounter++ === 0) this.setState({dragging: true});
    event.preventDefault();
  }

  private dragExitHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    if(this.state.loading || this.state.anonymizationKeyProcessing === 'waiting') return;
    if(--this.dragCounter === 0) this.setState({dragging: false});
    event.preventDefault();
    return;
  }

  private getUploderContent()
  {
    return (
      <UploaderContent 
        dragging={this.state.dragging}
        uploadError={this.state.uploadError}
        uploadLimitMB={this.uploadLimitMB}
        accept=".xls,.xlsx,.csv"
        readFile={this.readFile}
        onLoading={() => {
          this.setState({loading: true});
        }}
        uploadArrayBuffer={this.uploadArrayBuffer}
        loading={this.state.loading}
        title={lang.descUploadHRTable}
      />
    )
  }

  private getLoaderContent()
  {
    return (
      <UploaderProgress
        title={this.state.uploadFilename}
      />
    )
  }

  private getLoadedContent()
  {
    const uploadedFile: UploadedFile | null = StoreHR.getUploadedFile()!;
    const uploadedFileExt: string = typeExtensions[uploadedFile.type as AllowedMIMETypes];
    return (
      <Fragment>
        <UploaderLoaded 
          name={uploadedFile.name} 
          extension={uploadedFileExt} 
          onClick={() => {
            this.setState({uploadFileDelete: true});
          }}
        />
        {this.state.uploadFileDelete ? this.getDeleteFileModal() : null}
      </Fragment>
    )
  }

  private getUploaderTabSelect()
  {
    return (
      <Fragment>
        <UploaderTabSelect
          tabsList={this.state.tabsList!} 
          onBack={this.resetFile}
          onSelect={this.selectTab}
        />
      </Fragment>
    )
  }

  private getDeleteFileModal()
  {
    const uploadedFile: UploadedFile | null = StoreHR.getUploadedFile()!;
    return (
      <ModalDeleteFile
        name={uploadedFile.name}
        onCancel={() => this.setState({uploadFileDelete: false})}
        onConfirm={this.resetFile}
        onClose={() => this.setState({uploadFileDelete: false})}
      />
    )
  }

  private processAnonymizationKey = (anonymizationSettings: AnonymizationSettings) =>
  {
    window.setTimeout(() => {
      const { anonymizationSalt, rsaPublicKey, ...anonymizationDomainSettings } = anonymizationSettings;
      userKey.setAnomymizationKey(anonymizationSalt);
      userKey.setAnonymizationSettingsDomains(anonymizationDomainSettings);
      this.setState({ anonymizationKeyProcessing: 'processed' });
    }, 250);
  }

  private setErrorState = (uploadError: string) => {
    this.setState({loading: false, dragging: false, uploadError});
  }

  render()
  {
    const { organizationId } = this.props.match.params;
    const anonymizationKeyProcessing = this.state.anonymizationKeyProcessing;
    let content = null;
    if(this.state.loaded)
    {
      content = this.getLoadedContent();
    }
    else 
    {
      if(this.state.loading) 
      {
        content = (this.state.tabSelectPrompt ) ? this.getUploaderTabSelect() : this.getLoaderContent();
      } 
      else 
      {
        content = this.getUploderContent();
      }
    }

    let nextButton;
    if(this.state.loaded)
    {
      nextButton = { 
        onClick: () => {
          pathReplace(this.props.history, RouteList.excel.path.replace(':organizationId', getClientId() as string))
        }, 
        disabled: anonymizationKeyProcessing === 'waiting'
      }
    }

    return (
      <Container>
        <Header organizationId={organizationId} />
        <ProgressBar step={1} nextButton={nextButton} />
        <Screen>
        <WrapperMain>
          <Title title={`1. ${anonymizationKeyProcessing !== 'disabled' ? lang.progressStep1TitleAnonymization : lang.progressStep1Title}`}/>
          {anonymizationKeyProcessing === 'waiting' ? (
            <AnonymizationKey
              key={`anonymization-key-${this.state.anonymizationIndex}`}
              publicKey={userKey.getAnomymizationPK()}
              onUploaded={this.processAnonymizationKey}
            />
          ) : null}
          <UploaderWrapper
            onDrop={this.dropHandler} 
            onDragEnter={this.dragEnterHandler} 
            onDragOver={(event: any) => event.preventDefault()}
            onDragLeave={this.dragExitHandler}
            overflow="visible"
          >
            {anonymizationKeyProcessing === 'waiting' ? (
              <UploaderAnonymizationOverlay className="uploader-anonymization-overlay" />
            ) : null}
            {content}
          </UploaderWrapper>
          <DownloadExample 
            guideDownloadUrl={`${publicURL}/files/guide.xlsx`}
            guideDownloadTitle={lang.titleDownloadGuideLink}
          />
        </WrapperMain>
        </Screen>
    </Container>
    )
  }
}
