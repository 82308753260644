import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { ButtonLink } from '../ButtonLink';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
  rightEl?: React.ReactNode;
} & ({ href: string } | { onClick: () => void });

export const DropdownItem: FC<Props> = observer(props => {
  const { children, icon, rightEl, ...rest } = props;

  return (
    <StyledButtonLink {...rest}>
      <Icon>{icon}</Icon>
      <Content>{children}</Content>
      <RightEl>{rightEl}</RightEl>
    </StyledButtonLink>
  );
});

const StyledButtonLink = styled(ButtonLink)`
  height: 38px;
  width: 100%;
  min-width: 150px;
  display: flex;
  align-items: center;
  padding-left: 18px;
  padding-right: 10px;
  cursor: pointer;
  outline: none;
  border: none;
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.darkGray};
  font-size: ${props => props.theme.fontSize.small};
  line-height: ${props => props.theme.lineHeight};

  &:hover {
    background: ${props => props.theme.colors.ultraLightGray};
  }

  ${props =>
    props.disabled &&
    css`
      &,
      &:hover {
        cursor: not-allowed;
        background: ${props.theme.colors.white};
        color: ${props.theme.colors.veryLightGray};
      }
    `}
`;

const Icon = styled.span<{ disabled?: boolean }>`
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: 3px;
  justify-content: center;
  align-items: center;
`;

const RightEl = styled.span<{ disabled?: boolean }>`
  display: flex;
  margin-left: 10px;
`;

const Content = styled.span`
  display: flex;
  margin-left: 10px;
`;
