import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { RouteList } from '../../screens'
import {
  CLIENT_STORAGE_KEY,
  FILE_TRANSFER_STORAGE_KEY,
  importPublicKey,
  INIT_ERROR__STORAGE_KEY,
  isFileUploadPart,
  langErrorMsg,
  localStore,
  pathPush,
  setClientId,
  useAuth,
} from '../../libs'
import { getQueryHQ, userHQ, XHRRequestHQ } from '../../rest'
import { projectConfig, userKey, UserProfile } from '../../user'
import { Loader } from '../../components/ui'
import { BoxScreen } from '../../components'

type InitProps = RouteComponentProps<{
  organizationId: string
}>

const currentUser = (organizationId: string, token: string): Promise<any> => {
  const query = getQueryHQ(userHQ).replace(':organizationId', organizationId)
  return XHRRequestHQ(query, {}, token, 'GET', true)
}

export const Init: React.FC<InitProps> = ({ history, match }) => {
  const { organizationId } = match.params
  const { token, isLoading, logout } = useAuth()
  localStore.save(CLIENT_STORAGE_KEY, organizationId);
  localStore.save(FILE_TRANSFER_STORAGE_KEY, isFileUploadPart().toString());

  const LoginRoute = isFileUploadPart()
    ? RouteList.loginFileUpload
    : RouteList.login

  const handleError = React.useCallback(
    (error: string) => {
      localStore.save(INIT_ERROR__STORAGE_KEY, error)
      userKey.clear()
      logout()
    },
    [logout]
  )

  React.useEffect(() => {
    if (isLoading) {
      return
    }
    if (!token) {
      pathPush(
        history,
        LoginRoute.path.replace(':organizationId', organizationId)
      )
      return
    }

    ;(async () => {
      const data = await currentUser(organizationId, token)
      if (data.anonymization_required) {
        if (!data.rsa_public_key) {
          handleError(langErrorMsg.invalidConfigEmptyPK)
          return
        }
        try {
          const publicKey = await importPublicKey(data.rsa_public_key)
          userKey.setAnomymizationPK(publicKey)
        } catch {
          handleError(langErrorMsg.invalidConfigInvalidPK)
        }
      }

      projectConfig.clear()
      const userData = {
        identity: data.identity,
        organizationName: data.customer_id,
        anonymizationRequired: data.anonymization_required,
      }
      UserProfile.login(organizationId, userData)
      setClientId(organizationId);
      userKey.setUser(userData)
      userKey.setKey(token)
      if (Object.prototype.hasOwnProperty.call(data, 'config')) {
        projectConfig.setConfig(data.config)
        projectConfig.addHiddenColumn('timezone')
      }
      const WelcomeRoute = isFileUploadPart()
        ? RouteList.welcomeFileUpload
        : RouteList.welcome
      pathPush(
        history,
        WelcomeRoute.path.replace(':organizationId', organizationId)
      )
    })()
  }, [LoginRoute.path, handleError, history, isLoading, organizationId, token])

  if (isLoading) {
    return (
      <BoxScreen>
        <Loader />
      </BoxScreen>
    )
  }

  return null
}
