import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import ReactDOM from 'react-dom'
import { toast } from 'react-toastify'
import { Router } from './screens'

// config toaster
import 'react-toastify/dist/ReactToastify.css'
toast.configure()

const isDev = process.env.NODE_ENV === 'development'
const rootEl = document.getElementById('root')

// @ts-ignore
if (isDev && module.hot) {
  // @ts-ignore
  module.hot.accept('./screens', () => {
    ReactDOM.render(<Router />, rootEl)
  })
}
ReactDOM.render(<Router />, rootEl)
