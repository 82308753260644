import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { StyledTableBody } from './TableBody';
import { TableContext } from './TableContext';

interface Props {
  className?: string;
}

export const TableRow: FC<Props> = observer(({ children, className }) => {
  const { dense, striped, scrollable } = useContext(TableContext);

  return (
    <StyledTableRow dense={dense} striped={striped} scrollable={scrollable} className={className}>
      {children}
    </StyledTableRow>
  );
});

export const StyledTableRow = styled.tr<{ dense?: boolean; striped?: boolean; scrollable?: boolean }>`
  display: flex;
  flex: 1 0 auto;

  ${StyledTableBody} > & {
    height: ${props => (props.dense ? 40 : 54)}px;
    align-items: center;
  }

  ${props =>
    props.striped &&
    css`
      ${StyledTableBody} > &:nth-child(2n - 1) {
        background-color: ${props.theme.colors.ultraLightGray};
      }

      ${StyledTableBody} &:only-child {
        background: none;
      }
    `}
`;
