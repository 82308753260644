import React, { FC } from 'react'
import styled from 'styled-components'
import { theme } from '../../ui'

interface Prop {
  guideDownloadTitle: string
  guideDownloadUrl: string
}

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 50px 0 0;
  user-select: none;
`

const DownloadContaier = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 460px;
  z-index: 1;
`

const DownloadTitle = styled.a`
  font-size: 13px;
  color: ${theme.colors.blue};
  padding-bottom: 2px;
  text-decoration: none;
  border-bottom: 1px solid ${theme.colors.extraLightGray};
`

export const DownloadExample: FC<Prop> = ({
  guideDownloadTitle,
  guideDownloadUrl,
}) => (
  <DownloadWrapper>
    <DownloadContaier>
      <DownloadTitle href={guideDownloadUrl} target="_blank" rel="noreferrer">
        {guideDownloadTitle}
      </DownloadTitle>
    </DownloadContaier>
  </DownloadWrapper>
)
