export interface CacheStorageItem
{
  timeout: number;
  value: any;
}

export interface CacheStorage
{
  [key: string]: CacheStorageItem;
}


export class Cache {
  
  private cacheInvalidateTimeMs: number;
  private storage: CacheStorage;

  constructor(cacheInvalidateTimeMs: number = 0)
  {
    this.cacheInvalidateTimeMs = cacheInvalidateTimeMs;
    this.storage = Object.assign({}, null);
  }

  setItem(key: string, value: any)
  {
    this.storage[key] = {
      timeout: this.getExpiredTimeMs(),
      value
    }
  }

  getItem(key: string): CacheStorageItem | null
  {
    if(key in this.storage)
    {
      const item = this.storage[key];
      if(item.timeout >= this.getTimeMs())
      {
        return item;
      }
      delete this.storage[key];
    }
    return null;
  }

  clear(): void
  {
    this.storage = Object.assign({}, null);
  }

  private getExpiredTimeMs(): number
  {
    return this.getTimeMs() + this.cacheInvalidateTimeMs;
  }

  private getTimeMs(): number
  {
    return (new Date().getTime());
  }
}