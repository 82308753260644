import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../ui';

interface Prop {
  w?: number;
  h?: number;
}

interface WrapperProps
{
  width: number;
  height: number;
}

const Wrapper: React.FC<WrapperProps> = styled.div`
  top: 0;
  left: 0;
  overflow: hidden;
  ${(props: WrapperProps) => `
    width: ${props.width}px;
    height: ${props.height}px;
  `}
`;

interface BoxProps
{
  width: number;
  height: number;
  item?: boolean;
}

const Box: React.FC<BoxProps> = styled.div`
  float: left;
  margin: 0 1px 1px 0;
  ${(props: BoxProps) => `
    width: ${props.width}px;
    height: ${props.height}px;
  `}

  ${(props: BoxProps) => props.item && css`
    background-color: ${theme.appColors.brandDark};
  `}
`;

const places: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
const empty: number = 4;

export const BoxActive: FC<Prop> = ({ 
  w = 50,
  h = 50,
}) => {

  const itemW = (w / 3) - 2;
  const itemH = (h / 3) - 2;

  return (
    <Wrapper width={w} height={h}>
      {places.map((item: any, index: number) => (
        <Box 
          key={`loader-item-${index}`} 
          width={itemW} 
          height={itemH} 
          item={index !== empty} 
        />
      ))}
    </Wrapper>
  )
};