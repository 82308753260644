import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { TableColumnAlign } from '../../types';

import { TableContext } from './TableContext';
import { StyledTableRow } from './TableRow';

interface Props {
  align?: TableColumnAlign;
  size?: number; // Default 100
  colSpan?: number;
  className?: string;
}

export const TableCell: FC<Props> = observer(({ align, size, children, colSpan, className }) => {
  const { borders, dense, scrollable } = useContext(TableContext);

  return (
    <StyledTableCell
      align={align || 'left'}
      borders={borders}
      dense={dense}
      scrollable={scrollable}
      size={size || 100}
      colSpan={colSpan}
      className={className}
    >
      {children}
    </StyledTableCell>
  );
});
// eslint-disable-next-line
interface StyledTableCellProps {
  align: TableColumnAlign;
  borders?: boolean;
  dense?: boolean;
  scrollable?: boolean;
  size: number;
}
export const StyledTableCell = styled.td<StyledTableCellProps>`
  flex: ${props => props.size} 0 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  white-space: nowrap;
  overflow: hidden;
  padding: 0 ${props => (props.dense ? 10 : 20)}px;

  ${props =>
    props.align === 'center' &&
    css`
      justify-content: center;
    `}

  ${props =>
    props.align === 'right' &&
    css`
      justify-content: flex-end;
    `}

  ${props =>
    props.borders &&
    css`
      border-right: 1px solid #cfd8dc;
      border-bottom: 1px solid #cfd8dc;

      &:last-child {
        border-right: none;
      }

      ${StyledTableRow}:last-child > & {
        border-bottom: none;
      }
    `}

  ${props =>
    props.scrollable &&
    props.dense &&
    css`
      &:last-child {
        padding-right: 18px;
      }
    `}
`;
