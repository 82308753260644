import React from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { Avatar } from '../Avatar';
import { ButtonLink } from '../ButtonLink';
import { focus, applySpacingProps, transition } from '../../utils';

export type ButtonType = 'contained' | 'outlined' | 'text';
export type ButtonSize = 'small' | 'medium' | 'large';

type ButtonLinkProps = React.ComponentProps<typeof ButtonLink>;

interface Props extends ButtonLinkProps {
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  fullWidth?: boolean;
  type?: ButtonType;
  size?: ButtonSize;
}

export const Button = observer<Props>(props => {
  const { type = 'contained', size = 'medium', icon, iconRight, children, ...rest } = props;

  return (
    <StyledButtonLink type={type} size={size} {...rest}>
      {icon && (
        <Avatar size={24} background='transparent' spacing={{ mr: 4 }}>
          {icon}
        </Avatar>
      )}
      {children}
      {iconRight && (
        <Avatar size={24} background='transparent' spacing={{ ml: 4 }}>
          {iconRight}
        </Avatar>
      )}
    </StyledButtonLink>
  );
});

type StyledButtonLinkProps = ButtonLinkProps & { type: ButtonType; size: ButtonSize; fullWidth?: boolean };
const StyledButtonLink = styled(ButtonLink).withConfig<StyledButtonLinkProps>({
  shouldForwardProp: prop => !['type', 'size', 'fullWidth'].includes(prop),
})`
  display: flex;
  align-items: center;
  height: ${props => props.theme.button.height[props.size]};
  font-weight: ${props => props.theme.fontWeight.medium};
  font-size: ${props => props.theme.fontSize.regular};
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.appColors.brandLight};
  padding: 0 ${props => props.theme.button.paddingX};

  ${focus}
  ${transition('background, box-shadow')};
  ${props => applySpacingProps(props.spacing)};

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
      justify-content: center;
    `}

  ${props =>
    props.disabled &&
    css`
      &,
      &:hover {
        background: ${props => props.theme.colors.veryLightPurple};
      }
    `}

  ${props =>
    props.type === 'contained' &&
    css`
      &:hover {
        background: ${props => props.theme.appColors.brandDark};
      }
      &:active {
        background: ${props => props.theme.colors.lightPurple};
      }
      &:focus {
        background: ${props => props.theme.colors.purple};
      }
    `}

  ${props =>
    props.type === 'outlined' &&
    css`
      color: ${props => props.theme.colors.darkGray};
      background: ${props => props.theme.colors.white};
      border: 1px solid ${props => props.theme.colors.veryLightGray};

      &:hover {
        background: ${props => props.theme.colors.extraLightGray};
      }
      &:active {
        color: ${props => props.theme.colors.white};
        background: ${props => props.theme.colors.lightGray};
      }

      ${props.disabled &&
      css`
        &,
        &:hover {
          color: ${props => props.theme.colors.veryLightGray};
          background: ${props => props.theme.colors.white};
        }
      `}
    `}

  ${props =>
    props.type === 'text' &&
    css`
      color: ${props => props.theme.colors.darkGray};
      background: ${props => props.theme.colors.white};

      &:hover {
        background: ${props => props.theme.colors.extraLightGray};
      }
      &:active {
        background: ${props => props.theme.colors.veryLightGray};
      }

      ${props.disabled &&
      css`
        &,
        &:hover {
          color: ${props => props.theme.colors.veryLightGray};
          background: ${props => props.theme.colors.white};
        }
      `}
    `}
`;
