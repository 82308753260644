import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { TableContext } from './TableContext';

interface Props {
  // Display borders between table cells and rows
  borders?: boolean;

  // Dense table with decreased padding
  dense?: boolean;

  // Zebra-striped background of rows
  striped?: boolean;

  // Enable scrolling table body
  scrollable?: boolean;
}

export const Table: FC<Props> = observer(({ borders, dense, striped = true, scrollable, ...props }) => (
  <TableContext.Provider value={{ borders, dense, striped, scrollable }}>
    <StyledTable scrollable={scrollable} {...props} />
  </TableContext.Provider>
));

export const StyledTable = styled.table.withConfig<{ scrollable?: boolean }>({
  shouldForwardProp: prop => !['scrollable'].includes(prop),
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #455a64;
  font-size: ${props => props.theme.fontSize.small};
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 2px 12px rgba(69, 90, 100, 0.16);

  ${props =>
    props.scrollable &&
    css`
      max-height: 100%;
      overflow-y: hidden;
      overflow-x: auto;
    `}
`;
