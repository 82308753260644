import * as patterns from '../patterns'
import { fteNormalize } from '../normalizator';
import { emailCheckEmpty } from './validatorEmail';

export const fteCheckFormat = (value: any) => ((typeof value === 'number') && value >= 0 && value <= 2.0) || (typeof value === 'string' && patterns.numberFloatFTERE.test(value.trim()));
export const fteCheckEmpty = emailCheckEmpty;

export class FTE
{
  static empty = (row: any[], colIndex: number) => {
    const value = row[colIndex];
    return fteCheckEmpty(value);
  }
  
  static format = (row: any[], colIndex: number) => {
    const value = fteNormalize(row[colIndex]);
    return fteCheckFormat(value);
  }
}