import React, { FC, useRef, useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { Checkbox } from '../Checkbox';

import { SidebarContext } from './SidebarContext';
import { SidebarLink } from './SidebarLink';

interface Props extends React.ComponentProps<typeof Checkbox> {
  children: React.ReactNode;
  rightEl?: React.ReactNode;
}

export const SidebarCheckbox: FC<Props> = observer(props => {
  const { className, children, rightEl, ...checkboxProps } = props;
  const checkboxRef = useRef<HTMLInputElement>(null);

  const { variant } = useContext(SidebarContext);

  return (
    <SidebarLink
      className={className}
      icon={<Checkbox {...checkboxProps} inverted={variant !== 'light'} ref={checkboxRef} />}
      rightEl={rightEl}
      secondary
      active={checkboxProps.checked}
      disabled={checkboxProps.disabled}
      onClick={() => checkboxRef.current ? checkboxRef.current.click() : undefined}
    >
      {children}
    </SidebarLink>
  );
});
