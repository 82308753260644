import { MappingDefinition, TargetColumnDefinition, targetColumnType, dateType, DateFormats } from './types';
import { excelDateToDate } from './utils';
import { dates, numberRE, numberFloatFTERE, nullRE } from './patterns';

export class DataContainer
{
  private data: any[][]                                     = [];
  private header: boolean                                   = true;
  private mappingDefinition: null | MappingDefinition       = null;
  private isExcel: boolean                                  = false;
  private undetectableDates: TargetColumnDefinition[]       = [];
  private undetectableDateFormats: DateFormats[]            = [];

  constructor(data?: any[][], mappingDefinition?: MappingDefinition)
  {
    if(data) this.setData(data);
    if(mappingDefinition) this.setMappingDefinition(mappingDefinition);
  }

  setData(data: any[][])
  {
    this.data = data;
  }

  setMappingDefinition(mappingDefinition: MappingDefinition)
  {
    this.mappingDefinition = mappingDefinition;
    this.undetectableDates = this.findUndetectableDate();
    if(mappingDefinition.dateFormats) this.assignUndetectableFormats(mappingDefinition.dateFormats);
  }

  setIsExcel(isExcel: boolean)
  {
    this.isExcel = isExcel;
  }

  setHeader(header: boolean)
  {
    this.header = header;
  }

  assignUndetectableFormats(dateFormats: DateFormats[])
  {
    for(const dateFormat of dateFormats)
    {
      this.assignUndetectableFormat(dateFormat.name, dateFormat.format);
    }
  }

  assignUndetectableFormat(name: targetColumnType, format: dateType)
  {
    const undetectableDateFormats = this.undetectableDateFormats.filter(targetColumn => name !== targetColumn.name);
    undetectableDateFormats.push({ name, format });
    this.undetectableDateFormats = undetectableDateFormats;
  }

  unassignUndetectableFormat(name: targetColumnType)
  {
    this.undetectableDateFormats = this.undetectableDateFormats.filter(targetColumn => name !== targetColumn.name);
  }

  getData(raw: boolean = false): any[][]
  {
    return (raw) ? this.data : this.trasformData();
  }

  getMappingDefinition(): null | MappingDefinition
  {
    return this.mappingDefinition;
  }

  getIsExcel(): boolean
  {
    return this.isExcel;
  }

  getUndetectableDates(filterAssigned: boolean = false): TargetColumnDefinition[]
  {
    if(!filterAssigned)
    {
      return this.undetectableDates;
    }
    const undetectableDates = this.undetectableDates.filter(undetectableDate => {
      return !this.undetectableDateFormats.find(undectableFormat => undetectableDate.name === undectableFormat.name);
    });
    return undetectableDates;
  }

  getUndetectableFormats(): DateFormats[]
  {
    return this.undetectableDateFormats;
  }

  protected findUndetectableDate(): TargetColumnDefinition[]
  {
    const undetectableDates: TargetColumnDefinition[] = [];
    if(this.data.length < 2 || this.data[0].length < 2 || this.mappingDefinition === null) return undetectableDates;
    const targetColumnsDates = this.mappingDefinition.targetColumns.filter(targetColumn => ['start_date', 'end_date'].includes(targetColumn.name) && targetColumn.sourceIndex !== null);
    if(targetColumnsDates.length === 0) return undetectableDates;
    let startDate = targetColumnsDates.find(targetColumn => targetColumn.name === 'start_date');
    let endDate = targetColumnsDates.find(targetColumn => targetColumn.name === 'end_date');
    for(let rowIndex = 1, len = this.data.length; rowIndex < len; rowIndex++)
    {
      const row = this.data[rowIndex];
      if(startDate)
      {
        const cell = row[startDate.sourceIndex!];
        if(DataContainer.isUndectableDate(cell))
        {
          undetectableDates.push(startDate);
          startDate = undefined;
        }
      }
      if(endDate)
      {
        const cell = row[endDate.sourceIndex!];
        if(DataContainer.isUndectableDate(cell))
        {
          undetectableDates.push(endDate);
          endDate = undefined;
        }
      }
      if(!startDate && !endDate)
      {
        break;
      }
    }
    return undetectableDates;
  }

  static isUndectableDate(cell: any): boolean
  {
    if(!(cell.match)) return false;
    return (cell.match(dates['d/m/y']) || cell.match(dates['m/d/y']));
  }

  protected trasformData(): any[][]
  {
    if(this.data.length < 2 || this.data[0].length < 2) return this.data;
    let targetColumnsIndexed = (new Array(this.data[0].length)).fill(null);
    if(this.mappingDefinition)
    {
      const targetColumns = this.mappingDefinition.targetColumns;
      targetColumnsIndexed = this.data[0].map((col: any, index: number) => {
        const targetColumn = targetColumns.find(column => column.sourceIndex === index);
        return targetColumn ? targetColumn : null;
      })
    }
    return this.data.map((row: any[], index: number) => (index === 0 && this.header) ? row : this.transformRow(row, targetColumnsIndexed));
  }

  protected transformRow(row: any[], targetColumnsIndexed: (null | TargetColumnDefinition)[]): any[]
  {
    return row.map((cell: any, index: number) => {
      return (targetColumnsIndexed[index] === null) ? cell : this.transformCell(cell, targetColumnsIndexed[index]!);
    })
  }

  protected transformCell(cell: any, targetColumn: TargetColumnDefinition): any
  {
    // eslint-disable-next-line no-control-regex
    cell = (typeof cell === 'string') ? cell.replace(/\x0a|\x0d|\x0a\x0d/g, '').trim() : cell;
    if(['start_date', 'end_date'].includes(targetColumn.name))
    {
      cell = this.transformDateCell(cell, targetColumn);
    }
    if(targetColumn.name === 'fte')
    {
      cell = (cell === 'undefined' || cell === null) ? '' : cell;
      cell = (typeof cell === 'string') ? this.transformFTECell(cell) : cell;
    }
    if(targetColumn.name === 'hourly_rate')
    {
      cell = (typeof cell === 'string') ? this.transformHourlyRateCell(cell) : cell;
    }
    return (cell === 'undefined' || cell === null || nullRE.test(cell)) ? '' : cell;
  }

  protected transformDateCell(cell: any, targetColumn: TargetColumnDefinition): any
  {
    if(typeof cell === 'number' && this.isExcel)
    {
      const outputDateFormat = this.undetectableDateFormats.find(column => targetColumn.name === column.name);
      const outputFormat: dateType = !outputDateFormat ? 'y-m-d' : outputDateFormat.format;
      cell = excelDateToDate(cell, outputFormat);
    }
    return cell;
  }

  protected transformFTECell(cell: string): number | string
  {
    const fteEmptyValue = 1.0;
    if(!cell) return fteEmptyValue;
    if(!cell.match(numberFloatFTERE)) return cell;
    return parseFloat(cell.replace(',', '.'));
  }

  protected transformHourlyRateCell(cell: string): number | string
  {
    if(!cell) return 0;
    if(!cell.match(numberRE)) return cell;
    return parseFloat(cell.replace(',', '.'));
  }
}