import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Error from '@material-ui/icons/ErrorOutlined';
import Warning from '@material-ui/icons/WarningOutlined';
import {
  getValidationBothDesc,
  getValidationTitle,
  langValidationMsg,
} from '../../../libs';
import {
  ValidatorSummaryResult
} from '../../../libs/types';

interface Props {
  summary: ValidatorSummaryResult;
}

export const SummaryBlock: FC<Props> = ({
  summary,
}) => {
  let elementIcon: JSX.Element | null = null;
  let title: string | null = null;
  let descriptions: string[] = [];

  const isError: boolean = !!(summary && summary.error > 0);
  const isWarning: boolean = !!(summary && summary.warning > 0);
  const isErrorAndWarning: boolean = isError && isWarning;
  const isErrorOrWarning: boolean = isError || isWarning;
  
  if(!isErrorOrWarning) return null;

  if(!isWarning) {
    elementIcon = <Error fontSize="inherit" />;
  } else {
    elementIcon = <Warning fontSize="inherit" />;
  }

  if(isErrorAndWarning) {
    title = getValidationTitle('both', summary.error + summary.warning);
    descriptions = getValidationBothDesc(summary.error, summary.warning)
  } else if(isError) {
    title = getValidationTitle('error', summary.error);
    descriptions.push(langValidationMsg.postfixError);
  } else {
    title = getValidationTitle('warning', summary.warning);
    descriptions.push(langValidationMsg.postfixWarning);
  }

  return (
    <Notification error={isError} warning={isWarning && !isErrorAndWarning}>
      <NotificationContent>
        <div>
          <NotificationBlockIcon>
            {elementIcon}
          </NotificationBlockIcon>
          <NotificationBlockText marginLeft={7} marginTop={0}>{title}</NotificationBlockText>
        </div>
        {descriptions.map((description, index) => (
          <NotificationBlockText key={index} marginLeft={0} marginTop={6}>{description}</NotificationBlockText>
        ))}
      </NotificationContent>
    </Notification>
  )
}

interface NotificationProps
{
  error?: boolean;
  warning?: boolean;
}

const Notification: React.FC<NotificationProps> = styled.div`
  width: 600px;
  padding: 18px 18px;
  border-radius: 4px;
  margin: 0 20px 24px;
  box-shadow: 0 3px 6px 0 rgba(69,90,100,0.16);

  ${(props: NotificationProps) => props.warning && css`
    background-color: #FFAA001C;
    color: #6C4C10;
`}

  ${(props: NotificationProps) => props.error && css`
    background-color: #FF00001C;
    color: #691E1F;
  `}
`;

const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotificationBlock = styled.div`
  display: inline-block;
  line-height: 19px;
  vertical-align: middle;
`;

const NotificationBlockIcon = styled(NotificationBlock)`
  font-size: 21px;
`;

interface NotificationBlockTextProps
{
  marginLeft: number;
  marginTop: number;
  counter?: boolean;
}

const NotificationBlockText: React.FC<NotificationBlockTextProps> = styled(NotificationBlock)`
  margin-left: ${(props: NotificationBlockTextProps) => props.marginLeft}px;
  margin-top: ${(props: NotificationBlockTextProps) => props.marginTop}px;
  font-size: 14px;

  ${(props: NotificationBlockTextProps) => props.counter && css`
    font-weight: bold;
  `}
`;