import { localStore } from "./localStore";
import { CLIENT_STORAGE_KEY, FILE_TRANSFER_STORAGE_KEY } from "./types";
import { isFileUploadPart } from "./utils";

export const setClientId = (clientId: string) => {
  localStore.save(CLIENT_STORAGE_KEY, clientId);
  localStore.save(FILE_TRANSFER_STORAGE_KEY, isFileUploadPart().toString());
}

export const getClientId = (): string | undefined => {
  return localStore.get(CLIENT_STORAGE_KEY);
}