import * as React from 'react';
import { 
  ToastContainer, 
  toast
} from 'react-toastify'

type NotificationProps = {
  type?: "info" | "success" | "warning" | "error" | "default" | undefined;
  position?: "bottom-right" | "top-right" | "top-center" | "top-left" | "bottom-center" | "bottom-left" | undefined;
  hideProgressBar?: boolean;
}

const defaultOtions: NotificationProps = {
  type: 'default',
  position: 'bottom-right',
  hideProgressBar: true
}

export const Notification: React.FC<NotificationProps> = () => {
  return (
    <ToastContainer
      position="bottom-right"
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
    />
  )    
}

Notification.defaultProps = defaultOtions;

export const notification = (message: string, options: NotificationProps) => {
  const toastOptions = {...defaultOtions, ...options};
  toast(message, toastOptions);
}