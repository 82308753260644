import React from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../ui/theme';

interface UploaderWrapperProps
{
  gap?        : boolean;
  overflow?   : 'hidden' | 'auto' | 'scroll' | 'visible';
  onDrop?     : any;      // @TODO old Typescript version or styled props problem?
  onDragEnter?: any;      // @TODO old Typescript version or styled props problem?
  onDragOver? : any;      // @TODO old Typescript version or styled props problem?
  onDragLeave?: any;      // @TODO old Typescript version or styled props problem?
}

export const UploaderWrapper: React.FC<UploaderWrapperProps> = styled.div`
  position: relative;
  width: 460px;
  height: auto;
  overflow: ${(props: UploaderWrapperProps) => props.overflow ? props.overflow : 'hidden'};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: ${theme.colors.white};
  ${(props: UploaderWrapperProps) => props.gap && css`
    padding: 30px 0 30px;
  `}
`;
