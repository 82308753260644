import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import {
  LoginContainer,
  LoginTitle,
  LoginButton,
  BoxScreen,
  LoginError,
} from '../../components'
import { theme } from '../../components/ui/theme'
import {
  CLIENT_STORAGE_KEY,
  FILE_TRANSFER_STORAGE_KEY,
  INIT_ERROR__STORAGE_KEY,
  isFileUploadPart,
  lang,
  localStore,
  LoginMethod,
  useAuth,
} from '../../libs'

type LoginProps = RouteComponentProps<{
  organizationId: string
}>

export const Login: React.FC<LoginProps> = ({ match }) => {
  const { login } = useAuth()
  const { organizationId } = match.params
  localStore.save(CLIENT_STORAGE_KEY, organizationId);
  localStore.save(FILE_TRANSFER_STORAGE_KEY, isFileUploadPart().toString());

  const initError = localStore.get(INIT_ERROR__STORAGE_KEY)

  const handleLogin = React.useCallback(
    (method: LoginMethod) => {
      localStore.delete(INIT_ERROR__STORAGE_KEY)
      login(method)
    },
    [login]
  )

  return (
    <LoginContainer
      background={theme.colors.white}
      isFileTransfer={isFileUploadPart()}
    >
      <LoginTitle>{lang.titleLogin}</LoginTitle>
      {initError && (
        <LoginError>
          <ReactMarkdown>{initError}</ReactMarkdown>
        </LoginError>
      )}
      <BoxScreen direction="column" mx={0} my={0}>
        <StyledFlex>
          <LoginButton
            provider="google"
            onClick={() => handleLogin('google')}
          />
          <LoginButton provider="ms" onClick={() => handleLogin('ms')} />
        </StyledFlex>
      </BoxScreen>
    </LoginContainer>
  )
}

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
