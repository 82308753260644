import styled, { css } from 'styled-components';
import { Cell } from './Cell';

interface CellMappingProps
{
  withoutBorder?: boolean;
}

export const CellMapping: React.FC<CellMappingProps> = styled(Cell)`
  padding: 0 10px 10px 10px;
  ${(props: CellMappingProps) => props.withoutBorder && css`
    border-right: 1px solid transparent;
    &:not(:last-child) {
      border-right: 1px solid transparent;
    }
  `}
`;