import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { theme } from './ui/theme';

interface Prop
{
  isValid?: boolean;
}

const DashAnimation = keyframes`
  0% { stroke-dashoffset: 1000; }
  100% { stroke-dashoffset: 0; }
`;

const DashCheckAnimation = keyframes`
  0% { stroke-dashoffset: -100; }
  100% { stroke-dashoffset: 900; }
`;

const Svg = styled.svg`
  width: 100px;
  display: block;
  margin: 40px auto 40px;
`;

const Circle = styled.circle`
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation-name: ${DashAnimation};
  animation-duration: .9s;
  animation-timing-function: ease-in-out;
`;

const Polyline = styled.polyline`
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  stroke-dashoffset: -100;
  animation: ${DashCheckAnimation} .9s .35s ease-in-out forwards;

`;

const Line = styled.line`
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  stroke-dashoffset: 1000;
  animation: ${DashAnimation} .9s .35s ease-in-out forwards;
`;

export const CheckMark: FC<Prop> = ({ 
  isValid = true
}) => {
  return (
    <Svg className="svg-mark" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
      {isValid ? (
        <>
          <Circle className="path circle" fill="none" stroke={theme.colors.green} strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
          <Polyline className="path check" fill="none" stroke={theme.colors.green} strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
        </>
      ) : (
        <>
          <Circle className="path circle" fill="none" stroke={theme.colors.red} strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
          <Line className="path line" fill="none" stroke={theme.colors.red} strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
          <Line className="path line" fill="none" stroke={theme.colors.red} strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
        </>
      )}
    </Svg>
  )
}