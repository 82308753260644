import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { focus, transition } from '../../utils';

import { BaseLink } from '../BaseLink';

type BaseLinkProps = React.ComponentProps<typeof BaseLink>;

interface Props extends BaseLinkProps {
  white?: boolean;
}

export const Link: FC<Props> = observer(props => {
  return <StyledBaseLink {...props} />;
});

const StyledBaseLink = styled(BaseLink).withConfig<Props>({
  shouldForwardProp: prop => !['white'].includes(prop),
})`
  color: ${props => props.theme.link.color[props.white ? 'white' : 'normal']};
  border-bottom: 1px solid ${props => props.theme.link.borderColor[props.white ? 'white' : 'normal']};

  &:hover {
    border-color: ${props => props.theme.link.color[props.white ? 'white' : 'normal']};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  ${focus}
  ${transition('border-color, box-shadow')}
`;
