import styled, { css } from 'styled-components';
import { Cell } from './Cell';
import { theme } from '../../ui/theme';

interface CellDataProps
{
  withoutBorder?: boolean;
  isEmpty?: boolean;
  isEven?: boolean;
  title?: string;
}

export const CellData: React.FC<CellDataProps> = styled(Cell)`
  background-color: ${theme.colors.white};
  width: 200px;
  color: ${theme.colors.mediumGray};
  font-size: 13px;
  padding: 14px 1px;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px solid ${(props: CellDataProps) => props.withoutBorder ? `transparent` : theme.colors.extraLightGray};
  }

  &:last-child {
    border-right: none;
  }

  ${(props: CellDataProps) => props.withoutBorder && css`
    border-right: 1px solid transparent;
  `}

  ${(props: CellDataProps) => props.isEmpty && css`
    color: ${theme.colors.veryLightGray};
  `}

  ${(props: CellDataProps) => props.isEven && css`
    background-color: ${theme.colors.ultraLightGray};
  `}
`;