import React from 'react';
import styled from 'styled-components';
import { theme } from './ui';

interface MappingResetProps
{
  title: string;
  onClick: () => void;
}

const ResetWrapper = styled.div`
  position: absolute;
  right: 26px;
  top: 36px;
`;

const ResetLink = styled.p`
  font-size: 13px;
  color: ${theme.colors.darkRed};
  border: 1px solid ${theme.colors.veryLightGray};
  border-width: 0 0 1px 0;
  padding: 0 0 1px 0;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.red};
  }
`;

export const MappingReset: React.FC<MappingResetProps> = ({
  title,
  onClick
}) => {
  return (
    <ResetWrapper>
      <ResetLink className="mapping-reset" onClick={onClick}>{title}</ResetLink>
    </ResetWrapper>
  )
}