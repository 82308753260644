import { TargetColumnDefinition } from '../types'

const emailSupervisorTeamsRangeDefault: [number, number] = [5, 30];

export class EmailSupervisorTeams
{
  readonly emailSupervisorTeamsRange: [number, number];
  private emailSupervisorTeams: Record<string, number[]> = {};
  
  constructor(emailSupervisorTeamsRange?: [number, number])
  {
    this.emailSupervisorTeamsRange = emailSupervisorTeamsRange ?? emailSupervisorTeamsRangeDefault;
    this.clean();
  }

  clean = () => 
  {
    this.emailSupervisorTeams  = {};
  }

  processRow = (row: any[], rowIndex: number, emailSupervisor: TargetColumnDefinition) =>
  {
    const emailSupervisorValue = row[emailSupervisor.sourceIndex!];
    if(!(emailSupervisorValue in this.emailSupervisorTeams))
    {
      this.emailSupervisorTeams[emailSupervisorValue] = [];
    }
    this.emailSupervisorTeams[emailSupervisorValue].push(rowIndex);
  }

  getTeamsOutOfRange = (): number[] => {
    return [...Object.values(this.emailSupervisorTeams).filter(team => team.length < this.emailSupervisorTeamsRange[0] || team.length > this.emailSupervisorTeamsRange[1])].flat();
  }
}
