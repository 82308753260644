export const AUTH_URL = `${process.env.REACT_APP_AUTH_URL}`

export const isDev = process.env.NODE_ENV === 'development'

export const uploadLinkHQHrTable = `/upload/hr_tables/session`
export const uploadDoneHQHrTable = `/upload/hr_tables/done`

export const uploadLinkHQFiles = `/upload/files/session`
export const uploadDoneHQFile = `/upload/files/done`

export const userHQ = `/upload/current_user?customer_id=:organizationId`

export const getQueryHQ = (query: string): string => {
  return `${process.env.REACT_APP_BACKEND}${query}`
}
