import React, { FC, useState, Fragment } from 'react';
import styled from 'styled-components';
import { 
  Modal,
  Button, 
} from '../../../components'
import { 
  ButtonType,
  Text,
  theme
} from '../../../components/ui';
import {
  lang,
  DataContainer,
  DateFormats,
} from '../../../libs'

interface Prop {
  dataContainer: DataContainer;
  onSubmit: (dateFormats: DateFormats[]) => any;
}

const ButtonsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 5px;
`;

const NotificationWrapper = styled.div`
  padding: 18px 18px;
  margin: 0 20px 10px;
  box-shadow: 0 3px 6px 0 rgba(69,90,100,0.16);
  color: ${theme.colors.gray};
`;

const Notification = styled.div`
  padding: 0 6px 8px;
`;


export const ModalDateFormat: FC<Prop> = ({
  dataContainer,
  onSubmit,
}) => {
  const undetectableDates = dataContainer.getUndetectableDates(true);

  const undetectableDatesStates: (null | DateFormats)[] = (new Array(undetectableDates.length)).fill(null);
  const [dates, setDates] = useState<(null | DateFormats)[]>(undetectableDatesStates);
  let elementsDates: JSX.Element[] = [];

  if(undetectableDates.length)
  {
    elementsDates = [];
    for(let i = 0, len = undetectableDates.length; i < len; i++)
    {
      const undetectableDate = undetectableDates[i];
      const dmyButtonType: ButtonType = dates[i] === null || dates[i]!.format !== 'd/m/y' ? 'outlined' : 'contained';
      const mdyButtonType: ButtonType = dates[i] === null || dates[i]!.format !== 'm/d/y' ? 'outlined' : 'contained';
      elementsDates.push((
        <Fragment key={`undetectable-dates-${i}`}>
          <NotificationWrapper className="notification">
            <Notification>
              <Text size="medium">{lang.descUndetectableDate.replace('%1', undetectableDate.title)}</Text>
            </Notification>
            <ButtonsWrapper>
              <Button
                disabled={dates[i] !== null && dates[i]!.format === 'd/m/y'}
                text="day/month/year"
                onClick={() => {
                  const newDates = [...dates];
                  newDates[i] = { name: undetectableDate.name, format: 'd/m/y' };;
                  setDates(newDates);
                }}
                type={dmyButtonType}
              />
              <Button
                disabled={dates[i] !== null && dates[i]!.format === 'm/d/y'}
                text="month/day/year"
                onClick={() => {
                  const newDates = [...dates];
                  newDates[i] = { name: undetectableDate.name, format: 'm/d/y' };
                  setDates(newDates);
                }}
                type={mdyButtonType}
              />
            </ButtonsWrapper>
          </NotificationWrapper>
        </Fragment>
      ))
    }
  }
  const datesEmpty = dates.filter(date => date === null);
  const rightButton = {title: lang.buttonSave, disabled: datesEmpty.length > 0, onClick: () => {
    if(dates.includes(null))
    {
      return;
    }
    onSubmit(dates as DateFormats[]);
  }}
  return (
    <Modal 
      title={lang.titleUndetectableDate}
      rightButton={rightButton}
      justify="left"
      closeOnBackground={false}
      className="modal-date-format"
    >
      {elementsDates}
    </Modal>
  )}