import React, { FC } from 'react';
import styled from 'styled-components';
import { theme } from './ui/theme';

const Background = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${theme.colors.white};
  z-index: 1;
`;

const ContainerWrapper = styled.div`
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: auto;
  background-image: linear-gradient(-180deg, #f7f7f7 0%, #efefef 100%);
  font-size: 16px;
`;

export const Container: FC = ({ children }) => (
  <Background>
    <ContainerWrapper>{children}</ContainerWrapper>
  </Background>
);