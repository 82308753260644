import * as React from 'react'
import { Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import XLSX from 'xlsx';
import { RouteList } from '../../screens'
import {
  lang,
  langErrorMsg,
  StoreHR,
  Validator,
  Download,
  pathPush,
  getClientId,
} from '../../libs'
import {
  Header,
  ProgressBar,
  Title,
  WrapperMain,
  CheckMark,
  Container,
  Screen,
  ModalValidate,
  UploaderWrapper,
  ValidatorProgress,
  notification,
} from '../../components'
import {
  mimeType,
  mimeTypes,
  ValidatorSummaryResult,
  ValidatorCallbackResponse
} from '../../libs/types';
import { projectConfig } from '../../user';

/* @TODO: for making test scenario and debug 
declare global { interface Window { StoreHR: StoreHR; } }
window.StoreHR = StoreHR;
/* @TODO: REMOVE! for making test scenario and debug END */

interface ValidateProps extends RouteComponentProps<{
  organizationId: string;
}> {
}

interface ValidateState {
  animationDone: boolean;
  validatorSummary: ValidatorSummaryResult | null;
  validatorProgress: ValidatorCallbackResponse;
}

export class Validate extends React.Component<ValidateProps, ValidateState>
{
  private timer: any;
  private validator: Validator | null = null;

  constructor(props: Readonly<ValidateProps>) 
  {
    super(props);
    this.state = this.getDefaultState();
  }

  protected getDefaultState(): ValidateState 
  {
    const dataContainer = StoreHR.getData();
    const mapping = StoreHR.getMapping();
    if(mapping) 
    {
      this.validator = new Validator(dataContainer);
      this.validator.applyOverriddenRules(projectConfig.getConfigRules());
      this.validator.validateProcess(mapping, this.validatorCallback);
      StoreHR.setValidator(this.validator);
    }
    return {
      animationDone: false,
      validatorSummary: null,
      validatorProgress: {
        percent: 0,
        processed: false
      }
    }
  }

  validatorCallback = (response: ValidatorCallbackResponse) => {
    const validatorSummary = this.validator && response.processed ? this.validator.getSummary() : null;
    const validatorProgress = { ...response };
    const newState = { 
      validatorProgress, 
      validatorSummary
    };
    this.setState(newState);
  }

  componentDidMount() {
    this.processing();
  }

  componentDidUpdate() {
    this.processing();
  }

  componentWillUnmount() {
    if(this.validator)
    {
      this.validator.unregisterCallback()
    }
    if(this.timer)
    {
      window.clearTimeout(this.timer);
    }
  }

  private processing = () => {
    if(this.state.animationDone)
    {
      return;
    }
    if(this.timer)
    {
      return;
    }
    this.timer = window.setTimeout(() => {
      this.setState({animationDone: true}, () => {
        const isValidFull = (this.state.validatorSummary && (this.state.validatorSummary.error === 0 && this.state.validatorSummary.warning === 0));
        if(isValidFull) this.nextStepForward();
      });
    }, 1500);
  }

  private downloadStatusFile = (delimiter: string = ';') => {
    const data = StoreHR.getData();
    if(!data || !this.validator || !this.validator.getValidated())
    {
      return;
    }
    const uploadedFile = StoreHR.getUploadedFile();
    if(!this.validator)
    {
      notification(langErrorMsg.unableDownloadStatus, {type: 'error'});
      return;
    }
    let dataStatus: any[][] = this.validator.getStatusesVerbose();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataStatus, { skipHeader: true, dateNF: 'YYYY-MM-DD' });
    /* @TODO - Width not work for XLS
    const worksheetColWidth: ColInfo[] = [];
    data[0].forEach(() => {
      worksheetColWidth.push({ wch: 100, width: 200, wpx: 400 });
    })
    worksheet['!cols'] = worksheetColWidth;
    */
    dataStatus = [];
    let fileType = uploadedFile!.type;
    fileType = (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ? 'application/vnd.ms-excel' : fileType; // @TODO - Problem with writing XLSX format, force XLS
    let result: any;
    const extension: string = mimeTypes[(fileType as mimeType)];
    let mime: string;
    let isBuffer: boolean = true;
    switch (fileType) {
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        mime = fileType;
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, `Validator Status`);
        result = XLSX.write(wb, { bookType: fileType === 'application/vnd.ms-excel' ? 'xls' : 'xlsx', type: 'buffer', compression: true });
        break;

      default:
        isBuffer = false;
        const BOM = "\ufeff";
        mime = 'text/csv;encoding:utf-8';
        result = BOM + XLSX.utils.sheet_to_csv(worksheet, { FS: delimiter });
    }
    
    Download(result, `${uploadedFile!.namePart}-STATUS.${extension}`, mime, isBuffer);
  }

  private nextStepForward = () => {
    const isValid = this.state.validatorSummary && this.state.validatorSummary.isValid;
    if(isValid)
    {
      pathPush(this.props.history, RouteList.finish.path.replace(':organizationId', getClientId() as string));
      return;
    }
  }

  private getValidatingScreen = (): JSX.Element =>
  {
    return (
      <UploaderWrapper key={`${this.state.validatorProgress.percent}`}>
        <ValidatorProgress
          title={lang.titleValidating}
          description={lang.titlePleaseWait}
        />
      </UploaderWrapper>
    )
  }

  private getValidatorScreen = (): JSX.Element =>
  {
    const summary = this.state.validatorSummary; 
    const isValid = !!(summary && summary.error === 0 && summary.warning === 0);
    const checkMark = (!isValid) ? null : <CheckMark isValid={isValid} />;
    if(checkMark)
    {
      return (
        <Fragment>
          {checkMark}
        </Fragment>
      )
    }

    if(isValid)
    {
      return (<></>);
    }
    return (
      <ModalValidate 
        summary={summary!}
        downloadStatusButton={{
          title: lang.buttonValidateStatusFile,
          onClick: () => this.downloadStatusFile()
        }}
        continueButton={{
          title: lang.buttonValidateContinue,
          onClick: () => {
            pathPush(this.props.history, RouteList.finish.path.replace(':organizationId', getClientId() as string));
          }
        }}
        reimportButton={{
          title: lang.buttonValidateReimport,
          onClick: () => {
            pathPush(this.props.history, RouteList.welcome.path.replace(':organizationId', getClientId() as string));
          }
        }}
      />
    )
  }

  render()
  {
    const { organizationId } = this.props.match.params;
    const isValid = this.state.validatorSummary && this.state.validatorSummary.isValid;
    const isNextButtonDisabled = !isValid;
    const prevButton = {
      onClick: () => {
        pathPush(this.props.history, RouteList.excel.path.replace(':organizationId', getClientId() as string));
      },
      disabled: false
    }
    const nextButton = {
      onClick: () => {
        if(isValid) {         
          pathPush(this.props.history, RouteList.finish.path.replace(':organizationId', getClientId() as string));
          return;
        }
      },
      disabled: isNextButtonDisabled
    }

    let content = null;
    if(this.state.validatorProgress.processed)
    {
      content = this.getValidatorScreen();
    } 
    else
    {
      content = this.getValidatingScreen();
    }
    return (
      <Container>
        <Header organizationId={organizationId} />
        <ProgressBar 
          step={3} 
          prevButton={prevButton} 
          nextButton={nextButton} 
        />
        <Screen>
          <WrapperMain>
            <Title title={`3. ${lang.progressStep3Title}`} />
            {content}
          </WrapperMain>
        </Screen>
      </Container>
    )
  }
}