import * as React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import OfflineBolt from '@material-ui/icons/OfflineBoltOutlined';
import { 
  targetColumnType,
  SourceColumnDefinition,
  TargetColumnDefinition,
  MappingDefinition,
  nameType,
  DetectorAllowed,
  transpose,
  formatYMDDate,
} from '../libs';
import {
  Select,
  SubModalMappingName,
  TableMapping,
  TableBody,
  Row,
  CellMapping,
  CellTitle,
  CellData
} from '../components';


import { isDev } from '../rest';

const MappingCointainer = styled.div`
  display: flex;
  width: 100%;
  user-select: none;
`;

interface Position {
  w: number;
  h: number;
  t: number;
  l: number;
}

export interface MappingProps {
  sourceColumns    : string[];
  sourceData       : string[][];
  mappingDefinition: MappingDefinition;
  onChange         : (mapping: MappingDefinition) => any;
  mappingForced    : boolean;
  allowedColumns   : DetectorAllowed[] | null;
  hiddenColumns    : targetColumnType[];
  scrollLeft       : number;
  scrolling        : boolean;
}

export interface MappingState {
  targetColumns        : TargetColumnDefinition[];
  targetColumnsForced  : targetColumnType[];
  nameType             : nameType;
  nameTypeModal        : boolean;
  sourceColumns        : SourceColumnDefinition[];
  sourceOptions        : string[];
  sourceIndexesSelected: number[];
  targetColumnsDetected: targetColumnType[];
  tablePosition        : Position;
  columnSelectOpened   : number | null;
}

const getDefaultState = (): MappingState => {
  return {
    targetColumns        : [],
    targetColumnsForced  : [],
    nameType             : 'first_last',
    nameTypeModal        : false,
    sourceColumns        : [],
    sourceOptions        : [],
    sourceIndexesSelected: [],
    targetColumnsDetected: [],
    tablePosition        : { w: 0, h: 0, l: 0, t: 0 },
    columnSelectOpened   : null,
  }
}

export class Mapping extends React.Component<MappingProps, MappingState> 
{
  private readonly rowsPreview: number = 11;
  private tableRef : any   = null;
  private mappingRef: any  = null;
  private timerResize: any = null;
  private isMountedComponent: boolean = false;

  constructor(props: Readonly<MappingProps>)
  {
    super(props);
    this.state = this.getInitialState(/*props*/);
  }

  componentDidMount()
  {
    this.isMountedComponent = true;
    window.addEventListener('resize', this.handlerResize);
  }

  componentWillUnmount()
  {
    this.isMountedComponent = false;
    if(this.timerResize) window.clearTimeout(this.timerResize);
    window.removeEventListener('resize', this.handlerResize);
  }

  private handlerResize = () => {
    if(this.timerResize) window.clearTimeout(this.timerResize);
    this.timerResize = window.setTimeout(() => this.handleResize(), 20);
  }

  private getInitialState(/*props: Readonly<MappingProps>*/): MappingState
  {
    const state = getDefaultState();
    state.targetColumns = [...this.props.mappingDefinition.targetColumns];
    // let preset = this.props.mappingDefinition;

    // Recover targetColumns from preset / detected
    // Mapping.applyPreset(state, this.props.sourceColumns, preset);
    return state;
  }

  static getDerivedStateFromProps(nextProps: Readonly<MappingProps>, prevState: MappingState)
  {
    if(prevState.targetColumns === nextProps.mappingDefinition.targetColumns)
    {
      return prevState;
    }
    const state = { ...prevState };
    state.targetColumns = [...nextProps.mappingDefinition.targetColumns];
    Mapping.applyPreset(state, nextProps.sourceColumns, nextProps.mappingDefinition);
    return state;
  }

  private handleResize = () => {
    if(this.tableRef === null || this.mappingRef === null || !this.isMountedComponent)
    {
      return;
    }
    const newPosition = {
      w: this.mappingRef.offsetWidth, 
      h: this.tableRef.offsetHeight,
      l: this.tableRef.offsetLeft, 
      t: this.tableRef.offsetTop
    }
    if(JSON.stringify(this.state.tablePosition) !== JSON.stringify(newPosition))
    {
      this.setState({tablePosition: newPosition});
    }
  }

  private changeNameType = () => {
    this.setState({nameTypeModal: !this.state.nameTypeModal});
  }

  static applyPreset(state: MappingState, sourceColumns: string[], preset: MappingDefinition)
  {
    const targetColumns:TargetColumnDefinition[] = preset.targetColumns;
    // Recover targetColumns from preset / detected
    const sourceIndexesSelected: number[] = [];
    const targetColumnsDetected: targetColumnType[] = [];
    if(targetColumns && targetColumns.length === state.targetColumns.length)
    {
      let isValidPreset = true;
      let isApplied     = false;
      for(const type in targetColumns)
      {
        if(!Object.prototype.hasOwnProperty.call(targetColumns, type))
        {
          continue;
        }
        const targetColumn = { ...targetColumns[type] };
        if(targetColumn.source === null || targetColumn.sourceIndex === null)
        {
          continue;
        }
        if(!(targetColumn.sourceIndex in sourceColumns) || sourceColumns[targetColumn.sourceIndex] !== targetColumn.source)
        {
          isValidPreset = false;
          break;
        }
        targetColumnsDetected.push((targetColumn.name as targetColumnType));
        sourceIndexesSelected.push(targetColumn.sourceIndex);
        isApplied = true;
      }
      const presetApplied = (isValidPreset && isApplied);     
      if(presetApplied)
      {
        state.targetColumns         = targetColumns;
        state.nameType              = preset.nameType;
        state.sourceIndexesSelected = sourceIndexesSelected;
        state.targetColumnsDetected = targetColumnsDetected;
      }
    }
    const sourceColumnsNew: SourceColumnDefinition[] = [];
    const sourceOptionsNew: string[] = [];
    for(let index = 0, len = sourceColumns.length; index < len; index++)
    {
      sourceColumnsNew.push({ name: sourceColumns[index] });
      sourceOptionsNew.push(sourceColumns[index]);
    }
    state.sourceColumns = sourceColumnsNew;
    state.sourceOptions = sourceOptionsNew;
  }

  private changeColumnMapping = (index: number, indexOption: number | null) =>
  {
    const sourceOptions         = this.state.sourceOptions;
    const targetColumn          = (this.state.targetColumns[index] as TargetColumnDefinition)
    const sourceIndexesSelected = ([...this.state.sourceIndexesSelected]) as number[];
    const targetColumns         = ([...this.state.targetColumns]) as TargetColumnDefinition[];
    const targetColumnsDetected = ([...this.state.targetColumnsDetected]) as targetColumnType[];
    const indexSelectedIndex    = sourceIndexesSelected.indexOf(targetColumn.sourceIndex!);
    const indexDetected         = targetColumnsDetected.indexOf(targetColumn.name);
    if(indexSelectedIndex > -1)
    {
      sourceIndexesSelected.splice(indexSelectedIndex, 1);
    }
    if(indexDetected > -1)
    {
      targetColumnsDetected.splice(indexDetected, 1);
    }
    const targetColumnNew = { ...targetColumns[index] };
    if(indexOption === null)
    {
      
      targetColumnNew.source = null;
      targetColumnNew.sourceIndex = null;
    }
    else
    {
      if((sourceOptions.length - 1) < indexOption)
      {
        if(isDev) console.warn('Invalid Select value!');
        return;
      }
      const value = sourceOptions[indexOption];
      sourceIndexesSelected.push(indexOption);
      targetColumnNew.source = value;
      targetColumnNew.sourceIndex = indexOption;
    }
    targetColumns[index] = targetColumnNew;
    const nameType = this.state.nameType;
    const dateFormats = this.props.mappingDefinition.dateFormats;
    this.setState({ targetColumns, sourceIndexesSelected, targetColumnsDetected, columnSelectOpened: null }, () => this.props.onChange({targetColumns, nameType, dateFormats}));
  }

  private removeColumnMapping = (index: number, indexOption: number | null) =>
  {
    const targetColumnSelected = this.state.targetColumns.find((target: TargetColumnDefinition) => {
      return target.sourceIndex === indexOption;
    })
    if(!targetColumnSelected)
    {
      // eslint-disable-next-line
      if(isDev) console.log('Cannor removeColumnMapping');
      return;
    }
    const targetColumnIndex = this.state.targetColumns.indexOf(targetColumnSelected);
    if(targetColumnIndex < 0)
    {
      // eslint-disable-next-line
      if(isDev) console.log('Cannot removeColumnMapping - targetColumn not found');
      return;
    }
    const targetColumns         = ([...this.state.targetColumns]) as TargetColumnDefinition[];
    let   sourceIndexesSelected = ([...this.state.sourceIndexesSelected]) as number[];
    let   targetColumnsDetected = ([...this.state.targetColumnsDetected]) as targetColumnType[];

    targetColumns[targetColumnIndex].source = null;
    targetColumns[targetColumnIndex].sourceIndex = null;
    sourceIndexesSelected = sourceIndexesSelected.filter((sourceIndex: number) => sourceIndex !== indexOption);
    targetColumnsDetected = targetColumnsDetected.filter((type: targetColumnType) => type !== targetColumnSelected!.name);
    const nameType = this.state.nameType;
    const dateFormats = this.props.mappingDefinition.dateFormats;
    this.setState({ targetColumns, sourceIndexesSelected, targetColumnsDetected, columnSelectOpened: null }, () => this.props.onChange({targetColumns, nameType, dateFormats}));
    // this.setState({ targetColumns, sourceIndexesSelected, targetColumnsDetected, columnSelectOpened: null }, () => this.changeColumnMapping(index, indexOption));
  }

  getColumns(targetColumn: TargetColumnDefinition, index: number): JSX.Element[]
  {
    const withoutBorder          = ((['first_name', 'middle_name'] as targetColumnType[]).includes(targetColumn.name));
    const nameChange: boolean    = ((this.state.nameType === 'full' && targetColumn.name === 'full_name') || targetColumn.name === 'last_name');
    const nameMapped: boolean    = !!(nameChange && this.state.targetColumns.find(targetColumn => (['first_name', 'middle_name', 'last_name', 'full_name'] as targetColumnType[]).includes(targetColumn.name) && targetColumn.sourceIndex !== null));
    const isDate                 = targetColumn.sourceIndex !== null && ['start_date', 'end_date'].includes(targetColumn.name);
    // 
    // const isFTE                  = targetColumn.sourceIndex !== null && targetColumn.name === 'fte';
    const sourceOptions          = this.state.sourceOptions;
    const targetColumnsDetected  = this.state.targetColumnsDetected;
    let   rowIndex               = 0;
    const isSelected             = targetColumn.sourceIndex !== null;
    const isDetected             = targetColumnsDetected.includes(targetColumn.name);
    const getKey                 = () => `mapping-col-${rowIndex++}-${index}`;
    const columns: JSX.Element[] = [];

    // const cellDetected = isSelected && targetColumnsDetected.includes(targetColumn.name);
    // const cellSelected = isSelected && !cellDetected;
    // const cellRequired = targetColumn.required;
    columns.push(
      <CellTitle 
        key={getKey()} 
        required={targetColumn.required}
        onNameTypeChange={nameChange ? this.changeNameType : undefined}
        withoutBorder={withoutBorder}
      >
        {targetColumn.title}
        {this.state.nameTypeModal && nameChange && !this.props.scrolling ? (
            <SubModalMappingName 
              nameType={this.state.nameType} 
              mapped={nameMapped}
              scrollLeft={this.props.scrollLeft}
              onCancel={() => {
                this.setState({nameTypeModal: false});
              }}
              onSave={(type: nameType) => {
                if(type === this.state.nameType)
                {
                  this.setState({ nameTypeModal: false });
                  return;
                }
                let sourceIndexesSelected: number[] = [...this.state.sourceIndexesSelected];
                const targetColumns = this.state.targetColumns.map(targetColumn => {
                  const targetColumnNew = {...targetColumn};
                  if(targetColumnNew.sourceIndex !== null && ['first_name', 'middle_name', 'last_name', 'full_name'].includes(targetColumnNew.name))
                  {
                    sourceIndexesSelected = sourceIndexesSelected.filter(sourceIndex => sourceIndex !== targetColumnNew.sourceIndex);
                    targetColumnNew.source = null;
                    targetColumnNew.sourceIndex = null;
                  }
                  return targetColumnNew;
                })
                this.setState({ nameType: type, nameTypeModal: false, targetColumns, sourceIndexesSelected }, () => this.props.onChange({targetColumns, nameType: type, dateFormats: this.props.mappingDefinition.dateFormats}))
              }} 
            />
        ) : null}
      </CellTitle>
    );

    const allowedIndexes: DetectorAllowed[] = this.props.allowedColumns ? this.props.allowedColumns.filter((allowed: DetectorAllowed) => allowed.type === targetColumn.name) : [];
    const options = sourceOptions.map((option: string, index: number) => {
      const isMapped = (this.state.sourceIndexesSelected.indexOf(index) > -1);
      let isAllowed = true;
      if(allowedIndexes.length && !allowedIndexes.find((allowed: DetectorAllowed) => allowed.index.includes(index)))
      {
        isAllowed = false;
      }
      return {value: option, mapped: isMapped, allowed: isAllowed};
    });
    const isOpen   = index === this.state.columnSelectOpened;
    const isEmpty  = targetColumn.sourceIndex === null;
    const isForced = isEmpty && targetColumn.required && this.props.mappingForced;
    columns.push(
      <CellMapping key={getKey()} withoutBorder={withoutBorder}>
        <Select
          id={`select-${index}`}
          placeholder="Select your column"
          options={options}
          onChange={(indexOption: number | null) => {
            this.changeColumnMapping(index, indexOption);
            this.setState({columnSelectOpened: null});
          }}
          onRemap={(indexOption: number | null) => {
            this.removeColumnMapping(index, indexOption);
            this.changeColumnMapping(index, indexOption);
          }}
          value={isEmpty ? undefined : targetColumn.sourceIndex!}
          icon={isDetected ? (
            <OfflineBolt fontSize="inherit" />
          ) : undefined}
          onClick={() => {
            const columnSelectNew = (this.state.columnSelectOpened === index) ? null : index;
            this.setState({columnSelectOpened: columnSelectNew});
          }}
          open={isOpen}
          forced={isForced}
        />
      </CellMapping>
    );
    const lastRowIndex = this.rowsPreview + 1;
    const rowLast = Math.min(lastRowIndex, this.props.sourceData.length);
    for(let row = 1; row < rowLast; row++)
    {
      const isEven        = (row % 2) === 0;
      let   value: any    = (isSelected) ? this.props.sourceData[row][targetColumn.sourceIndex!] : '\u00A0';
      let   noData        = false;
      if(isSelected)
      {
        value = (isDate && typeof value === 'string') ? formatYMDDate(value) : value;
        
        value = (['number', 'object'].includes(typeof value)) ? value.toString() : value;
        noData = (!value.trim || value.trim() === '') ? true : false;
        value = (noData) ? `No data for employee` : value;
      }
      columns.push(
        <CellData
          key={getKey()}
          isEmpty={noData}
          isEven={isEven}
          title={value}
          withoutBorder={withoutBorder}
        >
          <span title={value}>{isSelected && value && value.length > 15 && !noData ? `${value.substring(0, 15)}…` : value}</span>
        </CellData>
      );
    }
    if(rowLast < lastRowIndex)
    {
      for(let row = 0, len = (lastRowIndex - rowLast); row < len; row++)
      {
        const isEven = (row % 2) !== 0;
        const value  = `Empty row`;
        columns.push(
          <CellData
            key={getKey()}
            isEmpty
            isEven={isEven}
            title={value}
            withoutBorder={withoutBorder}
          >
            {value}
          </CellData>
        );
      }
    }
    return columns;
  }

  getTable(): JSX.Element
  {
    let tableColumns: JSX.Element[][] = [];
    const targetColumns = ([...this.state.targetColumns]) as TargetColumnDefinition[];
    
    let targetColumnSkip: targetColumnType[] = ['full_name'];
    if(this.state.nameType !== 'first_middle_last')
    {
      targetColumnSkip = (this.state.nameType === 'first_last') ? ['middle_name', 'full_name'] : ['first_name', 'middle_name', 'last_name'];
    }

    for(let i = 0, len = targetColumns.length; i < len; i++)
    {
      const targetColumn = targetColumns[i];
      if(targetColumnSkip.includes(targetColumn.name) || this.props.hiddenColumns.includes(targetColumn.name))
      {
        continue;
      }
      tableColumns.push(this.getColumns(targetColumn, i));
    }
    tableColumns = transpose(tableColumns);
    return (
      <>
        <TableMapping ref={(element: any) => {
          if(element === null) return;
          this.tableRef = element;
          this.handleResize();
        }} {...this.state.tablePosition}>
          <TableBody>
          {tableColumns.map((row: JSX.Element[], index: number) => {
            return (
              <Row key={`mapping-row-${index}`}>{row.map((col: JSX.Element) => col)}</Row>
            )
          })}
          </TableBody>
        </TableMapping>
      </>
    )
  }

  render()
  {
    return (
      <Fragment>
        <MappingCointainer ref={(element: any) => {
          if(element === null) return;
          this.mappingRef = element;
          this.handleResize();
        }} onClick={(element: any) => {
          if(this.state.columnSelectOpened === null)
          {
            return;
          }
          const nextIteration  = true;
          let iterationLimit = 10;
          let currentElement = element.target;
          while(nextIteration)
          {
            const isSelect = currentElement.classList.contains(`select-wrapper`);
            if(isSelect)
            {
              return;
            }
            currentElement = currentElement.parentNode;
            if(!currentElement || !currentElement.classList || --iterationLimit === 0)
            {
              break;
            }
          }
          this.setState({columnSelectOpened: null});
        }}>
          {this.getTable()}
        </MappingCointainer>
      </Fragment>
    )
  }
}
