import * as React from 'react'
import { RouteComponentProps, useLocation } from 'react-router'
import { RouteList } from '../../screens'
import {
  FILE_TRANSFER_STORAGE_KEY,
  getClientId,
  localStore, pathPush, setClientId, useAuth
} from '../../libs';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Auth: React.FC<RouteComponentProps> = ({
  history,
}) => {
  const query = useQuery();
  const { exchange } = useAuth();

  const code = query.get("code") as string | undefined;
  const state = query.get("state") as string | undefined;
  const organizationId = getClientId();
  const isFileUploadPart = localStore.get(FILE_TRANSFER_STORAGE_KEY) === 'true';
  
  React.useEffect(() => {
    if (!organizationId) return;
    if (!code || !state) {
      const LoginRoute = isFileUploadPart ? RouteList.loginFileUpload : RouteList.login;
      pathPush(history, LoginRoute.path.replace(':organizationId', organizationId))
      return;
    }
    setClientId(organizationId);
    (async () => {
      await exchange(state, code);
      const InitRoute = isFileUploadPart ? RouteList.initFileUpload : RouteList.initHRTable;
      pathPush(history, InitRoute.path.replace(':organizationId', organizationId))    
    })();
  }, [code, exchange, history, isFileUploadPart, organizationId, state]);

  if(!organizationId) {
    return <p>Missing organizationId!</p>
  }
  return (
    null
  );
}