import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { RouteList } from './index';
import { BoxScreen } from '../components';
import { Loader } from '../components/ui';
import { useAuth } from '../libs/auth';
import { userKey } from '../user';

export interface PrivateRouteProps extends RouteProps {
  authenticationPath: string;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ ...props }) => {
  const { isAuthenticated, isLoading } = useAuth();

  if(isLoading) {
    return (
      <BoxScreen>
        <Loader />
      </BoxScreen>
    )
  }

  let redirectPath: string = '';
  let organizationIdParam: string | null = null;
  const { computedMatch } = props as any;
  if(computedMatch && computedMatch.params && computedMatch.params.organizationId)
  {
    organizationIdParam = computedMatch.params.organizationId
  }

  if(organizationIdParam) 
  {
    if(!isAuthenticated && !(/login$/.test(window.location.href)))
    {
      redirectPath = props.authenticationPath.replace(':organizationId', organizationIdParam);
    }
    if(!userKey.getUser()) {
      const path = computedMatch.path;
      const paths = [ RouteList.excel.path, RouteList.finish.path, RouteList.finishFileUpload.path, RouteList.validate.path ];
      if(paths.indexOf(path) !== -1) {
        redirectPath = RouteList.init.path.replace(':organizationId', organizationIdParam);
      }
    }
  }
  else
  {
    redirectPath = `/`;
  }

  if(redirectPath) 
  {
    const renderComponent = () => (<Redirect to={{pathname: redirectPath, hash: window.location.hash}}/>);
    return <Route {...props} component={renderComponent} render={undefined}/>;
  }

  return <Route {...props}/>;
}
