import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  className?: string;
}

export const Loader: FC<Props> = ({ className }) => {
  return (
    <Container className={className}>
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </Container>
  );
};

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 61px;
  height: 13px;
`;

const loader1 = keyframes`
  0%   { transform: scale(0); }
  100% { transform: scale(1); }
`;

const loader2 = keyframes`
  0%   { transform: translate(0, 0); }
  100% { transform: translate(24px, 0); }
`;

const loader3 = keyframes`
  0%   { transform: scale(1); }
  100% { transform: scale(0); }
`;

const Dot = styled.div`
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(131, 134, 156);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 0;
    animation: ${loader1} 0.6s infinite;
  }
  &:nth-child(2) {
    left: 0;
    animation: ${loader2} 0.6s infinite;
  }
  &:nth-child(3) {
    left: 24px;
    animation: ${loader2} 0.6s infinite;
  }
  &:nth-child(4) {
    left: 48px;
    animation: ${loader3} 0.6s infinite;
  }
`;
