import {
  TargetColumnDefinition,
  MappingDefinition,
  EmailIndexMap,
  DateFormats,
  ValidatorStatusType,
  ValidatorStatusVerbose,
  ValidatorSummaryResult,
  ValidatorCallbackResponse,
  statusColumnTitle,
  OverriddedRule
} from './types'
import { DataContainer } from './DataContainer';
import {
  DateConverter
} from './dateConverter'
import * as validators from './validators';

export interface ValidatorResults
{
  emailEmpty                : number[];
  emailFormat               : number[];
  emailSupervisorEmpty      : number[];
  emailSupervisorFormat     : number[];
  emailSupervisorMissing    : number[];
  emailSupervisorCycled     : number[];
  emailSupervisorSelf       : number[];
  emailSupervisorCEOMulti   : number[];
  emailSupervisorRatio      : number;
  emailSupervisorTeams      : number[];
  emailAliasFormat          : number[];
  mainDepartmentInsufficient: number[];
  mainDepartmentMissing     : number[];
  startDateFormat           : number[];
  endDateEarly              : number[];
  endDateFormat             : number[];
  duplicateRows             : number[];
  duplicateRowsOverlaped    : number[];
  timeZoneFormat            : number[];
  timeZoneUnformattedFormat : number[];
  fteFormat                 : number[];
  hourlyRateFormat          : number[];
}

const getEmptyResults = (): ValidatorResults => {
  return {
    emailEmpty                : [],
    emailFormat               : [],
    emailSupervisorEmpty      : [],
    emailSupervisorFormat     : [],
    emailSupervisorMissing    : [],
    emailSupervisorCycled     : [],
    emailSupervisorSelf       : [],
    emailSupervisorCEOMulti   : [],
    emailSupervisorRatio      : 1.0,
    emailSupervisorTeams      : [],
    emailAliasFormat          : [],
    mainDepartmentInsufficient: [],
    mainDepartmentMissing     : [],
    startDateFormat           : [],
    endDateEarly              : [],
    endDateFormat             : [],
    duplicateRows             : [],
    duplicateRowsOverlaped    : [],
    timeZoneFormat            : [],
    timeZoneUnformattedFormat : [],
    fteFormat                 : [],
    hourlyRateFormat          : []
  }
}

export class Validator
{
  private isValidated: boolean         = false;
  private dataContainer: DataContainer = new DataContainer();
  private data: any[][]                = [];
  private usedEmails: string[]         = [];
  private emailIndexMap: EmailIndexMap = {
    processed: false,
    map: {}
  }
  private ceoRows: number[]                            = [];
  private employeesSupervisorRows: (number[] | null)[] = [];
  private overriddenRules: OverriddedRule[]            = [];

  private ignoreHeader: boolean;
  private results: ValidatorResults;
  private callback: any;

  constructor(dataContainer: DataContainer, ignoreHeader: boolean = true)
  {
    this.results       = getEmptyResults();
    this.dataContainer = dataContainer;
    this.ignoreHeader  = ignoreHeader;
  }

  applyOverriddenRules(overriddenRules: OverriddedRule[])
  {
    this.overriddenRules = overriddenRules;
  }

  validateProcess(mappingDefinition: MappingDefinition, callback: (response: ValidatorCallbackResponse) => void)
  {
    this.callback = callback;
    setTimeout(() => this.validate(mappingDefinition), 1);
  }

  unregisterCallback()
  {
    this.callback = null;
  }

  validate = (mappingDefinition: MappingDefinition): null => {
    const targetColumns: TargetColumnDefinition[] = mappingDefinition.targetColumns;
    const dateFormats: DateFormats[]              = mappingDefinition.dateFormats;
    this.isValidated                              = false;
    this.results                                  = getEmptyResults();
    this.emailIndexMap                            = {
                                                      processed: false,
                                                      map      : {}
                                                    }
    this.dataContainer.setMappingDefinition(mappingDefinition);
    this.data = this.dataContainer.getData();
    if(this.data.length < 2)
    {
      console.warn(`Validator - data error: too few rows - ${this.data.length}`);
      return null;
    }

    // email_id, supervisor_email_id, alias_emails & main_department
    const email = targetColumns.find(o => o.name === 'email_id');
    const emailSupervisor = targetColumns.find(o => o.name === 'supervisor_email_id');
    const mainDepartment = targetColumns.find(o => o.name === 'main_department');

    if(!email || !emailSupervisor || !mainDepartment)
    {
      console.warn(`Validator - unable to find one of required [email_id, supervisor_email_id, alias_emails and main_department] target columns:
      email ${email ? `ok ` : `miss `}
      emailSupervisor ${emailSupervisor ? `ok ` : `miss `}
      mainDepartment ${mainDepartment ? `ok ` : `miss `}
      `);
      return null;
    }

    if(email.sourceIndex === null || emailSupervisor.sourceIndex === null || mainDepartment.sourceIndex === null)
    {
      console.warn(`Validator - unable to find one of required [email_id, supervisor_email_id, alias_emails and main_department] target columns source index:
      email ${email.sourceIndex === null ? `miss ` : `ok `}
      emailSupervisor ${emailSupervisor.sourceIndex === null ? `miss ` : `ok `}
      mainDepartment ${mainDepartment.sourceIndex === null ? `miss ` : `ok `}
      `);
      return null;
    }

    if(this.callback) this.callback({ percent: 2, processed: false })
    this.preprocessData(email, emailSupervisor, mainDepartment);
    if(this.callback) this.callback({ percent: 42, processed: false })
    this.processData(targetColumns, dateFormats);
    this.isValidated = true;
    if(this.callback) this.callback({ percent: 100, processed: true })
    return null;
  }
 
  private preprocessData(email: TargetColumnDefinition, emailSupervisor: TargetColumnDefinition, mainDepartment: TargetColumnDefinition)
  {
    this.ceoRows = [];
    this.usedEmails = [];
    this.emailIndexMap.processed = false;
    const rowIndexStart = (this.ignoreHeader) ? 1 : 0;
    const rows = this.data.length;

    const overriddenRuleMainDepartmentInsufficient = this.overriddenRules.find(row => row.rule === 'mainDepartmentInsufficient');

    let emailSupervisorRatioValidator: validators.EmailSupervisorRatio | undefined = new validators.EmailSupervisorRatio();
    const mainDepartmentValidator: validators.MainDepartment = new validators.MainDepartment(overriddenRuleMainDepartmentInsufficient ? overriddenRuleMainDepartmentInsufficient.peopleSufficientLimit : undefined);
    const emailSupervisorTeamsValidator = new validators.EmailSupervisorTeams();

    /*
      Validate Emails
    */
    for(let rowIndex = rowIndexStart; rowIndex < rows; rowIndex++)
    {
      const row = this.data[rowIndex];
      const value = row[email.sourceIndex!];

      const emailEmpty = validators.Email.empty(row, email.sourceIndex!);
      const emailFormat = !validators.Email.format(row, email.sourceIndex!);
      const emailSupervisorEmpty = validators.EmailSupervisor.empty(row, emailSupervisor.sourceIndex!);
      const emailSupervisorFormat = !validators.EmailSupervisor.format(row, emailSupervisor.sourceIndex!);

      mainDepartmentValidator.processRow(row, rowIndex, mainDepartment);
      if(emailEmpty) this.results.emailEmpty.push(rowIndex);
      if(emailFormat) this.results.emailFormat.push(rowIndex);
      if(emailSupervisorEmpty) this.results.emailSupervisorEmpty.push(rowIndex);
      if(emailSupervisorFormat) this.results.emailSupervisorFormat.push(rowIndex);
      if(!emailEmpty && !emailFormat)
      {
        this.usedEmails.push(value);
        emailSupervisorRatioValidator.processRow(row, rowIndex, email, emailSupervisor);
        // @DISABLED temporary - for Teams, but confusing for non Teams clients.
        /*
        if(!emailSupervisorEmpty && !emailSupervisorFormat)
        {
          emailSupervisorTeamsValidator.processRow(row, rowIndex, emailSupervisor);
        }
        */
      }
    }
    this.results.emailSupervisorRatio = emailSupervisorRatioValidator.getRatio();
    emailSupervisorRatioValidator = undefined; // release memory

    /* 
      Collecting:
      - emails 
      - find CEO [empty supervisor / equal to email]
      - people in department [if is set]
    */
    this.employeesSupervisorRows = [null];
    for(let rowIndex = rowIndexStart; rowIndex < rows; rowIndex++)
    {
      const row = this.data[rowIndex];
      const value = row[email.sourceIndex!];
      
      // emailSupervisor missing
      if(validators.EmailSupervisor.missing(row, emailSupervisor!.sourceIndex!, this.usedEmails) && !this.results.emailSupervisorEmpty.includes(rowIndex)) this.results.emailSupervisorMissing.push(rowIndex);

      // emailIndexMap, employeesSupervisorRows && CEO
      if(!this.results.emailEmpty.includes(rowIndex))
      {
        if(!(value in this.emailIndexMap.map)) this.emailIndexMap.map[value] = [];
        this.emailIndexMap.map[value].push(rowIndex);
        // CEO
        if(row[emailSupervisor.sourceIndex!].trim && row[emailSupervisor.sourceIndex!].trim() === ''/* || value === row[emailSupervisor.sourceIndex!]*/)
        {
          this.ceoRows.push(rowIndex);
        }
        this.employeesSupervisorRows.push(null);
      }
      if(this.results.emailEmpty.includes(rowIndex) || this.results.emailFormat.includes(rowIndex))
      {
        continue;
      }
    }
    this.results.mainDepartmentInsufficient = mainDepartmentValidator.getInsufficientDepartmentsRows();
    this.results.mainDepartmentMissing      = mainDepartmentValidator.getMissingRows();
    this.results.emailSupervisorTeams       = emailSupervisorTeamsValidator.getTeamsOutOfRange();
    this.emailIndexMap.processed = true;
  }

  private processData(targetColumns: TargetColumnDefinition[], dateFormats: DateFormats[])
  {
    // Prepare DateFormats
    const dateFormatsIndexes = (new Array(targetColumns.length)).fill(null);
    for(const targetColumn of targetColumns)
    {
      const dateFormat = dateFormats.find(dateFormat => dateFormat.name === targetColumn.name);
      if(dateFormat && targetColumn.sourceIndex !== null)
      {
        dateFormatsIndexes[targetColumn.sourceIndex] = dateFormat.format;
      }
    }

    let duplicateRowsValidator: validators.DuplicateRows | undefined     = new validators.DuplicateRows(targetColumns, this.data);
    let emailSupervisorValidator: validators.EmailSupervisor | undefined = new validators.EmailSupervisor(this.emailIndexMap, this.employeesSupervisorRows, this.ceoRows);

    const emailSupervisor = targetColumns.find(o => o.name === 'supervisor_email_id');

    // OPTIONAL
    const emailAlias = targetColumns.find(o => o.name === 'alias_emails');
    const startDate = targetColumns.find(o => o.name === 'start_date');
    const endDate = targetColumns.find(o => o.name === 'end_date');
    const timeZone = targetColumns.find(o => o.name === 'timezone');
    const timeZoneUnformatted = targetColumns.find(o => o.name === 'timezone_unformatted');
    const fte = targetColumns.find(o => o.name === 'fte');
    const hourlyRate = targetColumns.find(o => o.name === 'hourly_rate');
    
    const isEmailAlias = (emailAlias && emailAlias.sourceIndex !== null);
    const isStartDate = (startDate && startDate.sourceIndex !== null);
    const isEndDate = (endDate && endDate.sourceIndex !== null);
    const isTimeZone = (timeZone && timeZone.sourceIndex !== null);
    const isTimeZoneUnformatted = (timeZoneUnformatted && timeZoneUnformatted.sourceIndex !== null);
    const isFTE = (fte && fte.sourceIndex !== null);
    const isHourlyRate = (hourlyRate && hourlyRate.sourceIndex !== null);

    if(!isEmailAlias) console.warn(`Validator - unable to find one of optional [email_alias] target columns!`);
    if(!isStartDate) console.warn(`Validator - unable to find one of optional [start_date] target columns!`);

    const startDateFormat = (startDate!.sourceIndex! !== null && dateFormatsIndexes[startDate!.sourceIndex!]) ? dateFormatsIndexes[startDate!.sourceIndex!] : null;
    const endDateFormat   = (endDate!.sourceIndex! !== null && dateFormatsIndexes[endDate!.sourceIndex!]) ? dateFormatsIndexes[endDate!.sourceIndex!] : null;
    const rowIndexStart   = (this.ignoreHeader) ? 1 : 0;
    const rows            = this.data.length;
    for(let rowIndex = rowIndexStart; rowIndex < rows; rowIndex++)
    {
      const row = this.data[rowIndex];
      const startDateFormatRow = isStartDate && DataContainer.isUndectableDate(row[startDate!.sourceIndex!]) && startDateFormat ? startDateFormat : null;
      const endDateFormatRow = isEndDate && DataContainer.isUndectableDate(row[endDate!.sourceIndex!]) && endDateFormat ? endDateFormat : null;
      if(!this.results.emailEmpty.includes(rowIndex) && !this.results.emailFormat.includes(rowIndex))
      {
        duplicateRowsValidator.hashRow(row, rowIndex);
      }
      if(emailSupervisor) emailSupervisorValidator.processRow(row, rowIndex, emailSupervisor.sourceIndex!);
      if(isEmailAlias && !validators.EmailAlias.format(row, emailAlias!.sourceIndex!)) this.results.emailAliasFormat.push(rowIndex);
      if(isStartDate)
      {
        if(validators.StartDate.empty(row, startDate!.sourceIndex!))
        {
          // empty StartDate validation skipped
        }
        else if(!validators.StartDate.format(row, startDate!.sourceIndex!, startDateFormatRow))
        {
          this.results.startDateFormat.push(rowIndex);
        }
        else if(isEndDate && !validators.StartDate.empty(row, endDate!.sourceIndex!) && validators.StartDate.format(row, endDate!.sourceIndex!))
        {
          const startDateConverter = new DateConverter(row[(startDate as TargetColumnDefinition).sourceIndex!], startDateFormatRow);
          if(startDateConverter.isBiggerThan(row[(endDate as TargetColumnDefinition).sourceIndex!], false, endDateFormatRow)) this.results.endDateEarly.push(rowIndex);
        }
      }
      if(isEndDate)
      {
        if(!validators.EndDate.format(row, endDate!.sourceIndex!, endDateFormatRow))
        {
          this.results.endDateFormat.push(rowIndex);
        }
      }
      if(isTimeZone)
      {
        if(!validators.TimeZone.format(row, timeZone!.sourceIndex!))
        {
          this.results.timeZoneFormat.push(rowIndex);
        }
      }
      if(isTimeZoneUnformatted)
      {
        if(!validators.TimeZone.format(row, timeZoneUnformatted!.sourceIndex!))
        {
          this.results.timeZoneUnformattedFormat.push(rowIndex);
        }
      }
      if(isFTE)
      {
        // const row = this.dataRaw[rowIndex];
        if(!validators.FTE.format(row, fte!.sourceIndex!))
        {
          this.results.fteFormat.push(rowIndex);
        }
      }
      if(isHourlyRate)
      {
        if(!validators.HourlyRate.format(row, hourlyRate!.sourceIndex!))
        {
          this.results.hourlyRateFormat.push(rowIndex);
        }
      }
    }

    duplicateRowsValidator.process();
    
    this.results.duplicateRows           = duplicateRowsValidator.getDuplicateRows();
    this.results.duplicateRowsOverlaped  = duplicateRowsValidator.getOverlapedRows();
    this.results.emailSupervisorCycled   = emailSupervisorValidator.getCycledRows();
    this.results.emailSupervisorSelf     = emailSupervisorValidator.getSelfRows();
    this.results.emailSupervisorCEOMulti = (this.ceoRows.length === 1 && this.results.emailSupervisorMissing.length <= 1) ? [] : [...this.ceoRows];
    this.results.emailSupervisorEmpty    = (this.ceoRows.length === 1 && this.results.emailSupervisorEmpty.length <= 1 && this.results.emailSupervisorEmpty.includes(this.ceoRows[0])) ? [] : this.results.emailSupervisorEmpty;
    duplicateRowsValidator   = undefined;  // release memory
    emailSupervisorValidator = undefined;  // release memory
    // @DEBUG for testing and making test scenarios 
    /*
    console.log('VALIDATOR targetColumns', targetColumns);
    console.log('VALIDATOR data', this.data);
    console.log('VALIDATOR RESULT', this.results);
    */
  }

  getValidated = (): boolean => {
    return this.isValidated;
  }

  getResults = (): ValidatorResults => {
    return this.results;
  }

  getSummary(): ValidatorSummaryResult
  {
    const summary: ValidatorSummaryResult = {
      warning: 0,
      error: 0,
      isValid: true
    }
    if(!this.isValidated)
    {
      throw new Error('getSummary error - is not validated already!');
    }
    const results = this.results;

    const mainDepartmentInsufficientResult = results.mainDepartmentInsufficient;
    const warningResults = [
      results.emailSupervisorMissing,
      results.emailSupervisorEmpty,
      results.emailSupervisorTeams,
      results.duplicateRows,
      results.timeZoneUnformattedFormat,
      results.hourlyRateFormat
    ];
    const errorResults = [
      results.emailEmpty,
      results.emailFormat,
      results.emailSupervisorFormat,
      results.emailSupervisorCycled,
      results.emailSupervisorSelf,
      results.emailAliasFormat,
      results.mainDepartmentMissing,
      results.startDateFormat,
      results.endDateEarly,
      results.endDateFormat,
      results.duplicateRowsOverlaped,
      results.timeZoneFormat,
      results.fteFormat,
    ];

    const overriddenRuleMainDepartmentInsufficient = this.overriddenRules.find(row => row.rule === 'mainDepartmentInsufficient');
    if(overriddenRuleMainDepartmentInsufficient && overriddenRuleMainDepartmentInsufficient.type === 'error')
    {
      errorResults.push(mainDepartmentInsufficientResult);
    }
    else
    {
      warningResults.push(mainDepartmentInsufficientResult);
    }
    summary.error = errorResults.map(result => result.length).reduce((a, b) => a + b, 0);
    summary.warning = warningResults.map(result => result.length).reduce((a, b) => a + b, 0);

    summary.isValid = summary.error === 0;
    return summary;
  }

  getStatusesVerbose(statusPrepend: boolean = true, statusColumn: string = statusColumnTitle, data?: any[][]): any[][]
  {
    if(!this.isValidated)
    {
      throw new Error('getStatusesVerbose error - is not validated already!');
    }
    // data = data || this.dataRaw;
    data = data || this.data;
    const results = this.results;
    const dataStatuses: ValidatorStatusVerbose[] = new Array(data.length).fill(undefined).map(() => ({ error: [], warning: [], info: [] }));

    const addToDataStatuses = (indexes: number[], note: string, statusType: ValidatorStatusType) => {
      indexes.forEach((index: number) => {
        dataStatuses[index][statusType].push(note);
      })
    }

    const mainDepartmentInsufficientResult = {result: results.mainDepartmentInsufficient, message: `Too few people in department`};
    const warningResults = [
      {result: results.emailSupervisorMissing, message: `Missing: supervisor as an employee`},
      {result: results.emailSupervisorEmpty, message: `Missing: supervisor_email_id`},
      {result: results.emailSupervisorTeams, message: `Team size is not in allowed range`},
      {result: results.timeZoneUnformattedFormat, message: `Invalid format: timezone`},
      {result: results.duplicateRows, message: `Duplicate rows`},
      {result: results.hourlyRateFormat, message: `Invalid format: hourly_rate`},
    ]
    const errorResults = [
      {result: results.emailEmpty, message: `Missing: email_id`},
      {result: results.emailFormat, message: `Invalid format: email_id`},
      {result: results.emailSupervisorFormat, message: `Invalid format: supervisor_email_id`},
      {result: results.emailSupervisorCycled, message: `Circular reference in supervisor_email_id`},
      {result: results.emailSupervisorSelf, message: `Self reporting in supervisor_email_id`},
      {result: results.emailAliasFormat, message: `Invalid format: alias_emails`},
      {result: results.mainDepartmentMissing, message: `Missing: main_department`},
      {result: results.startDateFormat, message: `Invalid format: start_date`},
      {result: results.endDateEarly, message: `End date is earlier than start date`},
      {result: results.endDateFormat, message: `Invalid format: end_date`},
      {result: results.timeZoneFormat, message: `Invalid format: timezone`},
      {result: results.fteFormat, message: `Invalid value: FTE`},
      {result: results.duplicateRowsOverlaped, message: `Overlapping records`},
    ];

    const overriddenRuleMainDepartmentInsufficient = this.overriddenRules.find(row => row.rule === 'mainDepartmentInsufficient');
    if(overriddenRuleMainDepartmentInsufficient && overriddenRuleMainDepartmentInsufficient.type === 'error')
    {
      errorResults.push(mainDepartmentInsufficientResult);
    }
    else
    {
      warningResults.push(mainDepartmentInsufficientResult);
    }

    warningResults.forEach((part) => {
      addToDataStatuses(part.result, part.message, 'warning');
    });

    errorResults.forEach((part) => {
      addToDataStatuses(part.result, part.message, 'error');
    });


    const dataStatus: any[][] = [];
    data.forEach((row: any[], index: number) => {
      const rowStatus = [...row];
      const status = dataStatuses[index];
      const message: string[] = [];
      if(status.error.length || status.warning.length || status.info.length)
      {
        if(status.error.length)
        {
          message.push(`${status.error.length} Error${status.error.length === 1 ? `` : `s`}: ${status.error.join(', ')}`);
        }
        if(status.warning.length) 
        {
          message.push(`${status.warning.length} Warning${status.warning.length === 1 ? `` : `s`}: ${status.warning.join(', ')}`);
        }
      }
      else
      {
        message.push(`Ok`);
      }

      const rowStatusMessage = message.join(', ');
      if(statusPrepend) 
      {
        rowStatus.unshift(rowStatusMessage);
      }
      else
      {
        rowStatus.push(rowStatusMessage);
      }
      dataStatus.push(rowStatus);
    });
    
    const statusTitleColumnIndex = (statusPrepend) ? 0 : dataStatus[0].length - 1;
    dataStatus[0][statusTitleColumnIndex] = statusColumn;
    return dataStatus;
  }
}
