import React, { FC } from 'react';
import styled from 'styled-components';
import { publicURL } from '../rest';
import { theme } from './ui/theme';

interface Prop
{
  background?: string;
}

interface ContainerProps
{
  background?: string;
}

const Background = styled.div`
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: ${theme.appColors.brandDark};
  overflow: auto;
`;

const LoginContainer = styled.div`
  width: 310px;
  height: 137px;
  margin-top: 25px;
  display: block;
`;

interface LogoProps
{
  logo: string;
}

const Logo: React.FC<LogoProps> = styled.div`
  background-image: url("${publicURL}/images/${(props: LogoProps) => props.logo}");
  background-repeat: no-repeat;
  width: 290px;
  height: 112px;
  margin-top: 25px;
`;


const Container: React.FC<ContainerProps> = styled.div`
  width: 360px;
  min-height: 216px;
  overflow: hidden;
  padding: 28px 30px 30px 30px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  font-size: 16px;
  ${({ background }: ContainerProps) => background ? `background-color: ${background};` : ``}
`;

export const IEContainer: FC<Prop> = ({ 
  background, 
  children 
}) => {
  background = background || `linear-gradient(-180deg, #f7f7f7 0%, #efefef 100%)`;
  const logo = 'TIL-logo2020-white.png';
  return (
    <Background>
      <LoginContainer>
        <Logo logo={logo} />
      </LoginContainer>
      <Container background={background}>{children}</Container>
    </Background>
  )
};