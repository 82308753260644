import React, { FC } from 'react';
import styled from 'styled-components';

type FlexDirection = 'row' | 'column';

interface Prop
{
  mx?: number;
  my?: number;
  mt?: number;
  mb?: number;
  direction?: FlexDirection;
  opacity?: number;
}

interface BoxScreenElementProps
{
  mx       : number;
  my       : number;
  mt?      : number;
  mb?      : number;
  opacity  : number;
  direction: FlexDirection;
}

const BoxScreenElement: React.FC<BoxScreenElementProps> = styled.div`
  ${(props: BoxScreenElementProps) => `
    margin: ${props.my}px ${props.mx}px;
    ${props.mt ? `margin-top: ${props.mt}px;` : ''}
    ${props.mb ? `margin-bottom: ${props.mb}px;` : ''}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    flex-direction: ${props.direction};
    opacity: ${props.opacity};
  `}
`;

export const BoxScreen: FC<Prop> = ({ 
  mx = 0,
  my = 15,
  mt,
  mb,
  direction = 'column',
  opacity = 1.0,
  children 
}) => {
  return (
    <BoxScreenElement
      mx={mx} my={my}
      mt={mt} mb={mb}
      direction={direction}
      opacity={opacity}
    >
        {children}
    </BoxScreenElement>
  )
};