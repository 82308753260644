import {
  targetColumnType,
  MappingDefinition,
  TargetColumnDefinition,
} from './types';
import {
  AnonymizerHashing,
  // AnonymizerEncryptor
} from '../libs'
import {
  localDebug
} from '../rest';

type ColumnsReplace = 'first_name' | 'middle_name' | 'last_name' | 'full_name' | 'position';
type ColumnsReplaceRecord = Pick<Record<targetColumnType, string>, ColumnsReplace>;

const replaceMap: ColumnsReplaceRecord = {
  'first_name': 'anonymized_name', 
  'middle_name': 'anonymized_name', 
  'last_name': 'anonymized_name', 
  'full_name': 'anonymized_name', 
  'position': 'anonymized_position',
}

const emailColumnsTypes: targetColumnType[] = ['email_id', 'supervisor_email_id', 'alias_emails'];

const replaceFallback: string = 'anonymized_value';

export class Anonymizer
{
  private hashing: AnonymizerHashing;
  private mapping: MappingDefinition;
  // private encryptor: AnonymizerEncryptor | null = null;

  constructor(hashing: AnonymizerHashing, mapping: MappingDefinition)
  {
    this.hashing = hashing;
    this.mapping = mapping;
  }

  // setEncryptor = (encryptor: AnonymizerEncryptor) => {
  //   this.encryptor = encryptor;
  // }

  anonymizeRow = async (dataRow: any[], targetColumnsAnonymize: TargetColumnDefinition[], targetColumnsReplace: TargetColumnDefinition[]): Promise<any[]> => {
    const dataRowAnonymized: any[] = [...dataRow];
    // Process email anonymization
    for(let col = 0, cols = targetColumnsAnonymize.length; col < cols; col++)
    {
      const column = targetColumnsAnonymize[col];
      const value = dataRow[column.sourceIndex!];
      if(!value || !value.trim)
      {
        if(localDebug) console.warn(`Anonymizer - Invalid email on col [${col}]!`, column, dataRow);
        continue;
      }
      if(emailColumnsTypes.includes(column.name))
      {
        // dataRowAnonymized[column.sourceIndex!] = this.encryptor === null ? await this.hashing.hashEmails(dataRow[column.sourceIndex!]) : await this.encryptor.encryptEmails(dataRow[column.sourceIndex!]);
        dataRowAnonymized[column.sourceIndex!] = await this.hashing.hashEmails(dataRow[column.sourceIndex!]);
      }
      else
      {
        dataRowAnonymized[column.sourceIndex!] = await this.hashing.process(dataRow[column.sourceIndex!]);
      }
    }

    // Process anonymization by replace
    for(let col = 0, cols = targetColumnsReplace.length; col < cols; col++)
    {
      const column = targetColumnsReplace[col];
      const value = dataRow[column.sourceIndex!];
      if(!value || !value.trim)
      {
        if(localDebug) console.warn(`Anonymizer - Invalid value on col [${col}]!`, column, dataRow);
      }
      switch(column.name)
      {
        case 'first_name':
        case 'middle_name':
        case 'last_name':
        case 'full_name':
        case 'position': {
          dataRowAnonymized[column.sourceIndex!] = replaceMap[column.name];
          break;
        }

        default: {
          dataRowAnonymized[column.sourceIndex!] = replaceFallback;
          break;
        }
      }
    }

    return dataRowAnonymized;
  }
  
  anonymize = async (data: any[][], anonymizeColumns: targetColumnType[], replaceColumns: targetColumnType[]): Promise<any[][]> => {
    const dataAnonymized: any[][] = [[...data[0]]];
    const targetColumnsAnonymize = this.mapping.targetColumns.filter(column => anonymizeColumns.includes(column.name) && column.sourceIndex !== null);
    const targetColumnsReplace = this.mapping.targetColumns.filter(column => replaceColumns.includes(column.name) && column.sourceIndex !== null);
    for(let row = 1, rows = data.length; row < rows; row++)
    {
      const dataRow = await this.anonymizeRow(data[row], targetColumnsAnonymize, targetColumnsReplace);
      dataAnonymized.push(dataRow);
    }
    return dataAnonymized;
  }
}