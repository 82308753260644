import * as patterns from '../patterns'

const { emailRE, emailAliasRE } = patterns;

const checkEmailAliasFormat = (value: any) => (typeof value === 'string') && emailAliasRE.test(value.trim()) && value.split(',').every(e => emailRE.test(e.trim()));
const checkNotEmpty         = (value: any) => (typeof value !== 'string') || value.trim().length > 0;

export class EmailAlias
{
  static format = (row: any[], colIndex: number) => {
    const value = row[colIndex];
    return !checkNotEmpty(value) || checkEmailAliasFormat(value);
  }
  
}

