import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { SpacingProps, applySpacingProps } from '../../utils';

interface Props {
  children: React.ReactNode;
  spacing?: SpacingProps;
}

export const Notification: FC<Props> = observer(({ children, spacing }) => {
  return <StyledNotification spacing={spacing}>{children}</StyledNotification>;
});

const StyledNotification = styled.div<{ spacing?: SpacingProps }>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fae5d6;
  box-shadow: 0px 1px 2px rgba(69, 90, 100, 0.16);
  padding: 5px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props => applySpacingProps(props.spacing)}
`;
