export type AllowedMIMETypes =
  | 'text/csv'
  | 'application/vnd.ms-excel'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/zip'

export interface UploadFile {
  name: string
  namePart: string
  extPart: string
  content: ArrayBuffer | string | null
  file: File
  type: AllowedMIMETypes | null
}

export interface UploadedFile {
  name: string
  namePart: string
  extPart: string
  content: ArrayBuffer | string
  type: string
  file: File
  data?: any[][]
}

export const UPLOAD_TYPE_CSV = 'hr_table'
export const UPLOAD_TYPE_EMAIL_MAP = 'hr_table_rsa_map'
export const UPLOAD_TYPE_TRANSFER = 'other'

export type UploadType =
  | typeof UPLOAD_TYPE_CSV
  | typeof UPLOAD_TYPE_EMAIL_MAP
  | typeof UPLOAD_TYPE_TRANSFER

export const PRESET_STORAGE_KEY = 'hr-table-preset'
export const CLIENT_STORAGE_KEY = 'client-id-preset'
export const FILE_TRANSFER_STORAGE_KEY = 'file-transfer'
export const INIT_ERROR__STORAGE_KEY = 'init-error'

// eslint-disable-next-line
export type targetColumnType =
  | 'start_date'
  | 'end_date'
  | 'first_name'
  | 'middle_name'
  | 'last_name'
  | 'full_name'
  | 'email_id'
  | 'alias_emails'
  | 'main_department'
  | 'supervisor_email_id'
  | 'sub_department_0'
  | 'sub_department_1'
  | 'sub_department_2'
  | 'sub_department_3'
  | 'sub_department_4'
  | 'position'
  | 'location'
  | 'timezone'
  | 'timezone_unformatted'
  | 'employee_grade'
  | 'employee_type'
  | 'employee_status'
  | 'fte'
  | 'hourly_rate'

export const targetColumnTypeList: targetColumnType[] = [
  'start_date',
  'end_date',
  'first_name',
  'middle_name',
  'last_name',
  'full_name',
  'email_id',
  'alias_emails',
  'main_department',
  'supervisor_email_id',
  'sub_department_0',
  'sub_department_1',
  'sub_department_2',
  'sub_department_3',
  'sub_department_4',
  'position',
  'location',
  'timezone',
  'timezone_unformatted',
  'employee_grade',
  'employee_type',
  'employee_status',
  'fte',
  'hourly_rate',
]

// eslint-disable-next-line
export const statusColumnTitle = 'HR Uploader Status'
// eslint-disable-next-line
export type dateType = 'd/m/y' | 'm/d/y' | 'y-m-d' | 'd.m.y'
// eslint-disable-next-line
export type nameType = 'full' | 'first_last' | 'first_middle_last'
// eslint-disable-next-line
export type mimeType =
  | 'application/vnd.ms-excel'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'text/csv'
  | 'application/zip'

export interface DateFormats {
  name: targetColumnType
  format: dateType
}
// eslint-disable-next-line
export interface targetColumnDefinition {
  title: string
  required: boolean
  source: null | string
  sourceIndex: null | number
}

export interface TargetColumnDefinition extends targetColumnDefinition {
  name: targetColumnType
}

export interface SourceColumnDefinition {
  name: string
}

export interface MappingDefinition {
  targetColumns: TargetColumnDefinition[]
  nameType: nameType
  dateFormats: DateFormats[]
}
// eslint-disable-next-line
export type targetColumnNamesType = Record<
  targetColumnType,
  TargetColumnDefinition
>
// eslint-disable-next-line
export const targetColumnNames: targetColumnNamesType = {
  email_id: {
    name: 'email_id',
    title: 'Email',
    required: true,
    source: null,
    sourceIndex: null,
  },
  supervisor_email_id: {
    name: 'supervisor_email_id',
    title: 'Supervisor Email',
    required: true,
    source: null,
    sourceIndex: null,
  },
  main_department: {
    name: 'main_department',
    title: 'Main Department',
    required: true,
    source: null,
    sourceIndex: null,
  },
  start_date: {
    name: 'start_date',
    title: 'Start date',
    required: false,
    source: null,
    sourceIndex: null,
  },
  end_date: {
    name: 'end_date',
    title: 'End date',
    required: false,
    source: null,
    sourceIndex: null,
  },
  alias_emails: {
    name: 'alias_emails',
    title: 'Alias Emails',
    required: false,
    source: null,
    sourceIndex: null,
  },
  first_name: {
    name: 'first_name',
    title: 'First Name',
    required: false,
    source: null,
    sourceIndex: null,
  },
  middle_name: {
    name: 'middle_name',
    title: 'Middle Name',
    required: false,
    source: null,
    sourceIndex: null,
  },
  last_name: {
    name: 'last_name',
    title: 'Last Name',
    required: false,
    source: null,
    sourceIndex: null,
  },
  full_name: {
    name: 'full_name',
    title: 'Full Name',
    required: false,
    source: null,
    sourceIndex: null,
  },
  sub_department_0: {
    name: 'sub_department_0',
    title: 'Sub Department 0',
    required: false,
    source: null,
    sourceIndex: null,
  },
  sub_department_1: {
    name: 'sub_department_1',
    title: 'Sub Department 1',
    required: false,
    source: null,
    sourceIndex: null,
  },
  sub_department_2: {
    name: 'sub_department_2',
    title: 'Sub Department 2',
    required: false,
    source: null,
    sourceIndex: null,
  },
  sub_department_3: {
    name: 'sub_department_3',
    title: 'Sub Department 3',
    required: false,
    source: null,
    sourceIndex: null,
  },
  sub_department_4: {
    name: 'sub_department_4',
    title: 'Sub Department 4',
    required: false,
    source: null,
    sourceIndex: null,
  },
  location: {
    name: 'location',
    title: 'Location',
    required: false,
    source: null,
    sourceIndex: null,
  },
  position: {
    name: 'position',
    title: 'Position',
    required: false,
    source: null,
    sourceIndex: null,
  },
  fte: {
    name: 'fte',
    title: 'FTE',
    required: false,
    source: null,
    sourceIndex: null,
  },
  timezone: {
    name: 'timezone',
    title: 'TimeZone',
    required: false,
    source: null,
    sourceIndex: null,
  },
  timezone_unformatted: {
    name: 'timezone_unformatted',
    title: 'TimeZone',
    required: false,
    source: null,
    sourceIndex: null,
  },
  employee_grade: {
    name: 'employee_grade',
    title: 'Employee grade',
    required: false,
    source: null,
    sourceIndex: null,
  },
  employee_type: {
    name: 'employee_type',
    title: 'Employee type',
    required: false,
    source: null,
    sourceIndex: null,
  },
  employee_status: {
    name: 'employee_status',
    title: 'Employee status',
    required: false,
    source: null,
    sourceIndex: null,
  },
  hourly_rate: {
    name: 'hourly_rate',
    title: 'Hourly rate',
    required: false,
    source: null,
    sourceIndex: null,
  },
}

export const anonymizedAllowedColumns: targetColumnType[] = [
  'email_id',
  'supervisor_email_id',
  'alias_emails',
  'main_department',
  'start_date',
  'end_date',
  'location',
  'position',
  'first_name',
  'middle_name',
  'last_name',
  'full_name',
  'sub_department_0',
  'sub_department_1',
  'sub_department_2',
  'sub_department_3',
  'sub_department_4',
  'fte',
  'timezone',
  'timezone_unformatted',
  'employee_grade',
  'employee_type',
  'employee_status',
]
export const anonymizedHashedColumns: targetColumnType[] = [
  'email_id',
  'supervisor_email_id',
  'alias_emails',
]

export const nameTypes: Record<nameType, targetColumnType[]> = {
  full: ['full_name'],
  first_last: ['first_name', 'last_name'],
  first_middle_last: ['first_name', 'middle_name', 'last_name'],
}

export const dateTypesNames: Record<dateType, string> = {
  'd.m.y': 'day.month.year',
  'd/m/y': 'day/month/year',
  'm/d/y': 'month/day/year',
  'y-m-d': 'year-month-day',
}

export const mimeTypes: Record<mimeType, string> = {
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'text/csv': 'csv',
  'application/zip': 'zip',
}

export interface EmailIndexMap {
  processed: boolean
  map: Record<string, number[]>
}

export type OverriddedRuleType = 'error' | 'warning'

export type OverriddedRuleInsufficientPeopleOptions = {
  rule: 'mainDepartmentInsufficient'
  peopleSufficientLimit: number
  type: OverriddedRuleType
}

export type OverriddedRule = OverriddedRuleInsufficientPeopleOptions

export type Config = {
  rules: OverriddedRule[]
  hiddenColumns: targetColumnType[]
  additionalRequiredColumns: targetColumnType[]
}

export type ValidatorStatusType = 'info' | 'warning' | 'error'

export type ValidatorStatusVerbose = {
  error: string[]
  warning: string[]
  info: string[]
}
// eslint-disable-next-line
export type validatorSummary = 'summary'

export interface ValidatorCommonResult {
  type: targetColumnType
  isValid: boolean
}

export interface ValidatorSummaryResult {
  warning: number
  error: number
  isValid: boolean
}

export type ValidatorResult = ValidatorCommonResult

export type ExportStatusFile = {
  file_name: string
  gs_link: string
  upload_type: UploadType
}

export interface ExportStatusMetadata {
  mapping: MappingDefinition | null
  mappingDetected: MappingDefinition | null
  mappingPreset: MappingDefinition | null
  validationSummary: ValidatorSummaryResult | null
}

export interface ExportStatus {
  csv: ExportStatusFile | null
  hashed?: ExportStatusFile
  rsa_map?: ExportStatusFile
  customer_id: string
  // metadata: ExportStatusMetadata | null;
  // metadataError: any;
}

export interface FileUploadStatus {
  file: ExportStatusFile | null
}

export type HTTPMethod = 'POST' | 'GET' | 'PUT'
export type UploaderStatus = 'uploaded' | 'failed' | 'cancelled'
export type GenericObject = { [key: string]: any }

export interface FileUploadProgressResult {
  size: number
  processed: number
  remain: number
  percent: number
}

export interface UploadInitResponse {
  url: string
  gsLink: string
  type: UploadType
}

export interface UploaderResponse {
  status: UploaderStatus
  message: string
  gsLink?: string
  fileName?: string
  type?: UploadType
}

export interface Logger {
  log(msg: any): void
}

export type EmptyHanlder = (...args: any[]) => void
export type DefaultHanlder = (...args: any[]) => void
export type CancelCallback = (handler: EmptyHanlder) => void
export type ProgressCallback = (progress: FileUploadProgressResult) => void
export type ResponseCallback = (response: UploaderResponse) => void
export type RejectCallback = (response: UploaderResponse) => void

export interface CallbackPromise {
  resolve: ResponseCallback
  reject: RejectCallback
}
// eslint-disable-next-line
export type hashAlgorithmsAllowed = 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512'
// eslint-disable-next-line
export const hashAlgorithmsAllowedLength: Record<
  hashAlgorithmsAllowed,
  number
> = {
  'SHA-1': 40,
  'SHA-256': 64,
  'SHA-384': 96,
  'SHA-512': 128,
}

export type EncryptionAlgorithmsAllowed = 'RSA-OAEP'

export interface AnonymizationSettingsDomains {
  internalDomainList: string[]
  anonymizeExternalUsername: boolean
  anonymizeExternalDomain: boolean
  anonymizeInternalUsername: boolean
  anonymizeInternalDomain: boolean
}

export interface AnonymizationSettings extends AnonymizationSettingsDomains {
  anonymizationSalt: string
  rsaPublicKey: string
}

export type ValidatorCallbackResponse = {
  percent: number
  processed: boolean
}
