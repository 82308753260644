import React, { FC } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { theme } from '../../ui';

interface Prop {
  w?: number;
  h?: number;
}

interface WrapperProps
{
  width: number;
  height: number;
}

const Wrapper: React.FC<WrapperProps> = styled.div`
  top: 0;
  left: 0;
  overflow: hidden;
  ${(props: WrapperProps) => `
    width: ${props.width}px;
    height: ${props.height}px;
  `}
`;

const BoxAnimation = keyframes`
  0%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90%, 100% {
    background-color: ${theme.colors.extraLightGray};
  }
  10% {
    background-color: ${theme.appColors.brandDark};
  }
`;

interface BoxProps
{
  width: number;
  height: number;
  delay: number | null;
  item?: boolean;
  empty?: boolean;
}

const Box: React.FC<BoxProps> = styled.div`
  float: left;
  margin: 0 1px 1px 0;
  ${(props: BoxProps) => `
    width: ${props.width}px;
    height: ${props.height}px;
  `}

  ${(props: BoxProps) => props.item && css`
    background-color: ${theme.colors.extraLightGray};
    animation-name: ${BoxAnimation};
    animation-duration: 2.0s;
    animation-iteration-count: infinite;
    animation-timing-function: steps(1, end);
    animation-fill-mode: forwards;
  `}

  ${(props: BoxProps) => props.delay !== null && css`
    animation-delay: ${props.delay}s;
  `}
`;

const places: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
const empty: number = 4;
const delays = [0, 0.25, 0.5, 1.75, null, 0.75, 1.5, 1.25, 1.0];

export const BoxLoader: FC<Prop> = ({ 
  w = 50,
  h = 50,
}) => {

  const itemW = (w / 3) - 2;
  const itemH = (h / 3) - 2;

  return (
    <Wrapper width={w} height={h}>
      {places.map((item: any, index: number) => (
        <Box 
          key={`loader-item-${index}`} 
          width={itemW} 
          height={itemH} 
          empty={index === empty} 
          item={index !== empty} 
          delay={delays[index]}
        />
      ))}
    </Wrapper>
  )
};