import React from 'react';
import styled, { css } from 'styled-components';

import { ThemeColor } from '../../theme';
import { SpacingProps, applySpacingProps } from '../../utils';

interface Props {
  children: React.ReactNode;
  background?: ThemeColor;
  color?: ThemeColor;
  variant?: 'circle' | 'rounded' | 'square';
  size?: number;
  spacing?: SpacingProps;
}

export const Avatar = styled.div.withConfig<Props>({
  shouldForwardProp: prop => !['background', 'color', 'variant', 'size', 'spacing'].includes(prop),
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.size || 36}px;
  height: ${props => props.size || 36}px;
  color: ${({ theme, ...props }) => theme.getColor(props.color, 'inherit')};
  background-color: ${({ theme, ...props }) => theme.getColor(props.background, theme.appColors.brandLight)};
  border-radius: 50%;
  flex-shrink: 0;

  ${props =>
    props.variant === 'rounded' &&
    css`
      border-radius: 4px;
    `}
  ${props =>
    props.variant === 'square' &&
    css`
      border-radius: 0;
    `}

  ${props => applySpacingProps(props.spacing)}
`;
