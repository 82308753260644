import { dateType } from './types';
import { dates } from './patterns';

export const isDate = (value: Date | string): boolean => {
  return (typeof value === "object" && Object.prototype.toString.call(value) === '[object Date]');
}

export class DateConverter
{
  private dateSet: boolean = false;
  private dateValid: boolean = false;
  private date: string[] | null = null;

  readonly delimiterByType: Record<dateType, string> = {
    'y-m-d': '-',
    'd.m.y': '.',
    'd/m/y': '/',
    'm/d/y': '/'
  }
  
  /* input parts order [0-year, 1-month, 2-day] */
  readonly orderByType: Record<dateType, number[]> = {
    'y-m-d': [0,1,2],
    'd.m.y': [2,1,0],
    'd/m/y': [2,1,0],
    'm/d/y': [2,0,1]
  }
  
  /* output parts order [0-year, 1-month, 2-day] */
  readonly orderByTypeOutput: Record<dateType, number[]> = {
    'y-m-d': [0,1,2],
    'd.m.y': [2,1,0],
    'd/m/y': [2,1,0],
    'm/d/y': [1,2,0]
  }

  constructor(value?: Date | string, inputFormat?: dateType)
  {
    if(value)
    {
      this.set(value, inputFormat);
    }
  }

  isBiggerThan(date: DateConverter | string, orEqual: boolean = false, inputFormat?: dateType): boolean
  {
    const dateCompare = this.prepareSub(date, inputFormat);
    if(!dateCompare)
    {
      return false;
    }
    const diff = this.sub(dateCompare);
    if(isNaN(diff))
    {
      return false;
    }
    return (orEqual ? diff >= 0 : diff > 0);
  }

  isSmallerThan(date: DateConverter | string, orEqual: boolean = false, inputFormat?: dateType): boolean
  {
    return !this.isBiggerThan(date, orEqual, inputFormat);
  }

  isValid(): boolean
  {
    return this.dateValid;
  }

  set(value: Date | string, inputFormat?: dateType): boolean
  {
    this.date = null;
    // Convert Date to string y-m-d
    if(isDate(value))
    {
      value = (value as Date).toJSON().split('T')[0];
      inputFormat = 'y-m-d';
    }
    value = (value as string);
    if(!inputFormat)
    {
      let dateDetected = this.detect(value);
      dateDetected = (dateDetected === 'd/m/y' || dateDetected === 'm/d/y') ? this.processSlashedDate(value, dateDetected) : dateDetected;
      if(dateDetected === false)
      {
        console.warn(`Unable to detect date format in ${value}`);
        this.dateSet = this.dateValid = false;
        return false;
      }
      inputFormat = dateDetected;
    }

    // split date
    const dateParsed = value.split(this.delimiterByType[inputFormat]);
    if(dateParsed.length !== 3)
    {
      console.warn(`Invalid inputFormat ${inputFormat} or inputDate ${value}`);
      this.dateSet = this.dateValid = false;
      return false;
    }
    const dateParts = this.orderByType[inputFormat].map(index => dateParsed[index].trim());
    // Normalize Year to four digits - beware! safe only to Year 2069, day and month padding with leading zeros
    dateParts[0] = (dateParts[0].length === 2) ? ((parseInt(dateParts[0], 10) >= 70) ? `19${dateParts[0]}` : `20${dateParts[0]}`) : dateParts[0];
    dateParts[1] = dateParts[1].padStart(2, '0');
    dateParts[2] = dateParts[2].padStart(2, '0');
    if(!this.check(dateParts))
    {
      console.warn(`Invalid inputDate ${value} with inputFormat ${inputFormat} - checking failed`);
      this.dateSet = this.dateValid = false;
      return false;
    }
    this.date = dateParts;
    this.dateSet = this.dateValid = true;
    return true;
  }

  get(outputFormat: dateType = 'y-m-d'): string | null
  {
    if(!this.isValid || this.date === null)
    {
      console.warn(`Unable to get date - is not valid or not set`);
      return null;
    }
    const outputDateParts: string[] = this.orderByTypeOutput[outputFormat].map(index => this.date![index]);;
    return outputDateParts.join(this.delimiterByType[outputFormat]);
  }

  detect(value: string): dateType | false
  {
    for(const type in dates)
    {
      if(!Object.prototype.hasOwnProperty.call(dates, type))
      {
        continue;
      }
      const format = (type as dateType);
      if(dates[format].test(value))
      {
        return format;
      }
    }
    return false;
  }

  toString(): string
  {
    const value = this.get('y-m-d');
    return value ? value : '';
  }

  toJSON(): string
  {
    return this.toString();
  }

  private check(dateParts: string[]): boolean
  {
    const currentDate = new Date();
    // normalize to number
    const dateNormalized: number[] = dateParts.map(date => parseInt(date, 10));
    currentDate.setFullYear(dateNormalized[0], dateNormalized[1] - 1, dateNormalized[2]);
    if(currentDate.getFullYear() === dateNormalized[0] && (currentDate.getMonth() + 1) === dateNormalized[1] && currentDate.getDate() === dateNormalized[2])
    {
      return true;
    }
    return false;
  }

  private prepareSub(date: DateConverter | string, inputFormat?: dateType): false | DateConverter
  {
    if(!this.isValid)
    {
      return false;
    }
    const dateCompare = (date instanceof DateConverter) ? date : new DateConverter(date, inputFormat);
    if(!dateCompare.isValid)
    {
      return false;
    }
    return dateCompare;
  }

  private sub(date2: DateConverter): number
  {
    const date = this.get('y-m-d');
    const dateSub = date2.get('y-m-d');
    if(!date || !dateSub)
    {
      return NaN;
    }
    return new Date(date).getTime() - new Date(dateSub).getTime();
  }

  private processSlashedDate(value: string, dateDetected: dateType): dateType | false
  {
    const dateParsed = value.split(this.delimiterByType[dateDetected]);
    if(dateParsed.length !== 3) return false;
    const day = (dateDetected === 'd/m/y') ? parseInt(dateParsed[0], 10) : parseInt(dateParsed[1], 10);
    if(day < 13) return false;
    return dateDetected;
  }
}
