import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { SortDirection } from '../../types';
import { SpacingProps } from '../../utils';

import { IconArrowUpward, IconArrowDownward } from '../icons';

interface Props {
  className?: string;
  direction?: SortDirection;
  size?: number;
  spacing?: SpacingProps;
}

export const SortingArrow: FC<Props> = observer(({ direction, className, size, spacing }) => {
  const props = { className, size, spacing };

  if (direction === 'asc') return <IconArrowUpward {...props} />;
  if (direction === 'desc') return <IconArrowDownward {...props} />;
  return null;
});
