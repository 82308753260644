import React from 'react';
import styled from 'styled-components';
import Lock from '@material-ui/icons/LockOutlined';
import { theme } from '../../ui/theme';
import { langErrorMsg } from '../../../libs';

const UploaderOverlayWrapper = styled.div`
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  background-color: ${theme.colors.white};
  opacity: .9;
  z-index: 10;
`;

const UploaderOverlayDescription = styled.p`
  color: ${theme.colors.darkGray};
  padding: 10px 30px 0;
  text-align: center;
  font-weight: bold;
`;

const UploaderOverlayIcon = styled.div`
  padding: 120px 30px 0;
  font-size: 48px;
  text-align: center;
`;

type UploaderAnonymizationOverlayParams = {
  className: string
}

export const UploaderAnonymizationOverlay: React.FC<UploaderAnonymizationOverlayParams> = ({
  className
}) => {
  return (
    <UploaderOverlayWrapper className={className}>
      <UploaderOverlayIcon>
        <Lock fontSize="inherit" />
      </UploaderOverlayIcon>
      <UploaderOverlayDescription>{langErrorMsg.unableUploadAuthorization}</UploaderOverlayDescription>
    </UploaderOverlayWrapper>
  )
}