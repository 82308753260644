import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { RouteList } from '../../screens';
import { 
  StoreHR, 
  Detector,
  DetectorAllowed,
  Timer, 
  TargetColumnDefinition,
  MappingDefinition,
  UploadedFile,
  DataContainer,
  pathReplace,
  getClientId,
} from '../../libs';
import { 
  ProgressBar,
  Container,
  Title,
  Header,
  Screen,
  Mapping,
  ModalMapping,
  ModalDateFormat,
  Scrollbar,
  ScrollbarPointer,
  MappingReset,
  MappingOverview,
} from '../../components';
import {
  theme
} from '../../components/ui';
import {
  targetColumnNames,
  EmptyHanlder,
  PRESET_STORAGE_KEY,
} from '../../libs/types';
import { bypassValidation, isDev } from '../../rest';
import { projectConfig } from '../../user';
import { lang } from '../../libs';

interface ExcelProps extends RouteComponentProps<{
  organizationId: string;
}>
{
}

const MappingWrapper = styled.div`
  display: flex;
  width: calc(100% - 46px);
  margin: 0 20px 20px 20px;
  user-select: none;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  flex-direction: column;
  overflow-x: auto;
`;

const MappingScreen = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  background-color: ${theme.colors.ultraLightGray};
  height: calc(100%);
`;

type ExcelLoadingPhase = 'loading' | 'no-data' | 'parsing' | 'parsed';

interface Uploading
{
  progress      : boolean;
  cancelHandlers: EmptyHanlder[];
}

interface ScrollRef
{
  screen   : any;
  mapping  : any;
  scrollbar: any;
  pointer  : any;
}

interface ScrollState
{
  enabled    : boolean;
  initialized: boolean;
  active     : boolean;
  progress   : boolean;
  from       : number;
  ratio      : number;
  position   : number;
  positionMax: number;
}
interface ExcelState
{
  phase            : ExcelLoadingPhase;
  loadingTime      : number;
  dataContainer    : DataContainer;
  mappingDefinition: MappingDefinition;

  isValidMapping   : boolean;
  uploading        : Uploading;
  detectedColumns  : string[] | null;
  allowedColumns   : DetectorAllowed[] | null;
  undetectableDates: TargetColumnDefinition[];

  loadedModal     : boolean;
  mappingKey      : number;
  mappingForced   : boolean;
  mappingScrolling: boolean;

  refreshingKey   : number;
  scrolling       : boolean;
  scrollLeft      : number;
}
const mappingId: string = Math.random().toString(36).slice(2);
const timer = new Timer();

export class Excel extends React.Component<ExcelProps, ExcelState>
{
  private detector: Detector | null = null;

  private scrollRef: ScrollRef;
  private scrollState: ScrollState;

  private preset: string | null = null;
  private scrollingTimeout: number = 0;

  constructor(props: Readonly<ExcelProps>)
  {
    super(props);
    this.state           = this.getDefaultState();
    this.scrollRef       = {
      screen: null,
      mapping: null,
      scrollbar: null,
      pointer: null
    }
    this.scrollState     = {
      enabled: false,
      initialized: false,
      active: false,
      progress: false,
      from: 0,
      ratio: 1,
      position: 0,
      positionMax: 0,
    }

    this.preset = localStorage.getItem(PRESET_STORAGE_KEY);
  }

  protected getDefaultState(): ExcelState
  {
    const targetColumns: TargetColumnDefinition[] = [...Object.values({...targetColumnNames})];
    return {
      phase            : 'loading',
      loadingTime      : 0,
      dataContainer    : StoreHR.getData(),
      mappingDefinition: {
        targetColumns,
        nameType   : 'first_middle_last',
        dateFormats: []
      },
      isValidMapping: Excel.isValidTargetColumnDefinition(targetColumns),
      uploading     : {
        progress      : false,
        cancelHandlers: []
      },
      detectedColumns  : null,
      allowedColumns   : null,
      undetectableDates: [],
      loadedModal      : !!StoreHR.getWelcomeMessage(),
      mappingKey       : 0,
      mappingForced    : false,
      mappingScrolling : false,
      refreshingKey    : 0,
      scrolling        : false,
      scrollLeft       : 0
    }
  }

  static isValidTargetColumnDefinition(targetColumns: TargetColumnDefinition[]): boolean
  {
    const isValid = (!targetColumns.find((element: TargetColumnDefinition) => {
      const isEmptyRequired = ((element.source === null || element.sourceIndex === null) && element.required === true);
      return isEmptyRequired;
    }));
    return isValid;
  }

  private scrollInitialize = () =>
  {
    if(this.scrollState.initialized)
    {
      this.scrollUninitialize();
    }
    const ref = this.scrollRef;
    if(ref.pointer && ref.scrollbar && ref.screen && ref.mapping)
    {
      ref.pointer.addEventListener('mousedown', this.eventScrollActivate);
      ref.screen.addEventListener('mouseup', this.eventScrollDeactivate);
      ref.screen.addEventListener('mousemove', this.eventScroll);
      ref.screen.addEventListener('mouseleave', this.eventScrollDeactivate);
      window.addEventListener('resize', this.resize);
      const ratio = ref.mapping.clientWidth / ref.mapping.scrollWidth;
      ref.pointer.style.width = `${ref.scrollbar.clientWidth * ratio}px`;
      this.scrollState.positionMax = ref.scrollbar.clientWidth - ref.pointer.clientWidth;
      this.scrollState.ratio = ref.mapping.scrollWidth / ref.mapping.clientWidth;
      this.scrollState.initialized = true;
    }
  }

  private scrollUninitialize = () => 
  {
    if(!this.scrollState.initialized) return;
    const ref = this.scrollRef;
    if(ref.pointer && ref.scrollbar && ref.screen && ref.mapping)
    {
      ref.pointer.removeEventListener('mousedown', this.eventScrollActivate);
      ref.screen.removeEventListener('mouseup', this.eventScrollDeactivate);
      ref.screen.removeEventListener('mousemove', this.eventScroll);
      ref.screen.removeEventListener('mouseleave', this.eventScrollDeactivate);
      window.removeEventListener('resize', this.resize);
      this.scrollState.initialized = false;
    }
  }

  private eventScrollActivate = (e: React.MouseEvent<HTMLDivElement>) => 
  {
    this.scrollState.active = true;
    this.scrollState.from = e.screenX;
    this.setState({ scrolling: true });
  }

  private eventScrollDeactivate = () => 
  {
    if(!this.scrollState.active) return;
    this.scrollState.active = false;
    this.setState({ scrollLeft: this.scrollRef.mapping.scrollLeft, scrolling: false });
  }

  private eventScroll = (e: React.MouseEvent<HTMLDivElement>) => 
  {
    const state = this.scrollState;
    if(!state.active || state.progress) return;
    this.scrollState.progress = true;
    const ref = this.scrollRef;
    const el = ref.mapping;
    const scroll = el.scrollLeft;
    const scrollMax = el.scrollWidth - el.clientWidth;
    let offset = (e.screenX - state.from) * state.ratio;
    if((offset < 0 && scroll === 0) || (offset > 0 && scroll >= scrollMax))
    {
      state.from = e.screenX;
      state.progress = false;
      return; // on start/end
    } 
    if((scroll + offset) >= scrollMax) offset -= (scroll + offset) - scrollMax;
    el.scrollLeft += offset;
    const ratio = el.scrollLeft / scrollMax;
    state.position = state.positionMax * ratio;
    ref.pointer.style.left = `${state.position}px`;
    state.from = e.screenX;
    state.progress = false;
    e.preventDefault();
    e.stopPropagation();
  }

  private eventScrollNative = () =>
  {
    const ref = this.scrollRef;
    if(ref.pointer && ref.scrollbar && ref.screen && ref.mapping)
    {
      const state = this.scrollState;
      const el = this.scrollRef.mapping;
      const scrollMax = el.scrollWidth - el.clientWidth;
      const ratio = el.scrollLeft / scrollMax;
      state.position = state.positionMax * ratio;
      ref.pointer.style.left = `${state.position}px`;
      if(this.scrollingTimeout) {
        window.clearTimeout(this.scrollingTimeout);
      }
      this.scrollingTimeout = window.setTimeout(() => this.setState({ scrollLeft: el.scrollLeft }), 66);
    }
  }

  private resize = () => {
    const ref = this.scrollRef;
    if(ref.mapping)
    {
      this.scrollState.enabled = ((ref.mapping.scrollWidth - ref.mapping.clientWidth) > 0) ? true : false;
      if(ref.pointer && ref.scrollbar && ref.screen)
      {
        const ratio = ref.mapping.clientWidth / ref.mapping.scrollWidth;
        ref.pointer.style.width = `${ref.scrollbar.clientWidth * ratio}px`;
        this.scrollState.positionMax = ref.scrollbar.clientWidth - ref.pointer.clientWidth;
        this.scrollState.ratio = ref.mapping.scrollWidth / ref.mapping.clientWidth;
      }
      this.setState({refreshingKey: this.state.refreshingKey + 1});
    }
  }

  /*
  private detectColumnsMapping = (data?: any[][]): MappingDefinition => {
    const targetColumns: TargetColumnDefinition[] = [];
    this.detector = new Detector(data ? data : this.state.data);
    this.detector.detect();
    const colsDetected = this.detector.getResults();
    if(colsDetected === null)
    {
      if(isDev) console.warn('Unable to detect any column');
      return { targetColumns, nameType: 'first_middle_last' };
    }
    for(const targetColumnId in targetColumnNames)
    {
      const targetColumn: TargetColumnDefinition = Object.assign({}, targetColumnNames[targetColumnId as targetColumnType]);
      const col = colsDetected.find((col: DetectorResult) => {
        return (col.type === targetColumn.name);
      });
      targetColumns.push(targetColumn);
      if(!col)
      {
        continue;
      }
      targetColumn.source = col.name;
      targetColumn.sourceIndex = col.index;
    }
    return { targetColumns: this.state.mappingDefinition.targetColumns, nameType: 'first_middle_last' };
  }
  */

  private processing()
  {
    switch(this.state.phase)
    {
      case 'no-data':
      case 'parsed':
        break;

      case 'loading':
        timer.start();
        const uploadedFile: UploadedFile | null = StoreHR.getUploadedFile();
        if(uploadedFile === null)
        {
          this.setState({phase: 'no-data'});
          return;
        }
        const dataContainer    = (StoreHR.getData() as DataContainer);
        const isCSV: boolean   = !!(StoreHR.getUploadedFile() && StoreHR.getUploadedFile()!.extPart.toLowerCase() === 'csv');
        dataContainer.setIsExcel(!isCSV);
        /* @DETECTION allowed columns for mapping disabled 
        let mappingDefinitionDetected = this.detectColumnsMapping(data);
        StoreHR.setMappingDetected(mappingDefinitionDetected);
        */
        const mappingDefinitionDetected = this.state.mappingDefinition; // @REMOVE after enable columns detection
        // @TODO - Detected columns disabled - not enough testing on real-life HR tables
        mappingDefinitionDetected.targetColumns = mappingDefinitionDetected.targetColumns.map(targetColumn => {
          targetColumn.source = null;
          targetColumn.sourceIndex = null;
          return targetColumn;
        });
        const mappingDefinition     = this.applyPreset(dataContainer, mappingDefinitionDetected);
        const targetColumns         = mappingDefinition.targetColumns;
        dataContainer.setMappingDefinition(mappingDefinition);

        // StoreHR.setMappingPreset(mappingDefinition);
        const isValidMapping        = Excel.isValidTargetColumnDefinition(targetColumns);
        const detectedColumns       = targetColumns.filter(targetColumn => targetColumn.sourceIndex !== null).map(targetColumn => targetColumn.title);
        this.setState({
          phase: 'parsed', 
          dataContainer,
          mappingDefinition, 
          detectedColumns,
          undetectableDates: dataContainer.getUndetectableDates(),
          isValidMapping, 
          /* @DETECTOR - temporary disabled
          allowedColumns: this.detector!.getAllowed(), 
          */
          loadingTime: this.state.loadingTime + timer.stop()
        });
        break;
    }
  }

  private applyPreset = (dataContainer: DataContainer, mappingDefinitionDetected: MappingDefinition): MappingDefinition => {
    const preset = this.preset;// localStorage.getItem(PRESET_STORAGE_KEY);
    const appliedIndexes: number[] = [];
    let mappingDefinition: MappingDefinition = { targetColumns: [], nameType: this.state.mappingDefinition.nameType, dateFormats: [] };
    if(preset)
    {
      try {
        const data = dataContainer.getData(true);
        mappingDefinition = (JSON.parse(preset) as MappingDefinition);
        const targetColumnsPreset = mappingDefinition.targetColumns;
        if(!targetColumnsPreset || data.length < 3 || targetColumnsPreset.length !== mappingDefinitionDetected.targetColumns.length)
        {
          throw new Error(`Preset empty or don't fit`);
        }
        
        // check if can apply - match all preset indexes and header names
        for(const targetColumnPreset of targetColumnsPreset)
        {
          if(targetColumnPreset.sourceIndex === null)
          {
            continue;
          }
          if(targetColumnPreset.sourceIndex > (data[0].length - 1) || data[0][targetColumnPreset.sourceIndex] !== targetColumnPreset.source)
          {
            throw new Error(`Preset not match`);
          }
        }
        // apply preset to autodetected targetColumns
        for(let i = 0, len = targetColumnsPreset.length; i < len; i++)
        {
          const targetColumnPreset   = targetColumnsPreset[i];
          const targetColumnDetected = mappingDefinitionDetected.targetColumns.find(targetColumn => targetColumn.name === targetColumnPreset.name);
          if(!targetColumnDetected || targetColumnPreset.source === null || targetColumnPreset.sourceIndex === null || appliedIndexes.includes(targetColumnPreset.sourceIndex) || projectConfig.isColumnHiddenByName(targetColumnPreset.name))
          {
            continue;
          }
          targetColumnDetected.source      = targetColumnPreset.source;
          targetColumnDetected.sourceIndex = targetColumnPreset.sourceIndex;
          appliedIndexes.push(targetColumnPreset.sourceIndex);
        }
        mappingDefinitionDetected.nameType = mappingDefinition.nameType;
        mappingDefinitionDetected.dateFormats = [];
      } catch(e) {
        if(isDev) console.warn(`Unable to parse preset definition: ${(e as Error).message}`);
      }
    }
    return mappingDefinitionDetected;
  }

  private mappingChanged = (mappingDefinitionNew: MappingDefinition) =>
  {
    const targetColumns = this.state.mappingDefinition.targetColumns;
    const dataContainer = (StoreHR.getData() as DataContainer);
    if(targetColumns.find(targetColumn => 'start_date' === targetColumn.name && targetColumn.sourceIndex === null) && mappingDefinitionNew.targetColumns.find(targetColumnNew => 'start_date' === targetColumnNew.name && targetColumnNew.sourceIndex !== null))
    {
      dataContainer.unassignUndetectableFormat('start_date');
    }
    if(targetColumns.find(targetColumn => 'end_date' === targetColumn.name && targetColumn.sourceIndex === null) && mappingDefinitionNew.targetColumns.find(targetColumnNew => 'end_date' === targetColumnNew.name && targetColumnNew.sourceIndex !== null))
    {
      dataContainer.unassignUndetectableFormat('end_date');
    }
    mappingDefinitionNew.dateFormats = dataContainer.getUndetectableFormats();
    localStorage.setItem(PRESET_STORAGE_KEY, JSON.stringify(mappingDefinitionNew));
    const isValid = Excel.isValidTargetColumnDefinition(mappingDefinitionNew.targetColumns);
    // if(isValid && this.validator)
    // {
    //   // @TODO Disabled for debug
    //   const result = this.validator.getAll(targetColumnsNew);
    //   if(isDev) console.log('Validator Excel:', JSON.stringify(result, null, 2));
    // }
    this.state.dataContainer.setMappingDefinition(mappingDefinitionNew);
    const undetectableDates = dataContainer.getUndetectableDates();
    this.setState({mappingDefinition: mappingDefinitionNew, isValidMapping: isValid, undetectableDates });
  }

  componentDidMount()
  {
    this.processing();
    this.scrollInitialize();
  }

  componentDidUpdate()
  {
    this.processing();
    this.scrollInitialize();
  }

  componentWillUnmount()
  {
    this.scrollUninitialize();
  }

  private getDefaultMapping = (): MappingDefinition => {
    const targetColumnNamesCopy = {...targetColumnNames};
    const targetColumns: TargetColumnDefinition[] = Object.values(targetColumnNamesCopy).map((element: TargetColumnDefinition) => {
      const newElement = {...element};
      newElement.source = null;
      newElement.sourceIndex = null;
      return newElement;
    });
    return { targetColumns, nameType: 'first_last', dateFormats: [] };
  }

  private resetMappingColumns = () => {
    this.setState({mappingDefinition: this.getDefaultMapping(), isValidMapping: false, mappingKey: this.state.mappingKey + 1})
  }

  private nextStepForward = () => {
    StoreHR.setMapping(this.state.mappingDefinition);
    const path = bypassValidation ? RouteList.finish.path : RouteList.validate.path;
    pathReplace(this.props.history, path.replace(':organizationId', getClientId() as string));
  }

  private getUndetectableDateModal = () => {
    const dataContainer = this.state.dataContainer;
    const undetectableDates = dataContainer.getUndetectableDates(true);
    if(undetectableDates.length === 0)
    {
      return null;
    }
    return (
      <ModalDateFormat 
        dataContainer={dataContainer}
        onSubmit={(dateFormats) => {
          for(const dateFormat of dateFormats)
          {
            if(dateFormat === null)
            {
              continue;
            }
            dataContainer.assignUndetectableFormat(dateFormat.name, dateFormat.format);
          }
          
          const mappingDefinitionNew = { ...this.state.mappingDefinition, dateFormats: dataContainer.getUndetectableFormats() }
          this.mappingChanged(mappingDefinitionNew);
          this.setState({ undetectableDates:  dataContainer.getUndetectableDates(true) }); 
        }}
      />
    )
  }

  render()
  {
    const { organizationId } = this.props.match.params;
    const data: any[][]  = this.state.dataContainer ? this.state.dataContainer.getData() : [];
    const isSelected = this.state.mappingDefinition.targetColumns.find((col: TargetColumnDefinition) => {
      return (col.source !== null && col.sourceIndex !== null);
    });
    const prevButton = { 
      onClick: () => {
        pathReplace(this.props.history, RouteList.welcome.path.replace(':organizationId', getClientId() as string))
      }, 
      disabled: false
    }

    const nextButton = {
      onClick: () => {
        if(this.state.isValidMapping)
        {
          this.nextStepForward();
          return;
        }
        this.setState({mappingForced: true});
      },
      disabled: !this.state.isValidMapping
    }
    return (
      <Container>
        <Header organizationId={organizationId} />
        <ProgressBar 
          step={2} 
          prevButton={prevButton} 
          nextButton={nextButton}
        />
        <Screen key={this.state.refreshingKey}>
          <MappingScreen ref={ref => this.scrollRef.screen = ref}>
            <Title title={`2. ${lang.progressStep2Title}`}>
              {this.getUndetectableDateModal()}
              {isSelected ? <MappingReset title={lang.buttonMappingReset} onClick={this.resetMappingColumns} /> : null}
            </Title>
            {(data && data.length) ? (
              <>
              {this.scrollState.enabled ? (
                <Scrollbar ref={ref => this.scrollRef.scrollbar = ref}>
                  <ScrollbarPointer ref={ref => this.scrollRef.pointer = ref}/>
                </Scrollbar>
              ) : null}
              <MappingWrapper ref={ref => {
                this.scrollRef.mapping = ref;
                this.scrollState.enabled = (ref && (ref.scrollWidth - ref.clientWidth) > 0) ? true : false;
              }} onScroll={this.eventScrollNative}>
                <Mapping 
                  key={`${mappingId}-${this.state.mappingKey}`}
                  sourceColumns={data[0]} 
                  sourceData={data}
                  mappingDefinition={this.state.mappingDefinition}
                  onChange={this.mappingChanged}
                  mappingForced={this.state.mappingForced}
                  allowedColumns={this.state.allowedColumns}
                  hiddenColumns={projectConfig.getConfig().hiddenColumns ?? []}
                  scrolling={this.state.scrolling}
                  scrollLeft={this.state.scrollLeft}
                />
                <MappingOverview
                  title={`${data.length ? (data.length - 1) : `No`} ${lang.mappingSummary} `}
                  titleReupload={lang.buttonMappingReupload}
                  onClick={() => prevButton.onClick()}
                />
              </MappingWrapper>
              </>
            ) : (
              null
            )}
          </MappingScreen>
        </Screen>
        {this.state.loadedModal ? (
          <ModalMapping 
            dataContainer={this.state.dataContainer}
            detectedColumns={this.state.detectedColumns}
            leftButton={{title: lang.buttonMappingReimport, type: 'outlined', onClick: () => {
              pathReplace(this.props.history, RouteList.welcome.path.replace(':organizationId', getClientId() as string))
            }}}
            rightButton={{title: lang.buttonMappingStart, type: 'contained', onClick: () => {
              this.setState({loadedModal: false});
              StoreHR.setWelcomeMessage(false);
            }}}
            onClose={() => {
              this.setState({loadedModal: false});
              StoreHR.setWelcomeMessage(false);
            }}
          />
        ) : null}
      </Container>
    )

  }
}

