import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Upload } from '../components';
import { theme } from '../components/ui';
import { 
  lang, 
  langErrorMsg,
  exportPublicKey,
  stripKey,
  AnonymizationSettings
} from '../libs';

interface AnonymizationKeyProp
{
  publicKey : CryptoKey | null;
  onUploaded: (value: AnonymizationSettings) => void;
}

const requiredAnonymizationKeys = '["anonymizationSalt","anonymizeExternalDomain","anonymizeExternalUsername","anonymizeInternalDomain","anonymizeInternalUsername","internalDomainList","rsaPublicKey"]';

const UnlockWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const Unlock = styled.div`
  width: 460px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  border-radius: 5px;
  background-color: ${theme.colors.white};
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
`;

const UnlockContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  text-align: center;
  align-items: center;
`;

const ErrorMessage = styled.p`
  /*border-radius: 3px;*/
  margin-bottom: 20px;
  background-color: ${theme.colors.lightRed};
  color: #7C3435;
  padding: 9px;
`;

const Title = styled.p`
  margin-bottom: 20px;
`;

let dragCounter = 0;

export const AnonymizationKey: FC<AnonymizationKeyProp> = ({
  publicKey,
  onUploaded
}) => {
  const fileSizeLimitMB = 1;
  const [dragging, setDragging] = useState<boolean>(false);
  const [anonymizationKeyError, setAnonymizationKeyError] = useState<string | null>(null);
  const [publicKeyRaw, setPublicKeyRaw] = useState<string | null>(null);

  const processPublicKey = async () => {
    if(publicKey === null)
    {
      setAnonymizationKeyError(langErrorMsg.anonymizationKeyInitEmptyPK);
      return;
    }
    try {
      const exportedAsString = await exportPublicKey(publicKey);
      setPublicKeyRaw(exportedAsString);
    } catch {
      setAnonymizationKeyError(langErrorMsg.anonymizationKeyInitMissmatchPK);
    }
  }
  useEffect(() => { processPublicKey() })
  /*
  example: 
  {
    "internalDomainList": ["timeisltd.com","yourdomain.com"],
    "anonymizeExternalUsername": true,
    "anonymizeExternalDomain": true,
    "anonymizeInternalUsername": true,
    "anonymizeInternalDomain": false,
    "anonymizationSalt": "1234",
    "rsaPublicKey": "abcd"
  }
  */
  const processAnonymizationKey = (keyJSON: string): false | AnonymizationSettings => {
    try {
      const key = JSON.parse(keyJSON);
      try {
        if(typeof key !== "object")
        {
          throw new Error('JSON is not object!');
        }
        const providedAnonymizationKeys = JSON.stringify((Object.keys(key)).sort());
        if(providedAnonymizationKeys !== requiredAnonymizationKeys)
        {
          console.warn(`:: providedAnonymizationKeys`, providedAnonymizationKeys)
          throw new Error('Anonymization key has missing required keys!');
        }
        if(typeof key.rsaPublicKey !== "string")
        {
          throw new Error('Anonymization key has invalid key - rsaPublicKey is not string!');
        }
        if(typeof key.anonymizationSalt !== "string")
        {
          throw new Error('Anonymization key has invalid key - anonymizationSalt is not string!');
        }
        if((typeof key.internalDomainList !== "object" || Object.prototype.toString.call(key.internalDomainList) !== '[object Array]'))
        {
          throw new Error('Anonymization key has invalid key - internalDomainList is not array!');
        }
        if((key.internalDomainList.filter((i: any) => typeof i !== "string")).length > 0)
        {
          throw new Error('Anonymization key has missing invalid key - internalDomainList does not contains string only!');
        }
        const anonymizeParts = [key.anonymizeExternalUsername, key.anonymizeExternalDomain, key.anonymizeInternalUsername, key.anonymizeInternalDomain];
        if((anonymizeParts.filter(i => typeof i !== "boolean")).length > 0)
        {
          throw new Error('Anonymization key has missing invalid key - anonymizeExternal/anonymizeInternal does not contains boolean only!');
        }
      } catch(error) {
        console.warn(`AnonymizationKey invalid!`, error);
        setAnonymizationKeyError(langErrorMsg.anonymizationKeyFileStructure);
        return false;
      }
      const publicKeyFile = stripKey(key.rsaPublicKey);
      if(publicKeyFile !== publicKeyRaw)
      {
        setAnonymizationKeyError(langErrorMsg.anonymizationKeyFileMissmatchPK);
        return false;
      }
      return key as AnonymizationSettings;
    } catch(error) {
      setAnonymizationKeyError(langErrorMsg.anonymizationKeyFileJSON);
      return false;
    }
  }

  const readAnonymizationKeyFile = (fileRef: File) =>
  {
    if(fileRef.size > (1000000 * fileSizeLimitMB))
    {
      setAnonymizationKeyError(`File is bigger than ${fileSizeLimitMB} MB`);
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const content = reader.result as string;
      const anonymizationSettings = processAnonymizationKey(content);
      if(anonymizationSettings)
      {
        if(anonymizationKeyError) setAnonymizationKeyError(null);
        onUploaded(anonymizationSettings);
      }
    }
    reader.onerror = () => {
      setAnonymizationKeyError(`Error while loading file`);
    }
    reader.readAsText(fileRef, 'UTF-8');
  }

  const dropHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    event.preventDefault();
    // if(this.state.loading) return;
    let droppedFile = null;
    if(event.dataTransfer.items)
    {
      for(let i = 0; i < event.dataTransfer.items.length; i++)
      {
        if(event.dataTransfer.items[i].kind !== 'file')
        {
          continue;
        }
        const file = event.dataTransfer.items[i].getAsFile();
        if(file === null)
        {
          continue;
        }
        droppedFile = file;
      }
    }
    else
    {
      for(let i = 0; i < event.dataTransfer.files.length; i++)
      {
        droppedFile = event.dataTransfer.files[i];
      }
    }
    if(droppedFile === null)
    {
      setDragging(false);
      return;
    }
    readAnonymizationKeyFile(droppedFile);
  }

  const dragEnterHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    if(dragCounter++ === 0) setDragging(true);
    event.preventDefault();
  }

  const dragExitHandler = (event: React.DragEvent<HTMLDivElement>) =>
  {
    if(--dragCounter === 0) setDragging(false);
    event.preventDefault();
    return;
  }


  return (
    <UnlockWrapper
      onDrop={dropHandler} 
      onDragEnter={dragEnterHandler} 
      onDragOver={(event: any) => event.preventDefault()}
      onDragLeave={dragExitHandler}
    >
    <Unlock className="anonymization-key">
      <UnlockContent>
        {anonymizationKeyError ? (
          <ErrorMessage className='anonymization-key-error'>{anonymizationKeyError}</ErrorMessage>
        ) : null}
        <Title>{lang.titleAnonymizationKey}</Title>
        <Upload 
          progress="#7fc53b"
          accept='.json'
          onChange={readAnonymizationKeyFile}
          arrayBuffer={false}
          disabled={dragging}
          buttonText={lang.buttonAnonymizationKeyUpload}
        />
      </UnlockContent>
    </Unlock>
  </UnlockWrapper>
  )
}

