import React, { FC, Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Text, theme } from '../../ui';
import { OptionsWrapper, OptionsPart, Option, OptionText, OptionAdditional, OptionAdditionalTitle, OptionAdditionalIcon } from './Option';
import ArrowDropDown from '@material-ui/icons/ArrowDropDownOutlined';
import CheckCircle from '@material-ui/icons/CheckCircleOutline';
import Info from '@material-ui/icons/InfoOutlined';

export interface OptionItem
{
  value    : string;
  disabled?: boolean;
  mapped?  : boolean;
  allowed? : boolean;
}

interface Prop {
  id?        : string;
  options    : OptionItem[];
  placeholder: string;
  onChange   : (value: number | null) => void;
  onRemap?   : (value: number | null) => void;
  onClick    : () => void;
  value?     : number | null;
  forced?    : boolean;
  icon?      : JSX.Element;
  open       : boolean;
}

const sizeWidth = 330;

type ButtonProps = React.ComponentProps<typeof Button>;

const SelectWrapper = styled.div``;

interface ButtonDropdownProps extends ButtonProps
{
  selected: boolean;
  open: boolean;
}

const ButtonDropdown: React.FC<ButtonDropdownProps> = styled(Button)`
  padding: 0px 10px;
  justify-content: space-between;
  background-color: ${theme.colors.superLightGray};
  &:hover {
    background-color: ${theme.colors.superLightGray};
  }

  ${(props: ButtonDropdownProps) => props.open && css`
    background-color: ${theme.colors.extraLightGray};
    &:hover {
      background-color: ${theme.colors.extraLightGray};
    }
  `}

  ${(props: ButtonDropdownProps) => props.selected && css`
    background-color: ${theme.colors.green};
    &:hover {
      background-color: ${theme.colors.green};
    }
  `}
`;

const ButtonDropdownTitle = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    div:nth-child(2) {
      margin-left: 5px;
    }
`;

type TextProps = React.ComponentProps<typeof Text>;

type TextDropdownProps = TextProps & {
  selected: boolean;
  open: boolean;
}

const TextDropdown: React.FC<TextDropdownProps> = styled(Text)`
  color: ${theme.colors.lightGray};
  &:hover {
    color: ${theme.colors.lightGray};
  }

  ${(props: TextDropdownProps) => props.open && css`
    color: ${theme.colors.darkGray};
    &:hover {
      color: ${theme.colors.darkGray};
    }
  `}

  ${(props: TextDropdownProps) => props.selected && css`
    color: ${theme.colors.white};
    &:hover {
      color: ${theme.colors.white};
    }
  `}
`;

interface IconArrowProps
{
  selected: boolean;
  open: boolean;
}

const IconArrow: React.FC<IconArrowProps> = styled.div`
  color: ${theme.colors.lightGray};
  &:hover {
    color: ${theme.colors.darkGray};
  }

  ${(props: ButtonDropdownProps) => props.open && css`
    color: ${theme.colors.darkGray};
    &:hover {
      color: ${theme.colors.darkGray};
    }
  `}

  ${(props: ButtonDropdownProps) => props.selected && css`
    color: ${theme.colors.white};
    &:hover {
      color: ${theme.colors.white};
    }
  `}
`;

const ButtonPart = styled.div`
  margin: 0;
  width: 100%;
`;

type TextIconProps = TextProps & {

}

const TextIcon: React.FC<TextIconProps> = styled(Text)`
  margin-top: 2px;
`;

export const Select: FC<Prop> = ({
  id = `select-${Math.random().toString(36).slice(2)}`,
  options = [],
  value = null,
  onClick,
  onChange,
  onRemap,
  icon,
  open,
  placeholder
}) => {
  let ref: any = null;

  const [isLeftAlign, setLeftAlign] = useState<boolean>(true);

  const processRef = (element: any) => {
    if(ref || !element) return;
    const width: number = window.innerWidth;
    const scroll: number = window.scrollX;
    ref = element;
    const rect = element.getBoundingClientRect();
    const position = rect.x;
    const leftAlign = ((width + scroll) >= (position + sizeWidth));
    setLeftAlign(leftAlign);
  }

  const selectValue = (index: number | null) => {
    onChange(index);
  }

  const unselectValue = () => {
    onChange(null);
  }

  const createOption = (key: string, option: OptionItem, index: number | null, selected: boolean): JSX.Element => {
    return (
      <Option 
        key={key}
        className="option"
        mapped={option.mapped} 
        allowed={option.allowed} 
        selected={selected} 
        onClick={() => {
          if(!option.allowed)
          {
            return;
          }
          if(selected)
          {
            unselectValue();
          }
          else if(option.mapped)
          {
            if(onRemap)
            {
              onRemap(index);
            }
            return;
          }
          else
          {
            selectValue(index);
          }
        }}
      >
        <OptionText size="xsmall" color="black">
          {option.value}
          <OptionAdditional>
            {option.mapped && option.allowed ? (
              <>
              {selected ? (
                <Fragment>
                  <OptionAdditionalTitle>Selected</OptionAdditionalTitle>
                  <OptionAdditionalIcon><CheckCircle fontSize="inherit" /></OptionAdditionalIcon>
                </Fragment>
              ) : (
                <Fragment>
                  <OptionAdditionalTitle>Mapped</OptionAdditionalTitle>
                  <OptionAdditionalIcon><Info fontSize="inherit" /></OptionAdditionalIcon>
                </Fragment>
              )}
              </>
            ) : null}
              {/*option.allowed ? 
                null : (
                <Fragment>
                  <OptionAdditionalTitle>{`Disabled`}</OptionAdditionalTitle>
                  <OptionAdditionalIcon><NotInterested fontSize={'inherit'} /></OptionAdditionalIcon>
                </Fragment>
              )*/}
          </OptionAdditional>
        </OptionText>
      </Option>
    )
  }

  const getOptionsElements = () => options.map((option: OptionItem, index: number) => {
    const key = `${id}-option-${id}-${index}`;
    return option.allowed ? createOption(key, option, index, value === index) : null;
  })

  const isFilled = (value !== null);
  const selected = value !== null;

  return (
    <Fragment>
      <SelectWrapper ref={processRef}>
        <ButtonPart>
          <ButtonDropdown
            className="button-dropdown"
            fullWidth 
            size="small" 
            selected={selected} 
            open={open} 
            onClick={onClick}
          >
            <ButtonDropdownTitle>
              <TextIcon size="small" color="white">
                {icon ? icon : '\u00A0'}
              </TextIcon>
              <TextDropdown 
                size="xxsmall" 
                color="white" 
                selected={selected} 
                open={open}
              >
                {(isFilled && options[value!]) ? options[value!].value : placeholder}
              </TextDropdown>
            </ButtonDropdownTitle>
            <IconArrow selected={selected} open={open}>
              <ArrowDropDown fontSize="inherit" />
            </IconArrow>
          </ButtonDropdown>
        </ButtonPart>
        {open ? (<OptionsWrapper isLeftAlign={isLeftAlign}>
          <OptionsPart className="options-part">{getOptionsElements()}</OptionsPart>
        </OptionsWrapper>) : null}
      </SelectWrapper>
    </Fragment>
  )
}