import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { TableColumnAlign, SortDirection } from '../../types';

import { SortingArrow } from './SortingArrow';
import { TableContext } from './TableContext';

interface Props {
  align?: TableColumnAlign;
  sortDirection?: SortDirection;
  onClick?: () => void;
  title?: string;
  size?: number; // Default 100
}

export const TableHeaderCell: FC<Props> = observer(({ align, sortDirection, onClick, title, size, children }) => {
  const { borders, dense, scrollable } = useContext(TableContext);

  return (
    <StyledHeaderCell
      onClick={onClick}
      align={align || 'left'}
      borders={borders}
      dense={dense}
      scrollable={scrollable}
      title={title}
      size={size || 100}
    >
      <div>{children}</div>
      <StyledSortingArrow direction={sortDirection} size={20} spacing={{ my: -2 }} />
    </StyledHeaderCell>
  );
});
// eslint-disable-next-line
interface StyledHeaderCellProps {
  align: TableColumnAlign;
  borders?: boolean;
  dense?: boolean;
  scrollable?: boolean;
  size: number;
}
export const StyledHeaderCell = styled.th<StyledHeaderCellProps>`
  flex: ${props => props.size} 0 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  font-size: ${props => props.theme.fontSize.xsmall};
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.veryLightGray};
  line-height: 1.5;
  text-transform: uppercase;

  padding: 12px ${props => (props.dense ? 10 : 20)}px;

  ${props =>
    props.align === 'center' &&
    css`
      justify-content: center;
      text-align: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      & > ${StyledSortingArrow} {
        margin-left: 0.25rem;
        margin-right: -1.5rem;
      }
    `}

  ${props =>
    props.align === 'right' &&
    css`
      flex-direction: row-reverse;
      text-align: right;
    `}

  ${props =>
    props.borders &&
    css`
      border-right: 1px solid #cfd8dc;
      border-bottom: 1px solid #cfd8dc;

      &:last-child {
        border-right: none;
      }
    `}

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
      user-select: none;
    `}

  ${props =>
    props.scrollable &&
    props.dense &&
    css`
      &:last-child {
        padding-right: 18px;
      }
    `}
`;

const StyledSortingArrow = styled(SortingArrow)``;
