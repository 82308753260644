import React, { Fragment } from 'react'
import styled from 'styled-components';
import { BoxScreen, Upload } from '../../../components';
import { Text } from '../../../components/ui';
import ReactMarkdown from 'react-markdown';
import { publicURL } from '../../../rest'
import { theme } from '../../ui';

export interface UploaderContentProps
{
  dragging         : boolean;
  uploadError      : string | null;
  uploadLimitMB    : number;
  accept           : string;
  readFile         : (fileRef: File) => void;
  onLoading        : () => void;
  uploadArrayBuffer: boolean;
  loading          : boolean;
  title            : string;
}

interface UploaderWrapperProps
{
  dragging: boolean;
  uploadError: string | null;
}

const UploaderError = styled.div`
  margin: 20px 20px 0;
  border: 1px solid ${theme.colors.red};
  padding: 12px 12px 10px;
  background-color: ${theme.colors.lightRed};
  font-size: 13px;
  color: ${theme.colors.red};

  & > p {
    line-height: 145%;
  }
  & > p, & > p a {
    color: ${theme.colors.darkGray};
  }
`;

const UploaderWrapper: React.FC<UploaderWrapperProps> = styled.div`
  margin: 20px;
  border: 2px dashed ${theme.colors.superLightGray};
  background-color: ${(props: UploaderWrapperProps) => props.dragging ? theme.colors.extraLightGray : theme.colors.white};
  ${(props: UploaderWrapperProps) => props.uploadError ? `margin-top: 10px` : `0`}
  ${(props: UploaderWrapperProps) => props.dragging ? `
    border: 2px dashed ${theme.colors.darkGray};
    background-color: ${theme.colors.superLightGray};
  ` : ``}
`;

const DnDImage = styled.div`
  background-image: url("${publicURL}/images/Import-HR-table.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 180px;
  height: 90px;
`;

const Title = styled.h2`
  
  margin: 20px 0 10px;
`;

const Notes = styled.p`
  text-align: center;
  font-size: 16px;
  border-bottom: 1px solid ${theme.colors.extraLightGray};
  padding-bottom: 27px;
`;

const Divider = styled.p`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.extraLightGray};
  padding: 7px 9px;
  margin-top: -17px;
  border-radius: 18px;
`;

const UploadWrapper = styled.div`
  margin-top: 20px;
`;

export const UploaderContent: React.FC<UploaderContentProps> = ({
  dragging,
  uploadError = null,
  uploadLimitMB,
  accept,
  readFile,
  onLoading,
  uploadArrayBuffer,
  loading,
  title
}) => {
  const error: JSX.Element | null = uploadError ? (
    <UploaderError className="uploader-error"><ReactMarkdown>{uploadError}</ReactMarkdown></UploaderError>
  ) : null;
  return (
    <Fragment>
      {error}
      <UploaderWrapper
        dragging={dragging}
        uploadError={uploadError}
      >
        <BoxScreen mx={50} mt={19} mb={22} opacity={dragging ? 0.3 : 1.0}>
          <DnDImage />
          <Title><Text size="medium">{title}</Text></Title>
          <Notes>XLS, XLSX or CSV file. {uploadLimitMB} MB max.</Notes>
          <Divider>or</Divider>
          <UploadWrapper>
            <Upload 
              progress="#7fc53b"
              accept={accept}
              onChange={readFile}
              onLoading={onLoading}
              arrayBuffer={uploadArrayBuffer}
              disabled={loading}
            />
          </UploadWrapper>
        </BoxScreen>
      </UploaderWrapper>
    </Fragment>
  )
}