import React from 'react';

export interface TableContextType {
  borders?: boolean;
  dense?: boolean;
  striped?: boolean;
  scrollable?: boolean;
}

export const TableContext = React.createContext<TableContextType>({});
