import React, { SVGAttributes } from 'react';
import styled, { css } from 'styled-components';

import { ThemeColor } from '../../theme';
import { applySpacingProps, SpacingProps } from '../../utils';

type IconSvgProps = Omit<SVGAttributes<SVGElement>, 'color' | 'spacing'> & {
  color?: ThemeColor;
  size?: number;
  spacing?: SpacingProps;
};

export interface IconProps extends IconSvgProps {
  className?: string;
}

const SVGComponent = React.forwardRef<SVGSVGElement, IconSvgProps>(({ color, size, spacing, ...props }, ref) => (
  <svg {...props} ref={ref} />
));
export const SVG = styled(SVGComponent)`
  flex-shrink: 0;

  ${({ color, theme }) =>
    color !== undefined &&
    css`
      color: ${theme.getColor(color)};
    `}

  ${({ size }) =>
    size !== undefined &&
    css`
      height: ${size}px;
      width: ${size}px;
    `}

  ${({ spacing }) =>
    spacing !== undefined &&
    css`
      ${applySpacingProps(spacing)};
    `}
`;
