import React, { FC } from 'react';

import { IconProps, SVG } from './utils';

export const IconCheck: FC<IconProps> = props => (
  <SVG {...props} width='24' height='24' viewBox='0 0 24 24'>
    <path
      d='M9.30388 17.7885L4.21274 12.6623C3.92909 12.3803 3.92909 11.9212 4.21274 11.6362L5.24115 10.6116C5.5248 10.3295 5.98591 10.3295 6.26956 10.6116L9.81881 14.2L17.7304 6.21153C18.0141 5.92949 18.4752 5.92949 18.7589 6.21153L19.7873 7.23765C20.0709 7.51969 20.0709 7.98025 19.7873 8.26078L10.3323 17.7885C10.0486 18.0705 9.58753 18.0705 9.30388 17.7885'
      fill='currentColor'
    />
  </SVG>
);
