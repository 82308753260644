import { Store } from '../libs/store';
import { Validator } from './validator';
import { DataContainer } from './DataContainer';
import {
  UploadedFile,
  MappingDefinition,
  ValidatorSummaryResult
} from '../libs/types';

const id                   = Math.random().toString(36).slice(2);
const keyUploadedFile      = `${id}-uploaded-file`;
const keyMapping           = `${id}-mapping`;
const keyMappingDetected   = `${id}-mapping-detected`;
const keyMappingPreset     = `${id}-mapping-preset`;
const keyValidationSummary = `${id}-validation-summary`;
const keyValidator         = `${id}-validator`;
const keyData              = `${id}-data`;
const keyWelcomeMessage    = `${id}-welcome-message`;
// const keyDataRaw           = `${id}-data-raw`;

export class StoreHR extends Store
{
  static resetUploadedFile()
  {
    Store.reset(keyUploadedFile);
  }

  static getUploadedFile(): UploadedFile | null
  {
    const uploadedFile = Store.get(keyUploadedFile);
    return uploadedFile ? uploadedFile as UploadedFile : null;
  }
  
  static setUploadedFile(uploadedFile: UploadedFile)
  {
    Store.set(keyUploadedFile, uploadedFile);
    StoreHR.setWelcomeMessage(true);
  }

  static resetData()
  {
    Store.reset(keyData);
  }

  static getData(): DataContainer 
  {
    let data = Store.get(keyData);
    if(!data)
    {
      data = new DataContainer();
      StoreHR.setData(data);
    }
    return data;
  }

  static setData(value: DataContainer) 
  {
    Store.set(keyData, value);
  }

  // static resetDataRaw()
  // {
  //   Store.reset(keyDataRaw);
  // }

  // static getDataRaw(): any[][] | null 
  // {
  //   const data = Store.get(keyDataRaw);
  //   return data ? data as any[][] : null;
  // }

  // static setDataRaw(value: any[][]) 
  // {
  //   Store.set(keyDataRaw, value);
  // }

  static resetMapping()
  {
    Store.reset(keyMapping);
  }

  static getMapping(): MappingDefinition | null 
  {
    const mapping = Store.get(keyMapping);
    return mapping ? mapping as MappingDefinition : null;
  }

  static setMapping(value: MappingDefinition) 
  {
    Store.set(keyMapping, value);
  }

  static resetMappingDetected()
  {
    Store.reset(keyMappingDetected);
  }

  static getMappingDetected(): MappingDefinition | null 
  {
    const mapping = Store.get(keyMappingDetected);
    return mapping ? mapping as MappingDefinition : null;
  }

  static setMappingDetected(value: MappingDefinition) 
  {
    Store.set(keyMappingDetected, value);
  }

  static resetMappingPreset()
  {
    Store.reset(keyMappingPreset);
  }

  static getMappingPreset(): MappingDefinition | null 
  {
    const mapping = Store.get(keyMappingPreset);
    return mapping ? mapping as MappingDefinition : null;
  }

  static setMappingPreset(value: MappingDefinition) 
  {
    Store.set(keyMappingPreset, value);
  }

  static resetValidationSummary()
  {
    Store.reset(keyValidationSummary);
  }

  static getValidationSummary(): ValidatorSummaryResult | null
  {
    const mapping = Store.get(keyValidationSummary);
    return mapping ? mapping as ValidatorSummaryResult : null;
  }

  static setValidationSummary(value: ValidatorSummaryResult) 
  {
    Store.set(keyValidationSummary, value);
  }

  static resetValidator()
  {
    Store.reset(keyValidator);
  }

  static getValidator(): Validator | null
  {
    const validator = Store.get(keyValidator);
    return validator ? validator as Validator : null;
  }

  static setValidator(value: Validator) 
  {
    Store.set(keyValidator, value);
  }

  static setWelcomeMessage(value: boolean)
  {
    Store.set(keyWelcomeMessage, value);
  }

  static getWelcomeMessage(): boolean | null
  {
    const value = Store.get(keyWelcomeMessage);
    return value ? value as boolean : null;
  }

}
