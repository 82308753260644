import React, { FC } from 'react';
import styled from 'styled-components';

interface Prop {
  title: string;
}

const CurrentStep = styled.div`
  width: 100%;
  margin: 33px 0 30px;
  user-select: none;
`;

const TitleElement = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;

export const Title: FC<Prop> = ({ title, children }) => (
  <CurrentStep>
    <TitleElement>{title}</TitleElement>
    {children}
  </CurrentStep>
)