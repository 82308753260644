import React, { FC } from 'react';

import { IconProps, SVG } from './utils';

export const IconChevronLeft: FC<IconProps> = props => (
  <SVG {...props} viewBox='0 0 24 24' height='24' width='24'>
    <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z' fill='currentColor' />
    <path d='M0 0h24v24H0z' fill='none' />
  </SVG>
);
