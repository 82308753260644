import * as React from 'react'
import Cookies from 'js-cookie';
import { nanoid } from 'nanoid';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';

import { ApiError, authEndpoints, localStore, loginUrls } from './auth-api';
import { LoginMethod } from './types';

const EXPIRATION_TIMEOUT_SAFETY_ADVANCE = 15000;
const DEMO_AUTH_COOKIE_KEY = 'til-demo-auth';

export type User = {
  email: string;
  name: string;
};

type AuthContextType = {
  logout: () => void;
  login: (method: LoginMethod) => void;
  loginDemo: (email: string) => void;
  exchange: (state: string, code: string) => void;
  isAuthenticated: boolean;
  user?: User;
  error?: Error;
  isLoading: boolean;
  token?: string;
  isDemoUser?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const createRedirectUrl = (authPagePath: string) => {
  if (typeof window === 'undefined') return;
  return `${window.location.protocol}//${window.location.host}${authPagePath}`;
};

function parseJwt<T>(token: string): T | undefined {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isLoading: false,
  login: noop,
  loginDemo: noop,
  logout: noop,
  exchange: noop,
});

type Props = {
  authUrl: string;
  loginPagePath: string;
  authPagePath: string;
  demoMode: boolean;
};

const demoLogin = Cookies.get(DEMO_AUTH_COOKIE_KEY);

export const AuthProvider: FC<Props> = ({ children, authUrl, loginPagePath, authPagePath, demoMode }) => {
  const [token, setToken] = useState<string | undefined>(() => {
    if (demoMode) return demoLogin;
  });
  const [error, setError] = useState<ApiError>();
  const [isDemoUser, setIsDemoUser] = useState(() => {
    if (demoMode && demoLogin) return true;
    return false;
  });
  const refreshTimeout = useRef<number>(null);

  const logout = useCallback(async () => {
    try {
      if (!isDemoUser) await authEndpoints['/token/revoke']()(authUrl);
      else {
        const isLocalhost = window.location.origin.startsWith('http://localhost');
        Cookies.remove(DEMO_AUTH_COOKIE_KEY, {
          domain: isLocalhost ? undefined : '.timeisltd.com',
          sameSite: isLocalhost ? undefined : 'None',
          secure: !isLocalhost,
        });
      }
      setToken(undefined);
      window.location.href = loginPagePath;
    } catch (e) {
      setError(e as ApiError);
    }
  }, [authUrl, loginPagePath, isDemoUser]);

  const login = useCallback(
    (method: LoginMethod) => {
      const nonce = nanoid();
      localStore.saveNonce(nonce, { method });
      const redirect_uri = createRedirectUrl(authPagePath);
      if (!redirect_uri) return;
      window.location.href = loginUrls['/auth']({ authUrl, redirect_uri, service: method, state: nonce });
    },
    [authUrl, authPagePath]
  );

  const loginDemo = useCallback((email: string) => {
    setIsDemoUser(true);
    setToken(email);
    const isLocalhost = window.location.origin.startsWith('http://localhost');
    Cookies.set(DEMO_AUTH_COOKIE_KEY, email, {
      domain: isLocalhost ? undefined : '.timeisltd.com',
      sameSite: isLocalhost ? undefined : 'None',
      secure: !isLocalhost,
    });
  }, []);

  const exchange = useCallback(
    async (state: string, code: string) => {
      const redirect_uri = createRedirectUrl(authPagePath);
      const nonceData = localStore.getNonce(state);
      localStore.deleteNonce(state);
      if (!redirect_uri || !nonceData) return;
      try {
        const token = await authEndpoints['/token']({ code, redirect_uri, service: nonceData.method })(authUrl);
        setToken(token);
      } catch (e) {
        setError(e as ApiError);
      }
    },
    [authUrl, authPagePath]
  );

  const refreshResult = useSWR('/token/refresh', () => authEndpoints['/token/refresh']()(authUrl), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: Boolean(token),
    isPaused: () => demoMode,
  });
  useEffect(() => {
    if (refreshResult.data) {
      setToken(refreshResult.data);
    }
  }, [refreshResult.data, setToken]);
  useEffect(() => {
    if (refreshResult.error) setError(refreshResult.error);
  }, [refreshResult.error, token, loginPagePath]);

  const checkResult = useSWR('/token/check', () => authEndpoints['/token/check']()(authUrl), {
    refreshInterval: 60000,
    isPaused: () => !token || demoMode,
  });
  useEffect(() => {
    if (checkResult.data === 'false') {
      setToken(undefined);
      window.location.href = loginPagePath;
    }
  }, [checkResult.data, logout, token, loginPagePath]);

  useEffect(() => {
    if (!token || isDemoUser || refreshTimeout.current !== null) return;
    const tokenPayload = parseJwt<{ exp: number; iat: number }>(token);
    if (!tokenPayload) return;

    const refreshTimeoutMs = (tokenPayload.exp - tokenPayload.iat) * 1_000 - EXPIRATION_TIMEOUT_SAFETY_ADVANCE;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    refreshTimeout.current = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refreshTimeout.current = null;
      refreshResult.mutate();
    }, refreshTimeoutMs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshResult.mutate, token]);

  const isAuthenticated = Boolean(token);
  const isLoading = !demoMode && !refreshResult.error && (!refreshResult.data || !token);
  const user = useMemo((): User | undefined => {
    if (!token) return;
    if (isDemoUser) return { email: token, name: token };
    return parseJwt<User>(token);
  }, [token, isDemoUser]);

  return (
    <AuthContext.Provider
      value={{
        logout,
        login,
        loginDemo,
        exchange,
        token,
        user,
        error,
        isAuthenticated,
        isLoading,
        isDemoUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
