import React, { FC, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { 
  Button
} from '../../../components';
import {
  lang,
} from '../../../libs';
import {
  ValidatorSummaryResult
} from '../../../libs/types';
import { theme } from '../../ui/theme';
import { publicURL } from '../../../rest';
import { SummaryBlock } from './SummaryBlock';

interface ButtonProps {
  onClick: () => any;
  title: string;
  disabled?: boolean;
}

interface ModalValidateProps {
  summary: ValidatorSummaryResult;
  downloadStatusButton: ButtonProps;
  reimportButton: ButtonProps;
  continueButton: ButtonProps;
}

const Results = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
`;

interface ResultsHeaderProps
{
  error?   : boolean;
  back?    : boolean;
}

const ResultsHeader: React.FC<ResultsHeaderProps> = styled.h2`
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  ${(props: ResultsHeaderProps) => props.error && css`
    margin: 0 130px 24px;
  `}

  ${(props: ResultsHeaderProps) => props.back && css`
    margin-bottom: 24px;
  `}
`;

interface ResultsDescriptionProps
{
  gapTop?: boolean;
  gapBottom?: boolean;
}

const ResultsDescription: React.FC<ResultsDescriptionProps> = styled.p`
  text-align: center;
  font-size: 13px;
  line-height: 15px;

  ${(props: ResultsDescriptionProps) => props.gapBottom && css`
    margin-bottom: 16px;
  `}

  ${(props: ResultsDescriptionProps) => props.gapBottom && css`
    margin-top: 16px;
  `}
`;

interface BlockProps
{
  isSummary?: boolean;
  isBack?: boolean;
}

const Block: React.FC<BlockProps> = styled.div`
  width: 640px;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: ${theme.colors.white};

  ${(props: BlockProps) => props.isSummary && css`
    padding: 34px 0 31px;
    margin-bottom: 20px;
  `}

  ${(props: BlockProps) => props.isBack && css`
    padding: 30px 0 21px;
  `}
`;

const BlockWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ModalValidate: FC<ModalValidateProps> = ({
  summary,
  downloadStatusButton,
  reimportButton,
  continueButton,
}) => {
  const isError: boolean = !!(summary && summary.error > 0);
  const isWarning: boolean = !!(summary && summary.warning > 0);
  const isErrorAndWarning: boolean = isError && isWarning;
  const isErrorOrWarning: boolean = isError || isWarning;
  return (
    <Fragment>
      <Results>
        <Block isSummary={isErrorOrWarning}>
          <BlockWrapper>
            {(isErrorAndWarning || isError) ? (
              <ResultsHeader error>{lang.titleValidateSummary}</ResultsHeader>
            ) : (null)}
            <SummaryBlock summary={summary} />
            <Button
              className="button-download-status"
              disabled={downloadStatusButton.disabled}
              text={downloadStatusButton.title ? downloadStatusButton.title : lang.buttonValidateStatusFile}
              onClick={downloadStatusButton.onClick}
            />
            <a href={`${publicURL}/files/HR_uploader_warnings-errors.pdf`} target="_blank" rel="noreferrer">
              <ResultsDescription gapBottom>{lang.titleDownloadHowToFix}</ResultsDescription>
            </a>
          </BlockWrapper>
        </Block>
        {isErrorOrWarning ? (
          <Block isBack>
            <BlockWrapper>
              <ResultsHeader back>{isError ? lang.titleValidateSummaryError : lang.titleValidateSummaryWarning}</ResultsHeader>
              <Button
                disabled={reimportButton.disabled}
                text={reimportButton.title}
                onClick={reimportButton.onClick}
                type="outlined"
              />
              {!isError ? (
                <>
                  <ResultsDescription gapBottom gapTop>or</ResultsDescription>
                  <Button
                    disabled={continueButton.disabled}
                    text={continueButton.title}
                    onClick={continueButton.onClick}
                  />
                </>
              ) : (null)}
            </BlockWrapper>
          </Block>
        ) : (null)}
      </Results>
    </Fragment>
  )}