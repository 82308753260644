import React, { Fragment/*, MouseEventHandler*/ } from 'react';
import { Button as ButtonUI, ButtonSize, ButtonType } from './ui/components';

export interface ButtonColor {
  color: string;
  border?: string;
  background: string;
}

export interface ButtonTheme {
  default: ButtonColor;
  disabled: ButtonColor;
  active: ButtonColor;
}

type ButtonProps = React.HTMLAttributes<unknown> & {
  onClick?      : () => void;
  text          : string;
  disabled?     : boolean;
  prepend?      : React.ReactNode;
  append?       : React.ReactNode;
  size?         : ButtonSize;
  type?         : ButtonType;
  fullWidth?    : boolean;
}

export const Button: React.FC<ButtonProps> = ({ 
  onClick,
  text,
  disabled = false,
  prepend = null,
  append = null,
  size = 'medium',
  type = 'contained',
  fullWidth,
  className = ''
}) => {
  const onClickCallback = disabled ? undefined : onClick;
  return (
    <Fragment>
      <ButtonUI
        onClick={onClickCallback}
        size={size}
        type={type}
        icon={prepend}
        iconRight={append}
        fullWidth={fullWidth}
        className={`button ${className && className}`}
      >
        {text}
      </ButtonUI>      
    </Fragment>
  )
}