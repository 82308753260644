import { dateType } from './types';

export const emailAliasSeparator = ',';
export const emailRE = /^[a-z0-9_-]+(\.[a-z0-9_-]+)*@{1,1}([a-z0-9]([a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]([a-z0-9-]*[a-z0-9])+$/i;
export const emailAliasRE = /(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]{2,})/i;
export const numberRE = /^(\d+(?:[,.]{1,1}\d+)?)$/;
export const numberFloatRE = /^\+?([01]{1,1}|1([,.]{1})0{0,}|0?([,.]{1})[0-9]+)$/;
export const numberFloatFTERE = /^\+?([012]{1,1}|2([,.]{1})0{0,}|[01]?([,.]{1})[0-9]+)$/; //0..2[.,]0
export const numberPercentFTERE = /^(0{0,2}[1-9]|0?[1-9][0-9]|1[0-9][0-9]|200)$/; //1 or 001..200
export const nullRE = /^\s*NULL\s*$/i;

/* Simple Comma separated emails RegEx https://www.regextester.com/99193
(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)
*/

export const dates: Record<dateType, RegExp> = {
  'd/m/y': /^(0{0,1}[1-9]|[12][0-9]|3[01])\/(0{0,1}[1-9]|1[012])\/((19|20){1})?[0-9]{2,2}$/,
  'm/d/y': /^(0{0,1}[1-9]|1[012])\/(0{0,1}[1-9]|[12][0-9]|3[01])\/((19|20){1})?[0-9]{2,2}$/,
  'd.m.y': /^(0{0,1}[1-9]|[12][0-9]|3[01])\.[\s]{0,}(0{0,1}[1-9]|1[012])\.[\s]{0,}(19|20)?[0-9]{2,2}$/,
  'y-m-d': /^(19|20){1}[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
}
