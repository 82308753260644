import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { SpacingProps, applySpacingProps } from '../../utils';

export interface InputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  icon?: React.ReactNode;
  className?: string;
  spacing?: SpacingProps;
}

export const Input = observer<InputProps, HTMLInputElement>(
  (props, ref) => {
    const { value, onChange, icon, placeholder, className, spacing } = props;

    return (
      <Wrapper className={className} spacing={spacing}>
        {icon && <Icon>{icon}</Icon>}
        <StyledInput ref={ref} value={value} onChange={e => onChange(e.target.value)} placeholder={placeholder} />
      </Wrapper>
    );
  },
  { forwardRef: true }
);

const Wrapper = styled.div<{ spacing?: SpacingProps }>`
  display: flex;
  width: 100%;
  ${props => applySpacingProps(props.spacing)};
`;

const Icon = styled.div`
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled.input`
  background: none;
  border: none;
  outline: none;
  font-size: ${props => props.theme.fontSize.small};
  height: 36px;
  line-height: 36px;
  padding: 0;
  color: ${props => props.theme.colors.white85};
  flex-grow: 1;
  margin: 0 12px;

  &::placeholder {
    color: ${props => props.theme.colors.white75};
  }
`;
