import React from 'react';
import styled from 'styled-components';

import { SpacingProps, applySpacingProps } from '../../utils';

interface Props {
  color: string;
  spacing?: SpacingProps;
}

const ColorBoxComponent = ({ spacing, ...props }: Props) => <div {...props} />;

export const ColorBox = styled(ColorBoxComponent)`
  height: 1em;
  width: 1em;
  background: ${props => props.color};
  flex-shrink: 0;

  ${props => applySpacingProps(props.spacing)};
`;
