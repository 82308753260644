import * as Encoding from 'encoding-japanese';

export const getEncodingFromBrowser = (buffer: ArrayBuffer): string => {
  const languageToEncoding: Record<string, string> = {
    'cs': 'CP1250',
    'sk': 'CP1250',
    'pl': 'CP1250',
    'ru': 'CP1251',
    'uk': 'CP1251',
    'be': 'CP1251',
    'bg': 'CP1251',
    'sr': 'CP1251',
    'mk': 'CP1251',
    'it': 'CP1252',
    'es': 'CP1252',
    'pt': 'CP1252',
    'fr': 'CP1252',
    'de': 'CP1252',
    'nl': 'CP1252',
    'da': 'CP1252',
    'se': 'CP1252',
    'nb': 'CP1252',
    'is': 'CP1252',
    'el': 'CP1253',
    'tr': 'CP1254',
    'he': 'CP1255',
    'en': 'CP1252',
  }
  const defaultEncoding = languageToEncoding.en;
  let encoding = defaultEncoding;
  for(const languageCode in languageToEncoding)
  {
    if(!navigator.languages.includes(languageCode))
    {
      continue;
    }
    encoding = languageToEncoding[languageCode];
    break;
  }
  if(encoding === 'CP1250')
  {
    encoding = detectOldEncoding(buffer);
  }
  return encoding;
}

export const detectOldEncoding = (buffer: ArrayBuffer): string => {
  // https://stackoverflow.com/questions/3895478/does-javascript-have-a-method-like-range-to-generate-a-range-within-the-supp
  // https://phpfashion.com/autoczech-aneb-automaticka-detekce-kodovani
  // https://en.wikipedia.org/wiki/ISO/IEC_8859-2
  // https://www.qqxiuzi.cn/wz/zixun/1669.htm
  // https://www.charset.org/img/charsets/iso-8859-2.gif
  const view = new Uint8Array(buffer);
  const CP1250 = Array.from(Array(33).keys()).map(i => i + 0x7f);
  CP1250.push(0xbc);
  let isCP1250 = false;
  for(let i = 0, len = view.length; i < len; i++)
  {
    if(view[i] < 0x7f) continue;
    if(CP1250.includes(view[i]))
    {
      isCP1250 = true;
      break;
    }
  }
  return isCP1250 ? 'CP1250' : 'ISO8859-2';
}

export const isUTF8 = (bytes: ArrayBuffer) => {
  return !isNotUTF8(bytes);
}

export const isNotUTF8 = (buf: ArrayBuffer) => {
  const bytes = new Uint8Array(buf);
  return Encoding.detect(bytes) !== 'UTF8';
}

const getCharLength = (theByte: number) => {
  // 4 bytes encoded char (mask 11110000)
  if (0xf0 === (theByte & 0xf0)) {
    return 4;
    // 3 bytes encoded char (mask 11100000)
  } else if (0xe0 === (theByte & 0xe0)) {
    return 3;
    // 2 bytes encoded char (mask 11000000)
  } else if (0xc0 === (theByte & 0xc0)) {
    return 2;
    // 1 bytes encoded char
  } else if (theByte === (theByte & 0x7f)) {
    return 1;
  }
  return 0;
}

export const getStringFromBytes = (buf: ArrayBuffer, checkOnly: boolean, strict?: boolean): string | null => {
  let charLength
  const chars = [];
  let byteOffset = 0;
  const bytes = new Uint8Array(buf);
  const byteLength = bytes.byteLength;
  for (; byteOffset < byteLength; byteOffset++) 
  {
    charLength = getCharLength(bytes[byteOffset]);
    if(byteOffset + charLength > byteLength) 
    {
      if(strict) 
      {
        throw Error(`Index ${byteOffset}: Found a ${charLength} bytes encoded char declaration but only ${(byteLength - byteOffset)} bytes are available.`);
      }
    } 
    else 
    {
      if(!checkOnly) chars.push(String.fromCodePoint(getCharCode(bytes, byteOffset, charLength)));
    }
    byteOffset += charLength - 1;
  }
  return (checkOnly) ? null : chars.join('');
}

const getCharCode = (bytes: Uint8Array, byteOffset: number, charLength: number) => {
  let charCode = 0; let mask = '';
  byteOffset = byteOffset || 0;
  // validate that the array has at least one byte in it
  if(bytes.length - byteOffset <= 0) 
  {
    throw new Error(`No more characters remaining in array.`);
  }
  // Retrieve charLength if not given
  charLength = charLength || getCharLength(bytes[byteOffset]);
  if(charLength === 0) 
  {
    throw new Error(`${bytes[byteOffset].toString(2)} is not a significative byte (offset: ${byteOffset}).`);
  }
  // Return byte value if charlength is 1
  if(1 === charLength) 
  {
    return bytes[byteOffset];
  }
  // validate that the array has enough bytes to make up this character
  if(bytes.length - byteOffset < charLength) 
  {
    throw new Error(`Expected at least ${charLength} bytes remaining in array.`);
  }
  // Test UTF8 integrity
  mask = '00000000'.slice(0, charLength) + 1 + '00000000'.slice(charLength + 1);
  if(bytes[byteOffset] & parseInt(mask, 2)) 
  {
    throw Error(`Index ${byteOffset}: A ${charLength} bytes encoded char cannot encode the ${(charLength + 1)}th rank bit to 1.`);
  }
  // Reading the first byte
  mask = '0000'.slice(0, charLength + 1) + '11111111'.slice(charLength + 1);
  charCode += (bytes[byteOffset] & parseInt(mask, 2)) << (--charLength * 6);
  // Reading the next bytes
  while(charLength) 
  {
    if(0x80 !== (bytes[byteOffset + 1] & 0x80) || 0x40 === (bytes[byteOffset + 1] & 0x40)) 
    {
      throw Error(`Index ${(byteOffset + 1)}: Next bytes of encoded char must begin with a "10" bit sequence.`);
    }
    charCode += (bytes[++byteOffset] & 0x3f) << (--charLength * 6);
  }
  return charCode;
}

// https://developer.mozilla.org/en-US/docs/Web/API/TextEncoder
export const encodePolyfill = (str?: string) => 
{
  if(!str) return new Uint8Array();
  const Len = str.length;
  let resPos = -1;
  // The Uint8Array's length must be at least 3x the length of the string because an invalid UTF-16
  //  takes up the equivelent space of 3 UTF-8 characters to encode it properly. However, Array's
  //  have an auto expanding length and 1.5x should be just the right balance for most uses.
  const resArr = new Uint8Array(Len * 3);
  for (let point=0, nextcode=0, i = 0; i !== Len; ) {
    point = str.charCodeAt(i);
    i += 1;
    if(point >= 0xD800 && point <= 0xDBFF)
    {
      if(i === Len)
      {
        resArr[resPos += 1] = 0xef/*0b11101111*/; resArr[resPos += 1] = 0xbf/*0b10111111*/;
        resArr[resPos += 1] = 0xbd/*0b10111101*/; break;
      }
      // https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
      nextcode = str.charCodeAt(i);
      if(nextcode >= 0xDC00 && nextcode <= 0xDFFF)
      {
        point = (point - 0xD800) * 0x400 + nextcode - 0xDC00 + 0x10000;
        i += 1;
        if(point > 0xffff) 
        {
          resArr[resPos += 1] = (0x1e/*0b11110*/<<3) | (point>>>18);
          resArr[resPos += 1] = (0x2/*0b10*/<<6)     | ((point>>>12)&0x3f/*0b00111111*/);
          resArr[resPos += 1] = (0x2/*0b10*/<<6)     | ((point>>>6)&0x3f/*0b00111111*/);
          resArr[resPos += 1] = (0x2/*0b10*/<<6)     | (point&0x3f/*0b00111111*/);
          continue;
        }
      } else {
        resArr[resPos += 1] = 0xef/*0b11101111*/; resArr[resPos += 1] = 0xbf/*0b10111111*/;
        resArr[resPos += 1] = 0xbd/*0b10111101*/; continue;
      }
    }
    if(point <= 0x007f)
    {
      resArr[resPos += 1] = (0x0/*0b0*/<<7) | point;
    }
    else if(point <= 0x07ff)
    {
      resArr[resPos += 1] = (0x6/*0b110*/<<5) | (point>>>6);
      resArr[resPos += 1] = (0x2/*0b10*/<<6)  | (point&0x3f/*0b00111111*/);
    }
    else
    {
      resArr[resPos += 1] = (0xe/*0b1110*/<<4) | (point>>>12);
      resArr[resPos += 1] = (0x2/*0b10*/<<6)   | ((point>>>6)&0x3f/*0b00111111*/);
      resArr[resPos += 1] = (0x2/*0b10*/<<6)   | (point&0x3f/*0b00111111*/);
    }
  }
  return resArr.subarray(0, resPos + 1);
}