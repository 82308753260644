export class Timer
{
  private startTime: Date;

  constructor()
  {
    this.startTime = new Date();
  }

  start()
  {
    this.startTime = new Date();
  }

  stop(inSeconds: boolean = true)
  {
    const endTime = new Date();
    const diff = endTime.getTime() - this.startTime.getTime();
    return (inSeconds) ? diff / 1000 : diff;
  }

  meantime(inSeconds: boolean = true)
  {
    const time = this.stop(inSeconds);
    this.start();
    return time;
  }
}